import React from 'react';
import { Contacts } from '../../helpers/LayoutTexts';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as Phone } from '../../assets/svg/phone.svg';
import { ReactComponent as Mail } from '../../assets/svg/mail.svg';

export interface Props {
    styles: any;
};

export const Communication: React.FC<Props> = (props) => {
    const styles = props.styles;

    return (        
        <div style={styles.container}>            
            <div>
                <div style={styles.title}><Location style={styles.icon}/>{"Mailing Address"}</div>
                <div style={styles.value}>{Contacts.address.no}</div>
                <div style={styles.value}>{Contacts.address.road}</div>
                <div style={styles.value}>{Contacts.address.city}</div>
            </div>
            <div>
                <div style={styles.title}><Phone style={styles.icon}/>{"Phone"}</div>
                <div><a style={styles.value} href={`tel:${Contacts.telephone}`}>{Contacts.telephone}</a></div>                                          
            </div>
            <div>
                <div style={styles.title}><Mail style={styles.icon}/>{"Email"}</div>
                <div><a style={styles.value} href={`mailto:${Contacts.email}`}>{Contacts.email}</a></div>
            </div>
        </div>
    );
}