export const logoGride = {
    mobile: {
        width: "100%",
        marginTop: "19.32vw",
        logo: {
            width: "16.66vw",
            height: "16.66vw",
            background: "transparent",
            borderRadius: "2.77vw",
            objectFit: "contain",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            marginRight: "6.03vw",
            normal: {
                WebkitFilter: "none",
                filter: "none",
                opacity: "1"
            },
            hover: {
                WebkitFilter: "unset",
                filter: "unset",
                opacity: "1"
            }
        },
        row: {
            display: "flex",
            overflowX: "scroll",
            justifyContent: "center",
            paddingLeft: "20vw"
        },
        nextRow: {
            marginTop: "3.6vw",
        },
        loaderBox: {
            width: "16.66vw",
            height: "16.66vw",
            display: "inline-block",
            marginRight: "6.03vw",
            marginTop: "3.6vw"
        },
        loaderContainer: {
            marginTop: "15.72vw"
        }
    },
    desktop: {
        marginTop: "6.25vw",
        logo: {
            width: "5.2vw",
            height: "5.2vw",
            borderRadius: "0.52vw",
            marginRight: "2.34vw",
            normal: {
                WebkitFilter: "grayscale(1)",
                filter: "grayscale(1)",
                opacity: "0.5"
            }
        },
        row: {
            paddingLeft: "0"
        },
        nextRow: {
            marginTop: "1.30vw",
        },
        loaderBox: {
            width: "5.2vw",
            height: "5.2vw",
            marginRight: "2.34vw",
            marginTop: "1.30vw"
        },
        loaderContainer: {
            marginTop: "4.90vw"
        }
    }
}