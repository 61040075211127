import React from 'react';
import { JobApplyPageTexts as Texts } from '../../helpers/LayoutTexts';
import { PopUpWindow } from '../Shared/PopUpWindow';

interface Props {
    open: boolean;
    styles: any;
    toggle(): void;
}

export const Guidelines: React.FC<Props> = (props) => {
    return (
        <PopUpWindow toggle={props.toggle} styles={props.styles.dialog} open={props.open} closeCircle={true}>
            <h3 style={props.styles.title}>{Texts.guideLinesTitle}</h3>
            <div style={props.styles.content}>
                <ul style={props.styles.list}>
                    {Texts.guideLines.map((item, index) => 
                        <li key={index} style={props.styles.listItem}>
                            <div style={props.styles.bullet}></div>
                            <div>{item}</div>
                        </li>)
                    }
                </ul>
            </div>
        </PopUpWindow>
    )
}