import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const candidateHomePage = {
    mobile: {
        appliedJobs: {
            marginTop: "18.11vw",
            titleWrapper: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            },
            select: {
                display: "none"
            },
            sliderButtonWrapper: {
                display: "none"
            },
            redirectButton: {
                display: "flex",                
                padding: "0",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                color: Color.Blue,
                fontWeight: "bold",
                fontSize: "3.86vw",
                margin: "auto 4.83vw auto auto",
                icon: {
                    fill: Color.Blue,
                    marginLeft: "1.5vw"
                }  
            }
        },
        recommendedJobs: {
           position: "relative"
        },
        appliedJobsSeeAll: {  
            display: "none",          
            width: "14.74vw",
            height: "3.64vw",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "0.52vw",
            cursor: "pointer",
            color: Color.White,
            fontFamily: Font.Default,
            fontSize:"1.04vw",
            fontWeight: "bold" as "bold",
            marginLeft: "7.29vw"              
        },        
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            marginTop: "9.66vw"
        },
        leftWrapper: {            
            width: "90%",
            overflow: "hidden",
            marginTop: "auto",
            marginLeft: "4.83vw",
            marginRight: "4.83vw" 
        },
        rightWrapper: {
            display: "none",
            width: "19.94vw",
            marginLeft: "auto",
            marginTop: "7.44vw"
        },
        flippingWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        verifyDialog: {
            position: "fixed" as "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0",
            transition: "transform 0.3s ease-out",
            zIndex: "2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: {
                position: "absolute" as "absolute",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0",
                backgroundColor: Color.Black,
                opacity: 0.4
            },
            container: {
                position: "relative",
                backgroundColor: Color.White,
                borderRadius: "2.41vw",
                padding: "2.41vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "73.50%",
                height: "21.40%"
            },
            close: {
                position: "absolute" as "absolute",
                width: "6.28vw",
                height: "6.28vw",
                top: "2vw",
                right: "3vw",
                cursor: "pointer",
                zIndex: "3"
            },
            text: {
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "4vw",
                lineHeight: "1.5",
                width: "83%"   
            }
        }
    },
    desktop: {
        appliedJobs:{
            marginTop:"2.54vw",
            redirectButton: {
                display: "none"
            }
        },
        appliedJobsSeeAll: {
            display: "block" 
        },
        baseWrapper: {
            flexDirection: "row" as "row",
            marginTop:"3.32vw",
            marginLeft: "7.29vw",
            marginRight:"7.29vw",
            fontSize:"1.04vw"
        },
        jobCategories: {
            marginTop: "6.4vw"
        },
        leftWrapper: {
            width: "auto",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "0"      
        },
        noSavedJobsWrapper: {
            width: "100%",
            marginBottom:"-11vw" 
        },
        rightWrapper: {
            display: "block"   
        },
        flippingWrapper: {
            flexDirection: "column-reverse"
        },
        verifyDialog: {
            container: {
                borderRadius: "0.52vw",
                height: "19.20%",
                width: "57.50%",
                padding: "1vw 3vw"
            },
            close: {
                borderRadius: "50%",
                width: "1.8vw",
                height: "1.8vw",
                top: "0.73vw",
                right: "0.73vw"
            },
            text: {
                fontSize: "1.10vw",
                lineHeight: "1.5vw",
                width: "85%"
            }
        }
    }
}