import React from 'react';
import { Recruiter } from '../models/Recruiter';
import { LoggedCandidate } from '../models/LoggedCandidate';
import CryptoJS from 'crypto-js';

export interface AuthContextType {
    setLoggedCandidate(loggedCandidate:any, token?: string): any;        
    getLoggedCandidate(): any;
    getLoggedRecruiter(): any;
}

export const AuthContext = React.createContext<AuthContextType>({ 
    setLoggedCandidate: () => {},       
    getLoggedCandidate: () => {},
    getLoggedRecruiter: () => {}
});

export const AuthContextProvider: React.FC = (props) => {    

    const setCandidate = (loggedCandidate: any, token?: string) => {
        const candidateString = JSON.stringify(loggedCandidate);
        const encryptedCandidateString = CryptoJS.AES.encrypt(candidateString, "").toString();
        localStorage.setItem('candidate', encryptedCandidateString);    
        localStorage.setItem('savedJobIds', JSON.stringify(loggedCandidate.savedJobIds)); 
        if(token) {  
            localStorage.setItem('token', token);   
        } 
    }

    const getCandidate = (): LoggedCandidate | null => {           
        const candidateString = localStorage.getItem("candidate"); 
        const token = localStorage.getItem("token");           
        if(candidateString !== null) {
            const decryptedCandidateString = CryptoJS.AES.decrypt(candidateString, "").toString(CryptoJS.enc.Utf8);            
            const candidate = JSON.parse(decryptedCandidateString);
            candidate.token = token;
            return candidate;
        } else {
            return null;
        }       
    }

    const getRecruiter = (): Recruiter | null => {           
        return null;        
    }

    return (
        <AuthContext.Provider value={{     
            setLoggedCandidate: setCandidate,                   
            getLoggedCandidate: getCandidate,
            getLoggedRecruiter: getRecruiter
        }}>           
            {props.children}
        </AuthContext.Provider>
    );
}