import { useContext, useState } from "react";
import { Button } from "../Shared/Buttons/Button";
import { TextInput } from "../Shared/Inputs/TextInput";
import { EmailPasswordModel } from "../../models/EmailPasswordModel";
import { updateCandidateEmail } from "../../services/CandidateService";
import useErrorPage from "../../hooks/useErrorPage";
import { InputValidation } from "../../models/InputValidation";
import { validateEmail } from "../../helpers/Common";
import { ResponseModel } from "../../models/ResponseModel";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import { ChangeEmailLoader } from "../Loaders/ChangeEmailLoader";

interface Props {
    candidate: LoggedCandidate;
    email: string;
    styles: any;
    openAlert(status: string, message: string): void;
}

export const Email: React.FC<Props> = (props) => {

    const intialFormInputs: EmailPasswordModel = {
        candidateId: props.candidate.candidateId,
        email: props.email,
        newEmail: ""
    }
  
    const [emailModel, setEmailModel] = useState<EmailPasswordModel>(intialFormInputs);
    const [validation, setValidation] = useState<InputValidation>();
    const styles = props.styles;
    const [toErrorPage] = useErrorPage();
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setEmailModel((values: any) => ({ ...values, [name]: value }));
        if(validation?.invalid) {
            validateInput(value);
        }        
    }

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement, Element>){
        let value = event.target.value;
        validateInput(value);
    }

    const validateInput = (value: string) => {
        if (value === "") {
            setValidation({ valid: false, invalid: true, invalidMessage: "Email is required" });
        } 
        else if (!validateEmail(value)) {
            setValidation({ valid: false, invalid: true, invalidMessage: "Invalid email address!" });
        } 
        else {
            setValidation({ valid: true, invalid: false });
        }
    }


    async function handleUpdateClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if(validation?.valid) {
            setLoading(true);        
            await updateCandidateEmail(emailModel, props.candidate.token)
            .then((response: ResponseModel) => {
                if (response.status) {
                    props.openAlert("success", response.message);
                    authContext.setLoggedCandidate(response.data);    
                    let formInputs: EmailPasswordModel = {
                        candidateId: props.candidate.candidateId,
                        email: emailModel.newEmail,
                        newEmail: ""
                    };
                    setEmailModel(formInputs);   
                    setValidation({});
                          
                }
                else {
                    props.openAlert("error", response.message);
                }
                setLoading(false);
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
        else {
            validateInput(emailModel.newEmail!);
        }
    }

    return (
        <div style={styles}>
            {loading ? <ChangeEmailLoader/> :
            <>            
                <div style={styles.title}>{"Change Email"}</div>            
                <form style={styles.formWrapper} onSubmit={handleUpdateClick}>                                
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"Current Email"}</label>                    
                        <TextInput
                            inputType={"text"}
                            name={"email"}
                            value={emailModel.email}
                            styles={styles.textInput}
                            disabled={true}
                        />                    
                    </div>
                    <hr style={styles.line} />
                    <div style={styles.inputWrapper}>
                        <label style={styles.label}>{"New Email"}</label>
                        <TextInput
                            inputType={"text"}
                            name={"newEmail"}
                            value={emailModel.newEmail}
                            styles={styles.textInput}
                            handleChange={handleInputChange}
                            validation={validation}
                            handleBlur={handleInputBlur}
                        />
                    </div>                                       
                    <Button type="submit" style={styles.updateButton} onClick={handleUpdateClick}>{"UPDATE EMAIL"}</Button>                
                </form>
            </>}     
        </div>
    );
}