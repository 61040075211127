import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Icon } from '../../assets/svg/search.svg';
import { Button } from './Buttons/Button';
import { LocationSelect } from './LocationSelect';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';

interface Props {     
    placeholder?: string;
    inputValue: string;
    setInputValue(inputValue: string): void;
    selectEnable?: boolean;
    selectedIds?: string;
    setSelectedIds?(selectedIds: string): void;
    setSelectedNames?(selectedNames: string): void;
    buttonText?: string;         
    handleSearch?(event: React.ChangeEvent<HTMLFormElement>): void;    
    isJobsPage?: boolean;
};

export const SearchArea: React.FC<Props> = (props) => {
    const [selectHovered, setSelectHovered] = useState<boolean>(false);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchArea"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let style = styleContext.getComponentStyle("searchArea");
        setStyles(style);
    }, [isMobile]);
    
    return (
        <>
            <form style={{...styles, ...(selectHovered && styles.overlay), ...(props.isJobsPage && styles.jobsMargin)}} onSubmit={props.handleSearch}>            
                <input style={styles.input} placeholder={props.placeholder} value={props.inputValue} onChange={event => props.setInputValue(event.currentTarget.value)}/>
                {props.selectEnable && <LocationSelect styles={styles.select} default={"Location"} selectedIds={props.selectedIds!} getSelectedIds={props.setSelectedIds} getSelectedNames={props.setSelectedNames} setSelectHovered={setSelectHovered}/>}
                <Button type="submit" style={{...styles.button, ...(props.isJobsPage && styles.button.green)}}>
                    <div style={styles.button.buttonWrapper}>
                        <div style={styles.button.buttonText}>{props.buttonText}</div>
                        <Icon style={styles.button.buttonIcon} />
                    </div>                
                </Button>
            </form>            
        </>
    );
}