import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const cvFormatsPage = {
    mobile: {
        container: {
            position: "relative" as "relative",
            margin: "5.80vw 5% 17.6vw 5%",
            zIndex: 1
        },
        text: {
            fontFamily: Font.Poppins,
            fontSize: "3.38vw",
            margin: 0,
            color: Color.MiddleBlack,
            textAlign: "justify"
        },
        toggleWrapper: {
            display: "flex" as "flex",
            flexDirection: "column" as "column"
        },
        leftWrapper: {
            width: "100%"
        },
        rightWrapper: {
            display: "none"
        },
        heading: {
            fontSize: "6.04vw",
            margin: "9.66vw 0 2.41vw 0",
            color: Color.LightBlack
        },
        select: {            
            width: "100%",
            margin: "0",
            label: {                
                fontSize: "3.62vw",
                fontWeight: "normal"
            },
            button: {
                height: "3.91vh",
                width: "27.53vw",
                marginLeft: "0"                              
            },
            buttonWrapper: {
                marginTop: "0"                
            },                        
            hover: {
                width: "27.53vw"
            } 
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "5.80vw 2.41vw",
            marginTop: "7.49vw"
        },
        cvWrapper: {
            position: "relative" as "relative",
            width: "43.96vw",
            height: "56.52vw",
            borderRadius: "2.41vw",
            cursor: "pointer",
            border: "0.24vw solid" + Color.LightSilver
        },
        image: {
            width: "100%",
            height: "100%",
            borderRadius: "2.41vw",
            objectFit: "cover"            
        },
        layer: {
            position: "absolute" as "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            background: "transparent linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%) 0% 100% no-repeat padding-box",    
            border: "0.24vw solid " + Color.MiddleAsh,        
            borderRadius: "2.41vw",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            height: "46.61vw",
            borderTop: "none"
        },
        detailWrapper: {
            position: "absolute" as "absolute",
            left: "1.93vw",            
            bottom: "1.12vh",            
            color: Color.White,            
            fontSize: "3.86vw"            
        },
        price: {            
            fontWeight: "bold" as "bold",
            margin: "0 0 0.45vh 0"
        },
        name: {            
            margin: "0 0 0.45vh 0"
        },
        description: {
            fontSize: "2.90vw",                   
            margin: 0                  
        },
        button: {
            width: "100%",
            height: "6.03vw",
            border: "1vw solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",
            fontWeight: "bold" as "bold",
            fontSize: "2.90vw",
            borderRadius: "1.21vw",
            marginTop: "2.89vw",
            disable: {
                border: "none",
                color: Color.SharpAsh,
                background: Color.LightAsh
            }
        },
        cartWrapper: {
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            height: "0",
            zIndex: "4"
        },
        cartButton: {
            position: "fixed" as "fixed",
            bottom: "3.38vw",
            right: "6%",
            height: "12.80vw",
            width: "12.80vw",
            borderRadius: "50%",            
            background: Color.Blue,
            cartIcon: {
                width: "6.08vw",
                height: "5.62vw"
            }
        },
        cartBadge: {
            position: "absolute" as "absolute",
            height: "5.07vw",
            width: "5.07vw",
            top: "-0.78vh",
            right: "-1.21vw",
            lineHeight: "4.83vw",
            borderRadius: "50%",
            background: Color.DeepBlack,
            fontSize: "2.90vw",
            color: Color.White
        },
        mobileCartWrapper: {
            border: "0.24vw solid" + Color.Black,
            marginTop: "7.25vw"
        },
        mobileCart: {
            margin: "7.25vw 4.83vw 3.57vh 4.83vw",
            checkoutButton: {
                width: "100%",
                height: "5.58vh",
                border: "none",
                color: Color.White,
                background: Color.Green,
                fontWeight: "bold" as "bold",
                fontSize: "4.35vw",
                borderRadius: "2.41vw",
                marginTop: "20vw"
            },
            emptyCart: {
                width: "100%",
                height: "16.94vw",
                marginBottom: "20vw"
            },
            emptyCartIconGride: {
                display: "flex",
                justifyContent: "center",
                margin: "3.57vh auto"       
            }
        },
        drawerClose: {
            width: "6.04vw",
            height: "6.04vw",
            margin: "3.57vh 0 0 6.52vw"
        },
        cart: {
            margin: "14.50vw 4.83vw 3.57vh 4.83vw",            
            heading: {
                fontSize: "6.04vw",                
                margin: "0 0 5.56vw 0", 
                color: Color.LightBlack
            },            
            cartItemsContainer: {
                maxHeight: "50vh",
                overflowY: "auto"
            }, 
            itemWrapper: {
                display: "flex" as "flex",
                flexDirection: "row" as "row",
                height: "15.94vw",
                border: "0.24vw solid " + Color.LightSilver,
                borderRadius: "2.41vw",
                alignItems: "center" as "center",
                background: Color.White,
                marginBottom: "1.45vw"
            },
            image: {
                width: "12.08vw",
                height: "7.37vh",
                borderRadius: "2.41vw 0 0 2.41vw",
                marginRight: "1.93vw"
            },
            name: {
                margin: 0,
                width: "38.64vw",
                fontSize: "3.86vw"
            },
            description: {
                margin: 0,
                fontSize: "2.90vw"
            },
            cvPrice: {
                marginLeft: "auto",
                fontSize: "3.86vw",
                fontWeight: "bold" as "bold",
                color: Color.SharpAsh
            },
            closeIcon: {
                border: "none",
                width: "5.5vw",
                height: "5.5vw",
                background: "transparent",
                margin: "0 2.41vw",
                cursor: "pointer",
                fill:Color.Gainsboro
            },
            line: {
                border: "none",
                height: "0.22vh",
                backgroundColor: Color.Gainsboro,
                margin: "8.82vw 0 4.95vw 0"
            },
            total: {
                display: "flex" as "flex",
                fontSize: "4.35vw",
                alignItems: "center" as "center",
                margin: 0,
                price: {                    
                    fontSize: "6.76vw",
                    fontWeight: "bold" as "bold",
                    marginLeft: "auto"
                }
            },
            checkoutButton: {
                width: "100%",
                height: "5.58vh",
                border: "none",
                color: Color.White,
                background: Color.Green,
                fontWeight: "bold" as "bold",
                fontSize: "4.35vw",
                borderRadius: "2.41vw",
                marginTop: "10vw",
                marginBottom: "61.50vw"
            },
            emptyCart: {
                width: "100%",
                height: "16.94vw"
            },
            iconGride: {
                display: "flex",
                justifyContent: "center",
                margin: "3.57vh auto"
            },
            emptyCartIconGride: {
                position: "absolute",
                bottom: "7.25vw",
                left: "23%"
            },
            paymentIcon: {
                maxWidth: "15.21vw",
                maxHeight: "4.83vw",
                margin: "0 1.93vw 0 0",
                WebkitFilter: "grayscale(1)", 
                filter: "grayscale(1)"
            }
        },
        cvPopUp: {
            position: "fixed" as "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: "0",
            zIndex: "3",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundLayer: {
                position: "absolute" as "absolute",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0",
                backgroundColor: Color.Black,
                opacity: 0.4
            },
            container: {
                position: "absolute",                
                width: "90.58vw",
                height: "73.10vh",
                padding: 0,
                margin: "auto",
                backgroundColor: Color.White,
                borderRadius: "2.41vw",
                fullscreen: {
                    height: "77vh",
                    width: "90.33vw",
                    borderRadius: "none"
                }
            },
            closeButton: {
                position: "absolute" as "absolute",
                width: "6.28vw",
                height: "6.28vw",
                top: "0.46vh",
                right: "1vw",
                borderRadius: "50%",
                background: Color.Black,
                cursor: "pointer",
                zIndex: "3"
            },
            zoomIn: {
                position: "absolute",
                bottom: "1.96vh",
                left: '34.50vw',
                cursor: "pointer",
                zIndex: "3",
                width: "5vw",
                height: "2.23vh",
                Color: Color.White
            },
            zoomOut: {
                position: "absolute",
                bottom: "1.96vh",
                left: '42.50vw',
                cursor: "pointer",
                zIndex: "3",
                width: "5vw",
                height: "2.23vh",
                Color: Color.White
            },
            fullScreenImage: {
                position: "absolute",
                bottom: "1.96vh",
                left: "4.5vw",
                cursor: "pointer",
                width: "4.83vw",
                height: "2.23vh",
                fill: Color.White,
                zIndex: "3"
            },
            arrowLeft: {
                fill: Color.White,
                position: "absolute",
                bottom: "1.96vh",
                left: "78%",
                zIndex: "3",
                width: "2vw",
                height: "2.23vh"
            },
            arrowRight: {
                fill: Color.White,
                position: "absolute",
                bottom: "1.96vh",
                left: "93%",
                zIndex: "3",
                width: "2vw",
                height: "2.23vh"
            },
            pageCount: {
                 position: "absolute",
                 height: "2.23vh",
                 bottom: "1.96vh",
                 fontSize: "3.5vw",
                 zIndex: "3",
                 left: "82.5%",
                 color: Color.White
            },
            leftWrapper: {
                position: "relative",
                width: "100%",
                height: "53.78vh",
                overflow: "hidden",
                fullscreen: {
                    height: "77vh",
                    width: "90.33vw",
                    borderRadius: "2.41vw"
                }
            },
            rightWrapper: {
                width: "100%",
                height: "19.31vh"
            },
            image: {
                width: "100%",
                height: "100%",
                borderRadius: "2.41vw 2.41vw 0 0",
                transition: "transform 0.3s ease",
                objectFit: "contain",
                cursor: "grabbing",
                fullscreen: {
                    height: "77vh",
                    width: "90.33vw",
                    objectFit: "fill"
                }           
            },
            imageLayer: {
                position: "absolute",
                bottom: "0vw",
                height: "6.93vh",
                background: "#f4f4f4 linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box",
                width: "100%"
            },
            name: {
                fontWeight: "bold" as "bold",
                fontSize: "4.83vw",
                margin: "4.46vh 6.04vw 0 6.04vw",
                color: Color.LightBlack
            },
            detail: {
                display: "flex" as "flex",
                flexDirection: "row" as "row",
                color: Color.MiddleBlack
            },
            price: {
                fontWeight: "bold" as "bold",
                fontSize: "3.38vw",
                margin: "1.12vh 0 0 6.04vw"
            },
            description: {
                fontSize: "3.38vw",
                margin: "1.11vh 8.21vw 0 auto"
            },
            button: {
                width: "-webkit-fill-available",
                height: "3.24vh",
                border: "0.48vw solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                fontWeight: "bold" as "bold",
                fontSize: "2.90vw",
                borderRadius: "1.21vw",
                margin: "2.68vh 6.04vw 3.46vh 6.04vw"
            }
        }
    },
    desktop: {
        container: {
            margin: "2.58vw 7% 6.30vw 7%"
        },
        toggleWrapper: {
            flexDirection: "row" as "row"
        },
        leftWrapper: {
            width: "56.67vw"
        },
        rightWrapper: {
            display: "block",
            width: "27.92vw",
            paddingLeft: "0.83vw"
        },
        text: {
            display: "none"
        },
        heading: {
            alignSelf: "center" as "center",
            fontSize: "1.56vw",
            margin: "0 0 2.24vw"
        },
        select: {            
            width: "auto",
            margin: "0 0 2.08vw auto",
            label: {         
                fontSize: "0.94vw",       
                margin: "0 1.04vw 0 0",
                alignSelf: "center" as "center"
            },
            button: {
                height: "2.08vw",
                width: "6.51vw",
                marginTop: "unset"                            
            },
            buttonText: {
                marginTop: "-0.1vw"
            },
            hover: {
                width: "6.51vw"
            }
        },
        grid: {
            gridTemplateColumns: "repeat(4, 1fr)",
            gridGap: "2.08vw 0.83vw",
            marginTop: "unset"
        },
        cvWrapper: {
            height: "17.44vw",
            width: "13.54vw",
            border: "0.05vw solid" + Color.LightSilver,
            borderRadius: "0.52vw"
        },
        image: {
            borderRadius: "0.52vw"
        },
        layer: {
            border: "0.05vw solid " + Color.MiddleAsh,
            borderRadius: "0.52vw",
            height: "10.05vw"
        },
        detailWrapper: {
            left: "1.04vw",            
            bottom: "2.31vh",         
            fontSize: "0.94vw"            
        },
        price: {
            margin: "0 0 0.46vh 0"
        },
        name: {
            margin: "0 0 0.37vh 0"
        },
        description: {
            fontSize: "0.73vw"                 
        },
        button: {
            height: "1.82vw",
            border: "0.35vw solid " + Color.Blue,
            fontSize: "0.78vw",
            marginTop: "0.83vw",
            borderRadius: "0.26vw"
        },
        cartWrapper: {
            display: "none",
            height: "46.61vw",
        },
        cartButton: {
            display: "none"
        },
        pagination: {
            margin: "2.60vw 0 0 0"
        },       
        cart: {
            background: Color.LightAsh,
            borderRadius: "0.52vw",
            padding: "2.22vh 1.25vw",
            margin: 0,            
            heading: {
                fontSize: "1.56vw",
                margin: "0 0 1.56vw 0"
            },
            cartItemsContainer: {
                maxHeight: "60vh",
            }, 
            itemWrapper: {
                border: "0.05vw solid " + Color.LightSilver,
                height: "3.44vw",
                borderRadius: "0.52vw",
                marginBottom: "0.31vw"
            },
            image: {
                width: "3.69vw",
                height: "100%",
                borderRadius: "0.52vw 0 0 0.52vw",
                marginRight: "1.04vw"
            },
            name: {
                width: "12.5vw",
                fontSize: "0.94vw"
            },
            description: {
                margin: 0,
                fontSize: "0.73vw"
            },
            cvPrice: {
                margin: "0 0.83vw 0 auto",
                fontSize: "0.94vw"
            },
            closeIcon: {
                margin: "0 1.04vw 0 0",
                width: "1.2vw",
                height: "1.2vw"
            },
            line: {
                height: "0.18vh",
                margin: "1.90vh 0"
            },
            total: {
                fontSize: "0.94vw",
                price: {
                    fontSize: "1.46vw"
                }
            },
            checkoutButton: {
                height: "2.60vw",
                fontSize: "1.04vw",
                borderRadius: "0.52vw",
                marginTop: "1.15vw",
                marginBottom:"0"
            },
            emptyCart: {
                height: "3.44vw",
                margin: 0
            },
            iconGride: {
                margin: "1.66vw 0 0 0"
            },
            emptyCartIconGride: {
                position: "unset",
                bottom: "unset",
                left: "unset",
                display: "flex",
                justifyContent: "center",
                margin: "1.66vw 0 0 0"
            },
            paymentIcon: {
                maxWidth: "5.10vw",
                maxHeight: "1.51vw",
                margin: "0 0.83vw 0 0"
            }
        },
        cvPopUp: {
            container: {                
                width: "51.46vw",                
                height: "80%",
                borderRadius: "0.52vw",
                fullscreen: {
                    width: "30.99vw",
                    height: "43.85vw"
                }                               
            },            
            closeButton: {
                width: "1.55vw",
                height: "1.55vw",
                top: "0.5vw",
                right: "0.5vw",
                backgroundColor: Color.Black
            },
            zoomIn: {
                position: "absolute",
                bottom: "3.75vw",
                width: "1.24vw",
                height: "1.24vw",
                left: "14.25vw",
                fullscreen: {
                    left: "12vw"
                }
            },
            zoomOut: {
                position: "absolute",
                bottom: "3.75vw",
                width: "1.24vw",
                height: "1.24vw",
                left: "16.25vw",
                fullscreen: {
                    left: "14vw"
                }
            },
            fullScreenImage: {
                bottom: "3.75vw",
                left: "1.95vw",
                width: "1.24vw",
                height: "1.24vw",
                fullscreen: {
                    left: "1.95vw"
                 }  
            },
            arrowLeft: {
                bottom: "3.75vw",
                left: "28.07vw",
                width: "0.83vw",
                height: "1.24vw",
                fullscreen: {
                    left: "24vw"
                }
            },
            arrowRight: {
                bottom: "3.75vw",
                left: "32.66vw",
                width: "0.83vw",
                height: "1.24vw",
                fullscreen: {
                    left: "29vw"
                }
            },
            pageCount: {
                height: "1.24vw",
                bottom: "3.75vw",
                fontSize: "0.94vw",
                left: "30.05vw",
                fullscreen: {
                    left: "26vw"
                } 
            },
            leftWrapper: {
                position: "absolute",
                width: "34.68vw",
                height: "100%",
                curser: "gabbing",
                fullscreen: {
                    width: "30.99vw",
                    height: "43.85vw",
                    borderRadius: "0.52vw"
                },
            },
            rightWrapper: {
                position: "absolute",
                left: "34.68vw",
                display: "flex",
                flexDirection: "column",
                width: "16.77vw",
                height: "100%"
            },
            image: {
                width: "100%",
                height: "33vw",
                borderRadius: "0.52vw 0 0 0.52vw",
                fullscreen: {
                    width: "30.99vw",
                    height: "43.85vw",
                    objectFit: "fill"
                }                        
            },
            imageLayer: {
                bottom: "-3vw",
                height: "5.94vw"
            },
            name: {                
                fontSize: "1.56vw",
                margin: "auto 1.56vw 0 1.30vw"
            },
            price: {                
                fontSize: "0.94vw",
                margin: "1.39vh 0 0 1.30vw"
            },
            description: {
                fontSize: "0.83vw",
                margin: "1.39vh 1.56vw 0 auto"
            },
            button: {
                height: "4.63vh",
                border: "0.15vw solid " + Color.Blue,
                fontSize: "1.04vw",
                borderRadius: "0.52vw",
                margin: "3.24vh 1.56vw 3.42vh 1.30vw"
            }
        }
    }
}