import React, { useEffect, useState } from 'react'
import { TabItem } from '../../models/TabItem';
import { useNavigate } from 'react-router-dom';

interface Props {
    styles: any;
    path?: string;
    tabItems: TabItem[];
    selectedTab: string;
    getSelectedTab?(selectedTabs: string): void;
}

export const Tabs: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState<string>(props.selectedTab);
    const [tabContent, setTabContent] = useState();
    const styles = props.styles;

    useEffect(() => {
        changeTab(props.selectedTab);
    }, [props.selectedTab]);

    const onTabChange = (tabValue: string) => {
        if(props.path) {
            navigate(props.path + tabValue + "?noScroll=true");
        } else {
            changeTab(tabValue);
        }
        if(props.getSelectedTab) {
            props.getSelectedTab(tabValue);
        }
    }

    const changeTab = (tabValue: string) => {
        let selectedTab: TabItem = props.tabItems.filter(t => t.value === tabValue)[0];
        if(selectedTab.component) {
            setSelectedTab(tabValue);
            setTabContent(props.tabItems.filter(t => t.value === tabValue)[0].component);
        }        
    }

    return (
        <div style={styles.container}>
            <div style={styles.linkWrapper} className={"disableScrollBar"}>
                {props.tabItems.map((tabItem, index) =>
                    <div
                        key={index}
                        style={{ ...styles.link, ...(selectedTab === tabItem.value && styles.link.active), ...tabItem.styles }}
                        onClick={() => onTabChange(tabItem.value)}>{tabItem.label}
                    </div>
                )}
            </div>
            <div style={styles.contentWrapper}>                
                {tabContent}
            </div>
        </div>
    )
}