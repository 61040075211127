import React from "react";
import ContentLoader from "react-content-loader";
import { Color } from "../../enums/Color";

interface Props {
  styles: any;
}

export const TagLoader: React.FC<Props> = (props) => {
  let curve = props.styles.curve;
  return (
    <ContentLoader
      width={props.styles.width}
      height={props.styles.height}
      backgroundColor={Color.DarkAsh}
      foregroundColor={Color.MiddleAsh}
    >
      <rect x="1%" y="5%" rx={curve} ry={curve} width="22%" height="100%" />
      <rect x="26%" y="5%" rx={curve} ry={curve} width="22%" height="100%" />
      <rect x="51%" y="5%" rx={curve} ry={curve} width="22%" height="100%" />
      <rect x="76%" y="5%" rx={curve} ry={curve} width="22%" height="100%" />
    </ContentLoader>
  );
};
