import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const loaderStyles = {
    mobile: {        
        width: "90%",
        margin: "4vh 5% 8vh 5%",
        contentHeight: "41vh",
        buttonWidth: "100%",
        buttonHeight: "5.58vh"
    },
    desktop: {
        width: "61%",
        margin: "6vh auto 10vh auto",
        contentHeight: "42vh",
        buttonWidth: "18.125vw",
        buttonHeight: "7.8vh"
    }
};
 
export const QuizLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    }    

    return (
        <div style={styles}>
            <ContentLoader
                speed={1}
                width={"100%"}
                height={styles.contentHeight}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                <rect x="0%" y="0%" rx="8" ry="8" width="80%" height="10%"/>
                <rect x="6%" y="20%" rx="8" ry="8" width="50%" height="5%"/>
                <rect x="6%" y="30%" rx="8" ry="8" width="40%" height="5%"/>
                <rect x="6%" y="40%" rx="8" ry="8" width="30%" height="5%"/>
                <rect x="6%" y="50%" rx="8" ry="8" width="90%" height="5%"/>
                <rect x="6%" y="60%" rx="8" ry="8" width="28%" height="5%"/>
                <rect x="0%" y="80%" rx="8" ry="8" width={styles.buttonWidth} height={styles.buttonHeight}/>                                                                
            </ContentLoader>
        </div>
    );
}