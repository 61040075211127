import { createSearchParams } from "react-router-dom";
import { InputValidation } from "../models/InputValidation";
import { JobView } from "../models/JobView";

export function validateEmail(email: string): boolean {    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;    
    return emailRegex.test(email);
}

export const validateName = (name: string, value: string, maxLimit: number) : InputValidation => {
    let regex = /^[A-Za-z\u0D80-\u0DFF\u0B80-\u0BFF\s]*$/;  
    let nameValidation: InputValidation = {valid: true}; 
    if(value === "") {
        nameValidation ={valid: false, invalid: true, invalidMessage: name + " is required"};
    }
    else if(value.length < 3 || value.length > maxLimit) { 
        nameValidation ={valid: false, invalid: true, invalidMessage: "Character length only allows between 3 - " + maxLimit};
    } 
    else if(!regex.test(value)) { 
        nameValidation ={valid: false, invalid: true, invalidMessage: "Symbols or numbers are not allowed"};
    }
    return nameValidation;
}

export const validateAddress = (address: string) => {
    let regex = /^.{5,200}$/;    
    return regex.test(address);
}

export const validateLettersOnlyText = (value: string) => {
    let regex = /^[A-Za-z\u0D80-\u0DFF\u0B80-\u0BFF\s]*$/;
    return regex.test(value);
}

export const validateNIC = (NIC: string) => {
    let regex = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/m;    
    return regex.test(NIC);
}

export const validateCV = (cv: File | undefined) : InputValidation => {        
    const validCVTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    let cvValidation: InputValidation = {valid: true};        
    if(!cv) {
        cvValidation = {valid: false, invalid: true, invalidMessage: "CV is required"};
    } else if (!validCVTypes.includes(cv?.type)) {
        cvValidation = {valid: false, invalid: true, invalidMessage: "Invalid CV format. Try pdf, docx or doc format"};
    } else if (cv?.size > 2000000) {
        cvValidation = {valid: false, invalid: true, invalidMessage: "CV file size exceeds maximum allowable size (2 MB)"};
    } else if (cv?.size <= 0) {
        cvValidation = {valid: false, invalid: true, invalidMessage: "CV upload was not successful. Please try again"};
    }
    return cvValidation;
}

export const getCandidateCVURL = (translationText: string, isDraftCV: boolean = false) => {
    let params = { translationText: translationText, isDraftCV: isDraftCV.toString() };
    return getHost() + "api/candidate/cv?" + createSearchParams(params);
}

export const getCandidateProfilePictureURL = (candidateId: number) => {
    let profilePictureURL = "";
    if(candidateId){
        let params = { candidateId: candidateId.toString() };
        profilePictureURL = getHost() + "api/candidate/getProfilePicture?" + createSearchParams(params)
    }
    return profilePictureURL;
}

export const getGovernmentJobLogoURL = (jobId?: number) => {
    let governmentJobLogoUrl = "";
    if(jobId) {
        let params = { jobId: jobId.toString() };
        governmentJobLogoUrl = getHost() + "api/jobs/governmentJobLogo?" + createSearchParams(params);
    }
    return governmentJobLogoUrl;
}

export const validatePhoneNumber = (number: string) => {
    //Valid numbers : 07********, 7********, +947********, +9407********, 947********, 
    let regex = /^(7|07|\+94[7]|94[7]|94[07]|\+94[07]?)[0-9]\d{7}$/;    
    return regex.test(number);
}

export const getFormatedDate = (date: Date) => {
    if (date) {
        let formatedDate = new Date(date).toLocaleTimeString("en-us", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric"
          });
      return formatedDate.replace("at", " | ");
    }
};

export const validateImageFile = (image: File): InputValidation => {
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    let imageValidation: InputValidation = { valid: true };
    if (!validImageTypes.includes(image?.type)) {
        imageValidation = { valid: false, invalid: true, invalidMessage: "Invalid Image format. Try jpg, jpeg or png format" };
    } else if (image?.size > 2000000) {
        imageValidation = { valid: false, invalid: true, invalidMessage: "File size exceeds maximum allowable size (2 MB)" };
    } else if (image?.size <= 0) {
        imageValidation = { valid: false, invalid: true, invalidMessage: "There is an issue with your file, please try a different one" };
    }
    return imageValidation;
}

export const validateLinkedInUrl = (linkedInUrl: string)  => {
    let regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/[A-Za-z0-9_-]+(\/[A-Za-z0-9_-]+)*\/?$/;
    return regex.test(linkedInUrl);
}

export const getSEOKeyword = (text: string): string => {
    return text
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')   // Remove special characters
        .replace(/\s+/g, '-')       // Replace spaces with hyphens
        .trim();                    // Trim any leading/trailing spaces
};

export const createJobStructuredData = (jobView: JobView) => {
    const structuredData = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "title": jobView.jobTitle,
        "educationRequirements": {
            "@type": "EducationalOccupationalCredential",
            "credentialCategory": jobView.education
          },
        "description": jobView.jobInfo,
        "datePosted": jobView.createdDate,
        "validThrough": jobView.jobItem.expiryDateOnWebsite,
        "employmentType": jobView.jobItem.jobType,
        "hiringOrganization": {
            "@type": "Organization",
            "name": jobView.organization.organizationName,
            "logo": jobView.organization.logoUri,
            "url": jobView.organization.website
        },
        "jobLocation": {
            "@type": "Place",
            "address": {
            "@type": "PostalAddress",
            ...(!jobView.jobItem.locations.includes("Foreign Job") && {
                "addressCountry": "SriLanka"
            })
            }
        },
        "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "RS",
            "value": {
                "@type": "QuantitativeValue",
                "minValue": jobView.minSalary,
                "maxValue": jobView.maxSalary,
            }
        }
    }
    return structuredData; 
}

export const getHost = () => {
    const host = window.location.protocol + "//" + window.location.host + "/";
    return host;
}
  