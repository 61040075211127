import axios from "axios";
import { getHost } from "../helpers/Common";

const apiBaseUrl = getHost() + "api/";

export async function postCredentials(credentials:any) {
    return new Promise<any>((resolve) => {
        axios({
            method: "post",
            url: apiBaseUrl + "recruiter/login",
            data: credentials,
            headers: { 
                "Accept" : "application/json",
                "Content-Type": "application/json" 
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function () {
                resolve(false);
            });
    });
}