import React, { useContext, useEffect, useState } from 'react';
import { ShortJobCardList } from '../components/Shared/ShortJobCardList';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { getFeaturedJobs } from '../services/HomeService';
import { Statistics } from '../components/Shared/Statistics';
import { JobCategories } from '../components/Home/JobCategories';
import { TopHiringOrganizations } from '../components/Home/TopHiringOrganizations';
import { LogoGride } from '../components/Shared/LogoGride';
import { StyleContext } from '../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { getFeaturedOrganizations } from '../services/HomeService';
import { AuthContext } from '../providers/AuthContextProvider';
import { HomeTexts as Texts } from '../helpers/LayoutTexts';
import { Helmet } from 'react-helmet-async';
import { SelectInputs } from '../models/SelectInputs';
import { LoggedCandidate } from '../models/LoggedCandidate';

export const HomePage: React.FC = () => {        
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("homePage"));
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" }); 
    const featuredJobCount: number = isMobile ? 10 : 24;
    const featuredOrganizationCount: number = isMobile ? 15 : 32;

    const sortSelectInputs: SelectInputs = {
        default: 'Default',
        options: [
            { "value": "[Priority]", "option": "Default" },
            { "value": "ExpiryDateOnWebsite DESC", "option": "Recent" },
            // { "value": "ViewCount DESC", "option": "Trending" } This will implement in later release
        ]
    }

    useEffect(() => {            
        setStyles(styleContext.getComponentStyle("homePage"));
    }, [isMobile]);

    useEffect(() => {             
        if(candidate !== null) {
            sortSelectInputs.options.push({ "value": "Recommended", "option": "Recommended" });
        }
    }, [candidate]);

    async function getFeaturedJobList(sortBy: string) {
        const preferredSectorIds = candidate?.preferredSectorIds.toString();
        return getFeaturedJobs(featuredJobCount, sortBy, preferredSectorIds);
    }

    return (
        <>
            <Helmet>
                <title>{Texts.siteTitle}</title>
                <meta name="description" content={Texts.metaDescription}/>
            </Helmet>
            <Header styleSheet="fullHeader" searchEnable={true} searchSelectEnable={true} heading={Texts.heading} text={isMobile ? Texts.mobileText : Texts.desktopText} searchText={isMobile ? Texts.mobileSearch : Texts.desktopSearch}/>
            <div style={styles.flippingWrapper}>
                <JobCategories/>
                <ShortJobCardList title={'Featured Jobs'} sortEnabled={true} initialSortBy={"[Priority]"} sortSelectInputs={sortSelectInputs} loadJobs={getFeaturedJobList}/>
            </div>        
            <TopHiringOrganizations/>
            <Statistics/>
            <LogoGride rowsCount={ isMobile ? 3 : 2 } loadOrganizations={getFeaturedOrganizations} recordsCount={featuredOrganizationCount}/>
            <Footer/>
        </>
    );
}