import React from "react";
import ContentLoader from "react-content-loader";
import { FeedbackCard } from "../Shared/FeedbackCard";

interface Props {
    jobCount: number;
    jobCountTitle: string;
    styles: any;
    isLoading: boolean;
}

export const JobsCount: React.FC<Props> = (props) => {
    let styles = props.styles;

    return (
        <>
            {props.isLoading ? <div style={styles.loaderWrapper}>
                <ContentLoader height={"100%"} width={"100%"}>
                    <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
            </div>
                : <>
                    {props.jobCount > 0 ?
                        <div style={styles.wrapper}>
                            <span style={styles.count}>{props.jobCount} </span> {props.jobCountTitle} 
                        </div>
                        : <div style={styles.noResults}>
                            <span style={styles.noResults.zero}>0</span> Job Results
                            <FeedbackCard styles={styles.noResults} message={"There are no any results for your searches"} />
                        </div>
                    }
                </>
            }
        </>
    );
};
