import React, { useState } from 'react';
import { Button } from './Buttons/Button';
import { ReactComponent as Icon } from '../../assets/svg/drop_down.svg';

interface Props {    
    styles: any;
    items: any[];
}

export const Dropdown: React.FC<Props> = (props) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const styles = props.styles;

    return (
        <div
            onMouseEnter={() => { setHovered(true); }}
            onMouseLeave={() => { setHovered(false); }} 
            onClick={() => { setHovered(!hovered); }}
            style={styles.wrapper}
        >
            <Button type="button" style={styles.button}>
                <div style={styles.labelWrapper}>
                    {props.children}
                    <Icon style={{...styles.labelIcon, ...(hovered && styles.labelIcon.hover)}}/>
                </div>
            </Button>
            <div style={hovered ? styles.hover : styles.leave}>
                {props.items.map((item, index) =>
                    <div 
                        key={index}
                        onMouseEnter={()=>{ setHoveredIndex(index); }}
                        onMouseLeave={()=>{ setHoveredIndex(-1); }} 
                        style={styles.item}>
                        {item.item}
                        <hr style={{...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || props.items.length === index + 1) && styles.hoveredBorder)}}/>
                    </div>
                )}
            </div>
        </div>
    );
}