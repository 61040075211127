import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Shared/Buttons/Button';

interface Props {
    styles: any;
    content: any;
}

export const QuizComplete: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            {props.content}
            <div style={styles.buttonWrapper}>
                {/* <Button style={styles.button} onClick={() => navigate("/candidate/myapplications")}>VIEW MY APPLICATIONS</Button> 
                <Button style={{...styles.button, ...styles.button.back}} onClick={() => navigate('/')}>GO BACK TO HOME</Button> */}
            </div>
        </div>
    )
}