import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { Organization } from '../../models/Organization';
import { StyleContext } from '../../providers/StyleContextProvider';
import { LogoGridLoader } from '../Loaders/LogoGridLoader';
import { Logo } from './Logo';
import useErrorPage from '../../hooks/useErrorPage';

export interface Props {
    styles?: any;
    loadOrganizations(recordsCount: number): Promise<any>;
    recordsCount: number;
    rowsCount: number;
};

export const LogoGride: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("logoGride"));
    const [rowElements, setRowElements] = useState<any>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [loading, setLoading] = useState<boolean>(true);
    const [toErrorPage] = useErrorPage();

    useEffect(() => {
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("logoGride");
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);
        getLogoList();
    }, [isMobile]);

    async function getLogoList() {
        await props.loadOrganizations(props.recordsCount)
        .then((organizationList: Organization[]) => {
            generateLogos(organizationList);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    function generateLogos(organizations: Organization[]) {
        let logoStyles = merge(styles, props.styles);
        let startIndex = 0;
        let logosPerRow = Math.floor(organizations.length / props.rowsCount);
        let tempRowElements = new Array<any>();
        for (let i = 0; i < props.rowsCount; i++) {
            let element = <div key={i} style={{ ...logoStyles.row, ...(i !== 0 && logoStyles.nextRow) }} className="disableScrollBar">
                {organizations.slice(startIndex, startIndex + logosPerRow).map((organization, index) => <React.Fragment key={index + "-" + i}>
                    <Logo style={logoStyles.logo} organizationId={organization.organizationId} organizationName={organization.organizationName} hasLogo={organization.hasLogo} logoUri={organization.logoUri} />
                </React.Fragment>
                )}
            </div>;
            tempRowElements.push(element);
            startIndex += logosPerRow;
        }
        setRowElements(tempRowElements);
        setLoading(false);
    }
    
    return (
        <>
            {loading ? <LogoGridLoader style={styles} rowsCount={props.rowsCount} /> :
                <div style={styles}>
                    {rowElements}
                </div>
            }
        </>
    );
}