import React, { useEffect } from 'react';
interface Props {
  url: string;
  styles: any;
}
export const InstagramEmbed: React.FC<Props> = (props) => {
  const styles = props.styles;
  const url = props.url
  useEffect(() => {
    // Load Instagram embed script
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // Function to extract the post ID from the URL
  const extractInstagramPostId = (url: string): string | null => {
    const regex = /\/(?:p|tv|reel)\/([A-Za-z0-9-_]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  // Extract post ID
  const postId = extractInstagramPostId(url);
  if (!postId) {
    return <div>Error: Invalid Instagram URL</div>;
  }
  const embedUrl = `https://www.instagram.com/p/${postId}/`;
  return (
    <blockquote className="instagram-media" data-instgrm-permalink={embedUrl} data-instgrm-version="14" style={styles.layout}>
      <div style={styles.linkSection}>
        <a href={embedUrl} style={styles.link} target="_blank"></a>
      </div>
    </blockquote>
  );
};