import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const jobViewPage = {
    mobile: {
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column",            
            padding: "0 4.83vw"
        },
        leftWrapper: {
            width: "100%"
        },
        rightWrapper: {
            width: "100%"
        },
        rowWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            paddingTop: "1.35vh",
        },
        mobileDetailCard: {
            display: "block"
        },
        desktopDetailCard: {
            display: "none"
        },
        verticalDetailCard: {
            height: "71vh",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-4.05vh",
            background: Color.White,
            borderRadius: "2.78vw",
            paddingBottom: "1.35vh",
            boxShadow: "5px 5px 45px #00000029",
            position: "relative" as "relative",
            zIndex: "2",
            wrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                paddingTop: "1.35vh",
                paddingLeft: "3.86vw",
                paddingRight: "3.86vw",
            },
            logo: {
                width: "16.67vw",
                height: "16.67vw",
                background: "transparent",
                borderRadius: "1.39vw",
                marginTop: "-4.05vh",
                marginLeft: "1.39vw",
                opacity: "1",
                objectFit: "contain",
            }
        },
        jobSectors: {
            wrapper: {
                marginTop: "9.66vw",
                marginBottom: "13.28vw"
            },
            sector: {
                display: "flex",
                justifyContent: "center",
                marginTop: "2.89vw",
                textAlign: "center" as "center",
                fontSize: "3.86vw",
                cursor: "pointer",
                fontWeight: "500"          
            },
            text: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "85%",
                marginRight: "2vw"
            }
        },
        horizontalDetailCard: {
            display: "none",
            flexDirection: "row",
            position: "fixed",
            top: "0",
            width: "-webkit-fill-available",
            marginLeft: "7.29vw",
            marginRight: "7.29vw",
            padding: "1vw",
            backgroundColor: Color.White,
            boxShadow: "0px 0px 10px grey",
            borderRadius: "0 0 0.73vw 0.73vw",
            zIndex: "3",
            alignItems: "center",
            rowWrapper: {
                display: "flex",
                flexDirection: "row"
            },
            columnWrapper: {
                display: "flex",
                flexDirection: "column",
                marginLeft: "1.40vw"
            },
            summaryWrapper: {
                maxWidth: "28%"
            },
            detailWrapper: {
                maxWidth: "17%",
                marginLeft: "2vw"
            },
            logo: {
                width: "4.20vw",
                height: "4.20vw",
                borderRadius: "0.35vw",
                objectFit: "contain",
                cursor: "pointer"
            },
            urgentTag: {
                width: "4.20vw",
                height: "2.40vh",
                marginRight: "0.35vw",
                textAlign: "center",
                lineHeight: "2.40vh",
                borderRadius: "1.75vw",
                background: Color.Red,
                fontSize: "0.56vw",
                fontWeight: "bold",
                color: Color.White,
                textTransform: "capitalize"
            },
            videoTag: {
                width: "4.20vw",
                height: "2.40vh",
                textAlign: "center",
                lineHeight: "2.40vh",
                borderRadius: "1.75vw",
                background: Color.Blue,
                fontSize: "0.56vw",
                color: Color.White,
                cursor: "pointer"
            },
            title: {
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "1.05vw",
                letterSpacing: "0px",
                color: Color.Black,
                opacity: "1",
                maxWidth: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap"
            },
            organization: {
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                fontSize: "0.97vw",
                letterSpacing: "0px",
                color: Color.Black,
                opacity: "1",
                fontWeight: "500"
            },
            icon: {
                width: "0.98vw",
                height: "2.24vh",
                marginLeft: "0.70vw",
                marginTop: "0.32vh",
                fill: Color.Red,
            },
            smallIcon: {
                width: "0.70vw",
                height: "1.60vh",
                fill: Color.MiddleBlack,
                marginRight: "0.70vw",
            },
            text: {
                marginTop: "0.8vh",
                fontSize: "0.84vw",
                color: Color.DeepAsh,
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "100%"
            },
            remote: {
                marginLeft: "0.70vw",
                display: "flex",
                alignItems: "center"
            },
            applyWrapper: {
                marginLeft: "auto"
            },
            apply: {
                position: "relative",
                top: "0",
                width: "100%",
                wrapper: {
                    display: "flex",
                    flexDirection: "row"
                },
                rowWrapper: {
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "1.60vh",
                    paddingLeft: "0.00vw",
                    paddingRight: "0.70vw",
                    alignItems: "center" as "center"
                },
                applyButton: {
                    position: "relative",
                    width: "18.125vw",
                    height: "3.645vw",
                    color: Color.White,
                    fontWeight: 500,
                    backgroundColor: Color.Green,
                    border: "none",
                    borderRadius: "0.52vw",
                    fontSize: "1vw"
                },
                saveButton: {
                    position: "relative",
                    width: "18.125vw",
                    height: "3.645vw",
                    color: Color.Blue,
                    fontWeight: "bold",
                    backgroundColor: "transparent",
                    borderRadius: "0.52vw",
                    borderColor: Color.Blue,
                    border: "3px solid",
                    fontSize: "1vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                },
                saveIcon :{
                    fill: Color.Blue,
                    marginLeft: "0.5vw",
                    width: "0.6vw",
                    height: "2vh"
                },
                notifyButton: {
                    position: "relative",
                    width: "2.4vw",
                    marginLeft: "0.70vw",
                    height: "2.4vw",
                    backgroundColor: Color.MiddleAsh,
                    border: "none",
                    borderRadius: "50%",
                    fill: Color.MiddleBlack,
                    hover: {
                        backgroundColor: Color.LightGreen,
                        fill: Color.Green,
                    }
                },
                icon: {
                    width: "60%"
                }
            },
        },
        share: {
            wrapper: {
                display: "flex",
                flexDirection: "column" as "column",
                marginTop: "4vw"
            },
            text: {
                width: "auto",
                maxWidth: "90%",
                marginBottom: "3vw",
                lineHeight: "4.3vw",
                fontSize: "3.33vw",
                color: Color. MiddleBlack,
                fontWeight: 400
            },
            button: {
                width: "7.729vw",
                height: "7.729vw",
                marginRight: "1.39vw",
                backgroundColor: Color.DarkAsh,
                borderRadius: "50%",
                padding: "1.69vw 1.81vw"
            },
            icon: {
                fill: Color.MiddleBlack,
                width: "3.89vw",
                height: "3.89vw",
                verticalAlign: "text-top",
                share: {
                    width: "3.35vw",
                    height: "4.34vw"
                },
                whatsapp: {
                    width: "4.10vw",
                    height: "4.10vw"
                },
                facebook: {
                    width: "1.93vw",
                    height: "3.62vw"
                },
                twitter:{
                    stroke: Color.MiddleBlack
                }
            },
        },
        apply: {
            wrapper: {
                display: "flex",
                flexDirection: "column" as "column",
                marginTop: "10.28vw"
            },
            rowWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                paddingTop: "2.41vw",
                alignItems: "center" as "center"
            },
            applyButton: {
                fontType: Font.Default,
                position: "relative" as "relative",
                width: "100%",
                height: "11.07vw",
                color: Color.White,
                fontWeight: "bold",
                backgroundColor: Color.Green,
                border: "none",
                borderRadius: "2.41vw",
                fontSize: "4.34vw",
                boxShadow: "5px 5px 50px #00000027"
            },
            saveButton: {
                position: "relative" as "relative",
                width: "100%",
                height: "10.07vw",
                color: Color.Blue,
                fontWeight: "bold",
                backgroundColor: "transparent",
                borderRadius: "2.41vw",
                borderColor: Color.Blue,
                border: "0.72vw solid",
                fontSize: "4.34vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },            
            saveIcon :{
                fill: Color.Blue,
                marginLeft: "1vw",
                width: "3vw",
                height: "5.07vw"
            },
            notifyButton: {
                position: "relative" as "relative",
                width: "11.11vw",
                marginLeft: "auto",
                height: "11.11vw",
                backgroundColor: Color.MiddleAsh,
                border: "none",
                borderRadius: "50%",
                fill: Color.MiddleBlack,
                hover: {
                    backgroundColor: Color.LightGreen,
                    fill: Color.Green,
                }
            },
            icon: {
                width: "60%"
            }
        },
        jobAdContainer: {
            backgroundColor: Color.LightAsh,
            marginTop: "1.35vh",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "1.2vw",
            padding: "0 3.3vw",
            expand: {
                padding: "0 3.33vw 2.5vh 3.33vw"
            }
        },
        expandButton: {
            position: "relative" as "relative",
            display: "flex",
            alignItems: "center" as "center",            
            height: "11.83vw",         
            color: Color.Black,
            fontWeight: "medium",
            backgroundColor: Color.LightAsh,
            border: "none",                        
            fontSize: "4vw",
            width: "100%",
            cursor: "pointer",            
            WebkitTapHighlightColor: "transparent",
            iconWrapper: {
                marginLeft: "auto"
            },
            icon: {
                width: "4.17vw",
                height: "2.03vh",
                fill: Color.Black,
                marginTop: "1.5vw",
                transition: "transform .2s ease-in-out"
            },
            expandIcon: {
                transform: "rotate(180deg)"
            }
        },
        jobAd: {
            borderRadius: "2vw",
            marginBottom: "1vh",
            width: "100%",
            objectFit: "contain",
            position: "relative" as "relative"
        },
        description: {
            height: "auto",
            marginTop: "3.38vh",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
            title: {
                fontSize: "5.56vw",
                fontWeight: "bold",
                color: Color.LightBlack,
            },
            content: {
                marginTop: "1.35vh",
                color: Color.MiddleBlack
            },
        },
        organizationCard: {
            display: "block",
            marginTop: "2vh",
        },
        verticalBanner: {
            display: "none"
        },
        horizontalBanner: {
            margin: "6.5vw 4.3vw"
        },
        video: {
            border: `0.1vh solid ${Color.MiddleAsh}`, 
            margin: "1.82vh 0",
            display: "flex",
            justifyContent: "center",
            padding:"2vh",
            borderRadius: "0.9vw",
            overflowY: "auto",
            overflowX: "hidden",
            youtube:{
                height: "calc(100vw * (9 / 16))",  
                width: "100%",
                objectFit: "contain",
                borderRadius: "0.9vw",
                border: `none`
            },
            tiktok: {
                width: "100%",
                height: "calc(100vh * (9 / 16))",
                objectFit: "contain",
                borderRadius: "0.9vw",
                border: `none`,
                background:"none"
            },
            youtubeShort: {
                width: "100%",
                height: "calc(100vw * (9 / 16))",  
                objectFit: "contain",
                borderRadius: "0.9vw",
                border: `none`
            },
            facebookReel: {
                width: "100%",
                height: "calc(100vh * (9 / 16))",
                borderRadius: "0.9vw",
                border: `none`
            },
            linkedIn: {
                height: "calc(100vh * (9 / 16))",
                width: "100%",
                objectFit: "contain",
                borderRadius: "0.9vw",
                border: `none`
            },
            instagram: {
                layout: {
                    background: Color.White,
                    border: "0",
                    borderRadius: "2.9vw",
                    margin: "0.1vw",
                    minWidth: "80vw",
                    width: "100%",
                    padding: "0"
                },
                linkSection: {
                    padding: "1.6vh"
                },
                link: {
                    width: "100%",
                    background: Color.White,
                    textAlign: "center"
                }
            },
            drive: {
                width: "100%",
                height: "calc(100vw * (9 / 16))",  
                objectFit: "contain",
                borderRadius: "0.9vw",
                border: `none`
            }
        }
    },
    desktop: {
        baseWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginLeft: "7.29vw",
            marginRight: "7.29vw",
            padding: "0"
        },
        leftWrapper: {
            width: "70%"
        },
        rightWrapper: {
            width: "30%",
            display: "block"
        },
        mobileDetailCard: {
            display: "none"
        },        
        desktopDetailCard: {
            display: "block"
        },
        verticalDetailCard: {
                width: "20.72vw",
                marginRight: "0.00vw",
                marginTop: "-20vh",
                paddingBottom: "2.40vh",
                borderRadius: "0.70vw",
                height: "66.11vh",
            logo: {
                width: "8vw",
                height: "8vw",
                marginTop: "-7vh",
                borderRadius: "0.5vw",
                marginLeft: "0.4vw",
            }
        },
        jobSectors: {
            wrapper: {
                marginTop: "1.80vw",
                marginLeft: "auto",
                marginRight: "unset",
                width: "21vw",
                marginBottom: "2.6vw"
            },
            sector: {
                fontSize: "0.93vw",
                marginTop: "1.11vh"
            },
            text: {                
                marginRight: "0.3vw"
            }
        },
        horizontalDetailCard: {
            display: "flex"
        },
        share: {
            display: "flex",
            wrapper: {
                flexDirection: "row" as "row",
                paddingLeft: "0.70vw",
                paddingRight: "0.73vw",
                marginTop: "1.52vw",
                marginLeft: "-0.6vw"
            },
            button: {
                width: "2.08vw",
                height: "2.08vw",
                marginRight: "0.35vw",
                borderRadius: "50%",
                marginBottom: "0.90vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "unset"
            },
            text: {
                width: "23.70vw",
                paddingTop: "0",
                paddingRight: "0.70vw",
                marginBottom: "1.60vh",
                fontSize: "0.83vw",
                lineHeight: "1vw",
                fontWeight: 400,
                color: Color. MiddleBlack
            },
            icon: {
                width: "0.98vw",
                height: "0.98vw",
                verticalAlign: "text-top",
                share: {
                    width: "0.83vw",
                    height: "0.93vw",
                },
                whatsapp: {
                    width: "0.99vw",
                    height: "0.99vw"
                },
                facebook: {
                    width: "0.47vw",
                    height: "0.89vw"
                },
                instagram: {
                    width: "1.065vw",
                    height: "1.064vw"
                },
                twitter: {
                    width: "0.98vw",
                    height: "0.80vw"
                },
                linkedIn: {
                    width: "0.98vw",
                    height: "0.98vw"
                }
            },
        },
        apply: {
            top: "21.00vw",
            wrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "4.2vw",
                marginLeft: "-0.6vw"
            },
            rowWrapper: {
                paddingTop: "1.60vh",
                paddingLeft: "0.70vw",
                paddingRight: "0.70vw",                
                alignItems: "center" as "center"
            },
            applyButton: {
                width: "18.125vw",
                height: "3.645vw",
                borderRadius: "0.52vw",
                fontSize: "1.0416vw",
                fontWeight: 500,
                boxShadow: "5px 5px 50px #00000027"
            },
            saveButton: {
                width: "14.739vw",
                height: "3.645vw",
                borderRadius: "0.52vw",
                border: "3px solid",
                fontSize: "1.0416vw",
                marginLeft: "-0.4vw"
            },
            saveIcon: {
                marginLeft: "0.5vw",
                width: "0.7vw",
                height: "2vh"
            },
            notifyButton: {
                marginLeft: "0.70vw",
                width: "7.8vh",
                height: "7.8vh"
            },
        },
        jobAdContainer: {
            width: "100%",
            marginTop: "3.5vh",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "0.5vw",
            padding: "0 1.82vw",
            expand: {
                padding: "0 1.82vw 2.5vh 1.82vw"
            }
        },
        expandButton: {
            height: "8vh",
            fontSize: "1vw",
            fontWeight: "bold",
            icon: {
                marginTop: "0.5vw", 
                width: "1.05vw",
                height: "2.40vh"
            }
        },
        jobAd: {
            marginBottom: "2.6vh",
            borderRadius: "1.46vw",
        },
        description: {
            paddingLeft: "0",
            paddingRight: "0.70vw",
            marginTop: "4.00vh",
            fontSize: "0.83vw",
            title: {
                fontSize: "1.56vw" 
            },
            content: {
                marginTop: "1.60vh"
            },
        },
        organizationCard: {
            width: "20.72vw",
            marginTop: "2.70vh",
            marginLeft: "auto"
        },
        verticalBanner: {
            display: "block",
            width: "20.72vw",
            margin: "1.56vw 0 -3vw auto",
            overflow: "hidden"
        },
        horizontalBanner: {
            margin: "1vw 7.29vw 3vw 7.29vw"
        },
        video: {
            height: "64.35vh",
            margin: "2.9vh 2.9vh",
            youtube: {
                height: "100%",
                width: "calc(64.35vh * (16 / 9))"
            },
            tiktok: {
                height: "100%",
                width: "calc(30vw * (16 / 9))"
            },
            youtubeShort: {
                height: "100%",
                width: "calc(64.35vh * (16 / 9))"
            },
            facebookReel: {
                height: "100%",
                width: "calc(64.35vh * 9 / 16)"
            },
            linkedIn: {
                height: "100%",
                width: "calc(50vw * (9 / 16))"
            },
            instagram: {
                layout: {
                    width: "calc(64.35vh * (9 / 16))"
                }
            },
            drive: {
                height: "100%",
                width: "calc(64.35vh * (16 / 9))"
            }            
        }
    }
}