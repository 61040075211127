import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../components/Shared/Footer";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Login } from "../components/CandidateLogin/Login";
import { Register } from "../components/CandidateLogin/Register";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LostPassword } from "../components/CandidateLogin/LostPassword";
import { ResetPassword } from "../components/CandidateLogin/ResetPassword";
import { useAlert } from "../hooks/useAlert";
import { CVUploadPopup } from "../components/CandidateLogin/CVUploadPopup";
import { postLinkedInAuthCode } from "../services/CandidateService";
import { ResponseModel } from "../models/ResponseModel";
import useErrorPage from "../hooks/useErrorPage";
import { Helmet } from "react-helmet-async";
import { Transition } from "../components/Shared/Transition";
import { AuthContext } from "../providers/AuthContextProvider";
import { LoggedCandidate } from "../models/LoggedCandidate";

export const CandidateLoginRegisterPage: React.FC = () => {
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const styleContext = useContext(StyleContext);
  const authContext = useContext(AuthContext);
  const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
  const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("loginRegister"));
  const stage = params.stage;
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  const callbackUrl: string | null = searchParams.get("callbackUrl");
  const message: string | null = searchParams.get("message");
  const linkedInAuthCode: string | null = searchParams.get("code");
  const linkedInError: string | null = searchParams.get("error_description");
  const stateParam: string | null = searchParams.get("state");
  const state = stateParam ? JSON.parse(stateParam) : { linkedInCallbackUrl: "" };
  const { linkedInCallbackUrl } = state;
  const { Alert, openAlert } = useAlert();
  const [showCVUploadPopup, setShowCVUploadPopup] = useState(false);
  const [candidateId, setCandidateId] = useState<number>();
  const [toErrorPage] = useErrorPage();

  useEffect(() => {    
    setStyles(styleContext.getComponentStyle("loginRegister"));
  }, [isMobile]);

  useEffect(() => {    
    if(candidate !== null) {        
      navigate("/candidate/home");
    }   
    if (linkedInAuthCode !== null && linkedInAuthCode.trim() !== "") {
      handlePostLinkedInAuthCode(linkedInAuthCode);
    }
    else if (linkedInError !== null && linkedInError !== "") {
      openAlert("error", linkedInError);
    }
  }, []);

  // This is to send the linkedin code to API at the first load. if the code is exist in query parameter
  async function handlePostLinkedInAuthCode(accessToken: string) {
    await postLinkedInAuthCode(accessToken)
      .then((response: ResponseModel) => {
        if (response.status) {
          if (response.message === "UploadCV") {
            handleCVUploadPopup(response.data);
          }
          else {
            authContext.setLoggedCandidate(response.data, response.token);
            if (linkedInCallbackUrl && linkedInCallbackUrl !== "") {
              navigate(linkedInCallbackUrl);
            }
            else {
              navigate("/candidate/home");
            }
          }
        }
        else {
          openAlert("error", response.message);
        }
      })
      .catch((error) => {
        toErrorPage(error);
      });
  }

  const handleCVUploadPopup = (candidateId: number) => {
    setCandidateId(candidateId);
    setShowCVUploadPopup(true);
  }

  const handleChangeState = (stage: string) => {
    if(callbackUrl !== null) {
      searchParams.set("callbackUrl", callbackUrl);
    }
    if(message !== null) {
      searchParams.set("message", message);
    }
    navigate("/candidates/" + stage + `?${searchParams}`);
  }

  return (
    <>
      <Helmet><title>Candidate Account - XpressJobs</title></Helmet>
      <Header styleSheet="lowestHeader" backButtonEnable={stage === "lostPassword" || stage === "resetPassword"} backButtonText="Back" handleBackButtonClick={() => {handleChangeState("login")}} />
      <div style={styles.mainWrapper}>
        <div style={styles.leftWrapper}>
          <div style={{...styles.candidatesSvg, ...(stage === "register" && styles.candidatesSvg.register)}}></div>
          <div style={styles.buildingSvg}></div>
        </div>
        <div style={styles.rightWrapper}></div>
        {stage === "login" && <Transition>
          <Login styles={styles} callbackUrl={callbackUrl} message={message} title={isMobile ? "Login" : "Job Seeker Login"} changeStage={handleChangeState} openAlert={openAlert} showCVUploadPopup={handleCVUploadPopup} />
        </Transition>}
        {stage === "register" && <Transition>
          <Register styles={styles} callbackUrl={callbackUrl} message={message} title={isMobile ? "Register" : "Job Seeker Register"} changeStage={handleChangeState} openAlert={openAlert} showCVUploadPopup={handleCVUploadPopup} />
        </Transition>}
        {stage === "lostPassword" && <Transition>
          <LostPassword styles={styles} openAlert={openAlert} />
        </Transition>}
        {stage === "resetPassword" && <Transition>
          <ResetPassword styles={styles} email={searchParams.get("email")!} token={searchParams.get("token")!} openAlert={openAlert} />
        </Transition>}
        {showCVUploadPopup && <Transition styles={styles.cvUpload.transition}>
          <CVUploadPopup styles={styles.cvUpload} candidateId={candidateId!} openAlert={openAlert} />
        </Transition>}
      </div>
      <Footer />
      <Alert />
    </>
  );
};
