import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { TextInput } from '../Shared/Inputs/TextInput';
import { getResetPasswordValidity, postResetPassword } from '../../services/CandidateService';
import { ResponseModel } from '../../models/ResponseModel';
import { EmailPasswordModel, EmailPasswordValidation } from '../../models/EmailPasswordModel';
import { useNavigate } from 'react-router-dom';
import useErrorPage from '../../hooks/useErrorPage';
import { AuthContext } from '../../providers/AuthContextProvider';
import { ResetPasswordLoader } from '../Loaders/ResetPasswordLoader';

interface Props {
    styles: any;  
    email: string;
    token: string;
    openAlert(status: string, message: string): void;
}

const initialPasswordValidation: EmailPasswordValidation = {
    newPassword: {},
    confirmNewPassword: {}
}

export const ResetPassword: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const intialFormInputs: EmailPasswordModel = {
        email: props.email,
        token: props.token,        
        newPassword: "",  
        confirmNewPassword: ""
    }
    const [validation, setValidation] = useState<EmailPasswordValidation>(initialPasswordValidation);
    const navigate = useNavigate();
    const [passwordModel, setPasswordModel] = useState<EmailPasswordModel>(intialFormInputs);
    const [toErrorPage] = useErrorPage();
    const styles = props.styles;
    const authContext = useContext(AuthContext);

    useEffect(() => {         
        validateRequest();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setPasswordModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {
        if(name === "newPassword") {    
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "confirmNewPassword") {
            const password = passwordModel.newPassword;      
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}}));
            } 
            else if (password !== value) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }        
    }    

    const validateForm = () => {
        let formValidation: EmailPasswordValidation = {
            newPassword: {valid: true},
            confirmNewPassword: {valid: true}
        }
        if(passwordModel.newPassword === ""){
            formValidation.newPassword = {valid: false, invalid: true, invalidMessage: "Password is required"}
        }
        if(passwordModel.confirmNewPassword === ""){
            formValidation.confirmNewPassword = {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}
        }
        else if (passwordModel.newPassword !== passwordModel.confirmNewPassword) {
            formValidation.confirmNewPassword = {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"};
        }
        setValidation(formValidation);
    }

    async function validateRequest() {
        setLoading(true);
        await getResetPasswordValidity(props.email, props.token)
        .then((response: ResponseModel) => {
            setLoading(false);
            if(!response.status) {
                props.openAlert("error", response.message);
            }
        })      
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    async function handleSubmitClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const validInputCount = Object.keys(validation).filter(key => validation[key]!.valid).length;        
        if(validInputCount === 2){
            setLoading(true);
            await postResetPassword(passwordModel)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.status) {
                    authContext.setLoggedCandidate(response.data, response.token);            
                    navigate('/candidate/home');            
                }
                else {
                    props.openAlert("error", response.message);
                }
            })
            .catch((error) => {            
                toErrorPage(error);
            });
        }
        else {                        
            validateForm();            
        }
    }
    
    return (
        <>
        {loading ? <ResetPasswordLoader/> : 
        <form style={{...styles.passwordForm, ...styles.passwordForm.reset}} onSubmit={handleSubmitClick}>
            <h5 style={styles.title}>Change Password</h5>
            <p style={{...styles.subTitle, ...styles.subTitle.reset}}>Please choose a new password.</p>
            <TextInput
                label={'New Password *'}
                inputType={'password'}
                name={"newPassword"}
                handleChange={handleInputChange}
                value={passwordModel.newPassword}
                handleBlur={handleInputBlur}
                validation={validation.newPassword}
            />
            <TextInput
                label={'Confirm New Password *'}
                inputType={'password'}
                name={"confirmNewPassword"}
                handleChange={handleInputChange}
                value={passwordModel.confirmNewPassword}
                handleBlur={handleInputBlur}
                validation={validation.confirmNewPassword}
            />
            <Button type="submit" disabled={loading} style={{...styles.submitButton, ...styles.resetButton}}>{"RESET PASSWORD"}</Button>
        </form>}
        </>
    )
}