import { Color } from "../enums/Color"

export const mobileMenu = {
    mobile: {                                   
        layer: {
            display: "none",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            inset: "0",
            zIndex: "3",
            boxShadow: "1px 0px 7px rgba(0,0,0,0.5)",
            open: {
                display: "block"
            }
        },
        wrapper: {
            background: Color.DeepBlack,
            width: "70%",
            height: "100%",
            right: "0",
            position: "fixed",
            zIndex: "4",           
            transition: "transform 0.3s ease-out",
            overflow: "auto"
        },
        open: {
            transform: "translateX(0)"
        },
        close: {
            transform: "translateX(100%)"
        },
        closeButton: {
            backgroundColor: "transparent",
            marginTop: "7.72vw",
            marginLeft: "7.24vw",                         
            border: "none"
        },
        closeIcon: {
            width: "6.03vw",
            height: "6.03vw",
            fill: Color.LightAsh                
        },
        menu: {
            listStyleType: "none",
            textAlign: "center",
            padding: "0"  
        },
        menuItem: {
            marginBottom: "4.86vh",
            WebKitTabHighLightColor: "transparent"
        },
        dropdown: {                        
            wrapper: {
                position: "relative" as "relative",
                display: "inline-block"   
            },
            button: {
                backgroundColor: "transparent",
                color: Color.LightAsh,
                fontSize: "4.34vw",                
                fontWeight: "bold" as "bold",
                border: "none",
                cursor: "pointer",
                WebKitTapHighLightColor: "transparent",
                paddingBottom: "4vw"
            },
            item: {
                paddingLeft: "1.38vw",
                paddingRight: "1.38vw",
                textAlign: "left"
            },
            labelWrapper: {        
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center"
            },
            labelIcon: {
                width: "3.33vw",
                height: "3.33vw",
                fill: Color.LightAsh,                
                marginLeft: "0.8vw",
                marginTop: "0.5vh",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            label: {             
                textDecoration: "none",
                fontSize: "4.34vw",                                
                letterSpacing: "0px",
                color: Color.LightAsh,
                opacity: "1" 
            },
            leave: {
                display: "none"            
            },
            hover: {                
                display: "block",
                position: "relative" as "relative", 
                width: "90%", 
                height: "auto",
                margin: "auto",
                padding: "1.38vw",                       
                backgroundColor: Color.White,                                
                borderRadius: "2.77vw",
                zIndex: 1
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }  
        },            
        recruitersButton: {                            
            width: "80%",
            height: "12.07vw",
            marginLeft: "10%",                
            marginTop: "3.38vw",        
            color: Color.White,            
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "2.41vw",
            cursor: "pointer",
            fontSize: "4.34vw"   
        },   
        jobSeekersButton: {                                         
            width: "80%",
            height: "12.07vw",
            marginLeft: "10%",                
            marginTop: "2.70vh",        
            color: Color.White,            
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            borderRadius: "2.41vw",
            cursor: "pointer",
            fontSize: "4.34vw"
        },      
        userDropdown: {                        
            wrapper: {
                position: "relative" as "relative",
                textAlign: "center"
            },        
            button: {
                backgroundColor: "transparent",
                color: Color.LightAsh,
                fontSize: "4.34vw",                
                fontWeight: "bold" as "bold",
                border: "none",
                cursor: "pointer"
            },
            item: {
                paddingLeft: "1.38vw",
                paddingRight: "1.38vw",
                textAlign: "left"
            },            
            labelWrapper: {        
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center" as "center"
            },
            labelIcon: {
                width: "4.16vw",
                height: "4.16vw",
                fill: Color.LightAsh,                
                marginLeft: "2.77vw",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            label: {                             
                textDecoration: "none",       
                letterSpacing: "0px",                
                color: Color.LightAsh,
                opacity: "1" 
            },
            leave: {
                display: "none"            
            },
            hover: {                
                display: "block",
                width: "48.8vw",
                margin: "2.70vh auto 2.70vh",
                padding: "1.38vw", 
                fontSize: "4vw",            
                backgroundColor: Color.White,            
                boxShadow: "0 0.8vw 2.77vw rgb(0 0 0 / 0.5)",
                borderRadius: "2.77vw",
                zIndex: 2
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        },
        userAvatar: {
            height: "13.05vw",
            width: "13.05vw",
            border: "1.38vw solid " + Color.Green,
            borderRadius: "50%",                        
            marginRight: "4.16vw",
            objectFit: "cover"
        }
    },
    desktop: {    
        display: "none"        
    }
}
