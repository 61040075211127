import React, { useState } from 'react';
import { Sector } from '../../models/Sector';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Button } from '../Shared/Buttons/Button';

interface Props {
    styles: any;
    jobCategory: Sector;
};

export const JobCategoryCard: React.FC<Props> = (props) => {
    const [hover, setHover] = useState<boolean>(false);
    const styles = props.styles;

    return (
        <div onMouseEnter={() => { setHover(true); }} onMouseLeave={() => { setHover(false); }} style={{...styles.wrapper, ...(hover && styles.wrapper.hover)}}>
            <p style={styles.category}>{props.jobCategory.sectorName}</p>
            <div style={styles.jobCount}>
                <div>{props.jobCategory.sectorCount}</div>
                <div style={styles.label}>Job Posts</div>
            </div> 
            <div style={{...styles.buttonWrapper, ...(hover && styles.buttonHover)}}>                
                <Button style={styles.button}><RightArrow style={styles.buttonIcon}/></Button>
            </div>
        </div>
    );
}