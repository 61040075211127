import React, { useState } from 'react';

interface Props {    
    styles: any;
    onClick?(): void;
}


export const DropdownItem: React.FC<Props> = (props) => {
    const [hover, setHover] = useState(false);
    const styles = props.styles;
    
    const handleOnClick = (event: any) => {
        event.stopPropagation();
        if(props.onClick) {
            props.onClick();
        }        
    }

    return (
        <div
            onMouseEnter={()=>{ setHover(true); }}
            onMouseLeave={()=>{ setHover(false); }} 
            style={{...styles,...(hover ? styles.hover : styles.leave)}} onClick={(e) => handleOnClick(e)}>
            {props.children}
        </div>
    );
}