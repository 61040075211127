import ContentLoader from "react-content-loader";
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import { Color } from "../../enums/Color";

const styles = {
    mobile: {
        wrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            margin: "29px 0"
        },
        card: {
            display: "inline-block",
            width: "100%",
            height: "15.2vh"
        }        
    },
    desktop: {
        card: {
            width: "96%",
            height: "15.6vh"
        }
    }
};

export const QuizFormLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles  = styles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(styles.mobile, styles.desktop); 
        loaderStyles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    for (let i = 0; i < 4; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={loaderStyles.card.height}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0%" y="10" rx="8" ry="8" width="100%" height="12"/>
                    <rect x="0%" y="28" rx="8" ry="8" width="68%" height="12"/>
                    {isMobile ? <>
                        <rect x="2%" y="52" rx="5" ry="5" width="40%" height="12"/>
                        <rect x="58%" y="52" rx="5" ry="5" width="26%" height="12"/>
                        <rect x="2%" y="78" rx="5" ry="5" width="28%" height="12"/>
                        <rect x="58%" y="78" rx="5" ry="5" width="36%" height="12"/>
                    </> :
                    <>
                        <rect x="2%" y="56" rx="5" ry="5" width="15%" height="16"/>
                        <rect x="26%" y="56" rx="5" ry="5" width="15%" height="16"/>
                        <rect x="50%" y="56" rx="5" ry="5" width="15%" height="16"/>
                        <rect x="74%" y="56" rx="5" ry="5" width="15%" height="16"/>
                    </>}
                </ContentLoader>
            </div>
        )
    }
    return (
        <div style={loaderStyles.wrapper}>
            {loaderElements}
        </div>
    );
}