export const horizontalBanner = {
    mobile: {
        wrapper: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        sliderOne: {
            display: "block"
        },
        sliderTwo: {
            display: "none"
        },
        banner: {
            width: "100%",
            background: "transparent",
            borderRadius: "1vw",
            marginLeft: "auto",
            marginRight: "auto",
            opacity: "1",
            objectFit: "contain",
            cursor: "pointer"
        },
        dots: {
            display: "flex",
            justifyContent: "center",
            paddingLeft: "unset",
            gap: "1.21vw"
        },
    },
    desktop: {
        wrapper: {
            flexDirection: "row"
        },
        sliderOne: {
            width: "49%",
            marginRight: "1%",
            marginBottom: "unset"
        },
        sliderTwo: {
            width: "49%",
            marginLeft: "1%",
            display: "block"
        },
        banner: {
            background: "transparent",
            borderRadius: "0.8vw",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "unset",
            opacity: "1"
        },
        dots: {
            marginTop: "-3vw",
            gap: "0.26vw"
        }
    }
}