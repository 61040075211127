import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Footer } from '../components/Shared/Footer';
import { GetInTouch } from '../components/Shared/GetInTouch';
import { Header } from '../components/Shared/Header'
import { StyleContext } from '../providers/StyleContextProvider';
import { ReactComponent as SuccessfullyApplied } from '../assets/svg/successfully-applied.svg'
import { ReactComponent as InvalidApplied } from '../assets/svg/invalid-applied.svg'
import useErrorPage from '../hooks/useErrorPage';
import { Button } from '../components/Shared/Buttons/Button';
import { downloadCVFormats } from '../services/CheckoutService';
import { ConfirmationLoader } from '../components/Loaders/ConfirmationLoader';
import { Transition } from '../components/Shared/Transition';
import { Helmet } from 'react-helmet-async';

export const CheckoutResponsePage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const checkoutId = searchParams.get("checkoutId");
    const checkoutTypeId = searchParams.get("checkoutTypeId");
    const success = searchParams.get("success") === "True";
    const message = searchParams.get("message");
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("confirmationPage"));
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [toErrorPage] = useErrorPage();    

    useEffect(() => {
        if(checkoutTypeId === "1" && success) {            
            downloadCVFormatList();
        }      
    }, []);

    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("confirmationPage"));
    }, [isMobile]);

    async function downloadCVFormatList() {
        await downloadCVFormats(+checkoutId!)
        .then((response: any) => {
           
        })
        .catch((error) => {
            toErrorPage(error);            
        });
    }

    return (
        <>  
            <Helmet><title>Checkout - Xpressjobs</title></Helmet>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"Checkout"}/>
            <div style={styles.container}>
                {loading ? <ConfirmationLoader /> : <Transition>
                    {/* replace un-successfull image here */}
                    {success ? <SuccessfullyApplied style={styles.image}/> : <InvalidApplied style={styles.image}/>}
                    <h3 style={{...styles.title, ...styles.title.checkout}}>{success ? "Payment Successful" : "Invalid Payment Request"}</h3>                                        
                    <div style={styles.description}>{message}</div>
                    <div style={styles.buttonsContainer}>
                        {(checkoutTypeId === "1") ? 
                          <Button style={styles.button} onClick={() => navigate("/candidate/myapplications")}>VIEW MY APPLICATIONS</Button> :
                          <Button style={styles.button} onClick={() => navigate("/candidate/updateprofile")}>VIEW PREMIUM STATUS</Button> }
                        <Button style={{...styles.button, ...styles.button.back}} onClick={() => navigate('/')}>GO BACK TO HOME</Button>
                    </div>
                </Transition>}       
            </div>
            <GetInTouch/>
            <Footer/>
        </> 
    )
}