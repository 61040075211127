import { Color } from "../enums/Color"
import { commonHeader } from "./CommonHeader"

export const searchHeader = {
    mobile: {
        ...commonHeader.mobile,
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "48.31vw",
            opacity: "1",
            position: "relative"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "79.46vw",
            height: "44.44vw",
            backgroundSize: "79.46vw 44.44vw"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            WebkitTapHighlightColor: "transparent",            
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        heading: {
            display: "none"
        },
        text: {
            display: "none"
        },
        jobTitleWrapper: {
            display: "none"
        },
        organization: {
            display: "none"
        }
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "20.83vw"
        },
        longBuildingSvg: {                                    
            width: "35.76vw",
            height: "20.06vw",
            backgroundSize: "35.76vw 20.06vw"
        },
        recruitersButton: {
            display: "inline"
        },
        jobSeekersButton: {
            display: "inline"
        },
        heading: {
            width: "auto",
            marginTop: "10vh",
            height: "unset",
            marginBottom: "1vh",
            fontSize: "2.5vw",
            moveDown: {
                marginTop: "10vh"
            }
        }
    }
}
