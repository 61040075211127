import React, { useState } from 'react';

export interface Props {
    type?: "button" | "submit" | "reset" | undefined;
    style: any;
    disabled?: boolean;
    icon?: string;
    onClick?(params: any): void;
    className?: string;
};

export const Button: React.FC<Props> = (props) => {
    const [hover, setHover] = useState(false);

    return (
        <button
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            type={props.type}
            style={{...props.style, ...(hover && props.style.hover)}}
            disabled={props.disabled}
            onClick={props.onClick}
            className={props.className}>
            {props.children}
        </button>
    );
}