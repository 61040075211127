import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const styles = {
    mobile: {
        card: {
            display: "inline-block",
            width: "100%",
            margin: "1%"
        },
        wrapper: {
            marginRight: "4.83vw",
            marginLeft: "4.83vw"
        },
    },
    desktop: {
        card: {
            width: "23%",
            flex: "none",
        },
        wrapper: {
            marginRight:"calc(7.29vw - 1%)",
            marginLeft:"calc(7.29vw - 1%)"
        },
    }
};
 
export const ShortJobCardListLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles  = styles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(styles.mobile, styles.desktop); 
        loaderStyles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 2 : 8;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={180}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="60%" rx="8" ry="8" width="40%" height="7%" />
                    <rect x="0" y="45%" rx="8" ry="8" width="55%" height="7%" />
                    <rect x="0" y="70%" rx="8" ry="8" width="60%" height="7%" />
                    <rect x="0" y="2%" rx="8" ry="8" width="76" height="76" />
                    <rect x="0" y="90%" rx="8" ry="8" width="28%" height="7%" />
                    <rect x="37%" y="90%" rx="8" ry="8" width="28%" height="7%" />
                    <rect x="55%" y="2%" rx="5" ry="5" width="20%" height="7%" />
                    <rect x="70%" y="90%" rx="8" ry="8" width="28%" height="7%" />
                    <rect x="70%" y="125" rx="8" ry="8" width="28%" height="7%" />
                    <rect x="80%" y="2%" rx="5" ry="5" width="20%" height="7%" />

                </ContentLoader>
            </div>
        )
    }

    return (
        <div style={loaderStyles.wrapper}>
            {loaderElements}
        </div>

    );
}