import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const statistics = {
    mobile: {
        overflow: "hidden",
        background: Color.Blue,        
        marginTop: "18%",
        width: "100%",
        height: "64.25vw",
        opacity: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        count: {
            textAlign: "center",
            fontSize: "10.86vw",
            fontFamily: Font.Advanced,
            color: Color.White,
            margin: "0"
        },
        name: {
            textAlign: "center",
            margin: "0",
            fontSize: "4.83vw",
            fontFamily: Font.Advanced,
            color: Color.Black,
        },
        dots: {
            top: "170%"
        },
        curve1: {
            position: "absolute",
            top: "-50%",
            left: "-23%",
            width: "100%",
            height: "100%"
        },
        curve2: {
            position: "absolute",
            top: "-18%",
            left: "-35%"
        },
        buildingSvg: {
            position: "absolute",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/long_building_mobile.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "170% 165%",
            backgroundPosition: "center bottom",
            inset: "0"

        }
    },
    desktop: {
        marginTop: "6.25vw",
        height: "20.20vw",
        count: {
            fontSize: "2.86vw"
        },
        name: {
            fontSize: "1.56vw"
        },
        dots: {
            display: "none"
        },
        buildingSvg: {
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/long_building_desktop.svg)",
            backgroundSize: "100% 79%",
        },
        sliderWrapper: {
            margin: "auto 5.83vw"
        }
    }
}