import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const disabilityFriendlyPage = {
    mobile: {
        container: {
            margin: "24px 5%"
        },
        pictureAlbum: {
            position: "relative",
            justifyContent: "center",
            display: "none"
        },
        albumPicture: {
            height: "fit-content",
            flexShrink: "0"
        },
        albumPicture3: {
            marginRight: "0"
        },
        friendlyEnvironment: {
            margin: "32.6vw 4.83vw 0 4.83vw"
        },
        disabledLadyPicture: {
            margin: "-18.11vw 4.83vw",
            width: "90.33vw",
            height: "67.149vw",
            position: "relative",
            objectFit: "cover",
            borderRadius: "4.83vw"
        },
        postTitle: {
            fontSize: "4.83vw",
            fontWeight: "bold",
            color: Color.DeepBlack
        },
        postTitleSpan: {
            color: Color.Blue
        },
        postContent: {
            margin: "0",
            color: Color.MiddleBlack,
            fontFamily: Font.Poppins
        },
        placement: {
            display: "block",
            marginTop: "21.98vw"
        },
        placementImage: {
            width: "95.16vw",
            borderRadius: "0 4.85vw 4.85vw 0"
        },
        placementText: {
            margin: "12.077vw 4.83vw"
        },
        logoGride: {
            marginTop: "19.32vw",
            overflow: "hidden",
            logo: {
                marginRight: "8.69vw",
                width: "16.66vw",
                height: "16.66vw",
                background: "transparent",
                borderRadius: "2.77vw",
                objectFit: "contain",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                WebkitTapHighlightColor: "transparent",
                normal: {
                    WebkitFilter: "none",
                    filter: "none",
                    opacity: "1"
                },
                hover: {
                    WebkitFilter: "unset",
                    filter: "unset",
                    opacity: "1"
                }
            },
            loaderContainer: {
                marginTop: "6.52vw"
            },
            nextRow: {
                marginTop: "7.97vw"
            },
            loaderBox: {
                marginTop: "7.97vw",
                width: "16.66vw",
                height: "16.66vw",
                display: "inline-block",
                marginRight: "6.03vw"
            },
            row: {
                display: "flex",
                overflowX: "scroll",
                justifyContent: "center",
                paddingLeft: "20vw"
            }
        },
        offerJob: {
            backgroundColor: Color.DeepBlack,
            color: Color.White,
            textAlign: "center",
            borderRadius: "4.83vw",
            margin: "12.56vw auto 0 auto",
            width: "90.33vw",
            height: "102.17vw",
            position: "relative",
            overflow: "hidden"
        },
        offerJobBuilding: {
            position: "absolute",
            bottom: "-3vw",
            left: "0"
        },
        offerJobTitle: {
            fontWeight: "bold",
            fontSize: "7.246vw",
            width: "56.52vw",
            margin: "0 auto 0 auto"
        },
        offerJobContent: {
            margin: "4.83vw 0 0 0",
            color: Color.White,
            width: "73.42vw",
            fontFamily: Font.Poppins
        },
        offerJobText: {
            margin: "0"
        },
        bottomText: {
            width: "90.33vw",
            margin: "7.48vw 4.83vw 21.98vw 4.83vw",
            textAlign: "justify",
            color: Color.SharpAsh,
            fontSize: "3.38vw"
        }
    },
    desktop: {
        pictureAlbum: {
            margin: "-3.28vw 7.29vw 6.77vw",
            display: "flex"
        },
        albumPicture: {
            width: "20.416vw",
            marginRight: "1.2vw"
        },
        albumPicture1: {
            marginTop: "-4.32vw"
        },
        albumPicture2: {
            marginTop: "-3.02vw"
        },
        postTitle: {
            fontSize: "1.82vw",
            marginBottom:"1.56vw"
        },
        postContent: {
            fontSize: "0.93vw",
            color: "color.SharpAsh"
        },
        logoGride: {
            marginTop: "6.25vw",
            logo: {
                width: "3.8vw",
                height: "3.8vw",
                borderRadius: "0.52vw",
                marginRight: "3.69vw"
            },
            loaderBox: {
                width: "5.2vw",
                height: "5.2vw",
                marginRight: "2.34vw",
                marginTop: "1.30vw"
            },
            loaderContainer: {
                marginTop: "4.90vw"
            },
            row: {
                paddingLeft: "0"
            },
            nextRow: {
                marginTop: "1.30vw"
            }
        },
        friendlyEnvironment: {
            width: "42.29vw",
            margin: "6.77vw auto 0 auto"
        },
        disabledLadyPicture: {
            display: "none"
        },
        placement: {
            display: "flex",
            alignItems: "center",
            marginTop: "8.85vw"
        },
        placementImage: {
            width: "50vw",
            borderRadius: "0 2.41vw 2.41vw 0"
        },
        placementText: {
            width: "37.29vw",
            margin: "0 0 0 7.29vw"
        },
        offerJob: {
            width: "72.70vw",
            height: "21.875vw",
            borderRadius: "1.04vw",
            margin: "8.33vw auto 0 auto"
        },
        offerJobBuilding: {
            width: "100%"
        },
        offerJobTitle: {
            fontSize: "2.86vw",
            width: "51.51vw",
            height:"6.30vw"
        },
        offerJobContent: {
            fontSize: "0.937vw",
            width: "49.68vw",
            margin: "1.25vw 0 0 0"
        },
        offerJobText: {
            margin: "0"
        },
        bottomText: {
            width: "65.41vw",
            margin: "2.083vw auto 8.59vw auto",
            fontSize: "0.937vw",
            height:"2.70vw",
            textAlign:"center",
            fontFamily: Font.Poppins
        }
    }
}