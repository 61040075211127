import React, { useState } from 'react';
import { Organization } from '../../models/Organization';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { ReactComponent as Heart} from '../../assets/svg/heart.svg';
import { Logo } from '../Shared/Logo';
import { Button } from '../Shared/Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { getSEOKeyword } from '../../helpers/Common';

interface Props {
    styles: any;
    organization: Organization
};

export const TopHiringOrganizationCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState<boolean>(false);
    const styles = props.styles;    
    const organization = props.organization;

    const onClick = (event: any) => {
        event.stopPropagation();
        navigate("/organization/" + organization.organizationId + "/" + getSEOKeyword(organization.organizationName))
    }

    return (
        <div 
            onMouseEnter={() => { setHover(true); }}
            onMouseLeave={() => { setHover(false); }} 
            style={{...styles.wrapper, ...(hover && styles.wrapper.hover)}} 
            onClick={onClick}
        >
            <Logo style={styles.logo} organizationId={organization.organizationId} organizationName={organization.organizationName} hasLogo={organization.hasLogo} logoUri={organization.logoUri} />
            <div style={{...styles.contents, ...( hover && styles.contents.hover)}}>
                <div style={styles.companyName}>{organization.organizationName}</div>
                {/* <Heart style={styles.icon}/> <br/> */}
                <div style={styles.jobCount}>{organization.liveJobCount} <span style={styles.label}>Job Posts</span></div>
            </div> 
            <div style={{...styles.buttonWrapper, ...( hover && styles.buttonWrapper.hover)}}>                
                <Button style={styles.button}><RightArrow style={styles.buttonIcon}/></Button>
            </div>
        </div>
    );
}