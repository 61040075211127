import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const submitArea = {
    mobile: {
        wrapper: {
            margin: "6.13vh auto 10vh auto",
            textAlign: "center"
        },
        submitNotice: {
            color: Color.MiddleBlack,
            textAlign: "center" as "center",
            fontFamily: Font.Default,
            letterSpacing: "0px",
            fontSize: "3.38vw",
            margin: "0 0 4.46vh 0",
            lineHeight: "5.5vw"
        },
        buttonsContainer: {
            display: "flex",
            width: "100%",
            marginLeft: "unset",
            flexDirection: 'column', 
            alignItems: 'center'
        },
        submitButton: {
            backgroundColor: Color.Green,            
            borderRadius: "2.41vw",
            color: Color.White,
            height: "12.08vw",
            width: "100%",
            border: "none",
            textTransform: "uppercase",
            fontSize: "4.34vw",            
            fontWeight: 500,
            boxShadow: "5px 5px 50px #00000027"
        },
        saveDraft: {
            background: "transparent",
            border: "none",            
            fontWeight: "bold" as "bold",
            color: Color.SharpAsh,
            fontSize: "4.34vw",
            marginTop: "2.94vw",
            extendTop: {
                marginTop: "10vw"
            }
        },
        cancel: {
            display:  "block",
            background: "transparent",
            border: "none",            
            fontWeight: "bold" as "bold",
            color: Color.DeepAsh,
            fontSize: "4.34vw",
            marginTop: "9.66vw"
        },
        backButton: {
            display: "none",
            height: "37px",
            width: "180px",
            background: "transparent",
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            fontSize: "4.34vw",
            letterSpacing: "0px",
            textTransform: "uppercase",
            color: Color.Blue,
            border: "3px solid " + Color.Blue,
            borderRadius: "2.41vw",
            marginTop: "5.24vh"
        }
    },
    desktop: {
        wrapper: {
            width: "47.08vw",
            margin: "8.33vh auto 0",
            textAlign: "unset"
        },
        buttonsContainer: {
            marginLeft: "14.9vw",
            flexDirection: "row"
        },
        submitNotice: {
            fontSize: "0.83vw",
            margin: "0 0 4.16vh 0",
            lineHeight: "2.8vh",
            maxWidth: "97%"
        },
        submitButton: {
            width: "18.125vw",
            height: "3.64vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw"
        },
        saveDraft: {
            marginLeft: "1.56vw",
            fontSize: "0.93vw",
            extendTop: {
                marginTop: "unset"
            }
        },
        cancel: {
            display:  "none"
        },
        backButton: {
            display: "block",
            height: "2.60vw",
            width: "12.91vw",
            borderRadius: "0.52vw",
            fontSize: "1.04vw",
            marginTop: "1.85vh",
            marginLeft: "17.5vw"
        }
    }
}