import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { XpressJobsTitle } from './XpressJobsTitle';
import { Button } from './Buttons/Button';
import { MenuItem } from '../../models/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { ReactComponent as MobileMenu } from '../../assets/svg/mobile_menu.svg';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenuDrawer } from './MobileMenuDrawer';

export const AdminHeader: React.FC= () => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle('adminHeader'));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle('adminHeader'));
    }, [isMobile]);

    const logOut = () => {
        sessionStorage.removeItem('user');
        navigate("/login")
    }
    const menuItems: MenuItem[] = [
        { isList: false, item: <Link to='/' style={styles.label}>Home</Link> },
        { isList: false, item: <a href='https://xpress.jobs/recruiter/vacancy' style={styles.label}>Vacancy manager</a> },
        { isList: false, item: <p style={styles.label} onClick={logOut}>Logout</p> },
    ];
    const handleRecruitersButtonClick = () => {

    }

    const handleJobSeekersButtonClick = () => {

    }

    const handleMobileMenuToggle = () => {
        setMobileMenuToggle((prev) => !prev);
    }

    return (
        <div style={styles.wrapper}>
            <XpressJobsTitle style={styles.title}/>
            <DesktopMenu menuItems={menuItems}/>
            <Button type="button" style={styles.mobileMenuButton} onClick={handleMobileMenuToggle}><MobileMenu/></Button>
            <MobileMenuDrawer open={mobileMenuToggle} toggle={handleMobileMenuToggle} menuItems={menuItems} recruitersLogin={handleRecruitersButtonClick} jobSeekersLogin={handleJobSeekersButtonClick} candidate={null} recruiter={null} candidateProfileItems={[]} />
        </div>
    )
}