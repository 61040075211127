import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const Input = {
    mobile: {
        display: "flex",
        position: "relative" as "relative",
        width: "100%",
        height: "12.08vw",
        borderRadius: "1.2vw",
        padding: "2.78vw",
        fontFamily: Font.Default,
        fontSize: "4.44vw",
        marginTop: "1.5vh",
        backgroundColor: Color.LightAsh,
        boxSizing: "border-box" as "border-box",
        alignItems: "center",
        color: Color.SharpAsh,
        transition: "all .3s ease-in-out",
        textArea: {
            height: "43.72vw",
            alignItems: "start"
        },
        active: {
            backgroundColor: Color.White,
            border: "1px solid " + Color.SharpAsh
        },
        valid: {
            backgroundColor: Color.LightBlue,
            border: "1px solid " + Color.Blue
        },
        invalid: {
            backgroundColor: Color.CoralRed,
            border: "1px solid " + Color.Red,
            color: Color.Red
        },
        inputField: {
            border: "none",
            background: "transparent",
            width: "100%",
            height: "100%",
            fontSize: "16px",
            fontFamily: Font.Poppins,
            moveDown: {
                marginTop: "4vw"
            }
        },
        fileField: {
            display: "none"
        },
        editDisabled: {
            cursor: "not-allowed",
            color: Color.DeepBlack
        },
        areaInputField: {
            padding: "10px 0 0 0",
            resize: "none"
        },        
        floatingLabel: {
            fontFamily: Font.Poppins,
            fontSize: "3.86vw",
            position: "absolute" as "absolute",
            pointerEvents: "none",
            transform: "translate(0, 0) scale(1)",
            transformOrigin: "top left",
            transition: "200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            moveUp: { 
                fontSize: "2.90vw",
                transform: "translate(0, -2.40vw) scale(0.9)" 
            },
            moveUpTextArea: { 
                transform: "translate(0, -0.8vh) scale(0.9)" 
            }
        },
        iconButton: {
            display: "flex",
            alignItems: "center",
            WebkitTapHighlightColor: "transparent",
            background: "transparent"
        },
        icon: {
            width: "4.17vw",
            height: "4.17vw",
            fill: Color.SharpAsh,
            active: { 
                fill: Color.LightBlack 
            }
        },
        fileIcon: {
            margin: "0 1.93vw 0 auto"
        },
        selectedFileName: {
            fontSize: "3.83vw",
            fontFamily: Font.Poppins,            
            marginTop: "7.4vw"
        },
        radioInput: {
            margin: "auto 2.41vw auto 5.2vw",
            width: "3.83vw",
            height: "3.83vw",
            boxShadow: "0 0 0 2px " + Color.Gainsboro,
            border: "solid 3px " + Color.White
        },
        radioButton: {
            width: "2.78vw",
            height: "1.35vh",
            borderRadius: "50%",
            border: "2px solid " + Color.Gainsboro,
            padding: "0.83vw",
            margin: "auto",
            cursor: "pointer",
            wrapper: { 
                display: "inline-flex" 
            },
            inner: {
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundColor: Color.Blue,
                unChecked: {
                    opacity: "1"
                }
            },
            text: {
                fontFamily: Font.Default,
                fontSize: "4.44vw",
                color: Color.DeepBlack,
                margin: "auto",
                cursor: "pointer"
            }
        },
        checkboxWrapper: {
            display: "flex",
            alignItems: "center"
        },
        checkboxLabel: {
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent"
        },
        customCheckBoxContainer: {
            paddingLeft: "7vw",
            lineHeight: "7vw"
        },
        checkbox: {
            width: "4vw",
            height: "4vw",
            borderRadius: "5px",
            margin: "1vw 2.41vw 0 0"
        },
        switch: {
            backgroundColor: Color.LightGray,
            borderRadius: "69.44vw",
            cursor: "pointer",
            display: "flex",
            height: "5vw",
            width: "11.11vw",
            padding: "0.56vw",
            margin: "auto",
            marginRight: "unset",
            WebkitTapHighlightColor: "transparent",
            checked: {
                backgroundColor: Color.SharpGreen
            }
        },
        switchCircle: {
            height: "5vw",
            width: "5vw",
            borderRadius: "50%",
            backgroundColor: Color.White,
            checked: {
                marginLeft: "auto",
                backgroundColor: Color.Green
            }
        },
        inputError: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            fontSize: "4.44vw",
            textAlign: "center",
            icon: {
                width: "4.17vw",
                height: "4.17vw",
                overflow: "unset"
            }
        }
    },
    desktop: {
        height: "3.64vw",
        borderRadius: "0.52vw",
        padding: "0.70vw",
        fontSize: "0.93vw",
        marginTop: "1.5vh",
        areaInputField: {
            padding: "14px 0 0 0",
        },
        textArea: {
            width:"100%",
            height: "12.23vw", 
            alignItems: "start"
        },
        inputField: {
            fontSize: "0.83vw",
            marginLeft: "0.5vw",
            moveDown: {
                marginTop: "0.83vw"
            }
        },
        icon: {
            width: "1.05vw",
            height: "1.05vw",
            right: "1.75vw",
            top: "1.05vw",
        },
        fileIcon: {
            margin: "0 0.42vw 0 auto"
        },
        selectedFileName: {
            fontSize: "0.83vw",
            marginLeft: "0.5vw",
            marginTop: "1.63vw"
        },
        customCheckBoxContainer: {
            paddingLeft: "1.7vw",
            lineHeight: "1vw"
        },
        checkbox: {
            height: "0.8vw",
            width: "0.8vw",
            borderRadius: "5px",
            margin: "0 0.52vw 0 0"
        },
        radioInput: {
            margin: "0 0.52vw 0vw 1.14vw",
            border: "solid 3px "+ Color.White,
            width: "0.8vw",
            height: "0.8vw"
        },
        radioButton: {
            width: "0.70vw",
            height: "1.60vh",
            padding: "0.21vw",
            text: {
                fontSize: "1.12vw"
            }
        },
        switch: {
            borderRadius: "17.50vw",
            height: "1.04vw",
            width: "2.08vw",
            padding: "0.15vw 0.2vw"
        },
        switchCircle: {
            height: "1.04vw",
            width: "1.04vw"
        },
        inputError: {
            fontSize: "1vw",
            icon: {
                width: "1.05vw",
                height: "1.05vw"
            }
        },
        floatingLabel: {            
            fontSize: "0.94vw",
            moveUp: { 
                fontSize: "0.73vw",
                transform: "translate(0, -0.42vw) scale(0.9)" 
            },
            marginLeft:"0.5vw"
        }
    }
}