import React from 'react';
import { ReactComponent as Crown } from '../../assets/svg/crown.svg';

interface Props {
    styles: any;
    text: string;   
}

export const PrivilegeCard: React.FC<Props> = (props) => {
    const styles = props.styles;

    return (
        <div style={styles}>  
            <div style={styles.crownWrapper}>
                <Crown style={styles.crownIcon} />
            </div>            
            <div style={styles.text}>{props.text}</div>
        </div>
    )
}