import { Color } from "../enums/Color";
import { Font } from "../enums/Font"

export const termsConditionsPage = {
    mobile: {        
        container: {
            margin: "0 5% 100px 5%",
            color: Color.DeepAsh,
            fontFamily: Font.Poppins
        }
            
    },
    desktop: {
        container: {
            margin: "2.6vw 10% 5.45vw 10%"
        }
    }
}