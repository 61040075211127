import { Color } from "../enums/Color";

export const desktopMenu = {
    mobile: {
        wrapper: {
            display: "none",
            marginLeft: "auto",
            marginRight: "2vw",
            height: "5vh"
        },
        list: {
            listStyleType: "none",
            display: "flex",
            flexDirection: "row" as "row"
        },
        listItem: {
            marginRight: "0.87vw",
            cursor: "pointer",
            bottomBorder: {
                display: "block",
                backgroundColor: Color.LightBlue,
                border: "none",
                height: "0.15vw"
            }
        },
        dropdown: {
            wrapper: {
                position: "relative" as "relative",
                display: "inline-block"
            },
            button: {
                backgroundColor: "transparent",
                color: Color.LightAsh,
                fontSize: "0.937vw",
                fontWeight: "bold" as "bold",
                border: "none",
                cursor: "pointer"
            },
            item: {
                textAlign: "left"
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
            },
            labelIcon: {
                width: "0.8vw",
                fill: Color.LightAsh,
                marginLeft: "0.3vw",
                marginTop: "0.8vh",
                transition: "all 0.1s linear",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            label: {
                textDecoration: "none",
                fontSize: "0.8vw",
                letterSpacing: "0px",
                color: Color.LightAsh,
                opacity: "1"
            },
            leave: {
                display: "none"
            },
            hover: {
                display: "block",
                position: "absolute" as "absolute",
                width: "8.64vw",
                height: "auto",
                padding: "0.52vw",
                left: "50%",
                transform: "translate(-50%)",
                backgroundColor: Color.White,
                boxShadow: "5px 5px 45px #00000029",
                borderRadius: "0.52vw",
                zIndex: 3
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "95%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        }
    },
    desktop: {
        wrapper: {
            display: "inline",
            marginTop: "2.08vw"
        },
        listItem: {
            marginRight: "1.4vw",
            bottomBorder: {
                marginTop: "-3vh"
            },
            text: {
                marginBottom: "4vh"
            }
        },
        dropdown: {
            wrapper: {
                height: "7vh"
            },
            labelWrapper: {
                fontSize: "0.93vw"
            },
            hover: {
                top: "5vh"
            }
        }
    },
}