import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Location } from '../../assets/svg/location.svg';
import { ReactComponent as BreifCase } from '../../assets/svg/briefcase.svg';
import { ReactComponent as RedirectArrow } from '../../assets/svg/redirect-arrow.svg';
import { ReactComponent as Notification } from '../../assets/svg/notification.svg';
import { ReactComponent as Bin } from '../../assets/svg/bin.svg';
import { ReactComponent as DropDown } from '../../assets/svg/drop_down.svg';
import { ReactComponent as Globe } from '../../assets/svg/globe.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import { merge } from 'react-merge';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { SearchHistoryModel } from '../../models/SearchHistoryModel';
import { SearchPageTexts as Texts } from '../../helpers/LayoutTexts';
import { FeedbackCard } from './FeedbackCard';
import { Button } from './Buttons/Button';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getHost } from '../../helpers/Common';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {
    styles?: any;
    candidate: LoggedCandidate | null;
    searchHistories: SearchHistoryModel[];
    deleteSearchHistoryItem(searchHistoryId: number): void;
}

export const SearchHistory: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchHistory"));
    const [expand, setExpand] = useState<boolean>(false);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [hoveredItem, setHoveredItem] = useState(-1);

    useEffect(() => {
        let style = styleContext.getComponentStyle("searchHistory");
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    const redirectToSearch = (searchHistory: SearchHistoryModel) => {                       
        const searchParams = createSearchParams();
        if (searchHistory.keywords !== "") {
            searchParams.set("KeyWord", searchHistory.keywords);            
        }
        if (searchHistory.locationIds !== "") {
            searchParams.set("Locations", searchHistory.locationIds);            
        }
        if (searchHistory.sectorIds !== "") {
            searchParams.set("Sectors", searchHistory.sectorIds);            
        }
        if (searchHistory.jobTypeIds !== "") {
            searchParams.set("JobTypeId", searchHistory.jobTypeIds);            
        }
        if (searchHistory.careerLevelIds !== "") {
            searchParams.set("careerLevels", searchHistory.careerLevelIds);            
        }
        if (searchHistory.isRemote) {
            searchParams.set("remote", "true");            
        }
        if (searchHistory.postedInValue !== null) {
            searchParams.set("postedIn", searchHistory.postedInValue!.toString());            
        }
        let searchUrl = getHost() + "jobs" + `?${searchParams}`;
        window.open(searchUrl, '_blank');
    }

    const toggleCloseButton = () => {
        setExpand(!expand);
    }

    function redirectToLogin() {
        const params = { callbackUrl: window.location.pathname + window.location.search, message: "Please login/register to view saved search histories." };
        navigate({
            pathname: '/candidates/login',
            search: `?${createSearchParams(params)}`
        });
    }

    return (
        <div style={{ ...styles.container, ...(expand && styles.container.expand) }}>
            <div style={styles.wrapper} className="searchHistoryScrollArea">
                {props.candidate ? (props.searchHistories.length > 0 ? props.searchHistories.map((item, index) =>
                    <div key={index} style={{ ...styles.card, ...(hoveredItem === index && styles.card.hover) }}
                        onMouseEnter={() => setHoveredItem(index)}
                        onMouseLeave={() => setHoveredItem(-1)} 
                    >
                        <>
                            <div style={styles.contentWrapper}>
                                <div style={styles.title}>{item.searchHistoryName}</div>
                                {!isMobile && <div style={styles.detailWrapper}>
                                    {item.locations && <span style={styles.text}><Location style={styles.locationIcon} />{item.locations}</span>}
                                    {item.jobTypes && <span style={styles.text}><BreifCase style={styles.icon} />{item.jobTypes}</span>}
                                    {item.isRemote && <span style={styles.text}><Globe style={styles.icon} />{"Remote"}</span>}
                                    {item.postedInName && <span style={styles.text}><Clock style={styles.icon} />{item.postedInName}</span>}
                                </div>}
                                <div style={styles.actionButtons}>
                                    {/* <Button style={styles.notificationButton}> <Notification style={styles.notificationIcon} /></Button> */}
                                    <Button style={styles.binButton} onClick={() => props.deleteSearchHistoryItem(item.searchHistoryId!)}><Bin style={styles.binIcon}/></Button>
                                    <Button style={styles.redirectButton} onClick={() => redirectToSearch(item)}><RedirectArrow style={styles.redirectIcon}/></Button>                                                                
                                </div>
                            </div>
                            {isMobile && <div style={styles.detailWrapper}>
                                {item.locations && <span style={styles.text}><Location style={styles.locationIcon} />{item.locations}</span>}
                                {item.jobTypes && <span style={styles.text}><BreifCase style={styles.icon} />{item.jobTypes}</span>}
                                {item.isRemote && <span style={styles.text}><Globe style={styles.icon} />{"Remote"}</span>}
                                {item.postedInName && <span style={styles.text}><Clock style={styles.icon} />{item.postedInName}</span>}
                            </div>}
                        </>                        
                    </div>) : <FeedbackCard styles={styles.noResults} message={Texts.searchHistoryNoResult} />)
                    : <FeedbackCard
                        styles={styles.noResults}
                        message={<div>You need to <span style={styles.link} onClick={redirectToLogin}>login</span> to get saved search histories </div>}
                    />
                }
            </div>
            <DropDown style={{ ...styles.closeIcon, ...(expand && styles.closeIcon.close) }} onClick={toggleCloseButton} />
        </div>
    )
}