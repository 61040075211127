import { useContext, useEffect, useState } from "react";
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import { StyleContext } from "../../providers/StyleContextProvider";

interface Props {
    styles?: any;
    open: boolean;
    toggle(): void;
}

export const SideDrawer: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("sideDrawer")); 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    
    useEffect(() => {           
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("sideDrawer"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                  
    }, [isMobile]);
    
    return (
        <div style={{...styles, ...(props.open ? styles.open : styles.close)}}>
            <div style={styles.backgroundLayer} onClick={props.toggle}></div>
            <div style={styles.container}>                
                {props.children}
            </div>
        </div>
    )
}