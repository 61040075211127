import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";

export const TermsConditionsPage: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("termsConditionsPage"))               
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    

    useEffect(() => {
        window.scrollTo(0, 0);
        setStyles(styleContext.getComponentStyle("termsConditionsPage"));    
    }, [isMobile]);

    return (
        <>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"XpressJobs Terms of Service (\"Agreement\")"}/>                              
            <div className="termsConditions" style={styles.container}>
                <br/>
                <p>This Agreement was last modified on February 21, 2023.</p>

                <p>Please read these Terms & Conditions carefully before using xpress.jobs operated by XpressJobs (Pvt) Ltd.</p>
                <p>By accessing or using the site in any manner, including, but not limited to, visiting or browsing the site or contributing content or other materials to the site, you agree to be bound by these Terms & Conditions. Capitalized terms are defined in this Agreement.</p>
            
                <p><br/><strong>General</strong><br/><br/>Job Seekers and recruiters are responsible for ensuring that advertising content, text, images, graphics, cv, video ("Content") uploaded for inclusion on xpressjobs complies with all applicable laws. Xpressjobs assumes no responsibility for any illegality or any inaccuracy of the content.
                    <br/><br/>
                    Job Seekers and recruiters guarantee that their content do not violate any copyright, intellectual property rights or other rights of any person or entity, and agrees to release xpressjobs from all obligations, liabilities and claims arising in connection with the use of (or the inability to use) the service.
                </p>
                <p>
                    <br/><strong>License</strong><br/><br/>
                    Unless otherwise stated, xpress.jobs and/or it’s licensors own the intellectual property rights for all material on xpress.jobs. All intellectual property rights are reserved. You may view and/or print pages from xpress.jobs for your own personal use subject to restrictions set in these terms and conditions.
                    You must not:
                </p>
                <ul>
                    <li>republish material from xpress.jobs;</li>
                    <li>sell, rent or sub-license material from xpress.jobs; </li>
                    <li>reproduce, duplicate or copy material from xpress.jobs;</li>
                    <li>view, extract or copy material for competitive purposes; and</li>
                    <li>redistribute content from xpress.jobs (unless content is specifically made for redistribution).</li>
                </ul>

                <p><br/><strong>Intellectual Property</strong><br/><br/>The site and its original content, features and functionality are owned by xpressjobs.lk pvt ltd and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>

                <p>
                    <br/><strong>Prohibited Uses</strong><br/><br/>
                    In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
                </p>
                <ul>
                    <li> for any unlawful purpose;</li>
                    <li> to solicit others to perform or participate in any unlawful acts;</li>
                    <li> to violate any national, international, provincial or local regulations, rules, laws, or local ordinances;</li>
                    <li> to infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
                    <li> to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                    <li> to submit false or misleading information;</li>
                    <li> to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
                    <li> to collect or track the personal information of others;</li>
                    <li> to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                    <li> for any obscene or immoral purpose; or</li>
                    <li> to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses and will result in legal action taken.</li>
                </ul>
                <p><br/><strong>Termination</strong><br/><br/>We may terminate your access to the site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

                <p><br/><strong>Links to third party websites</strong><br/><br/>Our site may contain links to third-party sites that are not owned or controlled by xpressjobs.lk pvt ltd.
                    <br/><br/>
                    Xpressjobs.lk pvt ltd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services. In the event the user decides to leave xpressjobs and access Third Party Sites, the user does so at his/her own risk. We strongly advise you to read the terms and conditions and privacy policy of any third-party site that you visit.
                </p>

                <p><br/><strong>Paid content and services</strong><br/><br/>Some services of xpressjobs require payment, including but not limited to, candidate premier accounts, posting of job adverts and ad promotions.
                    <br/><br/>
                    Under no circumstances shall xpressjobs be obliged to refund any payments made in respect to Paid Services.
                </p>
                <p><br/><strong>Modifications</strong><br/><br/>Xpressjobs reserves the right to modify these Terms and Conditions. Such modifications shall be effective immediately upon posting on xpressjobs. You are responsible for the reviewing of such modifications. Your continued access or use of xpressjobs shall be deemed your acceptance of the modified terms and conditions.</p>

                <p><br/><strong>Governing Law</strong><br/><br/>This Agreement (and any further rules, policies, or guidelines incorporated by reference) shall be governed and construed in accordance with the laws of Western Province, Sri Lanka, without giving effect to any principles of conflicts of law.</p>
                <p><br/><strong>Contact Us</strong><br/><br/>If you have any questions about this Agreement, please contact us.</p>                
            </div>
            <GetInTouch/>
            <Footer/>
        </>
    );
}