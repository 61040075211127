import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const errorPage = {
    mobile: {
        container: {
            backgroundColor: Color.DeepBlack,
            textAlign: "center" as "center",
            overflow: "hidden" as "hidden"
        },
        image: {
            width: "100%",
            marginTop: "10vw",
            height: "35.72vw",
            marginBottom: "6vw"
        },
        oopsText: {
            fontSize: "30.19vw",
            fontFamily: Font.Advanced,
            color: Color.Cultured,
            opacity: 0.1            
        },
        connectText: {
            maxWidth: "50%",
            fontSize: "5.80vw",
            fontFamily: Font.Advanced,
            color: Color.White,
            margin: "-5vw auto 0 auto"
        },
        unavailableText: {
            width: "80%",
            fontSize: "3.38vw",
            fontWeight: "bold" as "bold",
            color: Color.SharpAsh,
            margin: "2.90vh auto 0 auto"
        },
        backButton: {
            position: "relative" as "relative",
            width: "51%",
            height: "10.60vw",
            marginTop: "3.35vh",
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "2.41vw",
            fontSize: "3.35vw",
            fontWeight: "bold" as "bold",
            zIndex: 1
        },
        socialIconWrapper: {
            position: "relative" as "relative",
            margin: "11.38vh -5.55vw 0 0",
            zIndex: 1
        },
        icon: {                
            width: "3.86vw",
            height: "3.86vw",
            marginRight: "5.55vw",
            cursor: "pointer",
            fill: Color.White,
            WebkitTapHighlightColor: "transparent"
        },
        copyright: {
            position: "relative" as "relative",
            width: "80%",
            fontSize: "2.66vw",
            color: Color.White,
            margin: "3.01vh auto",
            zIndex: 1
        },
        footerSvg: {
            display: "block",
            height: "auto",
            width: "100%",
            margin: "-26.55vh 0 0 4vw"
        }
    },
    desktop: {
        container: {
            height: "100%",
            backgroundImage: "url(img/desktop_error_footer.png)", 
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom"
        },
        imgContainer: {
            display: "flex"
        },
        flexChild: {
            flex: 1
        },
        imageLeft: {
            width: "74.0625vw",
            marginTop: "-25vw",
            height:  "52.91vw",
            marginLeft: "-30vw" 
        },
        imageRight: {
            width: "70.72vw",
            marginTop: "1vw",
            height: "25.72vw",
            marginLeft: "10.5vw"
        },
        oopsText: {
            fontSize: "19.11vw",
            marginTop: "-24.3vw",
            marginLeft: "1vw",
            height: "auto",
            width: "100%"
                      
        },
        connectText: {
            width: "auto",
            fontSize: "2.19vw",
            margin: "-0.5vw auto 0 auto"
        },
        unavailableText: {
            width: "auto",
            fontSize: "0.83vw",
            margin: "0.3vw auto 0 auto"
        },
        backButton: {
            width: "17.19vw",
            height: "2.60vw",
            borderRadius: "0.52vw",
            marginTop: "4.17vh",
            fontSize: "1.04vw"
        },
        socialIconWrapper: {
            margin: "3.90vw -1.20vw 0 0"
        },
        icon: {
            width: "0.83vw",
            height: "0.83vw",
            marginRight: "1.20vw",
            fill: Color.MiddleAsh
        },
        copyright: {
            width: "auto",
            fontSize: "0.78vw",
            color: Color.MiddleAsh,
            margin: "1.56vw auto 2.16vw auto"
        },
        footerSvg: {
            display: "Block",
            height: "auto",
            width: "100%",
            marginTop: "-16.5%",
            marginLeft: "0"
        }
    }
}