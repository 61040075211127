import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const loaderStyles = {
    mobile: {
        wrapper: {
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gridGap: "3.57vh 0",
            marginTop: "4.46vh"
        },
        card: {
            display: "inline-block",
            width: "100%",
            height: "46.54vh"
        }
    },
    desktop: {
        wrapper: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "2.31vh 1.14vw",
            marginTop: "3.89vh"
        },
        card: {
            width: "100%",
            height: "53.88vh",
            flex: "none"
        }
    }
};
 
export const PressReleaseLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile;
    if(!isMobile) {
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 3 : 9;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={styles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={styles.card.height}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="0%" rx="8" ry="8" width="100%" height="50%"/>
                    <rect x="0" y="55%" rx="3" ry="3" width="70%" height="4%"/>
                    <rect x="0" y="61%" rx="4" ry="4" width="80%" height="6%"/>
                    <rect x="0" y="73%" rx="2" ry="2" width="90%" height="2%"/>
                    <rect x="0" y="76%" rx="2" ry="2" width="90%" height="2%"/>
                    <rect x="0" y="79%" rx="2" ry="2" width="60%" height="2%"/>
                    <rect x="0" y="87%" rx="4" ry="4" width="30%" height="6%"/>
                </ContentLoader>
            </div>
        )
    }

    return (
        <div style={styles.wrapper}>
            {loaderElements}
        </div>

    );
}