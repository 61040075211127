import React, { useState } from 'react';
import { Logo } from '../Shared/Logo';
import { Tag } from '../Shared/Tag';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as CVLess } from '../../assets/svg/cv_less.svg';
import { ReactComponent as Calender } from '../../assets/svg/calendar.svg';
import { CandidateApplication } from '../../models/CandidateApplication';
import { Button } from '../Shared/Buttons/Button';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface Props {
    styles: any;
    application: CandidateApplication;
    isDraft?: boolean;
    onClick?(application: CandidateApplication): void;
};

export const ApplicationCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState<boolean>(false);
    const styles = props.styles;
    const application = props.application;

    const handleCompleteButtonClick = (event: any) => {
        event.stopPropagation();
        const stage = application.draftStage ? application.draftStage.toString() : "2";
        const isDraftCV = application.isDraftCV ? application.isDraftCV.toString() : "false";
        const params = { 
            stage: stage, 
            isDraft: "true", 
            isDraftCV: isDraftCV
        };
        
        navigate({
            pathname: "/jobs/apply/" + application.jobId,
            search: `?${createSearchParams(params)}`,
        });             
    }

    const onClick = (event: any) => {
        event.stopPropagation();
        props.onClick!(application);
    }

    const handleDisabilityClick = (event: any) => {
        event.stopPropagation();
        navigate("/home/differentlyabledfriendlyemployment");
    }

    const handleCVLessClick = (event : any) => {
        event.stopPropagation();
        navigate("/jobs/cvless");
    }

    return (
        <div 
            onMouseEnter={() => { setHover(true); }}
            onMouseLeave={() => { setHover(false); }}
            onClick={onClick}
            style={{...styles,...(hover ? styles.hover : styles.leave)}}
        >            
            <div style={{...styles.wrapper, ...styles.logoWrapper}}>
                <Logo style={styles.logo} organizationId={application.organizationId} organizationName={application.organizationName} hasLogo={application.hasLogo} logoUri={application.logoUri} />          
                <Tag styles={{...styles.tag, ...{background: application.colorCode}}}>{application.applicationStatus}</Tag>                
            </div>
            <div style={{...styles.wrapper, ...styles.titleWrapper}}>
                <span style={styles.title}>{application.jobTitle}</span>
            </div> 
            <div style={{...styles.wrapper, ...styles.organizationWrapper}}>
                <span style={styles.organization}>{application.organizationName}</span>
                {application.disabilityFriendly && <Disable style={styles.disableIcon} onClick={handleDisabilityClick}/>}
                {application.isPreApplicationFormEnabled && <CVLess style={styles.cvLessIcon} onClick={handleCVLessClick}/>}
            </div>   
            <div style={styles.wrapper}>                
                {application.applyEnable ? <Button style={styles.completeButton} onClick={handleCompleteButtonClick}>{"COMPLETE NOW"}</Button> :
                <span style={styles.applyText}>
                    <Calender style={styles.calendarIcon}/>
                    {props.isDraft ? "Drafted on" : "Applied on"} <span style={styles.applyDate}>{application.createdDateString}</span>
                </span>}
            </div>            
        </div>        
    );
}