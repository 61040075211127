import { Color } from "../enums/Color";

export const responseAlert = {
    mobile: {        
        default: {            
            width: "84%",
            height: "6.31vh",
            top: "3.34vh",
            position: "fixed",
            zIndex: "5",
            left: "50%",
            borderRadius: "2.41vw",
            transform: "translateX(-50%)",
            fontFamily: "Poppins",
            fontSize: "3.86vw",
            fontWeight: "600",
            padding: "0 6vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            icon: {
                marginRight: "2.41vw",
                width: "4.1vw",
                height: "4.1vw"
            }
        },
        success: {
            color: Color.Green,
            backgroundColor: Color.Nyanza,
            border: "1px solid " + Color.Green
        },
        error: {
            color: Color.Red,
            backgroundColor: Color.Linen,
            border: "1px solid " + Color.Red
        },
        warning: {
            color: Color.HarvestGold,
            backgroundColor: Color.OldLace,
            border: "1px solid " + Color.HarvestGold
        },
        normal: {
            color: Color.DeepBlack,
            backgroundColor: Color.AliceBlue,
            border: "1px solid " + Color.DeepBlack
        },        
    },
    desktop: {    
        default: {            
            width: "auto",
            height: "6.75vh",
            top: "7.25vh",
            fontSize: "0.93vw",
            borderRadius: "0.52vw",
            icon: {
                marginRight: "0.52vw",
                width: "0.95vw",
                height: "0.95vw"
            } 
        }
    }
}