import { Color } from "../enums/Color"
import { commonHeader } from "./CommonHeader"

export const mediumHeader = {
    mobile: {
        ...commonHeader.mobile,
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "48.30vw",
            opacity: "1",
            position: "relative"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "80%"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        }
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "20.83vw",
            paddingBottom: "0"
        },
        longBuildingSvg: {
            width: "36%"
        },
        desktopImageLeft: {
            display: "none"
        },
        recruitersButton: {
            display: "inline"
        },
        jobSeekersButton: {
            display: "inline"
        }
    }
}
