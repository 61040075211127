import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { ReactComponent as SuccessfullyApplied } from '../assets/svg/successfully-applied.svg'
import { Button } from "../components/Shared/Buttons/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { postCandidateEmailConfirmation } from "../services/CandidateService";
import useErrorPage from "../hooks/useErrorPage";
import { ResponseModel } from "../models/ResponseModel";
import { ConfirmationLoader } from "../components/Loaders/ConfirmationLoader";
import { Transition } from "../components/Shared/Transition";
import { Helmet } from "react-helmet-async";

export const EmailConfirmationPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("confirmationPage"));
    const [loading, setLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    let id: string = searchParams.get("id")!;
    let type: string = searchParams.get("type")!;          
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const [toErrorPage] = useErrorPage();
    
    useEffect(() => {
        setStyles(styleContext.getComponentStyle("confirmationPage"));    
    }, [isMobile]);

    useEffect(() => {
        if(type === "CAN" || type === null) {
            ConfirmCandidateEmail();
        }
        else if(type === "REC") {

        }
    }, []);

    async function ConfirmCandidateEmail ()
    {                    
        await postCandidateEmailConfirmation(id)
        .then((response: ResponseModel) => {
            setSuccess(response.status);
            setTitle(response.data.title);
            setDescription(response.data.description)
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    return (
        <>
            <Helmet><title>Email Confirmation - Xpressjobs</title></Helmet>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"Email Confirmation"}/>            
            <div style={styles.container}>
                {loading ? <ConfirmationLoader/> : 
                <Transition>                  
                    {/* replace un-successfull image here */}
                    {success ? <SuccessfullyApplied style={styles.image}/> : <SuccessfullyApplied style={styles.image}/>}
                    <h3 style={{...styles.title, ...styles.title.email}}>{title}</h3> 
                    <div style={styles.description}>{description}</div>
                    <div style={styles.buttonsContainer}>
                        <Button style={styles.button} onClick={() => navigate("/candidate/myapplications")}>VIEW MY APPLICATIONS</Button> 
                        <Button style={{...styles.button, ...styles.button.back}} onClick={() => navigate('/')}>GO BACK TO HOME</Button>
                    </div>                                   
                </Transition>} 
            </div>            
            <GetInTouch/>
            <Footer/>            
        </>
    );
}