import React from 'react';
import { ReactComponent as SadFace } from "../../assets/svg/sad-face.svg";

interface Props {
    styles: any;
    message: any;
}
export const FeedbackCard: React.FC<Props> = (props) => {
    let styles = props.styles;

    return (
        <div style={styles.wrapper}>
            <SadFace style={styles.icon} />
            {props.message}
        </div>
    )
}
