import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import ContentLoader from "react-content-loader";
import { Color } from "../../enums/Color";

const loaderStyle = {
    mobile: {
        container: {
            width: "90.33vw",
            height: "111.59vw"
        }
    },
    desktop: {
        container: {
            width: "35.10vw",
            height: "32.71vw"
        }
    }
};

export const ChangePasswordLoader : React.FC = () => {
    const isMobile = useMediaQuery ({ query: "(max-width: 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }
    return (
       <div style={styles.container}>
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width= {styles.container.width}
                height= {styles.container.height}
            >
                {isMobile ? <>
                    <rect x="0" y="0" rx="0" ry="0" width="33.75%" height="3.90%" />
                    <rect x="3.81%" y="12.55%" rx="0" ry="0" width="36.04%" height="4.98%" />
                    <rect x="0" y="18.61%" rx="0" ry="0" width="100%" height="10.82%" />
                    <rect x="3.81%" y="42.42%" rx="0" ry="0" width="36.04%" height="4.98%" />
                    <rect x="0" y="48.48%" rx="0" ry="0" width="100%" height="10.82%" />
                    <rect x="3.81%" y="63.63%" rx="0" ry="0" width="36.04%" height="4.98%" />
                    <rect x="0" y="69.69%" rx="0" ry="0" width="100%" height="10.82%" />
                    <rect x="0" y="89.17%" rx="10" ry="10" width="100%" height="10.82%" />
                </> : 
                <>
                    <rect x="4.45%" y="0" rx="0" ry="0" width="37.09%" height="5.41%" />
                    <rect x="1.48%" y="14.17%" rx="0" ry="0" width="23.59%" height="3.98%" />
                    <rect x="0" y="19.75%" rx="0" ry="0" width="100%" height="11.15%" />
                    <rect x="1.48%" y="45.06%" rx="0" ry="0" width="23.59%" height="3.98%" />
                    <rect x="0" y="50.64%" rx="0" ry="0" width="100%" height="11.15%" />
                    <rect x="1.48%" y="66.56%" rx="0" ry="0" width="23.59%" height="3.98%" />
                    <rect x="0" y="72.13%" rx="0" ry="0" width="100%" height="11.15%" />
                    <rect x="40.95%" y="92.04%" rx="0" ry="0" width="59.05%" height="7.96%" />      
                </>} 
            </ContentLoader>
       </div>
    )
}