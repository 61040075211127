import { Color } from "../enums/Color";

export const candidateSavedJobs = {
    mobile: {
        wrapper: {
            marginBottom: "3.24vh"
        },
        title: {
            display: "flex",
            flexDirection: "row",
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "6.11vw",
            color: Color.LightBlack,
            marginTop: "3.70vh",
            opacity: "1",
            recordText: {
                alignSelf: "center",
                marginLeft: "auto",
                fontSize: "3.62vw",
                fontWeight: 500,
                color: Color.SharpAsh,
            },
            RecordCount: {
                fontWeight: "bold",
                color: Color.Black
            }
        },
        sortBySelect: {
            marginLeft: "0",
            marginTop: "0.93vh"
        },
        card: {
            detailWrapper: {
                width: "100%"
            },
            applyWrapper: {
                display: "flex",
                flexDirection: "row",
                marginTop: "0.93vh"
            },
            applyButton: {
                width: "26.57vw",
                height: "6.038vw",
                color: Color.White,
                fontWeight: "bold",
                fontSize: "2.90vw",
                backgroundColor: Color.Green,
                border: "none",
                borderRadius: "1.2vw",
                cursor: "pointer"
            },
            removeButton: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "6.038vw",
                height: "6.038vw",
                marginLeft: "4.83vw",
                backgroundColor: Color.DarkAsh,
                border: "none",
                borderRadius: "50%",
                cursor: "pointer",
                padding: "0"
            },
            removeButtonIcon: {
                width: "2.41vw",
                height: "2.89vw"
            }
        },
        viewAll: {
            display: "flex",
            marginTop: "1.39vh",
            padding: "0",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            color: Color.Blue,
            fontWeight: "bold",
            fontSize: "3.86vw",
            icon: {
                fill: Color.Blue,
                marginLeft: "1.5vw"
            }
        },
        loadMore: {
            display: "flex",
            margin: "7.24vw auto 0 auto",
            padding: "0",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            color: Color.Blue,
            fontWeight: "bold",
            fontSize: "3.86vw"
        },
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 4vh 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        }        
    },
    desktop: {
        wrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: "0",
            marginBottom: "0.78vw"
        },
        title: {
            flexDirection: "column",
            marginTop: "2.45vw",
            marginRight: "3.38vw",
            fontSize: "1.56vw",
            recordText: {
                alignSelf: "auto",
                marginTop: "3.70vh",
                marginLeft: "0",
                fontSize: "0.94vw",
                fontWeight: 400
            }
        },
        sortBySelect: {
            marginTop: "2.45vw"
        },
        card: {
            width: "56.67vw",
            hover: {       
                alignItems: "normal"
            },
            detailWrapper: {
                width: "46.56vw",
                hover: {       
                    marginTop: "0vw"
                }
            },
            applyWrapper: {
                display: "none",
                marginTop: "1.48vh",
                hover: { display: "flex" }
            },
            applyButton: {
                width: "8.07vw",
                height: "4vh",
                fontSize: "0.78vw",
                borderRadius: "0.26vw"
            },
            removeButton: {
                width: "1.82vw",
                height: "1.82vw",
                fill: Color.MiddleBlack,
                marginLeft: "1.04vw"
            },
            removeButtonIcon: {
                width: "0.72vw",
                height: "0.83vw"
            },
            share: {
                marginTop: "-1.04vw",
                marginRight: "-0.36vw"
            }
        },
        viewAll: {
            display: "block",
            width: "14.74vw",
            height: "3.64vw",
            marginTop: "2.77vh",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "0.52vw",
            cursor: "pointer",
            color: Color.White,
            fontWeight: "bold",
            fontSize: "1vw"
        },
        loadMore: {
            display: "block",
            width: "14.74vw",
            height: "3.64vw",
            margin: "1.30vw auto 0 0",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "0.52vw",
            cursor: "pointer",
            color: Color.White,
            fontWeight: "bold",
            fontSize: "1vw"
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "49.47vw",
                borderRadius: "0.7vw",
                margin: "3.2vh auto 7.81vw auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        }
    }
}