import React, { useContext, useState } from "react";
import { PopUpWindow } from "../Shared/PopUpWindow";
import Dropzone from "react-dropzone";
import { ReactComponent as FileUpload } from "../../assets/svg/file-upload.svg";
import { Button } from "../Shared/Buttons/Button";
import { completeRegisterWithSocialApps } from "../../services/CandidateService";
import { useNavigate } from "react-router-dom";
import { ResponseModel } from "../../models/ResponseModel";
import { validateCV } from "../../helpers/Common";
import { DocumentViewer } from "../Shared/DocumentViewer";
import useErrorPage from "../../hooks/useErrorPage";
import { AuthContext } from "../../providers/AuthContextProvider";

interface Props {
    styles: any;
    candidateId: number;
    openAlert(type: string, message: string): void;
}

export const CVUploadPopup: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedCV, setUploadedCV] = useState<File>();
    const [toErrorPage] = useErrorPage();
    let styles = props.styles;
    const authContext = useContext(AuthContext);

    const handleFileChange = (uploadedFiles: File[]) => {
        if (uploadedFiles.length > 0) {
            let validation = validateCV(uploadedFiles[0]);
            if (validation.valid) {
                setUploadedCV(uploadedFiles[0]);
            } else {
                props.openAlert("error", validation.invalidMessage!);
            }
        }
    }

    const handleCancelCV = () => {
        setUploadedCV(undefined);
    }

    async function handleSubmitCV() {
        setIsLoading(true);
        var formData = new FormData();
        let candidateModel = {
            CandidateId: props.candidateId,
        };
        formData.append("candidateModelString", JSON.stringify(candidateModel));
        formData.append("candidateCV", uploadedCV!);
        await completeRegisterWithSocialApps(formData)
        .then((response: ResponseModel) => {
            if (response.status) {
                authContext.setLoggedCandidate(response.data, response.token);
                navigate("/candidate/home");
            }
            props.openAlert(response.status ? "success" : "error", response.message);
        })
        .catch(error => {
            toErrorPage(error)
        })
        setIsLoading(false);
    }

    return (
        <>
            <PopUpWindow open={true} styles={styles.popup}>
                {uploadedCV ?
                    <DocumentViewer styles={styles.document} document={uploadedCV}/> :
                    <Dropzone onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: styles.dropzone })}>
                                <input {...getInputProps()} />
                                <div style={styles.uploadCVTexts}>
                                    <div style={styles.uploadIconWrapper}>
                                        <FileUpload style={styles.uploadIcon} />
                                    </div>
                                    <p>Drag / Click to upload your CV</p>
                                    <label>Max file size : 2MB</label>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                }
                {uploadedCV && <div style={styles.buttonWrapper}>
                    <Button disabled={isLoading} style={{...styles.button, ...styles.button.cancel}} type="button" onClick={handleCancelCV}>Cancel</Button>
                    <Button disabled={isLoading} style={{...styles.button, ...styles.button.submit}} type="button" onClick={handleSubmitCV}>Submit</Button>                    
                </div>}
            </PopUpWindow>
        </>
    );
};
