import { Color } from "../enums/Color";

export const pressReleasesPage = {
    mobile: {
        container: {
            position: "relative" as "relative",
            margin: "4.80vh 5% 14.95vh 5%",
            zIndex: 1
        },
        selectWrapper: {
            display: "flex" as "flex",
            flexDirection: "row" as "row"
        },
        sortSelect: {
            width: "auto",
            margin: "0 3.86vw 0 0",
            label: {
                marginTop: "0",
                fontWeight: "bold"
            },
            button: {
                width: "27.53vw",
                height: "8.45vw",
                marginLeft: "0"
            },
            buttonWrapper: {
                marginTop: "0"
            },
            hover: {
                width: "49.75vw"
            }
        },
        postSelect: {            
            width: "auto",
            margin: "0",
            label: {                
                marginTop: "0",
                fontWeight: "bold"
            },
            button: {
                width: "20.77vw",
                height: "8.45vw",
                marginLeft: "0"                              
            },
            buttonWrapper: {
                marginTop: "0"                
            },                        
            hover: {
                width: "24.63vw"
            } 
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gridGap: "3.57vh 0",
            marginTop: "4.46vh"
        },
        cardWrapper: {
            backgroundColor: Color.LightAsh,
            borderRadius: "2.41vw",
            hovered: {
                backgroundColor: Color.White,
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)",
                cursor:"pointer"
            }
        },
        image: {
            width: "100%",
            height: "24.44vh",
            borderRadius: "2.41vw 2.41vw 0 0",
            objectFit: "cover"            
        },
        detailWrapper: {
            fontSize: "3.38vw"           
        },
        source: {
            margin: "1.78vh 2.89vw 0 2.89vw",
            color: Color.SharpAsh,
            bold: {
                fontWeight: "bold" as "bold"
            }
        },
        title: {
            margin: "0.89vh 2.89vw 0 2.89vw",
            fontSize: "3.86vw",
            color: Color.LightBlack,
            fontWeight: "bold" as "bold"
        },
        description: {
            margin: "1.34vh 2.89vw",                  
            color: Color.DeepAsh,
            lineHeight: 1.5             
        },
        readMore: {
            margin: "0 2.89vw 1.78vh 2.89vw",
            color: Color.DeepBlack,
            fontWeight: "bold" as "bold",
            cursor: "pointer"
        }
    },
    desktop: {
        container: {
            margin: "3.46vw 7.29vw 8.89vh 7.29vw"
        },
        selectWrapper: {
            justifyContent: "space-between" as "space-between"
        },
        select: {
            label: {         
                fontSize: "18px",       
                margin: "0 1.04vw 0 0",
                alignSelf: "center" as "center"
            }
        },
        sortSelect: {
            marginTop: "0",
            label: { 
                fontSize: "0.93vw",               
                margin: "0.3vw 1.04vw 0 0",
                fontWeight: "bold"
            },
            button: {
                width: "8.07vw",
                height: "2.07vw"                                       
            },                        
            hover: {
                width: "8.07vw"
            } 
        },
        postSelect: {            
            width: "auto",
            margin: "0",
            label: {         
                fontSize: "0.93vw",       
                margin: "0.3vw 1.04vw 0 0",
                fontWeight: "bold"
            },
            button: {
                width: "6.40vw",
                height: "2.08vw"                                             
            },                        
            hover: {
                width: "6.40vw"
            } 
        },
        grid: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "2.31vh 1.14vw",
            marginTop: "3.89vh"
        },
        cardWrapper: {
            borderRadius: "0.52vw"
        },
        image: {
            height: "28.42vh",
            borderRadius: "0.52vw 0.52vw 0 0"  
        },
        detailWrapper: {
            fontSize: "0.83vw",
            paddingBottom: "2.22vh"            
        },
        source: {
            margin: "2.96vh 1.67vw 0 1.67vw"                        
        },
        title: {
            margin: "0.52vw 1.67vw 0 1.67vw",
            fontSize: "1.04vw",
            overflow: "hidden",
            height:'2.44vw'
        },
        description: {
            margin: "1.25vw 1.67vw",
            lineHeight: 1.4,
            overflow: "hidden",
            height:'3.54vw'
        },
        readMore: {
            fontSize: "0.94vw",
            margin: "0 1.67vw 0.7vw 1.67vw"   
        }
    }
}