import { Color } from "../enums/Color";

export const confirmationPage = {
    mobile: {
        container: {
            margin: "4.83vh auto 11.16vh auto",
            textAlign: "center" as "center"
        },
        image: {
            width: "73vw",
            height: "auto",
            marginTop: "6.2vw",
            marginBottom: "17.6vw"
        },
        title: {                
            fontWeight: "bold" as "bold",
            fontSize: "6.03vw",
            color: Color.LightBlack,
            width: "61.35vw",
            email: {
                margin: "0 auto 7.25vw"
            },
            checkout: {
                margin: "0 auto 4vw"
            }
        },
        description: {
            color: Color.MiddleBlack,
            fontSize: "3.38vw",
            width: "90vw",
            margin: "0 auto 7.25vw auto",
            lineHeight: "6.04vw"
        },
        buttonsContainer: {
            display: "block",
            justifyContent: "center",
            marginBottom: "24.1vw"
        },
        button: {
            border: "none",
            backgroundColor: Color.Green,
            color: Color.White,
            borderRadius: "2.41vw",
            width: "90vw",
            height: "12.07vw",
            padding: "1.67vh",
            fontSize: "4.35vw",
            fontWeight: "bold" as "bold",
            boxShadow: "2.42vw 2.42vw 6.04vw #00000029",
            marginTop: "1.52vh",
            back: {
                backgroundColor: Color.Blue,
                marginLeft: "0",
                boxShadow: "none"
            },
        }
    },
    desktop: {
        container: {
            width: "43.85vw",
            margin: "0 auto 13.88vh auto"
        },
        title: {
            fontSize: "1.56vw",
            width: "unset",
            email: {
                margin: "0 auto 1.41vw"
            },
            checkout: {
                margin: "0 auto 1.41vw"
            }
        },
        image: {                
            width: "20vw",
            marginTop: "2.5vw",
            marginBottom: "1.6vw"
        },
        description: {                
            fontSize: "0.83vw",
            lineHeight: "1.30vw",
            margin: "0 0 1.93vw",
            width: "43.85vw"
        },
        buttonsContainer: {
            marginBottom: "7.81vw"
        },
        button: {
            width: "18.125vw",
            height: "3.64vw",
            fontSize: "1.04vw",
            padding: "2.12vh",
            borderRadius: "0.52vw",
            boxShadow: "0.52vw 0.52vw 1.30vw #00000029",
            back: {
                marginLeft: "0.78vw"
            }
        }
    }
}