import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const myInformation = {
    mobile: {
        wrapper: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        title: {
            textAlign: "left",
            fontSize: "6.04vw",
            fontWeight: "bold",
            letterSpacing: "0",
            color: Color.LightBlack
        },
        titleWrapper: {
            height: "6.03vw",
            marginTop: "10.14vw"
        },
        borderButton: {
            height: "6.03vw",
            width: "27.77vw",
            border: "2px solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",
            fontWeight: "bold",
            fontSize: "2.90vw",
            borderRadius: "1.21vw",
            marginLeft: "auto"
        },
        formWrapper: {
            marginRight: "unset",
            marginBottom: ""
        },
        subTitle: {
            textAlign: "left",
            fontSize: "3.86vw",
            fontWeight: "bold",
            letterSpacing: "0px",
            color: Color.LightBlack,
            margin: "3.35vh 0 0 0",
        },
        swiftWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        locationWrapper: {
            display: "flex",
            flexDirection: "column"
        },
        locationEditWrapper: {
            display: "flex",
            flexDirection: "row",
            item: {
                width: "100%"
            }
        },
        provinceSelect: {
            margin: "1.21vw",
            button: {
                width: "auto",
                height: "11.59vw",
                fontSize: "3.38vw",
                marginTop: "unset"
            },            
            buttonWrapper: {
                width: "auto",
                border: "1px solid " + Color.SharpAsh,
                borderRadius: "1.2vw"
            },
            buttonText: {
                fontFamily: Font.Poppins,
                fontSize: "3.38vw",
                color: Color.DeepAsh
            },
            hover: {
                width: "42.50vw",
                border: "1px solid " + Color.SharpAsh,
                borderRadius: "1.2vw",
                marginRight: "5.83vw"
            }
        },
        inputWrapper: {
            marginTop: "2.23vh",
            color: Color.DeepAsh
        },
        label: {
            fontFamily: Font.Poppins,
            fontSize: "3.86vw",
            marginLeft: "3.62vw"
        },
        textInput: {
            width: "auto",
            padding: "3.38vw 3.62vw",
            fontFamily: Font.Poppins,
            fontSize: "3.38vw",
            margin: "1.21vw",
            backgroundColor: Color.White,
            border: "1px solid " + Color.SharpAsh,
            inputField: {                
                fontFamily: Font.Poppins,
                color: Color.DeepAsh,
                marginLeft: "unset"
            }
        },
        textAreaInput: {
            height: "13.39vh",
            width: "80.25vw",
            borderRadius: "1.21vw",
            resize: "none"
        },
        line: {
            height: "0.22vh",
            backgroundColor: Color.Ash,
            color: Color.Ash,
            marginTop: "3.35vh",
            borderWidth: 0,
        },
        nameRightMargin: {
            marginRight: "1.21vw"
        },
        nameLeftMargin: {
            marginLeft: "1.21vw"
        },
        inputRightMargin: {
            marginRight: "0"
        },
        inputLeftMargin: {
            marginLeft: "0"
        },
        sectorContainer: {
            display: "block",
            flexDirection: "unset",
            border: "1px solid " + Color.SharpAsh,
            borderRadius: "1.21vw",
            padding: "3.62vw",
            position: "relative",
            margin: "1.21vw"
        },
        sector: {
            width: "fit-content",
            height: "3.91vh",
            paddingLeft: "4.83vw",
            paddingRight: "4.83vw",
            marginRight: "1.21vw",
            marginBottom: "0.56vh",
            textAlign: "center",
            lineHeight: "3.91vh",
            borderRadius: "6.04vw",
            backgroundColor: Color.Blue,
            color: Color.LightBlue,
            fontSize: "3.62vw",
            overflow: "hidden",
            cursor: "pointer",
            display: "inline-block",
            flex: "0 0 auto",
            WebkitTapHighlightColor: "transparent",
            userSelect: "none"
        },
        closeIcon: {
            position: "absolute",
            height: "2.23vh",
            width: "4.83vw",
            marginLeft: "1.45vw",
            marginTop: "-0.67vh",
        },
        allSectorWrapper: {
            border: "1px dashed " + Color.DarkGray,
            color: Color.SharpAsh,
            backgroundColor: "unset"
        },
        leave: {
            display: "none"
        },
        hover: {
            display: "block",
            position: "absolute",
            width: "auto",
            height: "33.59vh",
            bottom: "-34.59vh",
            left: "-0.5vw",
            backgroundColor: Color.White,
            border: "1px solid " + Color.MiddleAsh,
            borderRadius: "2.42vw",
            zIndex: 1,
        },
        search: {
            display: "flex",
            height: "4.05vh",
            width: "90%",
            margin: "auto",
            marginTop: "1.35vh",
            fontSize: "3.38vw",
            paddingLeft: "1.35vh",
            color: Color.Black,
            border: "none",
            borderRadius: "4.16vw",
            backgroundColor: Color.LightAsh,
            icon: {
                position: "absolute" as "absolute",
                width: "3.4vw",
                height: "3.4vw",
                fill: Color.SharpAsh,                
                top: "8%",
                left: "19.12vw"
            }
        },
        option: {
            margin: "0 2.19vw",
            padding: "0.312vw 2.19vw",
            textAlign: "left",
            color: Color.MiddleBlack,
            fontSize: "3.86vw",
            fontWeight: "medium",
            cursor: "pointer",
            zIndex: 1,
            WebkitTapHighlightColor: "transparent",
            userSelect: "none",
            hover: {
                backgroundColor: Color.LightBlue,
                borderRadius: "1.93vw",
            },
            leave: {
                backgroundColor: Color.White,
            },
        },
        saveWrapper: {
            flexDirection: "column",
            marginTop: "7.73vw"
        },
        discardButton: {
            height: "12.08vw",
            width: "88.40vw",
            border: "2px solid " + Color.Blue,
            color: Color.Blue,
            background: "transparent",
            fontWeight: "bold",
            fontSize: "4.34vw",
            borderRadius: "1.21vw",
            margin: "3.62vw 1.21vw 0 1.21vw"
        },
        saveButton: {
            height: "12.08vw",
            width: "88.40vw",
            border: "none",
            color: Color.White,
            background: Color.Green,
            fontWeight: "bold",
            fontSize: "4.34vw",
            borderRadius: "2.41vw",
            margin: "0 1.21vw"
        },
        scrollArea: {
            height: "80%",
            overflow: "auto",
            marginTop: "1.38vw"
        },
        bottomBorder: {
            border: 0,
            height: "1px",
            width: "92%",
            background: Color.MiddleAsh,
            margin: "0 auto",
            zIndex: "1"
        },
        hoveredBorder: {
            background: Color.White,
            borderRadius: "1px"
        },
        editDisabled: {
            cursor: "not-allowed"
        }
    },
    desktop: {
        title: {
            fontSize: "1.56vw",
            marginLeft: "0.52vw"
        },
        titleWrapper: {
            height: "1.82vw",
            marginTop: "2.60vw"
        },
        borderButton: {
            height: "1.82vw",
            width: "10.16vw",
            border: "3px solid " + Color.Blue,
            fontSize: "0.78vw",
            marginLeft: "1.04vw",
            borderRadius: "0.26vw"
        },
        formWrapper: {
            marginRight: "10%",
            marginBottom: "7.81vw"
        },
        subTitle: {
            fontSize: "1.04vw",
            marginBottom: "0",
            marginLeft: "0.52vw"
        },
        swiftWrapper: {
            flexDirection: "row",
            justifyContent: "space-between"
        },
        locationWrapper: {
            flexDirection: "row-reverse",
            justifyContent: "space-between"
        },
        provinceSelect: {
            margin: "0.52vw",
            button: {
                width: "13vw",
                height: "3.54vw",
                fontSize: "0.83vw",
                marginTop: "unset",
                marginLeft: "unset"
            },            
            buttonWrapper: {
                border: "1px solid " + Color.SharpAsh,
                borderRadius: "0.52vw"
            },
            buttonText: {
                fontFamily: Font.Poppins,
                fontSize: "0.83vw",
                color: Color.DeepAsh
            },
            hover: {
                position: "relative",
                width: "-webkit-fill-available",
                border: "1px solid " + Color.SharpAsh,
                borderRadius: "0 0 0.52vw 0.52vw",
                marginRight: "unset"
            }
        },
        inputWrapper: {
            marginTop: "1.30vw",
            width: "100%"
        },
        label: {
            fontSize: "0.94vw",
            marginLeft: "0.52vw"
        },
        textInput: {
            borderRadius: "0.52vw",
            fontSize: "0.83vw",
            margin: "0.52vw",
            padding: "1.25vw",
            inputField: {
                fontSize: "0.83vw"
            }  
        },
        textAreaInput: {
            height: "7.71vw",
            width: "24.55vw",
            borderRadius: "0.52vw",
            marginBottom: "0"
        },
        line: {
            height: "2px",
            marginTop: "1.56vw"
        },
        nameRightMargin: {
            marginRight: "0.42vw"
        },
        nameLeftMargin: {
            marginLeft: "0.42vw"
        },
        inputRightMargin: {
            marginRight: "0.42vw"
        },
        inputLeftMargin: {
            marginLeft: "0.42vw"
        },
        sectorContainer: {
            borderRadius: "0.52vw",
            padding: "1.25vw",
            margin: "0.52vw"
        },
        sector: {
            marginRight: "0.52vw",
            marginBottom: "0.52vw",
            width: "fit-content",
            height: "1.822vw",
            paddingLeft: "1.04vw",
            paddingRight: "1.04vw",
            lineHeight: "1.822vw",
            fontSize: "0.78vw"
        },
        allSectorWrapper: {
            width: "7.39vw",
            overflow: "visible",
            verticalAlign: "bottom",
            position: "relative"
        },
        hover: {
            width: "15.15vw",
            borderRadius: "0.52vw",
            height: "15.67vw",
            padding: "0.52vw",
            position: "unset",
        },
        hoverWrapper: {
            position: "absolute",
            paddingTop: "0.52vw",
            left: "-35%",
            zIndex: "1"
        },
        scrollArea: {
            marginTop: "0.52vw"
        },
        search: {
            height: "1.562vw",
            fontSize: "0.72vw",
            marginTop: "0.52vw",
            icon: {
                width: "0.62vw",
                height: "0.62vw",
                left: "4.60vw",
                top: "2.15vw"
            }
        },
        option: {
            margin: "0",
            padding: "0.312vw 0.52vw",
            fontSize: "0.78vw",
            hover: {
                borderRadius: "0.36vw",
            }
        },
        closeIcon: {
            height: "1.04vw",
            width: "1.04vw",
            marginLeft: "0",
            marginTop: "-0.67vh",
        },
        saveWrapper: {
            flexDirection: "row-reverse",
            marginTop: "1.93vw"
        },
        discardButton: {
            height: "2.60vw",
            width: "10.16vw",
            border: "3px solid " + Color.Blue,
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            margin: "0 0.83vw 0 0"
        },
        saveButton: {
            height: "2.60vw",
            width: "11.77vw",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            margin: "0 0.52vw 0 0"
        }
    }
}