import React from "react";
import ContentLoader from "react-content-loader";
import { Color } from "../../enums/Color";

interface Props {
    styles: any;
}

export const TextLoader: React.FC<Props> = (props) => {
    let width = props.styles.width;
    let height = props.styles.height;
    
    return (
        <ContentLoader
            width={width}
            height={height}
            backgroundColor={Color.LoaderBackground}
            foregroundColor={Color.LoaderForeground}
        >
            <rect x="0" y="0" rx={"5"} ry={"5"} width={width} height={height} />
        </ContentLoader>
    );
};
