import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const getInTouch = {
    mobile: {
        wrapper: {
            height: "64.25vw",
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/long_building_mobile.svg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "180% 175%",
            backgroundPosition: "calc(100% + 42.30vw) bottom",
            textAlign: "center" as "center",
            backgroundColor: Color.Blue,
            marginBottom: "-24.5%",
            color: Color.White,   
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10.86vw"
        },
        title: {
            fontFamily: Font.Advanced,
            fontSize: "10.869vw",
            lineHeight:"10.89vw",
            fontWeight: "normal",
            margin: "0",
            width: "78.47vw"
        },
        description: {
            fontFamily: Font.Advanced,
            fontSize: "3.62vw",
            lineHeight:"4.83vw",
            color: Color.DeepBlack,
            marginLeft: "7vw",
            maxWidth: "80%"
        }
    },
    desktop: {
        wrapper: {
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/img/long_building_desktop.svg)",
            backgroundSize:"100%",
            backgroundPosition: "center bottom",
            height: "20.20vw",
            padding: "0",
            marginBottom: "-7.8vw"
        },
        title: {        
            fontSize: "2.86vw",
            width: "unset",
            lineHeight: "unset"
        },
        description: {            
            fontSize: "1.52vw",
            margin: "0",
            maxWidth: "100%",
            lineHeight: "unset"
        }
    }
}