import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { GetInTouchTexts } from '../../helpers/LayoutTexts';
import { StyleContext } from '../../providers/StyleContextProvider';

export const GetInTouch: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("getInTouch"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("getInTouch"));
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>
            <div>
                <h2 style={styles.title}>{GetInTouchTexts.title}</h2>
                <p style={styles.description}>{GetInTouchTexts.description} {GetInTouchTexts.telephone}</p>
            </div>
        </div>
    )
}