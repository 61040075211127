import Axios from "axios";
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { CandidateProfileModel } from "../models/CandidateProfileModel";
import { CandidateSavedJob } from "../models/CandidateSavedJob";
import { LoginModel } from "../models/LoginModel";
import { EmailPasswordModel } from "../models/EmailPasswordModel";
import { SearchHistoryModel } from "../models/SearchHistoryModel";
import { FacebookUserModel } from "../models/FacebookUserModel";
import { getHost } from "../helpers/Common";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });
const apiBaseUrl = getHost() + "api/";

export async function postCandidateLogin(loginModel: LoginModel) {
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/login", loginModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postCandidateLogout(candidateId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/logout", "candidateId=" + candidateId )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });    
}

export async function postCandidateRegister(formData: FormData) {
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/register", formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postLinkedInAuthCode(authCode: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/authenticateWithLinkedIn?authCode=" + authCode)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function postGoogleAccessToken(accessToken: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/authenticateWithGoogle?accessToken=" + accessToken)
        .then((response) => {
            resolve(response.data)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

export async function postFacebookUserModel(facebookUserModel: FacebookUserModel){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/authenticateWithFacebook", facebookUserModel)
        .then(response => {
            resolve(response.data);
        })
        .catch(error => {
            reject(error);
        })
    })
}

export async function completeRegisterWithSocialApps(formData: FormData){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/completeRegisterWithSocialApps", formData, {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        })
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function getCandidate(candidateId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/getCandidate", {            
            params: {         
                candidateId: candidateId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function addNewCVToProfile(formData: FormData, token: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/newCV", formData, {
            headers: {
                "Content-Type" : "multipart/form-data",
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function addProfilePicture(formData: FormData, token: string){
    return new Promise<any>((resolve, reject) => {
        axios
        .post(apiBaseUrl + "candidate/addProfilePicture", formData, {
            headers: {
                "Content-Type" : "multipart/form-data",
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data)
        })
        .catch(error => {
            reject(error)
        })
    })
}

export async function getCandidateDraftApplications(page: number, pageSize: number, candidateId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/draftApplications", {            
            params: {      
                page: page,
                pageSize: pageSize,          
                candidateId: candidateId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCandidateDraftApplication(candidateId: number, jobId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/draftApplication", {            
            params: {         
                candidateId: candidateId,
                jobId: jobId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCandidateJobApplications(page: number, pageSize: number, candidateId: number, applicationStatusIds: string, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/jobApplications", {            
            params: {      
                page: page,
                pageSize: pageSize,          
                candidateId: candidateId,
                applicationStatusIds: applicationStatusIds
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postCandidateSaveJob(candidateId: number, jobId: number, isSaved: boolean, token: string) {
    // make deleted true for already saved jobs
    const candidateSavedJob: CandidateSavedJob = {        
        candidateId: candidateId,
        jobId: jobId,
        deleted: isSaved
    }
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/saveJob", candidateSavedJob,
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getCandidateProfile(candidateId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/profile", {            
            params: {         
                candidateId: candidateId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {                
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postCandidateProfile(candidateProfile: CandidateProfileModel, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/profile", candidateProfile,
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function updateCandidateEmail(emailModel: EmailPasswordModel, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/updateEmail", emailModel,
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function updateCandidatePassword(passwordModel: EmailPasswordModel, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/password", passwordModel,
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postLostPasswordRequest(email: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .post(apiBaseUrl + "candidate/lostPassword", "email=" + email )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getResetPasswordValidity(email: string, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "candidate/resetPasswordValidity",{            
            params: {      
                email: email,
                token: token
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postResetPassword(passwordModel: EmailPasswordModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/resetPassword", passwordModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getCVFormats(page: number, pageSize: number, pageCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/cvFormats", {            
            params: {      
                page: page,
                pageSize: pageSize,
                pageCount: pageCount
            },                     
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getAllCVFormats() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/allCVFormats", {                                            
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function deleteCandidateAccount(passwordModel: EmailPasswordModel, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/deleteAccount", passwordModel, 
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postSearchHistory(searchHistory: SearchHistoryModel, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "candidate/searchHistory", searchHistory,
        {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getSearchHistories(candidateId: number, token: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "candidate/searchHistories", {            
            params: {      
                candidateId: candidateId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function deleteSearchHistory(searchHistoryId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .delete(apiBaseUrl + "candidate/deleteSearchHistory", {            
            params: {      
                searchHistoryId: searchHistoryId
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getPromoCodeValidation(candidateId: number, promoCode: string, paymentTypeId: number, token: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .get(apiBaseUrl + "checkout/candidatePromoCodeValidation", {            
            params: {      
                candidateId: candidateId, 
                promoCode: promoCode, 
                paymentTypeId: paymentTypeId
            },
            headers: {
                'Authorization' : `Bearer ${token}`
            },
            cache: false
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postCandidateEmailConfirmation(key: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .post(apiBaseUrl + "candidate/emailConfirmation", "key=" + key )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateCandidateJobsByEmail(email: string) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .patch(apiBaseUrl + "candidate/updateJobsByEmail/" + email)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}