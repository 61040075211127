import { useContext, useEffect, useState } from "react";
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { StyleContext } from "../../providers/StyleContextProvider";
import { getBanners, updateBannerClickCount } from "../../services/HomeService";
import useErrorPage from "../../hooks/useErrorPage";
import { Banner } from "../../models/Banner";
import { AuthContext } from "../../providers/AuthContextProvider";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import { Adsense } from "@ctrl/react-adsense";

interface Props {
    styles?: any;
    adsenseClient: string;
    adsenseSlotOne: string;
    adsenseSlotTwo?: string;
    adsenseSlotThree?: string;
    recordCount?: number;
    pageSize?: number;
}

export const VerticalBanner: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("verticalBanner"));    
    const[bannersOne, setBannersOne] = useState<Banner[]>([]);
    const[bannersTwo, setBannersTwo] = useState<Banner[]>([]);
    const[bannersThree, setBannersThree] = useState<Banner[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const[loading, setLoading] = useState<boolean>(true);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const recordCount: number =  props.recordCount ?? 0;
    const pageSize: number = props.pageSize ?? 0;
    const [toErrorPage] = useErrorPage();
       
    useEffect(() => {
        let extendedStyles = merge(styleContext.getComponentStyle("verticalBanner"), props.styles);                  
        setStyles(extendedStyles);                       
    }, [isMobile]);

    useEffect(() => {
        getBannerList();
    }, []);
        
    const sliderSettings = {        
        infinite: true,        
        dots: true,         
        rows: 1,
        speed: 500,
        autoplay: true,      
        autoplaySpeed: 3000,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: false,
        appendDots: (dots: any) => (
            <div>
                <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };

    async function getBannerList() {        
        await getBanners("v2-vertical-banner")
        .then((bannerList: Banner[]) => {
            let bannerListOne = bannerList.filter(x => x.keyWord.includes("v2-vertical-banner-i-"));
            setBannersOne(bannerListOne);
            let bannerListTwo = bannerList.filter(x => x.keyWord.includes("v2-vertical-banner-ii-"));
            setBannersTwo(bannerListTwo);
            let bannerListThree = bannerList.filter(x => x.keyWord.includes("v2-vertical-banner-iii-"));
            setBannersThree(bannerListThree);
            setLoading(false);
        })    
        .catch((error) => {            
            toErrorPage(error);
        });       
    }

    const redirectToBannerUrl = (banner: Banner) => {        
        window.open(banner.redirectingUrl, '_blank'); 
        updateBannerClickCount(banner.id);
    }

    return (
        <>
            {!loading && <div style={styles.wrapper}>
                <div style={styles.sliderWrapper}>                         
                    {(bannersOne.length > 0) ? <Slider {...sliderSettings}>
                        {bannersOne.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.keyWord} style={styles.banner}></img>                           
                            </div>
                        )}
                    </Slider>
                    : !candidate?.isPremium && <>
                        <Adsense style={styles.googleBanner} client={props.adsenseClient} slot={props.adsenseSlotOne} />
                    </>}
                </div>
                {(pageSize !== 10 && recordCount > 19) &&  <> <div style={styles.sliderWrapper}>                         
                    {(bannersTwo.length > 0) ? <Slider {...sliderSettings}>
                        {bannersTwo.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.keyWord} style={styles.banner}></img>                           
                            </div>
                        )}
                    </Slider>
                    : !candidate?.isPremium && <>
                        <Adsense style={styles.googleBanner} client={props.adsenseClient} slot={props.adsenseSlotTwo!} />
                    </>}
                </div>
                <div style={styles.sliderWrapper}>                         
                    {(bannersThree.length > 0) ? <Slider {...sliderSettings}>
                        {bannersThree.map((banner, index) =>
                            <div key={index} onClick={() => redirectToBannerUrl(banner)}>
                                <img src={banner.keyWord} style={styles.banner}></img>                           
                            </div>
                        )}
                    </Slider>
                    : !candidate?.isPremium && <>
                        <Adsense style={styles.googleBanner} client={props.adsenseClient} slot={props.adsenseSlotThree!} />
                    </>}
                </div>
                </>}
            </div>}
        </>
    );
}