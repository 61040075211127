import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const loaderStyles = {
    mobile: {        
        width: "90%",
        margin: "4.83vh auto 11.16vh auto",
        contentHeight: "48vh",
        buttonHeight: "4.5vh"
    },
    desktop: {
        width: "43.85vw",
        margin: "4vh auto 0 auto",
        contentHeight: "46vh",
        buttonHeight: "6.5vh"
    }
};
 
export const ConfirmationLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    }    

    return (
        <div style={styles}>
            <ContentLoader
                speed={1}
                width={"100%"}
                height={styles.contentHeight}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                <rect x="20%" y="2%" rx="2" ry="2" width="18%" height="3%" />
                <rect x="40%" y="2%" rx="2" ry="2" width="18%" height="3%" />
                <rect x="60%" y="2%" rx="2" ry="2" width="25%" height="3%" />
                <rect x="15%" y="10%" rx="2" ry="2" width="15%" height="3%" />
                <rect x="32%" y="10%" rx="2" ry="2" width="22%" height="3%" />
                <rect x="58%" y="10%" rx="2" ry="2" width="30%" height="3%" />
                <rect x="8%" y="20%" rx="2" ry="2" width="30%" height="3%" />
                <rect x="42%" y="20%" rx="2" ry="2" width="28%" height="3%" />
                <rect x="75%" y="20%" rx="2" ry="2" width="18%" height="3%" />
                <rect x="5%" y="28%" rx="2" ry="2" width="20%" height="3%" />
                <rect x="30%" y="28%" rx="2" ry="2" width="35%" height="3%" />
                <rect x="68%" y="28%" rx="2" ry="2" width="30%" height="3%" />
                <rect x="0%" y="36%" rx="2" ry="2" width="30%" height="3%" />
                <rect x="35%" y="36%" rx="2" ry="2" width="32%" height="3%" />
                <rect x="70%" y="36%" rx="2" ry="2" width="30%" height="3%" />
                <rect x="0" y="60%" rx="8" ry="8" width="100%" height="5%"/>
                <rect x="10%" y="68%" rx="8" ry="8" width="80%" height="2%"/>
                <rect x="15%" y="71%" rx="8" ry="8" width="70%" height="2%"/>
                <rect x="5%" y="85%" rx="8" ry="8" width="40%" height={styles.buttonHeight}/>
                <rect x="55%" y="85%" rx="8" ry="8" width="40%" height={styles.buttonHeight}/>
            </ContentLoader>
        </div>
    );
}