import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { TopHiringOrganizationsTexts as Texts } from '../../helpers/LayoutTexts';
import { Organization } from '../../models/Organization';
import { StyleContext } from '../../providers/StyleContextProvider';
import { getTopHiringOrganizations } from '../../services/HomeService';
import { TopHiringOrganizationCard } from './TopHiringOrganizationCard';
import { TopHiringOrganizationsLoader } from '../Loaders/TopHiringOrganizationsLoader';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Button } from '../Shared/Buttons/Button';
import { merge } from 'react-merge';
import useErrorPage from '../../hooks/useErrorPage';

interface Props {
    styles?: any;
}

export const TopHiringOrganizations: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("topHiringOrganizations"));  
    const [topHiringOrganizations, setTopHiringOrganizations] = useState<Organization[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const sliderRef = useRef<Slider | null>(null);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const recordsCount: number = isMobile ? 12 : 36;
    const [toErrorPage] = useErrorPage();
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;
    
    useEffect(() => {                 
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => { 
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("topHiringOrganizations"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);  
        getTopHiringOrganizationList();                      
    }, [isMobile]);

    async function getTopHiringOrganizationList() {
        await getTopHiringOrganizations(recordsCount)
        .then((topHiringOrganizationList: Organization[]) => {
            setTopHiringOrganizations(topHiringOrganizationList);
            setLoading(false);
        })
        .catch((error) => {            
            if(isMountedRef.current) {
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getTopHiringOrganizationList, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
            }
        });
    } 

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    }

    const previousSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const sliderSettings = {
        className: "center",
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 3,
        speed: isMobile ? 500 : 1000,
        autoplay: isMobile ? false : true,      
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: isMobile ? 1 : 4,
        swipeToSlide: true,
        touchThreshold: 100,
        useTransform: false,
        appendDots: (dots: any) => (
            <div>
              <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };
    return (
        <div style={styles}> 
            <div style={styles.wrapper}>
                <div style={styles.title}>{Texts.title}</div> 
                <div style={styles.sliderButtonWrapper}>
                    <Button style={styles.leftSliderButton} onClick={previousSlide}><LeftArrow style={styles.buttonIcon} /></Button>
                    <Button style={styles.rightSliderButton} onClick={nextSlide}><RightArrow style={styles.buttonIcon} /></Button>
                </div> 
            </div>                                      
            {loading ? <TopHiringOrganizationsLoader/> : 
            <div style={styles.slider}>
                <Slider ref={sliderRef} {...sliderSettings}>
                    {topHiringOrganizations.map((topHiringOrganization, index) =>
                        <div key={index}>
                            <TopHiringOrganizationCard organization={topHiringOrganization} styles={styles.card} />
                        </div>                     
                    )}
                </Slider>
            </div>}
        </div>
    );
}