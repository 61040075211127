import React, { useContext, useEffect, useRef, useState } from 'react';
import { CandidateApplication } from '../../models/CandidateApplication';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { ApplicationCard } from './ApplicationCard';
import { Pagination } from '../Shared/Pagination';
import useErrorPage from '../../hooks/useErrorPage';
import { getCandidateDraftApplications } from '../../services/CandidateService';
import { FeedbackCard } from '../Shared/FeedbackCard';
import { StatusOfApplicationsLoader } from '../Loaders/StatusOfApplicationLoader';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {    
    candidate: LoggedCandidate;
}

export const DraftApplications: React.FC<Props> = (props) => { 
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("statusOfApplications"));    
    const [draftApplications, setDraftApplications] = useState<CandidateApplication[]>([]);        
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });      
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>(1); 
    const [recordCount, setRecordCount] = useState<number>(0);
    const pageSize: number = isMobile ? 5 : 15;
    const [toErrorPage] = useErrorPage();
    const scrollElementRef = useRef<any>();
    
    useEffect(() => {                       
        setStyles(styleContext.getComponentStyle("statusOfApplications"));                 
    }, [isMobile]);

    useEffect(() => {
        getDraftApplications()
    }, [page]);

    async function getDraftApplications() {
        await getCandidateDraftApplications(page, pageSize, props.candidate.candidateId, props.candidate.token)
        .then((draftApplicationList: CandidateApplication[]) => {
            setDraftApplications(draftApplicationList);
            if(draftApplicationList.length > 0) {
                let totalPageCount = Math.ceil(draftApplicationList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
                setRecordCount(draftApplicationList[0].recordCount);
            } else {
                setTotalPages(1);
                setRecordCount(0);
            } 
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const handlePagination = (page: number) => {        
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);        
    }

    const onCardClick = (application: CandidateApplication) => {        
        const params = { stage: application.draftStage.toString(), isDraft: "true", isDraftCV: application.isDraftCV.toString() };            
        navigate({
            pathname: "/jobs/apply/" + application.jobId,
            search: `?${createSearchParams(params)}`,
        });        
    }

    return (
        <>
            <p style={styles.title} ref={scrollElementRef}>Draft Applications</p>
            {loading ? <StatusOfApplicationsLoader /> : <div>
                {draftApplications.length > 0 ? <div>
                    <div style={styles.topWrapper}>
                        <div style={styles.applicationCount}><span style={styles.applicationCount.count}>{recordCount}</span> Applications</div>
                    </div>
                    <div style={styles.applicationsWrapper}>
                        {draftApplications.map((draftApplication, index) =>
                            <ApplicationCard key={index} styles={styles.application} application={draftApplication} isDraft={true} onClick={onCardClick}/>
                        )}
                    </div>
                    {(totalPages > 1) && <Pagination page={page} totalPages={totalPages} handlePagination={handlePagination}/>}
                </div>
                : <FeedbackCard styles={styles.noResults} message={"No Draft Applications found."} />}
            </div>}
        </>
    )
}