import { Color } from "../enums/Color";

export const searchArea = {
    mobile: {
        display: "flex",
        position: "relative" as "relative",
        left: "50%",
        transform: "translate(-50%, 0%)",
        height: "30.19vw",
        width: "90.33%",
        marginTop: "-14.97vw",        
        borderRadius: "2.77vw",
        backgroundColor: Color.White,
        boxShadow: "5px 5px 45px #00000029",        
        zIndex: "2",
        overlay: {
            zIndex: "3"
        },
        jobsMargin: {
            marginTop: "-19.32vw"
        },
        input: {
            width: "52.90vw",
            padding: "4.83vw 3.62vw 0 3.62vw",                        
            fontSize: "16px",
            border: "none",
            borderRadius: "2.77vw",
            height: "5.56vw",
            color: Color.DeepBlack
        },
        button: {
            position: "absolute" as "absolute",
            right: "3.62vw",
            left: "3.62vw",
            top: "14.49vw",
            bottom: "2.77vw",
            height: "12.08vw",
            width: "83.10vw",
            backgroundColor: Color.Blue,
            border: "none",
            borderRadius: "2.77vw",
            cursor: "pointer",
            boxShadow: "0.72vw 0.72vw 2.41vw #00000027",
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center"
            },
            buttonText: {
                color: Color.White,
                fontWeight: "bold" as "bold",
                marginLeft: "auto",
                fontSize: "4.347vw"
            },
            buttonIcon: {
                width: "4.16vw",
                height: "4.16vw",
                fill: Color.White,
                marginLeft: "1.2vw",
                marginRight: "auto"
            },
            green: {
                backgroundColor: Color.Green
            }
        },
        select: {
            wrapper: {
                width: "26.57vw",
                height: "7.25vw",                                
                marginTop: "3.62vw",
                marginRight: "3.62vw",
                zIndex: "3",
                WebkitTapHighlightColor: "transparent",
                borderLeft: `3px solid ${Color.LightAsh}`
            },
            label: {
                display: "none"
            },
            button: {
                width: "auto",                
                backgroundColor: "transparent",
                color: Color.Black,
                fontSize: "3.86vw",
                fontWeight: "medium",
                border: "none",
                cursor: "pointer"
            },
            buttonWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center",
                marginTop: "1.70vw"
            },
            buttonText: {
                marginLeft: "auto",
                fontSize: "3.86vw"
            },
            buttonIcon: {
                width: "3.33vw",
                height: "3.33vw",
                fill: Color.Black,                
                marginLeft: "1.93vw",                
                transition: "transform .1s ease-in-out",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            leave: {
                display: "none"
            },
            hover: {
                display: "block",
                position: "absolute" as "absolute",
                top: "14.49vw",
                width: "calc(100% - 4.83vw)",
                height: "56.52vw",
                WebkitOverflowScrolling: "touch",
                padding: "0 2.41vw 1.35vh",
                left: "0",
                backgroundColor: Color.White,
                borderRadius: "0 0 1.35vh 1.35vh",
                border: "1px solid " + Color.LightSilver,
                zIndex: 1
            },
            search: {
                display: "flex",
                height: "7.25vw",
                width: "calc(100% - 8.45vw)",
                margin: "2.41vw 2.41vw 0 2.41vw",
                fontSize: "3.38vw",
                paddingLeft: "1.35vh",
                color: Color.Black,
                border: "none",
                borderRadius: "3.62vw",
                backgroundColor: Color.LightAsh,
                iconWrapper: {
                    position: "absolute" as "absolute",
                    top: "4.5vw",
                    left: "21vw"
                },
                icon: {
                    width: "2.41vw",
                    height: "3vw",
                    fill: Color.SharpAsh,
                    cursor: "text"
                }
            },
            scrollArea: {
                height: "82%",
                overflow: "auto",
                marginTop: "1.38vw"
            },
            option: {
                margin: "0 1.38vw",
                padding: "1.44vw 0.46vw",
                cursor: "pointer",
                position: "relative" as "relative",
                display: "flex",
                zIndex: 1,
                hover: {
                    backgroundColor: Color.LightBlue,
                    margin: "0 1.38vw",
                    borderRadius: "1.69vw",
                },
                leave: {
                    backgroundColor: "unset",
                },
            },
            checkbox: {
                borderRadius: "0.5vw",
                marginTop: "0.8vw",
                zIndex: 0,
                cursor: "pointer",
                border: "0.07vw solid " + Color.SharpAsh,
                height: "2.65vw",
                width: "2.65vw",
                float: "left",
                marginLeft: "2.17vw"
            },
            checkMark: {
                marginLeft: "2.25vw",
                marginTop: "0.8vw",
                height: "2.72vw",
                width: "2.72vw",
                float: "left"
            },
            optionLabel: {
                cursor: "pointer",
                marginLeft: "3.62vw",
                fontSize: "3.86vw",
                fontWeight: "500",
                color: Color.MiddleBlack
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        }
    },
    desktop: {
        position: "relative" as "relative",
        marginTop: "-8.33vw",
        width: "71.04vw",
        height: "4.16vw",
        display: "block",
        borderRadius: "0.52vw",
        overlay: {
            zIndex: "2"
        },
        jobsMargin: {
            marginTop: "-6.61vw"
        },
        input: {
            padding: "1.43vw 0 0 1.56vw",             
            fontSize: "0.93vw",
            boxShadow: "none",            
            width: "42.54vw",
            height: "1.30vw",
            borderRadius: "0.52vw 0 0 0.52vw"
        },
        button: {
            left: "79.2%",
            top: "0",
            bottom: "0",
            right: "0.78vw",
            width: "13.54vw",
            margin: "auto",
            backgroundColor: Color.Green,
            height: "2.6vw",
            borderRadius: "0.52vw",
            boxShadow: "0.15vw 0.15vw 0.52vw #00000027",
            buttonText: {
                fontSize: "1.04vw"
            },
            buttonIcon: {
                width: "1.04vw",
                height: "1.04vw",
                marginLeft: "0.52vw"
            }
        },
        select: {
            wrapper: {
                width: "13%",
                height: "unset",
                position: "absolute" as "absolute",
                marginTop: "0",
                marginRight: "0",
                right: "16.92vw",
                top: "18%",
                bottom: "18%",
                paddingTop: "1.5vh",
                paddingRight: "0"
            },
            button: {
                width: "100%",
                height: "100%",
                paddingBottom: "25%",
                fontSize: "1.17vw"
            },
            buttonWrapper: {                
                marginTop: "unset"
            },
            buttonText: {
                fontSize: "0.93vw",
                fontWeight: 500,
                marginLeft: "auto"
            },
            buttonIcon: {
                width: "0.8vw",
                height: "0.93vw",
                marginLeft: "0.72vw",
                marginRight: "5%"
            },
            hover: {
                width: "9.63vw",
                height: "11.14vw",
                padding: "0.52vw",
                top: "3.64vw",
                left: "0",
                borderRadius: "0.52vw"
            },
            search: {
                width: "90%",
                fontSize: "0.72vw",
                height: "1.56vw",
                borderRadius: "0.78vw",
                margin: "0",
                paddingLeft: "0.78vw",
                iconWrapper: {
                    position: "absolute",
                    top: "0.7vw",
                    left: "4.32vw"
                },
                icon: {
                    width: "0.78vw",
                    height: "unset"
                }
            },
            scrollArea: {
                marginTop: "0.36vw"
            },
            option: {
                margin: "0",
                padding: "0.36vw",
                hover: {
                    margin: "0",
                    borderRadius: "0.364vw"
                }
            },
            optionLabel: {
                fontSize: "0.83vw",
                marginLeft: "0.51vw",
                maxWidth: 'calc(100% - 2vw)'
            },
            checkbox: {
                marginLeft: "0.46vw",
                borderRadius: "0.1vw",
                marginTop: "0.2vw",
                height: "0.57vw",
                width: "0.57vw"
            },
            checkMark: {
                marginLeft: "0.46vw",
                marginTop: "0.2vw",
                height: "0.7vw",
                width: "0.7vw"
            }
        }
    }
}