import { merge } from "react-merge"
import { useMediaQuery } from "react-responsive"
import { Color } from "../../enums/Color"
import ContentLoader from "react-content-loader"

const loaderStyle = {
  mobile : {
    form: {
      height: "90.82vw",
      marginTop: "7.73vw"
    }
  },
  desktop: {
    form: {
      height: "25.68vw",
      margin: "2.13vw 2.60vw 0 2.60vw"
    }
  }
};

export const ContactUsLoader : React.FC = () => {
  const isMobile = useMediaQuery({ query : "(max-width: 786px)"});
  var style = loaderStyle.mobile;
  if(!isMobile) {
    let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
    style = extendedStyle;
  }
  
  return (
    <div style={style.form}>
      <ContentLoader
        speed={1}
        backgroundColor={Color.LoaderBackground}
        foregroundColor={Color.LoaderForeground}
        width= "auto"
        height= {style.form.height}
      > 
        {isMobile ? <>
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="13.30%" />
          <rect x="0" y="17.29%" rx="3" ry="3" width="100%" height="13.30%" />
          <rect x="0" y="34.57%" rx="3" ry="3" width="100%" height="13.30%" />
          <rect x="0" y="51.86%" rx="3" ry="3" width="100%" height="48.14%" />
        </> : 
        <>
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="14.20%" />
          <rect x="0" y="17.44%" rx="3" ry="3" width="100%" height="14.20%" />
          <rect x="0" y="34.89%" rx="3" ry="3" width="100%" height="14.20%" />
          <rect x="0" y="52.33%" rx="3" ry="3" width="100%" height="47.67%" />
        </>} 
      </ContentLoader>
    </div>
  )
}