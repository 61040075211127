import { jobCategories } from "./JobCategories";
import { shortJobCardList } from "./ShortJobCardList";
import { topHiringOrganizations } from "./TopHiringOrganizations";
import { statistics } from "./Statistics";
import { logoGride } from "./LogoGride";
import { footer } from "./Footer";
import { jobViewPage } from "./JobViewPage";
import { horizontalBanner } from "./HorizontalBanner";
import { verticalBanner } from "./VerticalBanner";
import { searchPage } from "./SearchPage";
import { singleSelect } from "./SingleSelect";
import { pagination } from "./Pagination";
import { filterPanel } from "./FilterPanel";
import { fullHeader } from "./FullHeader";
import { candidateHomePage } from "./CandidateHomePage";
import { loginRegister } from "./LoginRegister";
import { Input } from "./Input";
import { jobApplyPage } from "./JobApplyPage";
import { verticalJobDetailCard } from "./VerticalJobDetailCard";
import { getInTouch } from "./GetInTouch";
import { submitArea } from "./SubmitArea";
import { jobOrganizationCard } from "./JobOrganizationCard";
import { popUpWindow } from "./PopUpWindow";
import { button } from "./Button";
import { emailManager } from "./EmailManager";
import { adminHeader } from "./AdminHeader";
import { emailManagerLogin } from "./EmailManagerLogin";
import { candidateProfilePage } from "./CandidateProfilePage";
import { multySelect } from "./MultySelect";
import { statusOfApplications } from "./StatusOfApplications";
import { candidateSavedJobs } from "./CandidateSavedJobs";
import { mobileMenu } from "./MobileMenu";
import { myInformation } from "./MyInformation";
import { candidateSettingsPage } from "./CandidateSettingsPage";
import { organizationJobsPage } from "./OrganizationJobsPage";
import { longJobCard } from "./LongJobCard";
import { homePage } from "./HomePage";
import { cvFormatsPage } from "./CVFormatsPage";
import { sideDrawer } from "./SideDrawer";
import { pressReleasesPage } from "./PressReleasesPage";
import { disabilityFriendlyPage } from "./DisabilityFriendlyPage";
import { searchHistory } from "./SearchHistory";
import { candidatePremiumPage } from "./CandidatePremiumPage";
import { smsJobAlertsPage } from "./SMSJobAlertsPage";
import { testimonialsPage } from "./TestimonialsPage";
import { contactPage } from "./ContactPage";
import { preferredPartnersPage } from "./PreferredPartnersPage";
import { termsConditionsPage } from "./TermsConditionsPage";
import { errorPage } from "./ErrorPage";
import { candidateCheckoutPage } from "./CandidateCheckoutPage";
import { responseAlert } from "./ResponseAlert";
import { searchArea } from "./SearchArea";
import { desktopMenu } from "./DesktopMenu";
import { lowHeader } from "./LowHeader";
import { mediumHeader } from "./MediumHeader";
import { jobViewHeader } from "./JobViewHeader";
import { searchHeader } from "./SearchHeader";
import { quizPage } from "./QuizPage";
import { tooltip } from "./Tooltip";
import { lowestHeader } from "./LowestHeader";
import { confirmationPage } from "./ConfirmationPage";
import { jobApplyLoader } from "./JobApplyLoader";
import { errorHeader } from "./ErrorHeader";
import { disabilityFriendlyHeader } from "./DisabilityFriendlyHeader";
import { privacyPolicyPage } from "./PrivacyPolicyPage";

export const styleDictionary = new Map<string, any>();

styleDictionary.set("button", button);
styleDictionary.set("input", Input);
styleDictionary.set("singleSelect", singleSelect);
styleDictionary.set("multySelect", multySelect);
styleDictionary.set("fullHeader", fullHeader);
styleDictionary.set("lowHeader", lowHeader);
styleDictionary.set("mediumHeader", mediumHeader);
styleDictionary.set("jobViewHeader", jobViewHeader);
styleDictionary.set("lowestHeader", lowestHeader);
styleDictionary.set("errorHeader", errorHeader);
styleDictionary.set("disabilityFriendlyHeader", disabilityFriendlyHeader);
styleDictionary.set("searchArea", searchArea);
styleDictionary.set("desktopMenu", desktopMenu);
styleDictionary.set("searchHeader", searchHeader);
styleDictionary.set("footer", footer);
styleDictionary.set("mobileMenu", mobileMenu);
styleDictionary.set("jobViewPage", jobViewPage);
styleDictionary.set("searchPage", searchPage);
styleDictionary.set("jobCategories", jobCategories);
styleDictionary.set("shortJobCardList", shortJobCardList);
styleDictionary.set("longJobCard", longJobCard);
styleDictionary.set("topHiringOrganizations", topHiringOrganizations);
styleDictionary.set("statistics", statistics);
styleDictionary.set("logoGride", logoGride);
styleDictionary.set("horizontalBanner", horizontalBanner);
styleDictionary.set("verticalBanner", verticalBanner);
styleDictionary.set("pagination", pagination);
styleDictionary.set("filterPanel", filterPanel);
styleDictionary.set("candidateHomePage", candidateHomePage);
styleDictionary.set("loginRegister", loginRegister);
styleDictionary.set("jobApplyPage", jobApplyPage);
styleDictionary.set("verticalJobDetailCard", verticalJobDetailCard);
styleDictionary.set("getInTouch", getInTouch);
styleDictionary.set("submitArea", submitArea);
styleDictionary.set("jobOrganizationCard", jobOrganizationCard);
styleDictionary.set("popUpWindow", popUpWindow);
styleDictionary.set("sideDrawer", sideDrawer);
styleDictionary.set("emailManager", emailManager);
styleDictionary.set("adminHeader", adminHeader);
styleDictionary.set("emailManagerLogin", emailManagerLogin)
styleDictionary.set("candidateProfilePage", candidateProfilePage);
styleDictionary.set("statusOfApplications", statusOfApplications);
styleDictionary.set("candidateSavedJobs", candidateSavedJobs);
styleDictionary.set("myInformation", myInformation);
styleDictionary.set("searchHistory", searchHistory);
styleDictionary.set("responseAlert", responseAlert);
styleDictionary.set("candidateSettingsPage", candidateSettingsPage);
styleDictionary.set("organizationJobsPage", organizationJobsPage);
styleDictionary.set("cvFormatsPage", cvFormatsPage);
styleDictionary.set("pressReleasesPage", pressReleasesPage);
styleDictionary.set("disabilityFriendlyPage", disabilityFriendlyPage);
styleDictionary.set("candidatePremiumPage", candidatePremiumPage);
styleDictionary.set("homePage", homePage);
styleDictionary.set("smsJobAlertsPage", smsJobAlertsPage);
styleDictionary.set("testimonialsPage", testimonialsPage);
styleDictionary.set("contactPage", contactPage);
styleDictionary.set("preferredPartnersPage", preferredPartnersPage);
styleDictionary.set("termsConditionsPage", termsConditionsPage);
styleDictionary.set("candidateCheckoutPage", candidateCheckoutPage);
styleDictionary.set("quizPage", quizPage);
styleDictionary.set("confirmationPage", confirmationPage);
styleDictionary.set("errorPage", errorPage);
styleDictionary.set("tooltip", tooltip);
styleDictionary.set("jobApplyLoader", jobApplyLoader);
styleDictionary.set("privacyPolicyPage", privacyPolicyPage);
