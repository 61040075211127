import { Color } from "../enums/Color"

export const multySelect = {
    mobile: {
        wrapper: {
            padding: "0.8vh 2.78vw",
            WebkitTapHighlightColor: "transparent",
            position: "relative",
            width: "25.36vw",
            border: "1px solid " + Color.LightSilver,
            borderRadius: "2vw",
            justifyContent: "center",
            cursor: "pointer",            
            hover: {
                borderRadius: "2vw 2vw 0 0"
            }
        },
        label: {
            display: "none"
        },
        text: {
            fontSize: "4.34vw",
            color: Color.SharpAsh,
            whiteSpace: "nowrap",
            overflow: "hidden",            
            maxWidth: "80%"
        },
        icon: {
            width: "3.33vw",
            height: "3.33vw",
            transition: "transform .2s ease-in-out",
            fill: Color.SharpAsh,
            marginLeft: "2.41vw"
        },
        rotateIcon: {
            transform: "rotate(180deg)"
        },
        hover: {
            position: "absolute",
            backgroundColor: Color.White,
            zIndex: 1,
            top: "4vh",
            left: "-17.5vw",
            width: "48.2vw",
            maxHeight: "40vh",
            padding: "1.22vh 0",
            borderRadius: "2.42vw 0 2.42vw 2.42vw",
            border: "1px solid " + Color.LightSilver,
        },
        option: {
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
            padding: "1.38vw",
            cursor: "pointer",
            zIndex: 1,
            width: "92%",
            hover: {
                backgroundColor: Color.LightBlue,
                borderTop: "none",
                borderRadius: "1.69vw",
            },
            leave: {
                backgroundColor: "unset",
            },
        },
        bottomBorder: {
            border: 0,
            height: "1px",
            width: "92%",
            background: Color.MiddleAsh,
            margin: "0 auto",
            zIndex: "1"
        },
        hoveredBorder: {
            background: Color.White,
            borderRadius: "1px"
        }
    },
    desktop: {
        wrapper: {
            padding: "1.5vh 3.70vw",
            width: "5.83vw",
            borderRadius: "0.52vw",            
            hover: {
                borderRadius: "0.52vw 0.52vw 0 0"
            }
        },
        text: {
            fontSize: "0.9375vw"
        },
        icon: {
            width:  "0.625vw",
            height: "0.625vw",
            marginLeft: "0.52vw"
        },
        hover: {
            padding: "0.35vw",
            left: "unset",
            top: "5vh",
            width: "calc(100% - 0.7vw)",
            maxHeight: "35vh",
            borderRadius: "0 0 0.52vw 0.52vw"
        },
        option: {
            padding: "0.312vw 0.41vw",
            hover: {
                borderRadius: "0.36vw"
            }
        }
    }
}
