import axios from "axios";
import { CheckoutModel } from "../models/CheckoutModel";
import { saveAs } from 'file-saver';
import { getHost } from "../helpers/Common";

const apiBaseUrl = getHost() + "api/";

export async function postCheckoutRequest(checkout: CheckoutModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "checkout/webXPay", checkout)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function downloadCVFormats(checkoutId: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "checkout/cvFormats", {            
            headers: {                
                'Content-Type': 'application/json'                
            },             
            params: {      
                checkoutId: checkoutId
            },
            responseType: 'blob'
        })
        .then((response) => {
            var blob = new Blob([response.data], {
                type: response.headers['content-type']
            });
            var fileName = response.headers['content-type'] === 'application/zip' ? "XpressJobs_Sample_CVFormats" : "XpressJobs_Sample_CVFormat"
            saveAs(blob, fileName);            
        })
        .catch((error) => {
            reject(error);
        })
    });
}