import React, { useEffect, useState } from 'react'
import { JobApplyPageTexts as Texts } from '../../helpers/LayoutTexts';
import { JobApplyQuestion, JobApplyQuestionModel } from '../../models/JobQuestion';
import { getJobApplyQuestions, getQuestionsApplyStatus } from '../../services/JobService';
import { TextInput } from '../Shared/Inputs/TextInput';
import { Switch } from '../Shared/Buttons/Switch';
import { Guidelines } from './Guidelines';
import { SubmitArea } from './SubmitArea';
import useErrorPage from '../../hooks/useErrorPage';
import { CheckboxInput } from '../Shared/Inputs/CheckboxInput';
import { RadioInput } from '../Shared/Inputs/RadioInput';
import { ExtendableTextAreaInput } from '../Shared/Inputs/ExtendableTextAreaInput';
import { useAlert } from '../../hooks/useAlert';
import { LoggedCandidate } from '../../models/LoggedCandidate';
import { ResponseModel } from '../../models/ResponseModel';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Shared/Buttons/Button';
import { PopUpWindow } from '../Shared/PopUpWindow';

interface Props {
  styles: any;
  jobId: number;
  candidate: LoggedCandidate;
  isDraft: boolean;
  byExternal?: boolean | null;
  isMobile: boolean;
  handleQuestionSubmit(jobApplyQuestionModel: JobApplyQuestionModel, saveAsDraft: boolean): Promise<any>;
}

export const QuestionForm: React.FC<Props> = (props) => {
  const styles = props.styles;
  const [jobApplyQuestions, setJobApplyQuestions] = useState<JobApplyQuestion[]>([]);
  const [showGuidelines, setShowGuidelines] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [toErrorPage] = useErrorPage();
  const {Alert, openAlert} = useAlert();
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {     
    getJobApplyQuestionList();
    if(props.byExternal) {
      getApplyStatus();
    }
  }, []);

  async function getJobApplyQuestionList() {  
    await getJobApplyQuestions(props.candidate.candidateId, props.jobId, props.isDraft, props.candidate.token)
    .then((jobApplyQuestionList: JobApplyQuestion[]) => {
      setJobApplyQuestions(jobApplyQuestionList);  
    })
    .catch((error) => {            
      toErrorPage(error);
    });
  }

  async function getApplyStatus() {
    await getQuestionsApplyStatus(props.jobId, props.candidate.candidateId, props.candidate.token)
    .then((applyResponse: ResponseModel) => {
      if(applyResponse.status) {
        let content = <>
            <h1 style={styles.dialogTitle}>{applyResponse.data}</h1>    
            <p style={styles.dialogMessage}>{applyResponse.message}</p>
            <div style={styles.dialogButtons}>
                <Button style={styles.closeButton} type="button" onClick={closeMessageDialog}>{"Close"}</Button>
                <Button style={styles.redirectButton} type="button" onClick={() => navigate("/candidate/myapplications")}>{"MY JOB LISTING"}</Button>                        
            </div>                       
        </> 
        setDialogContent(content);
        setOpenMessageDialog(true);           
    }
    })
    .catch((error) => {
        toErrorPage(error);
    }) 
  }

  const handleAnswerQuestion = (questionIndex: number, questionTypeId: number, answerIndex?: number, answer?: string) => {    
    let tempQuestions = [...jobApplyQuestions];
    switch(questionTypeId) {
      case 1:
        tempQuestions[questionIndex].yesNoAnswer = !tempQuestions[questionIndex].yesNoAnswer;        
        break;
      case 2:        
        tempQuestions[questionIndex].multipleChoiceAnswers![answerIndex!].selected = !tempQuestions[questionIndex].multipleChoiceAnswers![answerIndex!].selected;
        let candidateAnswer: string = "";    
        tempQuestions[questionIndex].multipleChoiceAnswers!.filter(x => x.selected).forEach(function (choiceAnswer) {          
          candidateAnswer += choiceAnswer.answer + ";";
        });    
        if(candidateAnswer !== "") {
          tempQuestions[questionIndex].candidateAnswer = candidateAnswer.slice(0, -1);
        }  
        else {
          tempQuestions[questionIndex].candidateAnswer = null;
        }      
        break;
      case 3:        
        tempQuestions[questionIndex].singleChoiceAnswers!.forEach((e) => {e.selected = false});
        tempQuestions[questionIndex].singleChoiceAnswers![answerIndex!].selected = true;
        tempQuestions[questionIndex].candidateAnswer = tempQuestions[questionIndex].singleChoiceAnswers![answerIndex!].answer;
        break;
      case 5:        
        tempQuestions[questionIndex].openAnswer = answer!;
        tempQuestions[questionIndex].candidateAnswer = answer!;
        break;
      case 6:
        tempQuestions[questionIndex].knockoutAnswers!.forEach((e) => {e.selected = false});
        tempQuestions[questionIndex].knockoutAnswers![answerIndex!].selected = true;  
        tempQuestions[questionIndex].candidateAnswer = tempQuestions[questionIndex].knockoutAnswers![answerIndex!].answer;      
        break;
    }
    setJobApplyQuestions(tempQuestions);
  }

  const handleQuestionSubmit = (event: React.FormEvent, saveAsDraft: boolean) => {
    event.preventDefault();
    // set yes no type questions candidate answers
    jobApplyQuestions.filter(x => x.questionTypeId === 1).forEach(e => {e.candidateAnswer = e.yesNoAnswer ? "Yes" : "No"});
    let notCompleted = jobApplyQuestions.some(x => x.candidateAnswer === null || x.candidateAnswer === "");    
    if(!saveAsDraft && notCompleted) {
      openAlert("error", Texts.questionnaireNotCompleted);
      setIsSubmitClicked(true);
    }
    else {
      const jobApplyQuestionModel: JobApplyQuestionModel = {
        jobId: props.jobId,
        candidateId: props.candidate.candidateId,
        jobApplyQuestions: jobApplyQuestions
      }  
      props.handleQuestionSubmit(jobApplyQuestionModel, saveAsDraft);
    }
  };

  const closeMessageDialog = () => {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    setOpenMessageDialog(false);
    if(redirectUrl){
        navigate(redirectUrl);
    }
    else {
        navigate({ pathname: '/jobs' });
    }
}

  return (
    <div>
      <div style={{...styles.guidlinesNotice, ...styles.guidlinesNotice.mobile}} onClick={() => setShowGuidelines(true)}>
        <p style={styles.guidlinesNotice.text} >Please {Texts.readTheGuidlines} <u><b>Read Now</b></u> </p>
      </div>
      <h2 style={styles.applicationStage}>{Texts.applyStage.screening}</h2>
      <div style={styles.container}>
        <p style={styles.stageDescription}>{Texts.stageDescription.screening}</p>
        <div style={{...styles.guidlinesNotice, ...styles.guidlinesNotice.desktop}} onClick={() => setShowGuidelines(true)}>
          <p  style={styles.guidlinesNotice.text}>Please <u><b>click here</b></u> to {Texts.readTheGuidlines}</p>
        </div>
      </div>
      <form onSubmit={(e) => handleQuestionSubmit(e, false)}>
        {jobApplyQuestions.map((question, questionIndex) =>
          <div key={questionIndex} style={{ ...styles.questionWrapper, ...(question.questionTypeId === 1 && styles.questionWrapper.yesNo), ...(isSubmitClicked && question.questionTypeId !== 1 && (question.candidateAnswer === null || question.candidateAnswer.trim() === "") && styles.notAnswered)}}>
            <div style={styles.question}>
              <div style={styles.question.index}>{questionIndex + 1}.</div><div style={styles.question.text}>{question.question}</div>
            </div>
            {question.questionTypeId === 1 &&
              <div style={styles.switchContainer}><Switch styles={styles.switchInside} checked={question.yesNoAnswer} handleClick={() => handleAnswerQuestion(questionIndex, question.questionTypeId)} /></div> 
            }
            {question.questionTypeId === 2 &&
              question.multipleChoiceAnswers!.map((choiceAnswer, answerIndex) =>
                <CheckboxInput name={`multipleChoice_${questionIndex}_${answerIndex}`} key={answerIndex} styles={{ ...styles.selectAnswer, ...(question.multipleChoiceAnswers!.length > 4 && styles.changeDirection)}} value={choiceAnswer.selected} handleChange={() => handleAnswerQuestion(questionIndex, question.questionTypeId, answerIndex)}>
                  {choiceAnswer.answer}
                </CheckboxInput>
              )
            }
            {question.questionTypeId === 3 &&
              question.singleChoiceAnswers!.map((choiceAnswer, answerIndex) =>
                <RadioInput id={`singleChoice_${questionIndex}_${answerIndex}`} name={"singleChoice_#" + questionIndex} key={answerIndex} styles={{ ...styles.selectAnswer, ...(question.singleChoiceAnswers!.length > 4 && styles.changeDirection)}} value={choiceAnswer.answer} checked={choiceAnswer.selected} handleChange={() => handleAnswerQuestion(questionIndex, question.questionTypeId, answerIndex)}>
                  {choiceAnswer.answer}
                </RadioInput>
              )
            }
            {question.questionTypeId === 5 &&
              <ExtendableTextAreaInput styles={styles.inputField} name={"openAnswer"} value={question.openAnswer} handleChange={(answer) => handleAnswerQuestion(questionIndex, question.questionTypeId, 0, answer)} />
            }
            {question.questionTypeId === 6 &&
              question.knockoutAnswers!.map((choiceAnswer, answerIndex) =>
                <RadioInput id={`knockout_${questionIndex}_${answerIndex}`} name={"knockOut_#" + questionIndex} key={answerIndex} styles={{ ...styles.selectAnswer, ...(question.knockoutAnswers!.length > 4 && styles.changeDirection)}} value={choiceAnswer.answer} checked={choiceAnswer.selected} handleChange={() => handleAnswerQuestion(questionIndex, question.questionTypeId, answerIndex)}>
                  {choiceAnswer.answer}
                </RadioInput>
              )
            }
          </div>
        )}
      </form>
      <SubmitArea submitButtonText={Texts.submitButton} submitNotice={Texts.submitNotice} draftEnable={true} handleSubmit={(e) => handleQuestionSubmit(e, false)} handleDraft={(e) => handleQuestionSubmit(e, true)}/>
      <Guidelines open={showGuidelines} styles={styles.guidelines} toggle={() => setShowGuidelines(false)}/>
      <Alert/>
      <PopUpWindow styles={styles.dialog} open={openMessageDialog} toggle={closeMessageDialog} closeSlice={!props.isMobile} closeCircle={props.isMobile}>
        {dialogContent}
      </PopUpWindow>
    </div>
  );
};
