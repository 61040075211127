import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const smsJobAlertsPage = {
    mobile: {
        alertGride: {
            container: {
                margin: "3.35vh 5% 10.27vh 5%"
            },
            title: {
                fontFamily: "Poppins",
                fontSize: "4.35vw",                
                fontWeight: "bold" as "bold",
                textDecoration: "underline"
            },
            price: {
                margin: "2.68vh 0 0.44vh 0",
                fontFamily: "Poppins",
                fontSize: "3.38vw",                
                fontWeight: "medium" as "medium",
                color: Color.SharpAsh
            },
            cardWrapper: {
                display: "flex",
                flexDirection: "column" as "column"
            },
            card: {
                margin: "1.78vh 0 0 0",
                borderRadius :"2.41vw",
                border: "0.24vw solid " + Color.Gainsboro,
                padding: "2.68vh 5.79vw"
            },
            logoWrapper: {
                textAlign: "right" as "right"
            },
            logo: {
                width: "26.08vw",
                height: "3.90vh",
                objectFit: "contain"
            },
            activate: {
                fontSize: "2.90vw",                
                fontWeight: "bold" as "bold",
                color: Color.Munsell,
                marginTop: "2.01vh"
            },
            deactivate: {
                fontSize: "2.90vw",                
                fontWeight: "bold" as "bold",
                color: Color.Red,
                marginTop: "2.23vh"
            },
            instruction: {
                fontFamily: Font.Poppins,
                fontSize: "3.38vw",                                
                color: Color.SharpAsh,
                marginTop: "1.12vh",
                fontWeight: 500
            },
            code: {
                fontFamily: Font.Poppins,
                color: Color.Black,
                fontWeight: "bold" as "bold" 
            },
            number: {
                color: Color.Black,
                fontWeight: 500 
            },
            line: {
                display: "none"
            }
        },
        tabs: {
            container: {
                marginTop: "3.125vh"
            },
            linkWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                height: "auto",
                minHeight: "2.08vw",
                width: "95%",
                overflow: "scroll",
                backgroundColor: Color.LightAsh,
                borderRadius: "1.20vw",
                marginLeft: "5%"
            },
            link: {
                lineHeight: "auto",
                minHeight:"4.83vw",
                flexShrink: "0",
                fontFamily: Font.Default,
                fontsize: "0.83vw",
                color: Color.DeepAsh,
                borderRadius: "1.20vw",
                padding: "2vw 4.83vw 2vw 4.83vw",               
                alignSelf: "center" as "center",
                cursor: "pointer",
                active: {
                    backgroundColor: Color.DeepBlack,
                    color: Color.LightAsh
                }
            },
            contentWrapper: {
    
            }
        }
    },
    desktop: {
        wrapper: {
            padding: "1.85vh 0 11.67vh 0"
        },
        alertGride: {
            container: {
                margin: "6.48vh 7%"
            },
            title: {
                fontFamily: Font.Default,
                fontSize: "1.56vw",
                textDecoration: "none",
                color: Color.LightBlack
            },
            price: {
                margin: "3.70vh 0 1.85vh 0",                
                fontSize: "0.83vw"                
            },
            cardWrapper: {                
                flexDirection: "row" as "row",
                marginRight: "-0.83vw"
            },
            card: {
                height: "24.63vh",
                width: "20.72vw",
                margin: "0 0.83vw 0 0",
                borderRadius: "0.52vw",
                border: "0.05vw solid " + Color.Gainsboro,
                padding: "2.22vh 1.25vw"
            },
            logo: {
                width: "7.03vw",
                height: "2.29vw"
            },
            activate: {
                fontSize: "1.04vw",                
                marginTop: "2.22vh"
            },
            deactivate: {
                fontSize: "1.04vw",                
                marginTop: "2.78vh"
            },
            instruction: {                
                fontSize: "0.93vw",                
                marginTop: "1.11vh"
            },
            line: {
                display: "block",
                height: "0.09vh",
                color: Color.BrightGray,
                backgroundColor: Color.BrightGray,
                marginTop: "6.48vh",
                marginBottom: "0px",
                border: "none"
            }
        }     
    }
}