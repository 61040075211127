import { Color } from "../enums/Color";

export const sideDrawer = {
    mobile: {                         
        display: "flex" as "flex",    
        flexDirection: "row" as "row",
        height: "100%",
        width: "100%",        
        position: "fixed",
        top: "0",
        right: "0",        
        zIndex: "3",               
        transition: "transform 0.3s ease-out",
        open: {
            transform: "translateX(0)"
        },
        close: {
            transform: "translateX(100%)"
        },
        backgroundLayer: {
            width: "10%",
            background: Color.DeepBlack,
            opacity: 0.4
        },
        container: {
            width: "90%",
            background: Color.White,
            overflow: "scroll"
        }
    },
    desktop: {
        display: "none"
    }
}