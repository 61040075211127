import { Color } from "../enums/Color";

export const jobCategories = {
    mobile: {
        overflow: "hidden",
        marginTop: "12.07vw",
        wrapper: { },
        zIndex: "1",
        title: {
            textAlign: "left" as "left",
            marginLeft: "4.83vw",            
            fontWeight: "bold" as "bold",
            fontSize: "6.03vw",                    
            letterSpacing: "0px",
            color: Color.LightBlack,
            marginTop: "auto",
            marginBottom: "auto",
            opacity: "1"
        },
        sliderButtonWrapper: {            
            display: "none",
            flexDirection: "row" as "row", 
            marginRight: "7.29vw",
            alignItems: "center",
            marginLeft: "auto"
        },
        rightSliderButton: {
            backgroundColor: Color.Blue,
            borderColor: Color.LightBlue,
            border: "0.5vw solid " + Color.SharpBlue,
            marginLeft: "10px",
            cursor: "pointer",
            width: "3.4vw",
            height: "3.4vw",
            borderRadius: "50%",
            borderWidth: "0.5vw",
            fill: Color.White
        },
        leftSliderButton: {
            width: "3.6vw",
            height: "3.6vw",
            borderRadius: "50%",
            backgroundColor: Color.LightAsh,
            borderColor: Color.White,
            border: "0.4vw solid white",
            marginLeft: "0.73vw",
            cursor: "pointer",
            fill: Color.SharpAsh
        },
        buttonIcon: {
            width: "45%",
            height: "auto"
        },
        slider: {                        
            margin: "8.21vw -4.83vw 7.03vw 4.83vw",
            width: "152.17vw"
        },
        cardWrapper: {
            display: "inline"
        },
        card: {
            wrapper: {                
                height: "19.32vw",
                display: "flex",
                flexDirection: "row" as "row",
                alignItems: "center",
                background: Color.LightAsh,
                borderRadius: "1.2vw",
                margin: "2.41vw 1.2vw 0 1.2vw",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                padding: "0 3.62vw",
                verticalAlign: "text-bottom",
                hover: {
                    background: Color.White,
                    boxShadow: "5px 5px 45px #00000029"
                }
            },
            category: {
                width: "54.83vw",
                fontWeight: "bold" as "bold",
                fontSize: "3.86vw",
                color: Color.Black,
                margin: "0",
                whiteSpace: "nowrap",
                overflow: "hidden"
            },
            jobCount: {
                width: "30%",                    
                fontWeight: "bold" as "bold",
                fontSize: "2.65vw",                   
                color: Color.Black,
                flexShrink: "0",
                textAlign: "right"
            },
            label: {                    
                fontSize: "2.65vw",                  
                color: Color.Black,
                fontWeight: "normal"
            },
            buttonWrapper: {
                display: "none",
                width: "2.60vw"
            },
            button: {
                width: "3.6vw",
                height: "3.6vw",
                backgroundColor: Color.Green,
                borderColor: Color.LightBlue,
                border: "0.52vw solid " + Color.LightGreen,           
                borderRadius: "50%",
                cursor: "pointer",
                fill: Color.White,                
            },
            buttonIcon: {
                width: "0.72vw",
                height: "100%"
            }   
        },
        grid: {
            display: "grid",
            gridTemplateColumns: "repeat(1, auto)",
            margin: "4.83vw 4.83vw 0 4.83vw"
        },
        dots: { 
            marginTop: "0.9%", 
            marginBottom: "0%", 
            marginLeft: "-48%" 
        }
    },
    desktop: {
        marginTop: "3.59vw", 
        title: {
            fontSize: "1.56vw",
            marginLeft: "7.29vw"
        },
        wrapper: {            
            display: "flex",
            flexDirection: "row" as "row",            
        },
        sliderButtonWrapper: {            
            display: "flex"
        },
        slider: {
            margin: "1.35vw 0 1.7vw 7.29vw",
            width: "115vw"     
        },
        card: {
            wrapper: {                
                height: "5.62vw",
                width: "24.79vw",
                borderRadius: "0.52vw",
                margin: "0.83vw 0.83vw 0 0",
                padding: "0 1.56vw",
                display: "inline-flex",
                verticalAlign: "bottom"
            },
            category: {
                fontSize: "1.041vw"
            },
            jobCount: {
                fontSize: "1.30vw",
                width: "4.53vw"
            },
            label: {
                fontSize: "0.937vw",
                fontWeight: 500
            },
            buttonWrapper: {                
                marginLeft: "1.04vw"
            }, 
            buttonHover: {
                display: "block"
            }   
        },
        grid: {            
            gridTemplateColumns: "repeat(auto-fill, 28.64vw)",
            gridTemplateRows: "5.62vw 5.62vw 5.62vw 5.62vw 5.62vw",
            gridAutoFlow: "column",
            rowGap: "0.83vw",            
            margin: "2.60vw 6.8vw 1.82vw 6.8vw"
        },
        dots: {
            marginLeft: "-27.5%",
            marginTop: "0.2%"
        }     
    }
}