import React from 'react'
import ContentLoader from 'react-content-loader';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { Color } from '../../enums/Color';

const loaderStyles: any = {
  mobile: {
    margin: "-15% 5% 0 5%",
    height: "28vh"
  },
  desktop: {
    margin: "5%",
    height: "70vh"
  }
}

export const JobOrganizationCardLoader: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
  var styles = loaderStyles.mobile;
  if (!isMobile) {
    let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop);
    styles = extendStyles;
  }

  return (
    <div style={styles}>
      <ContentLoader speed={1}
        height={"100%"}
        width={"100%"}
        backgroundColor={Color.LoaderBackground}
        foregroundColor={Color.LoaderForeground}
      >
        {isMobile ? <rect x="0" y="0" rx="1.2vh" ry="1.2vh" width="100%" height="28vh" /> : 
          <>
            <rect x="0" y="0" rx="1.2vh" ry="1.2vh" width="20vh" height="20vh" />
            <rect x="0" y="22vh" rx="1.2vh" ry="1.2vh" width="100%" height="2.5vh" />
            <rect x="0" y="26vh" rx="1.2vh" ry="1.2vh" width="80%" height="2vh" />
            <rect x="0" y="31vh" rx="1.2vh" ry="1.2vh" width="40%" height="4vh" />
            <rect x="45%" y="31vh" rx="1.2vh" ry="1.2vh" width="55%" height="4vh" />
            <rect x="0" y="38vh" rx="1.2vh" ry="1.2vh" width="100%" height="20vh" />
            <rect x="5%" y="60vh" rx="1.2vh" ry="1.2vh" width="60%" height="48" />
            <circle cx="80%" cy="63.8vh" r="27" />
          </>}

      </ContentLoader>
    </div>
  )
}
