import React from 'react';
import { ReactComponent as ApplyStage } from '../../assets/svg/apply-stage.svg';
import { ReactComponent as ScreeningStage } from '../../assets/svg/screening-stage.svg';
import { ReactComponent as AppliedStage } from '../../assets/svg/applied-stage.svg';

interface Props {
    styles: any;
    applyStage: number;
    hasFiltering: boolean;
}

export const ApplyStatus: React.FC<Props> = (props) => {
    const styles = props.styles;
    const applyStage= props.applyStage;
    return (
        <div style={{...styles, ...(!props.hasFiltering && styles.filteringBox), ...(applyStage == 3 && styles.applyComplete)}}>
            <div style={{...styles.boxHolder, ...styles.boxHolder.applying, ...(applyStage === 1 && styles.boxHolder.active)}}>
                <div style={{...styles.box, ...(applyStage === 1 ? styles.box.active : styles.box.pass)}}>
                    <ApplyStage style={{...styles.icon, ...styles.icon.apply, ...(applyStage === 1 ? styles.icon.active : styles.icon.pass)}} />
                </div>
            </div>
            {props.hasFiltering && <><hr style={{...styles.progressBar, ...styles.progressBar.left, ...(applyStage !== 1 && styles.progressBar.active)}} />
            <div style={{ ...styles.boxHolder, ...styles.boxHolder.screening, ...(applyStage === 2 && styles.boxHolder.active) }}>
                <div style={{...styles.box, ...(applyStage === 2 && styles.box.active), ...(applyStage === 3 && styles.box.pass)}}>
                    <ScreeningStage style={{ ...styles.icon, ...styles.icon.screening, ...(applyStage === 2 && styles.icon.active), ...(applyStage === 3 && styles.icon.pass)}} />
                </div>
            </div></>} 
            <hr style={{...styles.progressBar, ...styles.progressBar.right, ...(!props.hasFiltering && styles.progressBar.extendRight), ...(applyStage === 3 && styles.progressBar.active)}} />
            <div style={{ ...styles.boxHolder, ...styles.boxHolder.applied, ...(applyStage === 3 && styles.boxHolder.active) }}>
                <div style={{...styles.box, ...(applyStage === 3 && styles.box.active)}}>
                    <AppliedStage style={{ ...styles.icon, ...styles.icon.applied, ...(applyStage === 3 && styles.icon.active) }} />
                </div>
            </div>
        </div>       
    )
}