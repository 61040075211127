import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const privacyPolicyPage = {
    mobile: {
        container: {
            margin: "0 5% 100px",
            textAlign: "justify",
            fontFamily: Font.Poppins,
            color: Color.DeepAsh,
            hyphens: "auto"
        },
        title: {
            textAlign: "center"
        },
        list: {
            listStyleType: "none",
            marginBottom: "1vw",
            textAlign: "start"
        },
        tableWrapper: {
            maxWidth: "100%"
        }
    },
    desktop: {
        container: {
            margin: "2.6vw 10% 5.45vw"
        },
        list: {
            marginBottom: "0.60vw"
        }
    }
}