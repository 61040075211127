import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';
import { ErrorIndicator } from './ErrorIndicator';

interface Props {
    label?: string;
    styles?: any;
    id?: string;
    name?: string;
    value: any;
    handleChange?(event?: React.ChangeEvent<HTMLTextAreaElement>): void;
    handleBlur?(event?: React.FocusEvent<HTMLTextAreaElement>): void;
    disabled?: boolean;
    validation?: InputValidation;
}

export const TextAreaInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [focused, setFocused] = useState<boolean>(false);
    const validation = props.validation;

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    function handleOnBlur(event?: React.FocusEvent<HTMLTextAreaElement>) {
        setFocused(false);
        props.handleBlur && props.handleBlur(event);
    }

    return (
        <label htmlFor={props.id} style={{...styles, ...styles.textArea, ...(focused && styles.active), ...(validation?.valid && styles.valid), ...(validation?.invalid && styles.invalid)}} onClick={() => setFocused(true)}>
            <span style={{ ...styles.floatingLabel, ...((props.value || focused) && styles.floatingLabel.moveUpTextArea) }}>{props.label}</span>
            <textarea
                style={{...styles.inputField, ...styles.areaInputField}}
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                onBlur={handleOnBlur}
                disabled={props.disabled}
            />
            {validation?.invalid && <ErrorIndicator invalidMessage={validation.invalidMessage!} styles={styles.inputError}/>}
        </label>
    )
}