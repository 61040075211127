import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import useErrorPage from '../../hooks/useErrorPage';
import { Statistic } from '../../models/Statistic';
import { StyleContext } from '../../providers/StyleContextProvider';
import { getStatistics } from '../../services/HomeService';
import CountUp from 'react-countup';
import { ReactComponent as Curve1 } from "../../assets/svg/header-start-curve.svg";
import { ReactComponent as Curve2 } from "../../assets/svg/header-end-curve.svg";

export const Statistics: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("statistics")); 
    const[statistics, setStatistics] = useState<Statistic[]>([]);                 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });  
    const [toErrorPage] = useErrorPage();  
      
    useEffect(() => {                     
        setStyles(styleContext.getComponentStyle("statistics"));  
        getStatisticList();                        
    }, [isMobile]);
    
    async function getStatisticList() {        
        await getStatistics()
        .then((statisticList: Statistic[]) => {
            setStatistics(statisticList); 
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    } 

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: true,                 
        speed: 500,
        autoplay: true,      
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesPerRow: isMobile ? 1 : 4,
        appendDots: (dots: any) => <div style={styles.dots} className="slick-dots-white">{dots}</div>     
    };
    
    return (
        <div style={styles}>
            <Curve1 style={styles.curve1} />
            <Curve2 style={styles.curve2} />
            <div style={styles.buildingSvg}></div>
            <div style={styles.sliderWrapper}>
                <Slider {...sliderSettings}>
                    {statistics.map((statistic, index) =>
                        <div key={index}>
                            <p style={styles.count}>
                                <CountUp end={statistic.count} duration={5} suffix="+" />
                            </p>
                            <p style={styles.name}>{statistic.statName}</p>
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    );
}