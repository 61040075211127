import React from 'react';

interface Props {
    styles: any;
}

export const Tag: React.FC<Props> = (props) => {
    return (
        <div style={props.styles}>
            {props.children}
        </div>
    );
}