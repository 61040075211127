import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/Shared/Buttons/Button";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { Header } from "../components/Shared/Header";
import { Tabs } from "../components/Shared/Tabs";
import { CandidateProfilePageTexts as Texts, CommonTexts } from "../helpers/LayoutTexts";
import { getCandidateProfilePictureURL } from '../helpers/Common';
import { TabItem } from "../models/TabItem";
import { AuthContext } from "../providers/AuthContextProvider";
import { StyleContext } from "../providers/StyleContextProvider";
import { ReactComponent as Crown } from "../assets/svg/crown.svg";
import { Switch } from "../components/Shared/Buttons/Switch";
import { Password } from "../components/CandidateSettings/Password";
import { PopUpWindow } from "../components/Shared/PopUpWindow";
import { TextInput } from "../components/Shared/Inputs/TextInput";
import { EmailPasswordModel } from "../models/EmailPasswordModel";
import useErrorPage from "../hooks/useErrorPage";
import { deleteCandidateAccount, getCandidate } from "../services/CandidateService";
import { ReactComponent as CheckCircle } from "../assets/svg/check-circle.svg";
import { useAlert } from "../hooks/useAlert";
import { Email } from "../components/CandidateSettings/Email";
import { StoreButton } from "../components/Shared/StoreButton";
import { Tooltip } from "../components/Shared/Tooltip";
import { LoggedCandidate } from "../models/LoggedCandidate";
import { Candidate } from "../models/Candidate";
import { CandidateSettingsLoader } from "../components/Loaders/CandidateSettingsLoader";

export const CandidateSettingsPage: React.FC = () => {
    const navigate = useNavigate();
    let params = useParams();
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const loggedCandidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [candidate, setCandidate] = useState<Candidate>();
    const candidateProfilePictureURL = getCandidateProfilePictureURL(loggedCandidate?.candidateId!);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateSettingsPage"));
    const [tabItems, setTabItems] = useState<TabItem[]>([]);
    const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false);
    const [openInputDialog, setOpenInputDialog] = useState<boolean>(false);
    const [messageDialogContent, setMessageDialogContent] = useState<any>(null);    
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [loading, setLoading] = useState<boolean>(true); 
    const [password, setPassword] = useState<string>("");
    const [accountDeleted, setAccountDeleted] = useState<boolean>(false);
    const [shareCV, setShareCV] = useState<boolean>(false);
    const [toErrorPage] = useErrorPage();
    const { Alert, openAlert } = useAlert();
    const [premiumHover, setPremiumHover] = useState<boolean>(false)

    useEffect(() => {                        
        if(loggedCandidate === null) {    
            const searchParams = { callbackUrl: "/candidateSettings/" + params.tab! };
            navigate({
                pathname: "/candidates/login",
                search: `?${createSearchParams(searchParams)}`
            });
        } else {
            let newTabItems = [
                { label: "Change Password", value: "password", component: <Password candidate={loggedCandidate} styles={styles.form} openAlert={openAlert}/> },
                { label: "Change Email", value: "email", component: <Email candidate={loggedCandidate} email={loggedCandidate.email} styles={styles.form} openAlert={openAlert}/> },
                { label: "Delete Account", value: "deleteAccount", styles: styles.deleteTab }
            ]
            setTabItems(newTabItems);
            getCandidateModel();
        }  
    }, []);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("candidateSettingsPage"));
    }, [isMobile]);

    async function getCandidateModel() {
        await getCandidate(loggedCandidate!.candidateId, loggedCandidate!.token)
        .then((candidateModel: Candidate) => {
            setCandidate(candidateModel);
            setLoading(false);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    const tryPremium = () => {
        navigate({ pathname: "/candidate/premium" });
    }

    const getSelectedTab = (selectedTab: string) => {
        if(selectedTab === "deleteAccount") {
            displayDeleteWarning();
        }
    }

    const displayDeleteWarning = () => {
        let content = <>
            <h1 style={styles.deleteDialogTitle}>{"Are you sure?"}</h1>
            <p style={styles.deleteDialogMessage}>{Texts.deleteWarningMessage}</p>
            <div className="center-content" style={styles.deleteButtons}>
                <Button style={{...styles.deleteButton, ...styles.deleteButton.blue}} onClick={displayDeleteCancel}>{"NO"}</Button>                
                <Button style={{...styles.deleteButton, ...styles.deleteButton.red}} onClick={displayDeleteConfirm}>{"YES"}</Button>
            </div>
        </>        
        setMessageDialogContent(content);        
        setOpenMessageDialog(true);
    }

    const displayDeleteCancel = () => {
        setOpenMessageDialog(false);
        closeMessageDialog()
    }

    const displayDeleteConfirm = () => {
        setOpenMessageDialog(false);
        setOpenInputDialog(true);
    }

    async function deleteCandidate() {
        const passwordModel: EmailPasswordModel = {
            candidateId: loggedCandidate!.candidateId,
            currentPassword: password            
        }
        await deleteCandidateAccount(passwordModel, loggedCandidate!.token)
        .then((deleted: boolean) => {
            setOpenInputDialog(false);
            if(deleted) {
                setAccountDeleted(true);
                localStorage.removeItem("candidate");
                displayDeleteSuccess();
            } else {
                openAlert("error", "Password is incorrect!");
            } 
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    const displayDeleteSuccess = () => {        
        let content = <>
            <h1 style={styles.deleteDialogTitle}><CheckCircle/>{"Account Deleted"}</h1>    
            <p style={styles.deleteDialogMessage}>{Texts.deleteSuccessMessage}</p>                       
        </>        
        setMessageDialogContent(content);        
        setOpenMessageDialog(true);
    }

    const closeMessageDialog = () => {
        setOpenMessageDialog(false);
        if(accountDeleted) {
            navigate({ pathname: "/" });
        }
        else {
            navigate(-1);
        } 
    }

    const fillDesignation = () => {
        navigate({ pathname : '/candidate/updateprofile'})
    }

    const shareCandidateCV = () => {
        setShareCV(!shareCV)
    }

    return (
        <>
            <Header styleSheet="lowHeader" backButtonEnable={true} backButtonText="Back"/>
            <div style={styles.mainWrapper}>
                {loading ? <CandidateSettingsLoader candidate={loggedCandidate}/> :
                    <>
                        {candidate && <div style={styles.personalDetails}>
                            <div style={styles.imageWrapper}>
                                <div style={styles.imageHolder}>
                                    <img style={styles.candidateImage} src={candidateProfilePictureURL} alt="candidate profile picture" />
                                </div>                    
                                <StoreButton styles={styles.storeButton} />
                                <div>                
                                    {!candidate.isPremium && 
                                    <Button style={styles.premiumButton} onClick={tryPremium}>
                                        <Crown style={styles.crownIcon} />
                                        {isMobile ? "PREMIUM" : "TRY PREMIUM"}
                                    </Button>}
                                </div>
                            </div>
                            <div style={styles.detailWrapper}>
                                <div style={styles.premiumNameWrapper}>                
                                    <h4 style={styles.name}>{candidate!.forename + " " + candidate!.surname}</h4>
                                    {candidate.isPremium && <div
                                        onMouseEnter={() => {setPremiumHover(true);}}
                                        onMouseLeave={() => {setPremiumHover(false);}}  
                                        style={styles.crownWrapper}
                                    >
                                        <Crown style={styles.premiumCrownIcon}/>
                                    </div>}
                                    {(candidate.isPremium && premiumHover) && <Tooltip styles={styles.premiumRemeiningDays}>{"You are a premium candidate. Your subscription will end up in " + candidate.premiumRemainingDays + " days"}</Tooltip>}
                                </div>
                                <p style={styles.jobTitle}>{candidate.recentJobTitle ? <span>{candidate!.recentJobTitle}</span> 
                                : <span style={styles.jobTitle.empty} onClick={fillDesignation}>{"Fill your designation"}</span>}</p>
                                <div style={styles.shareWrapper}>                                    
                                    <div style={styles.shareText}>{Texts.shareCV}</div>
                                    <Switch styles={styles.shareCVSwitch} checked={shareCV} handleClick={shareCandidateCV}/>                
                                </div>
                            </div>                       
                        </div>} 
                    </> 
                }   
                <hr style={styles.line}/>
                <h4 style={styles.title}>{"Settings"}</h4>                                 
                {!loading && <Tabs styles={styles.tabs} path={"/candidateSettings/"} tabItems={tabItems} selectedTab={params.tab!} getSelectedTab={getSelectedTab}/>}                            
            </div>
            <PopUpWindow styles={styles.deleteWarningDialog} open={openInputDialog} toggle={() => {setOpenInputDialog(false)}} closeCircle={true}>
                <h1 style={styles.deleteDialogTitle}>{"Verify Account"}</h1>
                <TextInput
                    label={"Password *"}
                    inputType={"password"}
                    name={"password"}
                    handleChange={(e) => setPassword(e?.target.value!)}
                    value={password}
                />
                <Button style={{...styles.deleteButton, ...styles.deleteButton.blue}} onClick={deleteCandidate}>{"VERIFY"}</Button>
            </PopUpWindow>
            <PopUpWindow styles={styles.deleteWarningDialog} open={openMessageDialog} toggle={closeMessageDialog} closeCircle={true}>
                {messageDialogContent}
            </PopUpWindow>
            <Alert/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}