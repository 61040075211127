import { Color } from "../enums/Color";

export const jobApplyLoader = {
    mobile: {
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            margin: "4.83vw"
        },
        leftWrapper: {
            width: "100%"
        },
        rightWrapper: {
            width: "100%"
        },
        mobileDetailCard: {
            display: "block"
        },
        desktopDetailCard: {
            display: "none"
        },
        verticalDetailCard: {
            height: "auto",
            display: "none",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "-4.05vh",
            background: Color.White,
            borderRadius: "2.78vw",
            paddingBottom: "1.35vh",
            boxShadow: "5px 5px 45px #00000029",
            position: "relative" as "relative",
            zIndex: "2",
            wrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                paddingTop: "1.35vh",
                paddingLeft: "3.86vw",
                paddingRight: "3.86vw",
            },
            logo: {
                width: "16.67vw",
                height: "16.67vw",
                background: "transparent",
                borderRadius: "1.39vw",
                marginTop: "-4.05vh",
                marginLeft: "1.39vw",
                opacity: "1",
                objectFit: "contain",
            }
        },
        organizationCard: {
            display: "none",
            marginTop: "2vh",
        },
        mobileVerticalDetailCard: {
            marginTop: "5%",
            marginBottom: "5%"
        },
        inputLoader : {
            display: "inline-block",
            width: "100%",
            marginTop: "1.85vh",
            height: "12vh"
        }
    },
    desktop: {
        baseWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            margin: "17.6vh 7.29vw 12.03vh 7.29vw"
        },
        leftWrapper: {
            paddingRight: "4.14vw"
        },
        rightWrapper: {
            width: "30%",
            display: "block",
            borderLeft: "2px solid " + Color.MiddleAsh
        },
        mobileDetailCard: {
            display: "none"
        },
        desktopDetailCard: {
            display: "block"
        },
        verticalDetailCard: {
            display: "block",
            width: "20.72vw",
            marginRight: "0.00vw",
            marginTop: "0",
            paddingBottom: "2.40vh",
            borderRadius: "0.70vw",
            height: "66.11vh",
            marginLeft: "3.88vw",
            logo: {
                width: "8vw",
                height: "8vw",
                marginTop: "-7vh",
                borderRadius: "0.5vw",
                marginLeft: "0.4vw",
            }
        },        
        organizationCard: {
            display: "block",
            width: "20.72vw",
            marginTop: "2.70vh",
            marginLeft: "3.88vw",
        },
        mobileVerticalDetailCard: {
            display: "none"
        },
        inputLoader : {
            width: "50%",
            height: "15vh",

        }
    }
}