import { Color } from "../enums/Color";

export const tooltip = {
  mobile: {
    boxShadow: "3px 3px 10px #00000029",
    border: "1px solid #D1D1D1",
    position: "absolute",
    right: "-2.8%",
    top: "85%",
    padding: "2.41vw",
    borderRadius: "1vw",
    backgroundColor: Color.White,
    zIndex: "1",
    pointer: {
      position: "absolute",
      top: "-1.8vw",
      right: "10%",
      transform: "translateX(-50%) rotate(45deg)",
      width: "2.8vw",
      height: "2.8vw",
      backgroundColor: "#ffffff",
      borderTop: "1px solid #D1D1D1",
      borderLeft: "1px solid #D1D1D1",
      boxShadow: "-5px -5px 10px #00000029"
    }
  },
  desktop: {
    top: "unset",
    bottom: "95%",
    borderRadius: "0.6vw",
    right: "-2%",
    padding: "0.625vw",
    pointer: {
      top: "unset",
      width: "0.7vw",
      height: "0.6vw",
      bottom: "-0.4vw",
      borderTop: "unset",
      borderLeft: "unset",
      borderRight: "1px solid #D1D1D1",
      borderBottom: "1px solid #D1D1D1",
      boxShadow: "7px 7px 10px #00000029"
    }
  }
};
