import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FilterPanel } from '../components/Search/FilterPanel';
import { SingleSelect } from '../components/Shared/SingleSelect';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { HorizontalBanner } from '../components/Shared/HorizontalBanner';
import { LongJobCard } from '../components/Shared/LongJobCard';
import { Pagination } from '../components/Shared/Pagination';
import { Job } from '../models/Job';
import { SearchCriteria } from '../models/SearchCriteria';
import { SelectInputs } from '../models/SelectInputs';
import { StyleContext } from '../providers/StyleContextProvider';
import { getSearchJobs } from '../services/JobService';
import { SearchPageTexts as Texts, HomeTexts as SearchTexts } from '../helpers/LayoutTexts';
import { LongJobCardListLoader } from '../components/Loaders/LongJobCardListLoader';
import { AuthContext } from '../providers/AuthContextProvider';
import { JobsCount } from '../components/Search/JobsCount';
import useErrorPage from '../hooks/useErrorPage';
import { VerticalBanner } from '../components/Shared/VerticalBanner';
import { TextLoader } from '../components/Loaders/TextLoader';
import useSaveJob from '../hooks/useSaveJob';
import { SearchHistoryModel } from '../models/SearchHistoryModel';
import { deleteSearchHistory, getSearchHistories, postSearchHistory } from '../services/CandidateService';
import { useAlert } from '../hooks/useAlert';
import { SearchHistory } from '../components/Shared/SearchHistory';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { Helmet } from 'react-helmet-async';

const sortBySelectInputs: SelectInputs = {
    default: "Recent",
    options: [
        { "value": "NEWID()", "option": "Default"},
        { "value": "SortedCreateDate DESC", "option": "Recent"},
        { "value": "SortedCreateDate ASC", "option": "Old"}
    ]
}

export const SearchPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const [searchParams] = useSearchParams();  
    const styleContext = useContext(StyleContext);    
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("searchPage"));
    const [loading, setLoading] = useState<boolean>(true);
    const [jobs, setJobs] = useState<Job[]>([]);    
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const [totalPages, setTotalPages] = useState<number>(); 
    const [recordCount, setRecordCount] = useState<number>();    
    const pageSize: number = searchParams.get("pageSize") === null ? 20 : +searchParams.get("pageSize")!;
    const keyword: string = searchParams.get("KeyWord") === null ? "" : searchParams.get("KeyWord")!;
    const locations: string = searchParams.get("Locations") === null ? "" : searchParams.get("Locations")!;
    const byCVLess: boolean = params.type === "cvless";
    const byWalkIn: boolean = params.type === "walkIn";
    const sectors: string = searchParams.get("Sectors") === null ? "" : searchParams.get("Sectors")!;    
    const jobTypes: string = searchParams.get("JobTypeId") === null ? "" : searchParams.get("JobTypeId")!;
    const careerLevels: string = searchParams.get("careerLevels") === null ? "" : searchParams.get("careerLevels")!;
    const postedIn: number | undefined = searchParams.get("postedIn") === null ? undefined : +searchParams.get("postedIn")!;    
    const remoteEnabled: boolean = searchParams.get("remote") === "true";
    const [sortBy, setSortBy] = useState<string>("SortedCreateDate DESC");
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const workFromHomeLocation: string = "57";
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob} = useSaveJob();
    const [jobCountTitle, setJobCountTitle] = useState<string>();
    const {Alert, openAlert} = useAlert();
    const [searchHistories, setSearchHistories] = useState<SearchHistoryModel[]>([]);
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;
    const scrollElementRef = useRef<any>();

    const perPageSelectInputs: SelectInputs = {
        default: pageSize.toString(),
        options: [
            { "value": 10, "option": "10"},
            { "value": 20, "option": "20"},
            { "value": 50, "option": "50"},
            { "value": 100, "option": "100"}
        ]
    }

    useEffect(() => {         
        if (candidate) {
            getSearchHistoryList();
        }
        return () => {
            isMountedRef.current = false;
        };
    }, []);
        
    useEffect(() => {         
        setStyles(styleContext.getComponentStyle("searchPage"));
    }, [isMobile]);

    useEffect(() => {        
        getJobList();                              
    }, [page, pageSize, sectors, jobTypes, careerLevels, postedIn, keyword, locations, sortBy, byCVLess, byWalkIn, remoteEnabled]);

    async function getJobList() {        
        setLoading(true);
        let selectedLocations = locations;        
        if(remoteEnabled && locations.indexOf(workFromHomeLocation) === -1) {
            if(locations === "") {
                selectedLocations = workFromHomeLocation;
            } else {
                selectedLocations +=  "," + workFromHomeLocation;
            }            
        }
        let searchCriteria: SearchCriteria = {
            page: page,
            pageSize: pageSize,
            keyword: keyword.replaceAll("'", "''").trim(),
            locations: selectedLocations,
            sectors: sectors,
            jobTypes: jobTypes,
            careerLevels: careerLevels,
            postedIn: postedIn,
            sortBy: sortBy,
            byCVLess: byCVLess,
            byWalkIn: byWalkIn
        }
        await getSearchJobs(searchCriteria)
        .then((jobList: Job[]) => {
            setJobs(jobList); 
            if(jobList.length > 0) {
                let totalPageCount = Math.ceil(jobList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
                setRecordCount(jobList[0].recordCount);
                if(byCVLess){
                    setJobCountTitle("CV Less Jobs Found");
                }
                else if(byWalkIn){                    
                    setJobCountTitle("Walk In Jobs Found");
                }
                else {
                    setJobCountTitle("Jobs Found")
                }
            } else {
                setTotalPages(1);
                setRecordCount(0);
            }
            setLoading(false);
        })    
        .catch((error) => {            
            if(isMountedRef.current) {                   
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getJobList, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
            }
        });       
    }

    const handlePagination = (page: number) => {        
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);        
    }

    const setPageSize = (pageSize: number) => {
        searchParams.set("page", "1");
        searchParams.set("pageSize", pageSize.toString());
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }

    const saveJob = (jobId: number, isSave: boolean) => {
        saveCandidateJob(jobId, isSave);
    }

    async function saveSearchHistory (searchName: string, postedInName: string) {   
        if(candidate === null) { 
            let newParams = { callbackUrl: location.pathname + location.search, message: "Please login/register to save search histories." };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(newParams)}`
            });
        } else {

            if(!searchName || searchName?.trim() === ""){
                openAlert("warning", "Search history name can not be empty");
                return;
            }
            if(keyword.trim() === "" && locations === "" && sectors === "" && jobTypes === "" && careerLevels === ""){
                openAlert("warning", "Atleast one of the field should be filled");
                return;
            }            
            let searchHistory: SearchHistoryModel = {
                searchHistoryName: searchName,
	            candidateId: candidate.candidateId,
	            keywords: keyword,
	            locationIds: locations,
                sectorIds: sectors,
	            jobTypeIds: jobTypes,
                careerLevelIds: careerLevels,
                postedInValue: postedIn,
                postedInName: postedInName,
                isRemote: remoteEnabled,
                deleted: false
            }
            await postSearchHistory(searchHistory, candidate.token)
            .then((response: boolean) => {
                if(response) {
                    openAlert("success", Texts.saveSearchSuccess);
                    getSearchHistoryList();
                }
                else {
                    openAlert("error", Texts.saveSearchError);
                }
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
    }

    async function getSearchHistoryList() {
        await getSearchHistories(candidate?.candidateId!, candidate!.token)
        .then((searchHistoryList: SearchHistoryModel[]) => {
            setSearchHistories(searchHistoryList);            
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function deleteSearchHistoryItem(searchHistoryId: number) {
        await deleteSearchHistory(searchHistoryId)
        .then((deleted: boolean) => {
            if (deleted) {
                getSearchHistoryList();
            }
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    const backToHome = () => {
        navigate('/');
    }

    return (
        <>
            <Helmet>
                <title>{SearchTexts.siteTitle}</title>
                <meta name="description" content={SearchTexts.metaDescription}/>
            </Helmet>
            <Header styleSheet="searchHeader" searchEnable={true} searchSelectEnable={true} keyword={keyword} locationIds={locations} backButtonText={"Back to Home"} backButtonEnable={true} handleBackButtonClick={backToHome} searchText={isMobile ? SearchTexts.mobileSearch : SearchTexts.desktopSearch} />
            <SearchHistory candidate={candidate} searchHistories={searchHistories} deleteSearchHistoryItem={deleteSearchHistoryItem} />
            <div style={styles.baseWrapper}>
                <div style={styles.leftWrapper}>
                    {/* This is loading only mobile platform */}
                    {isMobile && <JobsCount styles={styles.jobsCount} jobCount={recordCount!} isLoading={loading} jobCountTitle={jobCountTitle!}/>}
                    <div style={styles.filterPanelWrapper}>
                        <FilterPanel keyword={keyword} sectorIds={sectors} jobTypeIds={jobTypes} careerLevelIds={careerLevels} remoteEnabled={remoteEnabled} byCVLess={byCVLess} byWalkIn={byWalkIn} postedIn={postedIn} locationIds={locations} saveSearchHistory={saveSearchHistory}/>
                    </div>
                    {jobs.length > 0 && <div style={styles.verticalBanner}>
                        <VerticalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.verticalAdSlotOne} adsenseSlotTwo={Texts.verticalAdSlotTwo} adsenseSlotThree={Texts.verticalAdSlotThree} recordCount={recordCount} pageSize={pageSize}/>                  
                    </div>}
                </div>
                <div style={styles.rightWrapper} ref={scrollElementRef}>
                    {/* This is loading only desktop platform */ }
                    {!isMobile && <JobsCount styles={styles.jobsCount} jobCount={recordCount!} isLoading={loading} jobCountTitle={jobCountTitle!}/>}
                    {recordCount! > 0 && <div style={styles.wrapper}>
                        {loading ? <TextLoader styles={styles.title.loader}/> :
                            <div style={styles.title}><span style={styles.title.count}>{recordCount}</span>{Texts.results}</div>
                        }                        
                        <div style={styles.selectWrapper}>
                            <SingleSelect styles={styles.sortBySelect} label={Texts.sortBy} selectInputs={sortBySelectInputs} getSelected={setSortBy}/>
                            <SingleSelect styles={styles.perPageSelect} label={Texts.perPage} selectInputs={perPageSelectInputs} getSelected={setPageSize}/>
                        </div>
                    </div>}
                    {loading ? <LongJobCardListLoader/> :
                        <>                                
                            {jobs.length > 0 && <>
                                {jobs.map((job, index) =>
                                    <React.Fragment key={index}>
                                        <LongJobCard styles={styles.card} job={job} saveJob={saveJob} isLastItem={jobs.length - 1 === index}/>
                                    </React.Fragment>
                                )}
                                {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination} />}
                            </>}
                        </>
                    }
                </div>
            </div>
            <div style={styles.horizontalBanner}>
                <HorizontalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.horizontalAdSlotOne} adsenseSlotTwo={Texts.horizontalAdSlotTwo}/>
            </div>
            <Footer /> 
            <Alert />         
        </>
    );
}