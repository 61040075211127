import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as FileUpload } from '../../../assets/svg/file-upload.svg';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';
import { Button } from '../Buttons/Button';
import { ErrorIndicator } from './ErrorIndicator';

interface Props {
    label?: string;
    styles?: any;    
    id?: string;
    name?: string;    
    handleChange?(event?: React.ChangeEvent<HTMLInputElement>): void;    
    disabled?: boolean;    
    selectedFileName?: string;
    validation?: InputValidation;
}

export const FileInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [clicked, SetClicked] = useState<boolean>(false);
    const validation = props.validation;   

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);
 
    return (
        <label htmlFor={props.id} style={{ ...styles, ...(clicked && styles.active), ...(validation?.valid && styles.valid), ...(validation?.invalid && styles.invalid) }} onClick={() => SetClicked(true)}>
            <span style={{ ...styles.floatingLabel, ...(clicked && styles.floatingLabel.moveUp) }}>{props.label}</span>
            <input
                type="file"
                style={styles.fileField}
                id={props.id}
                name={props.name}                
                onChange={props.handleChange}
                disabled={props.disabled}
            />            
            <>
                <p style={styles.selectedFileName}>{props.selectedFileName}</p>
                <FileUpload style={{ ...styles.icon, ...styles.fileIcon }} />
                {validation?.invalid && <ErrorIndicator invalidMessage={validation.invalidMessage!} styles={styles.inputError} />}                    
            </>
        </label>
    )                                                                                                           
}