import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const styles = {
    mobile: {
        card: {
            display: "inline-block",
            width: "100%",
            margin: "1%"
        },
        wrapper: {
            margin: "0 4.83vw"
        },
    },
    desktop: {
        card: {
            width: "23%"
        },
        wrapper: {
            margin: "0 7.29vw"
        }
    }
};

export const TopHiringOrganizationsLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles  = styles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(styles.mobile, styles.desktop); 
        loaderStyles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 3 : 12;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={75}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="12" rx="5" ry="5" width="20%" height="70%" />
                    <rect x="30%" y="50" rx="5" ry="5" width="30%" height="12%" />
                    <rect x="30%" y="30" rx="5" ry="5" width="40%" height="16%" />
                    <rect x="78%" y="30" rx="5" ry="5" width="20%" height="12%" />
                </ContentLoader>
            </div>
        )
    }
    return (
        <div style={loaderStyles.wrapper}>
            {loaderElements}
        </div>
    );
}