import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const adminHeader = {
    mobile: {
        wrapper: {
            position: "fixed" as "fixed",
            display: "flex",
            alignItems: "center" as "center",
            backgroundColor: Color.Blue,
            width: "100%",
            height: "70px",
            zIndex: 2,
            padding: "0 1%",
            boxShadow: "0 1px 5px rgb(0 0 0 / 0.5)"
        },
        title: {
            textAlign: "left" as "left",
            fontSize: "36px",
            fontFamily: Font.Advanced,
            letterSpacing: "0px",
            color: Color.White,
            cursor: "pointer",
            marginLeft: "2%"
        },
        label: {
            textDecoration: "none",
            fontFamily: Font.Default,
            color: Color.LightAsh,
            cursor: "pointer"
        },
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "auto",
            marginRight: "4%",
            paddingRight: "0%",
            paddingTop: "2%"
        },
        desktopMenu: {
            wrapper: {
                display: "none",
                marginLeft: "auto",
                padding: "2%"
            },
            list: {
                listStyleType: "none",
                display: "flex",
                alignItems: "center"
            },
            listItem: {
                marginRight: "12px"
            }
        },
    },
    desktop: {
        mobileMenu: {
            display: "none"
        },
        mobileMenuButton: {
            display: "none",
        },
        desktopMenu: {
            wrapper: {
                display: "block"
            }
        }
    }
}