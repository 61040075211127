import React, { useContext, useEffect, useState } from 'react'
import { ReactComponent as LinkedIn } from '../../assets/svg/linkedIn-2.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../assets/svg/google.svg';
import { Button } from '../Shared/Buttons/Button';
import { postFacebookUserModel, postGoogleAccessToken } from '../../services/CandidateService';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ResponseModel } from '../../models/ResponseModel';
import useErrorPage from '../../hooks/useErrorPage';
import { FacebookUserModel } from '../../models/FacebookUserModel';
import { getHost } from '../../helpers/Common';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { AuthContext } from '../../providers/AuthContextProvider';

interface Props {
    styles: any;
    callbackUrl: string | null;
    openAlert(status: string, message: string): void;
    showCvUploadPopup(candidateId: number): void;
}

const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
const appBaseUrl = getHost();
const googleScope = "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email";

export const AuthenticateBySocialApps: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [toErrorPage] = useErrorPage();
    const [googleTokenClient, setGoogleTokenClient] = useState<google.accounts.oauth2.TokenClient>();
    const styles = props.styles;
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            let tempGoogleTokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: googleClientId!,
                scope: googleScope,
                callback: response => {
                    handlePostGoogleAccessToken(response.access_token)                    
                }
            });
            setGoogleTokenClient(tempGoogleTokenClient);
        }

        return () => {
            document.body.removeChild(script);
        };
    }, [])

    const handleAuthenticateByGoogle = () => {
        googleTokenClient!.requestAccessToken();
    }

    async function handlePostGoogleAccessToken(accessToken: string) {
        setLoading(true);
        await postGoogleAccessToken(accessToken)
        .then((response: ResponseModel) => {
            if (response.status) {
                if (response.message === "UploadCV") {
                    let candidateId = response.data;
                    props.showCvUploadPopup(candidateId);
                }
                else {
                    authContext.setLoggedCandidate(response.data, response.token);
                    if (props.callbackUrl) {
                        navigate(props.callbackUrl);
                    }
                    else {
                        navigate('/candidate/home');
                    }
                }
            }
            else{
                props.openAlert("error", response.message);
            }
        })
        .catch(error => {
            toErrorPage(error);
        });
        setLoading(false);
    }

    async function handleAuthenticateByFacebook(facebookUser: FacebookUserModel) {
        setLoading(true);
        await postFacebookUserModel(facebookUser)
        .then((response: ResponseModel) => {
            if (response.status) {
                if (response.message === "UploadCV") {
                    let candidateId = response.data;
                    props.showCvUploadPopup(candidateId);
                }
                else {
                    authContext.setLoggedCandidate(response.data, response.token);
                    if (props.callbackUrl) {
                        navigate(props.callbackUrl);
                    }
                    else {
                        navigate('/candidate/home');
                    }
                }
            }
            else {
                props.openAlert("error", response.message);
            }
        })
        .catch(error => {
            toErrorPage(error);
        })
        setLoading(false);
    }


    const handleAuthenticateByLinkedIn = () => {
        const state = JSON.stringify({
            staticState: "987654321",
            linkedInCallbackUrl: props.callbackUrl ? props.callbackUrl : "",
        });
        let params = {
            response_type: "code",
            client_id: linkedinClientId!,
            redirect_uri: appBaseUrl + "candidates/login",
            state: state,
            scope: "openid,profile,email"
        };
        let linkedInUrl = `https://www.linkedin.com/oauth/v2/authorization?${createSearchParams(params)}`;
        window.location.href = linkedInUrl;
    }

    return (
        <>
            <Button disabled={loading || !googleTokenClient} type="button" style={styles.iconWrapper} onClick={handleAuthenticateByGoogle}>
                <GoogleIcon style={styles.icon} />
            </Button>
            <FacebookLogin
                appId={facebookAppId!}
                autoLoad={false}                    
                onFail={(error) => {
                    props.openAlert("error", "Authenticate with FaceBook failed!");
                }}
                onProfileSuccess={(response) => {
                    handleAuthenticateByFacebook(response);
                }}
                children={
                    <div style={styles.iconWrapper}>
                        <Facebook style={{...styles.icon, ...styles.icon.facebook}}/>
                    </div>
                }
            />            
            <Button disabled={loading} type="button" style={styles.iconWrapper} onClick={handleAuthenticateByLinkedIn}>
                <LinkedIn style={{...styles.icon, ...styles.icon.linkedIn}} />
            </Button>
        </>
    )
}