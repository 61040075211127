import React from 'react';

export interface ListProps {
    styles: any;
    label?: string;
    items?: any[];
}

export const List: React.FC<ListProps> = (props) => {
    return (
        <div style={props.styles}>
            <div style={props.styles.label}>{props.label}</div>
            <ul style={props.styles.list}>                                    
                {props.items!.map((item, index) => 
                    <li key={index}>
                        <div>{item.item}</div>
                    </li>
                )}
            </ul>
        </div>   
    );
}