import { Color } from "../enums/Color"
import { commonHeader } from "./CommonHeader"

export const disabilityFriendlyHeader = {
    mobile: {
        ...commonHeader.mobile,
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "75.12vw",
            opacity: "1",
            position: "relative"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "79.46vw"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            WebkitTapHighlightColor: "transparent",            
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            height: "3.64vw",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",            
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.52vw",
            height: "3.64vw",
            display: "none"
        },
        heading: {
            position: "relative" as "relative",
            left: "50%",
            transform: "translate(-50%, 0%)",
            width: "70.28vw",
            height:"24.87vw",
            marginTop: "12.07vw",
            marginBottom: "6.04vw",
            textAlign: "center" as "center",
            fontWeight: "bold" as "bold",
            fontSize: "6.038vw",
            letterSpacing: "0px",
            lineHeight: "1",
            color: Color.White,
            opacity: "1",
            moveDown: {
                marginTop: "12.07vw"
            }
        },
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "28.64vw",
        },
        heading: {
            width: "57.03vw",
            marginTop: "10vh",
            height: "5.26vw",
            marginBottom: "1vh",
            fontSize: "2.5vw",
            moveDown: {
                marginTop: "10vh"
            }
        },
        text: {
            width: "70%",
            height: "12.8vh",
            textAlign: "center" as "center",
            fontFamily: "Poppins",
            fontSize: "1vw",
            letterSpacing: "0px",
            color: Color.White,
            opacity: "1"
        },
        jobSeekersButton: {
            display: "inline"
        },
        recruitersButton: {
            display: "inline"
        }
    }
}
