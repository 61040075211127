import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import ContentLoader from "react-content-loader";
import { Color } from "../../enums/Color";

interface Props {
    candidate : LoggedCandidate | null;
}

const loaderStyle = {
    mobile: {
        wrapper: {
            height: "33.34vw"
        },
        container: {
            width: "90.34vw",
            height: "33.34vw",
            position: "relative" as "relative",
            top: "-24vw"
        },
        image: {
            width: "28.75vw",
            height: "24.15vw",
            position: "relative" as "relative",
            top: "-15vw",
            left: "-5vw"
        },
        premium: {
            position: "absolute" as "absolute",
            width: "0",
            height: "0"
        }
    },
    desktop: {
        wrapper: {
            height: "6.25vw"
        },
        container: {
            width: "42.92vw",
            height: "3.49vw",
            top: "-10vw",
            left: "12vw"
        },
        image: {
            width: "10.15vw",
            height: "10.15vw",
            top: "-7vw",
            left: "unset"
        },
        premium: {
            width: "10.16vw",
            height: "4.63vh",
            top: "4vw"
        }
    }

};

export const CandidateSettingsLoader : React.FC<Props> = (props) => {
    const isMobile = useMediaQuery ({ query: "(max-width: 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }
    return (
        <div style={styles.wrapper}>
            <div style={styles.image}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width= {styles.image.width}
                    height= {styles.image.height}
                >
                    {isMobile ? <>
                        <rect x="22.19%" y="0" rx="0" ry="0" width="100%" height="100%" />
                        
                    </> : 
                    <>
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
                    
                    </>} 
                </ContentLoader>
            </div>
            {!props.candidate?.isPremium && <div style={styles.premium}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width= {styles.premium.width}
                    height= {styles.premium.height}
                >
                    {!isMobile && <>
                        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />    
                    </>} 
                </ContentLoader>
            </div>}
            <div style={styles.container}>
                <ContentLoader
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width= {styles.container.width}
                    height= {styles.container.height}
                >
                    {isMobile ? <>
                        <rect x="68.72%" y="0" rx="0" ry="0" width="31.28%" height="25.36%" />
                        {!props.candidate?.isPremium && <rect x="68.72%" y="35%" rx="0" ry="0" width="31.28%" height="25.36%" />}
                        <rect x="0" y="43.48%" rx="0" ry="0" width="25.94%" height="13.04%" />
                        {props.candidate?.isPremium && <circle cx="33.36%" cy="47.80%" r="5.52%" />}
                        <rect x="0" y="62.48%" rx="0" ry="0" width="25.94%" height="13.04%" />
                        <rect x="0" y="86.95%" rx="0" ry="0" width="55.08%" height="13.04%" />
                        <rect x="91%" y="86.95%" rx="3" ry="3" width="8.82%" height="14.49%" /> 
                    </> : 
                    <>
                        <rect x="0" y="0" rx="0" ry="0" width="22.08%" height="50.75%" />
                        {props.candidate?.isPremium && <circle cx="26.50%" cy="25%" r="3%" />}
                        <rect x="0" y="65.67%" rx="0" ry="0" width="16.87%" height="34.33%" />
                        <rect x="69.48%" y="64.18%" rx="0" ry="0" width="25%" height="26.87%" />
                        <rect x="96%" y="64.18%" rx="5" ry="5" width="4%" height="29.85%" />
                    </>} 
                </ContentLoader>
            </div>
        </div>
    )
}