import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const emailManagerLogin = {
    mobile: {
        header: {
            header: {
                backgroundSize: "100% 90%",
                height: "180px"
            },
            recruitersButton: {
                display: "none"
            },
            jobSeekersButton: {
                display: "none"
            },
            heading: {
                display: "none"
            },
            text: {  
                display: "none"
            },
            searchArea: { 
                marginTop: "50px",
                button: {                                   
                    backgroundColor: Color.Green
                }
            }
        },
        loader: {
            position: "fixed" as "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: Color.LightBlue,
            zIndex: "2",
            loaderTextWrapper: {
                backgroundColor: Color.White,
                fontFamily: Font.Default,
                fontWeight: 500,
                color: Color.LightBlack,
                margin: "30vh auto",
                padding: "2%",
                maxWidth: "560px",
                width: "80%",
                textAlign: "center" as "center",
                boxShadow: "0 1px 5px rgb(0 0 0 / 0.5)",
                borderRadius: "10px",
            }
        },
        row: {
            display: "block",
            paddingTop: "70px",
            backgroundColor: Color.White,
        },
        wrapper: {
            boxShadow: "0 1px 5px rgb(0 0 0 / 0.5)",
            borderRadius: "8px",
            width: "90%", 
            maxWidth: "740px", 
            margin: "auto",
            padding:"2%"
        },text: {
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold"
        },
        inputGroup: {
            margin: "1%"
        },
        inputField: {
            width: "100%",
            padding: "12px 12px",
            margin: "8px 0",
            fontFamily: Font.Default,
            border: "none",
            boxSizing: "border-box" as "border-box",
            borderBottom: `1px solid ${Color.MiddleAsh}`
        },
        button: {
            border: "none",
            padding: "15px",
            cursor: "pointer",
            backgroundColor: Color.Blue,
            color: Color.White,
            fontFamily: Font.Default,
            fontWeight: "bold" as "bold",
            margin: "2%",
            borderRadius: "5px",
            textAlign: "center" as "center"
        }
    },
    desktop: {
        row: {
            display: "flex"
        }
    }
}