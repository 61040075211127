import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';

interface Props {
    styles?: any;
    id: string;
    name: string;
    value: any;
    checked?: boolean;
    handleChange(event?: React.ChangeEvent<HTMLInputElement>): void;
}

export const RadioInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>
            <input
                type="radio"
                id={props.id}
                style={styles.radioInput}
                name={props.name}
                value={props.value}
                checked={props.checked}
                onChange={props.handleChange}
            />
            <label htmlFor={props.id} style={styles.label}>{props.children}</label>
        </div>
    )
}