import { useState } from "react";
import { Button } from "../Shared/Buttons/Button";
import { TextInput } from "../Shared/Inputs/TextInput";
import { EmailPasswordModel, EmailPasswordValidation } from "../../models/EmailPasswordModel";
import { updateCandidatePassword } from "../../services/CandidateService";
import useErrorPage from "../../hooks/useErrorPage";
import { ResponseModel } from "../../models/ResponseModel";
import { LoggedCandidate } from "../../models/LoggedCandidate";
import { ChangePasswordLoader } from "../Loaders/ChangePasswordLoader";

interface Props {
    candidate: LoggedCandidate;
    styles: any;
    openAlert(status: string, message: string): void;
}

const intialValidation: EmailPasswordValidation = {
    currentPassword: {},
    newPassword: {},
    confirmNewPassword: {}
}

export const Password: React.FC<Props> = (props) => {
    const intialFormInputs: EmailPasswordModel = {
        candidateId: props.candidate.candidateId,
        currentPassword: "",
        newPassword: "",  
        confirmNewPassword: ""
    }  
    const [passwordModel, setPasswordModel] = useState<EmailPasswordModel>(intialFormInputs);
    const [validation, setValidation] = useState<EmailPasswordValidation>(intialValidation);
    const [loading, setLoading] = useState<boolean>(false);
    const styles = props.styles;
    const [toErrorPage] = useErrorPage();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setPasswordModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name]!.invalid) {
            validateInput(name, value);
        }
    }

    function handleInputBlur(event: React.FocusEvent<HTMLInputElement, Element>){
        let name = event.target.name;
        let value = event.target.value;
        validateInput(name, value);
    }

    function validateInput(name: string, value: any){        
        if(value === ""){
            setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "This field is required"}}));
        }
        else if(name === "confirmNewPassword" && value !== passwordModel.newPassword){
            setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "New password and confirmation do not match"}}));
        }
        else {
            setValidation((values: any) => ({ ...values, [name]: { valid: true, invalid: false }}));
        }        
    }

    function validateForm() {
        let formValidation: EmailPasswordValidation = {
            currentPassword: { valid: true },
            newPassword: { valid: true },
            confirmNewPassword: { valid: true },
        }
        if (passwordModel.currentPassword === "") {
            formValidation.currentPassword = { valid: false, invalid: true, invalidMessage: "Current password is required" };
        }
        if (passwordModel.newPassword === "") {
            formValidation.newPassword = { valid: false, invalid: true, invalidMessage: "New password is required" };
        }
        if (passwordModel.confirmNewPassword === "") {
            formValidation.confirmNewPassword = { valid: false, invalid: true, invalidMessage: "Confirm password is required" }
        }
        else if (passwordModel.confirmNewPassword !== passwordModel.newPassword) {
            formValidation.confirmNewPassword = { valid: false, invalid: true, invalidMessage: "New password and confirmation do not match" };
        }
        setValidation(formValidation);
    }

    async function handleUpdateClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputsCount = Object.keys(validation).filter(key => validation[key]!.valid).length;
        if(validInputsCount === 3){                    
            setLoading(true);
            await updateCandidatePassword(passwordModel, props.candidate.token)
            .then((response: ResponseModel) => { 
                setLoading(false);                   
                props.openAlert(response.status ? "success" : "error", response.message);                    
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
        else{
            validateForm();
        }
    }

    return (
        <div style={styles}>
            {loading ? <ChangePasswordLoader/> :
                <>             
                    <div style={styles.title}>{"Change Password"}</div>             
                    <form style={styles.formWrapper}>                                
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>{'Current Password'}</label>                    
                            <TextInput
                                inputType={'password'}
                                name={'currentPassword'}
                                value={passwordModel.currentPassword}
                                styles={styles.textInput}
                                handleChange={handleInputChange}
                                validation={validation.currentPassword}
                                handleBlur={handleInputBlur}
                            />                    
                        </div>
                        <hr style={styles.line} />
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>{'New Password'}</label>
                            <TextInput
                                inputType={'password'}
                                name={'newPassword'}
                                value={passwordModel.newPassword}
                                styles={styles.textInput}
                                handleChange={handleInputChange}
                                validation={validation.newPassword}
                                handleBlur={handleInputBlur}
                            />
                        </div>                                
                        <div style={styles.inputWrapper}>
                            <label style={styles.label}>{'Confirm New Password'}</label>
                            <TextInput
                                inputType={'password'}
                                name={'confirmNewPassword'}
                                value={passwordModel.confirmNewPassword}
                                styles={styles.textInput}
                                handleChange={handleInputChange}
                                validation={validation.confirmNewPassword}
                                handleBlur={handleInputBlur}
                            />
                        </div>                                        
                        <Button type="button" style={styles.updateButton} onClick={handleUpdateClick}>{"UPDATE PASSWORD"}</Button>                
                    </form>
                </>}   
            </div>
    );
}