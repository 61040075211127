import { Button } from "../Shared/Buttons/Button";
import { JobViewTexts as Texts } from '../../helpers/LayoutTexts';
import { ReactComponent as Notification } from '../../assets/svg/notification.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/bookmark-blue.svg';
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';

export interface Props {
    styles: any;
    jobId: number;
    isSaved: boolean;
    isGovernmentJob: boolean;
    isGovernmentJobApplication: boolean;
    handleApplyButtonClick(): void;
    saveJob (jobId: number): void;
}

export const JobApply: React.FC<Props> = (props) => {    
    const styles = props.styles;

    const handleSaveButtonClick = () => {        
        props.saveJob(props.jobId);        
    }
    
    const handleNotifyButtonClick = () => {
        
    }

    return (
        <div style={styles.wrapper}>
            {!props.isGovernmentJob && <div style={styles.rowWrapper}>
                <Button type="button" style={styles.applyButton} onClick={props.handleApplyButtonClick}>{Texts.apply}</Button>
            </div>} 
            {props.isGovernmentJob && props.isGovernmentJobApplication && <div style={styles.rowWrapper}>
                <Button type="button" style={styles.applyButton} onClick={props.handleApplyButtonClick}>{Texts.downloadApplication}</Button>
            </div>}            
            <div style={styles.rowWrapper}>
                <Button type="button" style={styles.saveButton} onClick={handleSaveButtonClick}>
                    {props.isSaved ? <>SAVED <BookmarkActive style={styles.saveIcon} /></> : <>SAVE JOB<Bookmark style={styles.saveIcon} /></>}
                </Button>
                {/* temporally hide notify buttons */}
                {/* <Button type="button" style={styles.notifyButton} onClick={handleNotifyButtonClick}><Notification style={props.styles.icon}/></Button> */}
            </div>
        </div>
    );
}