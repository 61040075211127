import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { postCandidateSaveJob } from "../services/CandidateService";
import useErrorPage from "./useErrorPage";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthContextProvider";
import { LoggedCandidate } from "../models/LoggedCandidate";

const useSaveJob = () => {    
    const navigate = useNavigate();
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();  
    const [toErrorPage] = useErrorPage();    
    
    async function saveCandidateJob (jobId: number, isSaved: boolean) {   
        if(candidate === null) { 
            const params = { callbackUrl: location.pathname + location.search, message: "Please login or register to save this job." };
            navigate({
                pathname: '/candidates/login',
                search: `?${createSearchParams(params)}`
            });
        } else {
            await postCandidateSaveJob(candidate.candidateId, jobId, isSaved, candidate.token)
            .then((savedJobIds: number[]) => {
                const savedJobIdString = JSON.stringify(savedJobIds);            
                localStorage.setItem('savedJobIds', savedJobIdString);
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
    }

    const isSavedJob = (jobId: number): boolean => {
        const savedJobIdString = localStorage.getItem('savedJobIds');
        if(savedJobIdString !== null) {
            const savedJobIdList: number[] = JSON.parse(savedJobIdString);
            const isSaved = savedJobIdList.includes(jobId);
            return isSaved;
        } 
        return false;       
    }
    
    return {saveCandidateJob, isSavedJob};    
};

export default useSaveJob;