import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Swal from 'sweetalert2';
import { postCredentials } from '../services/LoginServices';
import { useNavigate } from 'react-router-dom';
import { StyleContext } from '../providers/StyleContextProvider';
import { Header } from '../components/Shared/Header';
import { Footer } from '../components/Shared/Footer';

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("emailManagerLogin"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const hasUserSession = sessionStorage.getItem("user");

    useEffect(() => {
        if (hasUserSession) {
            navigate("/emailmanager")
        }
        setStyles(styleContext.getComponentStyle("emailManagerLogin"));
    }, [isMobile])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (email && password) {
            setIsLoading(true);
            const credentials = JSON.stringify({ email, password })
            const result = await postCredentials(credentials);
            setIsLoading(false);
            if (result) {
                sessionStorage.setItem("user", "true");
                navigate("/emailmanager")
            }
            else {
                Swal.fire("Oops!!", "Incorrect Email Address or Password!!", "error")
            }

        } else Swal.fire("Warning", "Both Email Address & Password fields are mandatory!!", "warning")

    }
    return (
        <>
            {isLoading &&
                <div style={styles.loader}>
                    <div style={styles.loader.loaderTextWrapper}>
                        <h1>Hold for a sec.</h1>
                        <p>We are working on it.</p>
                    </div>
                </div>}
            <Header styleSheet="fullHeader" searchEnable={false}/>
            <div style={styles.row}>
                <div style={styles.wrapper}>
                    <h3 style={styles.text}>Recruiter Login</h3>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div>
                            <label>Email Address</label>
                            <input type="email"
                                style={styles.inputField}
                                name="email"
                                value={email}
                                placeholder={"Enter your Email.."}
                                onChange={(e) => { setEmail(e.target.value) }}
                                required
                            />
                        </div>
                        <div>
                            <label>Password</label>
                            <input type="password"
                                style={styles.inputField}
                                name="password"
                                placeholder={"Enter your Password.."}
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                                required />
                        </div>
                        <div>
                            <button style={styles.button}>Login</button>
                        </div>
                    </form>

                </div>
            </div>
            <Footer/>
        </>

    )
}