import { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { Color } from '../../enums/Color';
import { StyleContext } from '../../providers/StyleContextProvider';


export const JobApplyLoader: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobApplyLoader"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const minNumber = 40;
    const maxNumber = 95;
    const inputLoaderCount = isMobile ? 8 : 12;
    let logoStyles = styles.verticalDetailCard.logo;
    let inputLoaders = new Array<any>();
    let randomRange = maxNumber - minNumber;

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("jobApplyLoader"));
    }, [isMobile])

    for (let i = 0; i < inputLoaderCount; i++) {
        // get random number between 40 and 95 for set the width of question loader
        let randomNumber = Math.random() * randomRange + minNumber;
        let width = Math.floor(randomNumber);
        let loader = <div style={styles.inputLoader} key={i}>
            <ContentLoader
                speed={1}
                width={"100%"}
                height={styles.inputLoader.height}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                <rect x="0" y="0" rx="8" ry="8" width={width + "%"} height="10%" />
                <rect x="0" y="15%" rx="8" ry="8" width={(width - 10) + "%"} height="8%" />
                <rect x="0" y="30%" rx="8" ry="8" width="95%" height="60%" />
            </ContentLoader>
        </div>;
        inputLoaders.push(loader)
    }

    return (
        <div style={styles.baseWrapper}>
            <div style={styles.leftWrapper}>
                <div style={styles.mobileDetailCard}>
                    <div style={styles.verticalDetailCard}>
                        <ContentLoader
                            speed={1}
                            style={logoStyles}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                            width={logoStyles.height}
                            height={logoStyles.height}
                        >
                            <rect x="0" y="0" rx="3" ry="3" width={logoStyles.width} height={logoStyles.height} />
                        </ContentLoader>
                        <ContentLoader
                            speed={1}
                            height={styles.verticalDetailCard.height}
                            width={"100%"}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                        >
                            <rect x="5%" y="5%" rx="3" ry="3" width="70%" height="3%" />
                            <rect x="5%" y="10%" rx="3" ry="3" width="40%" height="3%" />
                            <rect x="5%" y="15%" rx="3" ry="3" width="60%" height="2%" />
                            <rect x="5%" y="18%" rx="3" ry="3" width="30%" height="1.8%" />
                            <rect x="5%" y="22%" rx="3" ry="3" width="50%" height="1.8%" />
                            <rect x="5%" y="25%" rx="3" ry="3" width="60%" height="1.7%" />
                            <rect x="5%" y="28%" rx="3" ry="3" width="90%" height="1.5%" />
                            <rect x="5%" y="40%" rx="3" ry="3" width="70%" height="1.4%" />
                            <rect x="5%" y="44%" rx="3" ry="3" width="30%" height="1.4%" />
                            <rect x="10%" y="50%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="50%" rx="3" ry="3" width="20%" height="1.8%" />
                            <rect x="10%" y="54%" rx="3" ry="3" width="20%" height="1.4%" />
                            <rect x="40%" y="54%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="10%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="20%" y="70%" rx="3" ry="3" width="60%" height="10%" />
                        </ContentLoader>
                    </div>
                </div>
                <div style={styles.mobileVerticalDetailCard}>
                    <ContentLoader
                        speed={1}
                        height={150}
                        width={"100%"}
                        backgroundColor={Color.LoaderBackground}
                        foregroundColor={Color.LoaderForeground}
                    >
                        <rect x="30%" y="45" rx="3" ry="3" width="60%" height="10" />
                        <rect x="30%" y="62" rx="3" ry="3" width="50%" height="20" />
                        <rect x="5%" y="101" rx="3" ry="3" width="20%" height="10" />
                        <rect x="5%" y="130" rx="3" ry="3" width="70%" height="20" />
                        <rect x="5%" y="27" rx="3" ry="3" width="58" height="58" />
                    </ContentLoader>
                </div>
                <ContentLoader
                    speed={1}
                    height={100}
                    width={"100%"}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="0" rx="3" ry="3" width="50%" height="28" />
                    <rect x="0" y="40" rx="3" ry="3" width="80%" height="10" />                    
                    <rect x="0" y="60" rx="3" ry="3" width="70%" height="8" />
                </ContentLoader>
                <div style={styles.inputLoaderWrapper}>
                    {inputLoaders}
                </div>
            </div>
            <div style={styles.rightWrapper}>
                <div style={styles.desktopDetailCard}>
                    <div style={styles.verticalDetailCard}>
                        <ContentLoader
                            speed={1}
                            style={logoStyles}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                            width={logoStyles.height}
                            height={logoStyles.height}
                        >
                            <rect x="5%" y="0" rx={logoStyles.borderRadius} ry={logoStyles.borderRadius} width={logoStyles.width} height={logoStyles.height} />
                        </ContentLoader>
                        <ContentLoader
                            speed={1}
                            height={styles.verticalDetailCard.height}
                            width={"100%"}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                        >
                            <rect x="5%" y="5%" rx="3" ry="3" width="70%" height="3%" />
                            <rect x="5%" y="10%" rx="3" ry="3" width="40%" height="3%" />
                            <rect x="5%" y="15%" rx="3" ry="3" width="60%" height="2%" />
                            <rect x="5%" y="18%" rx="3" ry="3" width="30%" height="1.8%" />
                            <rect x="5%" y="22%" rx="3" ry="3" width="50%" height="1.8%" />
                            <rect x="5%" y="25%" rx="3" ry="3" width="60%" height="1.7%" />
                            <rect x="5%" y="28%" rx="3" ry="3" width="90%" height="1.5%" />
                            <rect x="5%" y="40%" rx="3" ry="3" width="70%" height="1.4%" />
                            <rect x="5%" y="44%" rx="3" ry="3" width="30%" height="1.4%" />
                            <rect x="10%" y="50%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="50%" rx="3" ry="3" width="20%" height="1.8%" />
                            <rect x="10%" y="54%" rx="3" ry="3" width="20%" height="1.4%" />
                            <rect x="40%" y="54%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="10%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="20%" y="70%" rx="3" ry="3" width="60%" height="10%" />
                        </ContentLoader>
                    </div>
                </div>
                <div style={styles.organizationCard}>
                    <ContentLoader
                        speed={1}
                        height={150}
                        width={"100%"}
                        backgroundColor={Color.LoaderBackground}
                        foregroundColor={Color.LoaderForeground}
                    >
                        <rect x="30%" y="45" rx="3" ry="3" width="60%" height="10" />
                        <rect x="30%" y="62" rx="3" ry="3" width="50%" height="20" />
                        <rect x="5%" y="101" rx="3" ry="3" width="20%" height="10" />
                        <rect x="5%" y="130" rx="3" ry="3" width="70%" height="20" />
                        <rect x="5%" y="27" rx="3" ry="3" width="58" height="58" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
}