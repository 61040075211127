import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SuccessfullyApplied } from '../../assets/svg/successfully-applied.svg'
import { JobApplyPageTexts as Texts } from '../../helpers/LayoutTexts';
import { Button } from '../Shared/Buttons/Button';

interface Props {
    styles: any;
    isDraftCompleted?: boolean;
}

export const ApplyComplete: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();

    return (
        <div style={styles.container}>
            <SuccessfullyApplied style={styles.image}/>
            <h3 style={styles.title}>{props.isDraftCompleted ? Texts.applyStage.drafted : Texts.applyStage.applied}</h3>
            <p style={styles.description}>{props.isDraftCompleted ? Texts.stageDescription.drafted : Texts.stageDescription.applied}</p>
            <div style={styles.buttonsContainer}>
                {props.isDraftCompleted ? <Button style={styles.button} onClick={() => navigate("/candidate/drafts")}>{Texts.viewDrafts}</Button> 
                : <Button style={styles.button} onClick={() => navigate("/candidate/myapplications")}>{Texts.viewMyApplications}</Button>}
                <Button style={{...styles.button, ...styles.button.back}} onClick={() => navigate('/')}>{Texts.backToHome}</Button> 
            </div>
        </div>
    )
}