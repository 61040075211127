import React, { useEffect, useRef, useState } from 'react';

interface Props {
  style: any;
  duration: number;
  stop: boolean;
  submit(duration: number): void;
  onTimeout(): void;
}

export const CountdownTimer: React.FC<Props> = (props) => {
  const [remainingTime, setRemainingTime] = useState(props.duration);
  const startTimeRef = useRef<number | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);  

  useEffect(() => {
    startTimeRef.current = Date.now();
    const animationFrameCallback = () => {
      updateTimer();      
    };
    timerRef.current = setInterval(updateTimer, 1000);
    window.requestAnimationFrame(animationFrameCallback);
    return () => {
      clearInterval(timerRef.current!);
    };
  }, []);

  useEffect(() => {
    if (props.stop) {
      clearInterval(timerRef.current!);
      props.submit(props.duration - remainingTime);
    }
  }, [props.stop]);

  const updateTimer = () => {    
    if (startTimeRef.current !== null) {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTimeRef.current) / 1000);
      const newRemainingTime = Math.max(0, props.duration - elapsedSeconds);
      setRemainingTime(newRemainingTime);

      if (newRemainingTime <= 0) {
        clearInterval(timerRef.current!);
        props.onTimeout();
      }
    }
  };

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div style={props.style}>
      {minutes.toString().padStart(2, '0')}m : {seconds.toString().padStart(2, '0')}s
    </div>
  );
}
