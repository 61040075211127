import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { JobApplyPageTexts as Texts } from '../../helpers/LayoutTexts'
import { StyleContext } from '../../providers/StyleContextProvider'
import { Button } from '../Shared/Buttons/Button'
import { merge } from 'react-merge'

interface Props {
    styles?: any;
    submitNotice?: string;
    submitButtonText: string;
    draftEnable: boolean;
    handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
    handleDraft?(event: React.FormEvent<HTMLFormElement>): void;
}

export const SubmitArea: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("submitArea"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);
    
    return (
        <div style={styles.wrapper}>
            <p style={styles.submitNotice}>{props.submitNotice}</p>
            <div style={styles.buttonsContainer}>
                <Button style={styles.submitButton} type="submit" onClick={props.handleSubmit} >{props.submitButtonText}</Button>
                <Button style={styles.cancel} type="button" onClick={() => {navigate('/jobs');}}>{Texts.cancelButton}</Button>
                {props.draftEnable && <Button style={{...styles.saveDraft, ...styles.saveDraft.extendTop}} type="button" onClick={props.handleDraft}>{Texts.saveDraft}</Button>}
            </div>
            <Button style={styles.backButton} type="button" onClick={() => {navigate('/jobs');}}>{Texts.backToSearch}</Button>
        </div>   
    )
}