import React, { useContext, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { AuthenticateBySocialApps } from './AuthenticateBySocialApps';
import { TextInput } from '../Shared/Inputs/TextInput';
import { LoginModel, LoginValidation } from '../../models/LoginModel';
import { postCandidateLogin } from '../../services/CandidateService';
import { useNavigate } from 'react-router-dom';
import { ResponseModel } from '../../models/ResponseModel';
import useErrorPage from '../../hooks/useErrorPage';
import { validateEmail } from '../../helpers/Common';
import { SubmitFormText } from '../../helpers/LayoutTexts'
import { AuthContext } from '../../providers/AuthContextProvider';
import { LoginFormLoader } from '../Loaders/LoginFormLoader';

interface Props {
    styles: any;  
    callbackUrl: string | null;
    message: string | null;
    title: string;
    changeStage(stage: string): void;
    openAlert(status: string, message: string): void;
    showCVUploadPopup(candidateId: number): void
}

const initialLoginModel: LoginModel  = {
    email: "",
    password: "",
}

const initialLoginValidation: LoginValidation = {
    email: {},
    password: {}
}

export const Login: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loginModel, setLoginModel] = useState<LoginModel>(initialLoginModel);
    const [validation, setValidation] = useState<LoginValidation>(initialLoginValidation);
    const styles = props.styles;
    const authContext = useContext(AuthContext);
    const [toErrorPage] = useErrorPage();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setLoginModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);
    }

    const validateInput = (name: string, value: string) => {
        if (name === "password") {
            if (value === "") {
                setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: name + " is required" } }));
            } else {
                setValidation((values: any) => ({ ...values, [name]: { valid: true, invalid: false } }));
            }
        }
        if (name === "email") {
            if (value === "") {
                setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "Email is required" } }));
            } else if (!validateEmail(value)) {
                setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "Invalid email address!" } }));
            } else {
                setValidation((values: any) => ({ ...values, [name]: { valid: true, invalid: false } }));
            }
        }
    }

    const validateForm = (): number => {
        let formValidation: LoginValidation = {
            email: { valid: true },
            password: { valid: true }
        }
        if (loginModel.email === "") {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        }
        else if (!validateEmail(loginModel.email)) {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        }
        if (loginModel.password === "") {
            formValidation.password = {valid: false, invalid: true, invalidMessage: "Password is required"};
        }
        setValidation(formValidation);
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;
    }

    async function submitLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;
        if (validInputCount < 2) {
            validInputCount = validateForm();
        }
        if(validInputCount === 2) {
            setLoading(true);
            await postCandidateLogin(loginModel)
            .then((loginResponse: ResponseModel) => {
                setLoading(false);
                if (loginResponse.status) {
                    authContext.setLoggedCandidate(loginResponse.data, loginResponse.token);
                    if (props.callbackUrl) {
                        navigate(props.callbackUrl);
                    } else {
                        navigate('/candidate/home');    
                    }
                }
                else {
                    props.openAlert("error", loginResponse.message);
                }
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
        else {
            props.openAlert("error", SubmitFormText.error);
        }
    }
 
    return (
        <> 
        {loading ? <LoginFormLoader/> :
        <form style={styles.loginForm} onSubmit={submitLogin}>
            <div style={styles.accordionButtonsContainer}>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.left, ...styles.accordionButton.selected }}
                    onClick={() => props.changeStage("login")} >
                    Login
                </div>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.right }}
                    onClick={() => props.changeStage("register")} 
                >
                    Register
                </div>
            </div>
            {props.message && <div style={styles.callbackMessage}>{props.message}</div>}
            <p style={styles.boldTitle}>{props.title}</p>
            <TextInput
                label={'Email *'}
                inputType={'text'}
                name={"email"}
                handleChange={handleInputChange}
                value={loginModel.email}
                validation={validation.email}
                handleBlur={handleInputBlur}
            />
            <TextInput
                label={'Password *'}
                styles={styles.loginPassword}
                inputType={'password'}
                name={"password"}
                value={loginModel.password}
                handleChange={handleInputChange}
                validation={validation.password}
                handleBlur={handleInputBlur}
            />
            <p style={styles.normalText}>Forgot Password ?
                <Button type="button" style={styles.resetNow} onClick={() => props.changeStage("lostPassword")}>Reset Now</Button>
            </p>
            <Button style={styles.submitButton} type="submit" disabled={loading}>Login</Button>
            <div style={styles.registerNowWrapper}>
                Don't have an account ?
                <span style={styles.hyperLink} onClick={() => props.changeStage("register")}>Register Now</span>
            </div>
            <div>
                <p style={styles.social.loginUsing.text}>Or Login Using</p>
                <div style={styles.social.loginUsing.wrapper}>
                    <AuthenticateBySocialApps styles={styles.social} callbackUrl={props.callbackUrl} openAlert={props.openAlert} showCvUploadPopup={props.showCVUploadPopup}/>
                </div>
            </div>
        </form>}
        </>
    )
}