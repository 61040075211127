import { Color } from "../enums/Color";

export const organizationJobsPage = {
    mobile: {
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column",
            zIndex: "1",
            position: "relative" as "relative"
        },
        leftWrapper: {
            width: "100%"
        },
        rightWrapper: {
            width: "90%",
            margin: "7.24vw 5% 0 5%"
        },
        organizationCard: {
            margin: "-17.39vw 5% 0 5%",
            background: Color.White,
            border: "2px solid " + Color.Gainsboro,
            tag: {
                background: Color.LightAsh
            },
            rowWrapper: {
                justifyContent: "unset"
            },
            logo: {
                cursor: "unset"
            },
            description: {
                width: "76.57vw"
            }
        },
        heading: {
            fontSize: "6.03vw",
            margin: "0 0 4.83vw 0",
            color: Color.LightBlack,
            whiteSpace: "nowrap",
            overflow: "hidden"
        },
        wrapper: {},        
        titleLoader: {
            width: "40vw",
            height: "4.83vw"
        },
        title: {
            textAlign: "left",
            fontSize: "4.35vw",
            letterSpacing: "0px",
            color: Color.SharpAsh,
            marginBottom: "auto",
            opacity: "1",
            count: {
                fontWeight: "bold" as "bold",
                color: Color.DeepBlack,
                marginRight: "1.21vw"
            }
        },
        selectWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginTop: "4.83vw",
            marginBottom: "7.24vw"
        },
        sortBySelect: {
            width: "100%",
            margin: "0",
            button: {
                width: "35.78vw",
                marginLeft: "0",
                height: "8.45vw"
            },
            buttonWrapper: {
                marginTop: "0"
            },
            hover: {
                width: "35.78vw"
            }
        },
        perPageSelect: {
            width: "100%",
            marginLeft: "0%",
            marginTop: 0,
            button: {
                width: "20.77vw",
                marginLeft: "0",
                height: "8.45vw"
            },
            buttonWrapper: {
                marginTop: "0"
            },
            hover: {
                width: "20.77vw"
            }
        },
        jobsLoader: {
            box: {
                w: "0",
                h: "0"
            }
        },
        jobsWrapper: {
            margin: "unset"
        },
        card: {
            detailWrapper: {
                paddingLeft: 0,
                width: "100%"
            },
            title: {
                maxWidth: "unset",
                jobSaved: {
                    maxWidth: "unset"
                }
            },
            bottomMobileTagWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
                marginTop: "0.68vh",
            },
            disableIcon: {
                minWidth: "3.38vw"
            },
            cvLessIcon: {
                minWidth: "4.83vw"
            }
        },
        pagination: {
            margin: "7.00vw auto 0 auto"
        },
        horizontalBanner: {
            margin: "24.15vw 5% 0 5%"
        },
        noResults: {
            textAlign: "center" as "center",
            color: Color.SharpAsh,
            fontSize: "5vw",
            marginTop: "5vh",
            zero: {
                fontWeight: "bold",
                color: Color.DeepBlack
            },
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                margin: "1.5vh 5% 4vh 5%"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        }
    },
    desktop: {
        baseWrapper: {
            flexDirection: "row" as "row",
            background: Color.White,
            margin: "-8.5vw 7.29vw 0 7.29vw",
            borderRadius: "1.562vw",
        },
        leftWrapper: {
            width: "20.73vw"
        },
        rightWrapper: {
            width: "64.69vw",
            margin: "1.04vw 0 0 0",
            padding: "0 0.86vw"
        },
        organizationCard: {
            width: "auto",
            margin: "1.45vw 0 0 1.45vw",
            borderRadius: "0",
            border: "none",
            borderRight: "1px solid " + Color.MiddleAsh,
            logoWrapper: {
                display: "flex",
                flexDirection: "column" as "column",
                padding: 0
            },
            rowWrapper: {
                padding: 0,
                alignItems: "center"
            },
            columnWrapper: {
                marginLeft: 0
            },
            logo: {
                width: "8.59vw",
                height: "8.59vw",
                borderRadius: "0.78vw"
            },
            name: {
                fontSize: "1.04vw",
                paddingTop: "1.04vw"
            },
            count: {
                fontSize: "1vw"
            },
            tag: {
                marginTop: "1.45vw"
            },
            line: {
                margin: "1.04vw 1.22vw 1.04vw 0"
            },
            favouriteButton: {
                width: "10.26vw",
                fontSize: "1vw",
                marginLeft: "0.8vw",
                icon: {
                    width: "1vw"
                }
            },
            description: {
                width: "17.86vw"
            }
        },
        heading: {
            fontSize: "1.56vw",
            margin: "2.03vw 0 1.25vw 1.02vw"
        },        
        titleLoader: {
            width: "12vw",
            height: "1.04vh",
        },
        title: {
            marginLeft: "1.02vw",
            fontSize: "0.93vw",
            color: Color.SharpAsh,
            count: {
                fontWeight: "bold",
                color: Color.DeepBlack,
                marginRight: "0.26vw"
            }
        },
        wrapper: {
            display: "flex",
            flexDirection: "row",
            marginLeft: 0,
            marginBottom: "0.80vh",
        },
        selectWrapper: {
            marginLeft: "auto",
            marginTop: "unset",
            marginBottom: "unset"
        },
        sortBySelect: {
            width: "unset",
            marginRight: "2.10vw",
            button: {
                width: "10.31vw",
                marginLeft: "1.05vw",
                height: "2.08vw"
            },
            hover: {
                width: "10.31vw"
            }
        },
        perPageSelect: {
            button: {
                width: "6.4vw",
                marginLeft: "1.05vw",
                height: "2.08vw"
            },
            width: "unset",
            hover: {
                width: "6.4vw"
            }
        },
        jobsWrapper: {
            margin: "1.61vw 0"
        },
        card: {
            height: "6.05vw",
            padding: "1.56vw 0.75vw 1.56vw 1.04vw",
            detailWrapper: {
                height: "4.51vw",
                width: "61.20vw",
                marginTop: "unset"
            },
            toggleWrapper: {
                marginTop: "0.73vw"
            },
            title: {
                height: "auto",
                maxWidth: "30vw",
                fontSize: "1.04vw"
            },
            bottomMobileTagWrapper: {
                display: "none"
            },
            text: {
                marginRight: "0"
            },
            location: {
                marginRight: "5.40vw"
            },
            jobType: {
                marginLeft: "-0.26vw",
                marginRight: "3.47vw"
            },
            smallIcon: {                
                width: "unset",
                height: "unset"
            },
            share: {                
                position: "absolute",
                right: "0.39vw",
                bottom: "1.04vw",
                button: {
                    width: "1.66vw",
                    height: "1.66vw"
                },
                icon: {
                    width: "0.9vw",
                    height: "0.9vw",
                    verticalAlign: "middle"
                }
            },
            bottomBorder: {
                bottom: 0,                
                marginBottom: "-0.02vw"
            },
            urgentTag: {
                marginTop: "0"
            },
            videoTag: {
                marginTop: "0"
            },
            desktopTagWrapper: {
                alignItems: "center"
            },
            disableIcon: {
                width: "0.88vw",
                height: "0.99vw",
                marginLeft: "0.83vw",
                minWidth: "unset"
            },
            cvLessIcon: {
                width: "0.88vw",
                height: "0.88vw",
                marginLeft: "0.83vw",
                minWidth: "unset"
            },
            saveIcon: {
                width: "1.2vw",
                height: "1.2vw",
                marginLeft: "0.73vw"
            },
            jobSaved: {
                fontSize: "1.04vw"
            },
            desktopCountDown: {
                fontSize: "0.82vw",                
                hover: {
                    marginTop: "-1.04vw"
                }
            }
        },
        pagination: {
            margin: "1.56vw 0 0 0"
        },
        horizontalBanner: {
            margin: "7.81vw 7.29vw -1.31vw 7.29vw"
        },
        noResults: {            
            fontSize: "1.3vw",
            marginTop: "13.6vh",
            wrapper: {
                width: "49.47vw",
                borderRadius: "0.7vw",
                margin: "3.2vh auto 7.81vw auto"
            },
            icon: {
                marginRight: "1.43vw",
                width: "2.19vw",
                height: "2.19vw"
            }
        }
    }
}