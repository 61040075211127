import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const searchPage = {
    mobile: { 
        baseWrapper: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        leftWrapper: {
            width: "100%"
        },
        rightWrapper: {
            width: "90%",
            overflow: "hidden",
            margin: "12.08vw 4.83vw 0 4.83vw"
        },
        jobsCount: {
            loaderWrapper: {                
                margin: "0 4.83vw 7.25vw",
                height: "12.56vw"
            },
            wrapper: {
                display: "flex",
                border: "1px solid " + Color.Blue,
                borderRadius: "2.41vw",
                backgroundColor: Color.LightBlue,
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                height: "12.07vw",
                fontSize: "4.34vw",
                color: Color.Blue,
                margin: "0 4.83vw 7.25vw",
                fontFamily: Font.Poppins,
                fontWeight: 500
            },
            count: {
                fontWeight: "bold",
                marginRight: "1.2vw"
            },
            noResults: {
                textAlign: "center" as "center",
                color: Color.SharpAsh,
                fontSize: "4.34vw",
                marginTop: "5vh",
                zero: {
                    fontWeight: "bold",
                    color: Color.DeepBlack,
                },
                wrapper: {
                    borderRadius: "2.41vw",
                    border: "1px solid " + Color.HarvestGold,
                    backgroundColor: Color.LightGold,
                    display: "flex",
                    alignItems: "center" as "center",
                    justifyContent: "center" as "center",
                    padding: "2.89vw 4.83vw",
                    color: Color.HarvestGold,
                    margin: "2.41vw 4.83vw 7.24vw 4.83vw",
                    fontFamily: Font.Poppins
                },
                icon: {
                    marginRight: "2.41vw",
                    width: "7.24vw",
                    height: "7.24vw"
                }
            }
        },
        wrapper: {
            marginBottom: "7.25vw"
        },
        selectWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginLeft: "auto",
            alignItems: "center" as "center",
            marginTop: "4.83vw",
        },
        title: {
            textAlign: "left" as "left",                        
            fontSize: "4.34vw",                    
            letterSpacing: "0px",
            color: Color.SharpAsh,
            marginBottom: "auto",
            opacity: "1",
            count: {         
                color: Color.DeepBlack,      
                fontWeight: "bold" as "bold"
            },
            loader: {
                width: "30vw",
                height: "2vh"
            }
        },
        sortBySelect: {        
            width: "100%",
            marginLeft: "0%",
            marginRight: "-2.90vw",
            marginTop: "0",
            label: {
                fontWeight: 500
            },
            button: {                
                marginLeft: "0",
                height: "8.45vw"
            },
            buttonWrapper: {
                marginTop: "0"                
            },
            hover: {
                width: "35.74vw"
            }        
        },
        perPageSelect: {            
            width: "100%",
            marginLeft: "0%",
            marginTop: "0",
            label: {
                fontWeight: 500
            },
            button: {
                width: "20.77vw",
                marginLeft: "0" , 
                height: "8.45vw"
            },
            buttonWrapper: {
                marginTop: "0"                
            },                        
            hover: {
                width: "20.77vw"
            } 
        },
        card: {
            detailWrapper: {
                width: "100%"
            }
        },
        pagination: {
            margin: "9.66vw auto 0 auto"
        },
        verticalBanner: {            
            display: "none",
            marginTop: "1.82vw"           
        },
        horizontalBanner: {
            margin: "12.08vw 4.83vw 0 4.83vw"
        }    
    },
    desktop: {
        baseWrapper: {
            flexDirection: "row" as "row",
            marginLeft: "7.29vw",
            marginRight: "7.29vw",
            position: "relative" as "relative",
            zIndex: 1
        },
        leftWrapper: {
            width: "20.73vw",
            marginRight: "0.83vw"
        },
        rightWrapper: {
            width: "63.85vw",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "2.86vw",
            overflow: "hidden"
        },
        jobsCount: {
            loaderWrapper: {
              margin: "0 0 1.56vw 0",
              height: "calc(2.6vw + 1px)"
            },
            wrapper: {
                margin: "0 0 1.56vw 0",
                fontSize: "0.937vw",
                borderRadius: "0.52vw",
                height: "2.6vw"
            },
            count: {
                marginRight: "0.3vw"
            },
            noResults: {                              
                marginTop: "3.38vw",
                fontSize: "0.937vw",
                wrapper: {
                    width: "49.47vw",
                    borderRadius: "0.52vw",
                    margin: "1.04vw auto auto auto",
                    padding: "1.56vw 0 1.56vw 0"
                },
                icon: {
                    marginRight: "1.02vw",
                    width: "1.58vw",
                    height: "1.58vw"
                }
            },
        },
        filterPanelWrapper: {
           width: "20.73vw"
        },
        wrapper: {            
            display: "flex",
            flexDirection: "row" as "row",
            marginLeft: "0",
            marginBottom: "2vh"
        },
        selectWrapper: {
            width: "33vw",
            marginTop: "0",
            marginRight: "0.15vw",
            justifyContent: "right"
        },
        title: {
            fontSize: "0.937vw",            
            loader: {
                width: "7.239vw"
            }
        },
        sortBySelect: {
            width: "unset",
            marginRight: "2.19vw",                
            button: {                
                marginLeft: "1.09vw",
                height: "2.08vw"
            },
            hover: {
                width: "10.31vw"
            }  
        },
        perPageSelect: {
            width: "unset",
            button: {                
                marginLeft: "1.09vw",
                width: "6.40vw",
                height: "2.08vw"
            },
            hover: {
                width: "6.40vw"
            }
        },
        card: {
            detailWrapper: {
                width: "52.71vw"
            }
        },        
        pagination: {
            margin: "1.57vw auto 0 0"
        },
        verticalBanner: {            
            display: "block"            
        },
        horizontalBanner: {
            margin: "7.03vw 7.29vw 0 7.29vw"
        }
    } 
}