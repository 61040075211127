import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as Icon } from '../../assets/svg/drop_down.svg';
import { DropdownItem } from './DropdownItem';
import { Option } from '../../models/SelectInputs';
import { StyleContext } from '../../providers/StyleContextProvider';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';

interface Props {
    styles?: any;
    label: string;
    options: Option[];
    selectOption(selectedId: number | string): void;
    onLeave?(): void;
}

export const MultySelect: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("multySelect"));
    const [hovered, setHovered] = useState<boolean>(false);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

    useEffect(() => {
        // merge base styles with component specific styles
        let style = styleContext.getComponentStyle("multySelect");
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    const onLeave = () => {
        setHovered(false);
        if (props.onLeave) {
            props.onLeave();
        }
    }

    const onClick = () => {
        if(hovered) {
            setHovered(false);
            if (props.onLeave) {
                props.onLeave();
            }
        }
        else {
            setHovered(true);
        }
    }

    return (
        <div 
            style={{ ...styles.wrapper, ...(hovered && styles.wrapper.hover) }} 
            className="center-content" 
            onMouseEnter={() => { setHovered(true); }} 
            onMouseLeave={() => { onLeave(); }}
            onClick={onClick}    
        >
            <div style={styles.text}>{props.label}</div>
            <Icon style={{ ...styles.icon, ...(hovered && styles.rotateIcon) }} />
            {hovered && <div style={styles.hover} className={"scrollArea"}>
                {props.options.map((option, index) =>
                    <div
                        key={index}
                        onMouseEnter={() => { setHoveredIndex(index); }}
                        onMouseLeave={() => { setHoveredIndex(-1); }}
                    >
                        <DropdownItem key={index} styles={styles.option} onClick={() => props.selectOption(option.value)}>
                            {option.option}
                        </DropdownItem>
                        <hr style={{ ...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || props.options.length === index + 1) && styles.hoveredBorder) }} />
                    </div>
                )}</div>}
        </div>
    );
}