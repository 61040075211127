import React from "react";
import { Link } from "react-router-dom";
import { Sector } from "../../models/Sector";

interface Props {
  styles: any;
  sectors: Sector[];
}

export const JobSectors: React.FC<Props> = (props) => {
  let styles = props.styles;

  return (
    <div style={styles.wrapper}>
      {props.sectors.map((sector, index) => (
        <Link
          to={"/jobs?Sectors=" + sector.sectorId + "&SeoKeyword=" + sector.friendlyName + "-jobs"}
          style={styles.sector}
          key={index}
          className={"link"}
        >
          <span style={styles.text}>{sector.sectorName}</span> ({sector.sectorCount})
        </Link>
      ))}
    </div>
  );
};
