import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../../providers/StyleContextProvider';

interface Props {    
    styles?: any,
    name?: string,
    value: any,
    handleChange(event?: React.ChangeEvent<HTMLInputElement>): void
}

export const CheckboxInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    return (
        <div style={styles.checkboxWrapper}>
            <label className="customCheckBoxContainer" style={{...styles.customCheckBoxContainer, ...styles.checkboxLabel}}>
                {props.children}
                <input
                    type="checkbox"
                    id={props.name}
                    name={props.name}
                    checked={props.value}
                    onChange={props.handleChange}
                />
                <span className="checkbox" style={styles.checkbox}></span>
            </label>
        </div>
    )
}