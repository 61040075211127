import { useEffect } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { getAllSectors } from "../../services/JobService";
import { Sector } from "../../models/Sector";
import useErrorPage from "../../hooks/useErrorPage";

export const RedirectBySector: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const sectorName = params.sectorName;
    const [toErrorPage] = useErrorPage();
    
    useEffect(() => {                     
        redirectBySector();          
    }, []);

    async function redirectBySector() {
        await getAllSectors()
        .then((sectors: Sector[]) => {
            let sectorId = sectors.find(x => x.friendlyName === sectorName)?.sectorId;
            if(sectorId) {
                const params = { Sectors: sectorId.toString(), SeoKeyword: sectorName + "-jobs" };
                navigate({
                    pathname: '/jobs',
                    search: `?${createSearchParams(params)}`,
                });
            }            
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }    
    return null; // This component doesn't render anything
};