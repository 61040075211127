import { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { Tabs } from "../components/Shared/Tabs";
import { TabItem } from "../models/TabItem";
import { TestimonialSlider } from "../components/Testimonials/TestimonialSlider";
import { TestimonialModel } from "../models/TestimonialModel";
import { getTestimonials } from "../services/HomeService";
import { TestimonialCard } from "../components/Testimonials/TestimonialCard";
import Slider from "react-slick";
import useErrorPage from "../hooks/useErrorPage";
import { useParams } from "react-router-dom";
import { Button } from "../components/Shared/Buttons/Button";
import { ReactComponent as LeftArrow } from '../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../assets/svg/right_arrow.svg';
import { TestimonialLoader } from "../components/Loaders/TestimonialLoader";
import { Helmet } from "react-helmet-async";

export const TestimonialsPage: React.FC = () => {  
    let params = useParams();  
    const styleContext = useContext(StyleContext);        
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("testimonialsPage")) 
    const [testimonialModel, setTestimonialModel] = useState<TestimonialModel>();
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const recentCount: number = 7;
    const topCount: number = isMobile ? 5 : 21;
    const [tabItems, setTabItems] = useState<TabItem[]>([]);
    const selectedTab = (params.tab === undefined) ? "recruitment" : params.tab;
    const sliderRef = useRef<Slider | null>(null);
    const [toErrorPage] = useErrorPage();

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    }

    const previousSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const sliderSettings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 1,
        speed: 1000,
        autoplay: false,      
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: 3,
        useTransform: false,
        appendDots: (dots: any) => (
            <div>
              <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };

    useEffect(() => {
        getTestimonialModel();
    }, []);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("testimonialsPage"));    
    }, [isMobile]);

    async function getTestimonialModel() {  
        setLoading(true);  
        await getTestimonials(recentCount, topCount)
        .then((testimonialList: TestimonialModel) => {           
            let tempTabItems: TabItem[] = [
                { label: "Recruiters", value: "recruitment", component: <TestimonialSlider styles={styles.slider} title={"Most Recent"} testimonials={testimonialList.recruiter.recent} /> },
                { label: "Candidates", value: "candidates", component: <TestimonialSlider styles={styles.slider} title={"Most Recent"} testimonials={testimonialList.candidate.recent} /> }
            ]; 
            setTabItems(tempTabItems);
            setTestimonialModel(testimonialList);
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    return (
        <>
            <Helmet><title>Testimonials - XpressJobs</title></Helmet>
            <Header styleSheet="lowHeader" heading={"Testimonials"} searchEnable={false}/>
            {loading ? <TestimonialLoader styles={styles}/> : <> 
                <div style={styles.rowWrapper}>
                    <div style={styles.imageWrapper}>
                        <img style={styles.image_2} src={process.env.PUBLIC_URL + "/img/testimonial_2.png"}/>
                    </div>
                    <Tabs styles={styles.tabs} path={"/testimonials/"} tabItems={tabItems} selectedTab={selectedTab}/>
                    <div style={styles.imageWrapper}>
                        <img style={styles.image_3} src={process.env.PUBLIC_URL + "/img/testimonial_3.png"}/>
                    </div>    
                </div>                
                <div style={styles.reviewContainer}>
                    <div style={styles.rowWrapper}>
                        <div style={styles.reviewTitle}>{"Top Reviews"}</div>
                        <div style={styles.sliderButtonWrapper}>
                            <Button style={styles.leftSliderButton} onClick={previousSlide}><LeftArrow style={styles.buttonIcon} /></Button>
                            <Button style={styles.rightSliderButton} onClick={nextSlide}><RightArrow style={styles.buttonIcon} /></Button>
                        </div>
                    </div>
                    <div style={styles.topReviewGride}>
                        {(selectedTab === "recruitment" ? testimonialModel?.recruiter : testimonialModel?.candidate)!.top.map((testimonial, index) =>
                            <TestimonialCard key={index} styles={styles.reviewCard} testimonial={testimonial}/>                
                        )}
                    </div>
                    <div style={styles.topReviewSlider}>                
                        <Slider ref={sliderRef} {...sliderSettings}>
                            {(selectedTab === "recruitment" ? testimonialModel?.recruiter : testimonialModel?.candidate)!.top.map((testimonial, index) =>
                                <div key={index}>
                                    <TestimonialCard styles={styles.reviewCard} testimonial={testimonial}/>                
                                </div>                            
                            )}
                        </Slider>
                    </div>                                
                </div>
            </>}
            <GetInTouch/>
            <Footer/>
        </>
    );
}