import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SingleSelect } from '../components/Shared/SingleSelect';
import { Footer } from '../components/Shared/Footer';
import { Header } from '../components/Shared/Header';
import { HorizontalBanner } from '../components/Shared/HorizontalBanner';
import { Pagination } from '../components/Shared/Pagination';
import { SearchCriteria } from '../models/SearchCriteria';
import { SelectInputs } from '../models/SelectInputs';
import { StyleContext } from '../providers/StyleContextProvider';
import { getOrganizationJobs } from '../services/JobService';
import { AuthContext } from '../providers/AuthContextProvider';
import { JobOrganizationCard } from '../components/Shared/JobOrganizationCard';
import { OrganizationJobsModel } from '../models/OrganizationJobsModel';
import { OrganizationPageTexts as Texts } from '../helpers/LayoutTexts';
import useErrorPage from '../hooks/useErrorPage';
import useSaveJob from '../hooks/useSaveJob';
import { Helmet } from 'react-helmet-async';
import { JobOrganizationCardLoader } from '../components/Loaders/JobOrganizationCardLoader';
import { TextLoader } from '../components/Loaders/TextLoader';
import { OrganizationLongJobCard } from '../components/OrganizationJobs/OrganizationLongJobCard';
import { OrganizationJobsLoader } from '../components/Loaders/OrganizationJobsLoader';
import { LoggedCandidate } from '../models/LoggedCandidate';
import { FeedbackCard } from '../components/Shared/FeedbackCard';

const sortBySelectInputs: SelectInputs = {
    default: "Recent",
    options: [
        { "value": "NEWID()", "option": "Default"},
        { "value": "SortedCreateDate DESC", "option": "Recent"},
        { "value": "SortedCreateDate ASC", "option": "Old"}
    ]
}

export const OrganizationJobsPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams(); 
    const params = useParams();
    const styleContext = useContext(StyleContext);    
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("organizationJobsPage"));
    const [loading, setLoading] = useState<boolean>(true);
    const [organizationJobs, setOrganizationJobs] = useState<OrganizationJobsModel>();  
    const [isBannerOffed, setIsBannerOffed] = useState<boolean>(false);  
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!; 
    const [totalPages, setTotalPages] = useState<number>(); 
    const [recordCount, setRecordCount] = useState<number>();    
    const pageSize: number = searchParams.get("pageSize") === null ? 20 : +searchParams.get("pageSize")!;
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [toErrorPage] = useErrorPage();
    const {saveCandidateJob} = useSaveJob();
    const [sortBy, setSortBy] = useState<string>("SortedCreateDate DESC");
    const [pageTitle, setPageTitle] = useState("Jobs at");
    const scrollElementRef = useRef<any>();

    const perPageSelectInputs: SelectInputs = {
        default: pageSize.toString(),
        options: [
            { "value": 10, "option": "10"},
            { "value": 20, "option": "20"},
            { "value": 50, "option": "50"},
            { "value": 100, "option": "100"}
        ]
    }

    useEffect(() => {         
        setStyles(styleContext.getComponentStyle("organizationJobsPage"));
    }, [isMobile]);

    useEffect(() => {                                
        getJobList();
    }, [page, pageSize, sortBy]);
    
    async function getJobList() {        
        setLoading(true);
        let searchCriteria: SearchCriteria = {
            page: page,
            pageSize: pageSize,
            sortBy: sortBy,
            organizationId: +params.organizationId!
        }
        await getOrganizationJobs(searchCriteria)
        .then((organizationJobsList: OrganizationJobsModel) => {
            setOrganizationJobs(organizationJobsList); 
            setIsBannerOffed(organizationJobsList!.organization.isBannerOffed);
            if(organizationJobsList.jobs.length > 0) {
                let totalPageCount = Math.ceil(organizationJobsList.jobs[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
                setRecordCount(organizationJobsList.jobs[0].recordCount);
                setPageTitle("Jobs at " + organizationJobsList.organization.organizationName);
            } else {
                setTotalPages(1);
                setRecordCount(0);
            }
            setLoading(false);
        })  
        .catch((error) => {            
            toErrorPage(error);
        });  
    }  

    const handlePagination = (page: number) => {        
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);        
    }

    const setPageSize = (pageSize: number) => {
        searchParams.set("page", "1");
        searchParams.set("pageSize", pageSize.toString());
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }
    
    const saveJob = (jobId: number, isSave: boolean) => {
        saveCandidateJob(jobId, isSave);
    }

    return (
        <>
            <Header styleSheet="mediumHeader" searchEnable={false} backButtonEnable={true} backButtonText="Back"/>
            <div style={styles.baseWrapper}>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="description" content={pageTitle} />
                    <meta name="keywords" content={pageTitle} />
                    <meta name="og:title" content={pageTitle} />
                </Helmet>   
                <div style={styles.leftWrapper}>
                    {loading ? <JobOrganizationCardLoader /> : 
                    <JobOrganizationCard styles={styles.organizationCard} organization={organizationJobs!.organization} buttonText={isMobile ? "ADD FAVOURITE" : "FAVOURITE"} preventRedirection={true} />}
                </div>                
                <div style={styles.rightWrapper} ref={scrollElementRef}>  
                    <h1 style={styles.heading}>{pageTitle}</h1>                                   
                    <div style={styles.wrapper}>
                        <div style={styles.title}>
                            {loading ? <TextLoader styles={styles.titleLoader} /> : 
                            <><span style={styles.title.count}>{recordCount}</span>Job Results</>}
                        </div>                        
                        <div style={styles.selectWrapper}>
                            <SingleSelect styles={styles.sortBySelect} label={"Sort by"} selectInputs={sortBySelectInputs} getSelected={setSortBy} />
                            <SingleSelect styles={styles.perPageSelect} label={"Posts Per Page"} selectInputs={perPageSelectInputs} getSelected={setPageSize} />
                        </div>                    
                    </div>                                     
                    {loading ? <OrganizationJobsLoader /> : (
                    <div style={styles.jobsWrapper}>
                        {organizationJobs?.jobs.length === 0 ? (
                            <FeedbackCard styles={styles.noResults} message={"There are no any results for your searches"} />
                        ) : (organizationJobs!.jobs.map((job, index) => (
                                <div key={index}>
                                    <OrganizationLongJobCard
                                        styles={styles.card}
                                        job={job}
                                        saveJob={saveJob}
                                        isLastItem={organizationJobs?.jobs.length! - 1 === index}
                                    />
                                </div>)))}
                    </div>)}
                    {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination} extendedStyles={styles.pagination} />}
                </div>
            </div>
            {!isBannerOffed && <div style={styles.horizontalBanner}>
                <HorizontalBanner adsenseClient={Texts.adClient} adsenseSlotOne={Texts.horizontalAdSlotOne} adsenseSlotTwo={Texts.horizontalAdSlotTwo} />
            </div>}
            <Footer/>          
        </>
    );
}