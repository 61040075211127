import './App.css';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import { StyleContextProvider } from './providers/StyleContextProvider';
import { AuthContextProvider } from './providers/AuthContextProvider';
import { CandidateHomePage } from './pages/CandidateHomePage';
import { HomePage } from './pages/HomePage';
import { JobViewPage } from './pages/JobViewPage';
import { SearchPage } from './pages/SearchPage';
import { CandidateLoginRegisterPage } from './pages/CandidateLoginRegisterPage';
import { JobApplyPage } from './pages/JobApplyPage';
import { CVLessJobApplyPage } from './pages/CVLessJobApplyPage';
import { EmailManager } from './pages/EmailManagerPage';
import { Login } from './pages/LoginPage';
import { CandidateProfilePage } from './pages/CandidateProfilePage';
import { CandidateSettingsPage } from './pages/CandidateSettingsPage';
import { OrganizationJobsPage } from './pages/OrganizationJobsPage';
import { useEffect } from 'react';
import { CVFormatsPage } from './pages/CVFormatsPage';
import { PressReleasesPage } from './pages/PressReleasesPage';
import { DisabilityFriendlyPage } from './pages/DisabilityFriendlyPage';
import { CandidatePremiumPage } from './pages/CandidatePremiumPage';
import { SMSJobAlertsPage } from './pages/SMSJobAlertsPage';
import { TestimonialsPage } from './pages/TestimonialsPage';
import { ContactPage } from './pages/ContactPage';
import { ErrorPage } from './pages/ErrorPage';
import { ErrorBoundary } from 'react-error-boundary';
import { PreferredPartnersPage } from './pages/PreferredPartnersPage';
import { TermsConditionsPage } from './pages/TermsConditionsPage';
import { CandidateCheckoutPage } from './pages/CandidateCheckoutPage';
import { CheckoutResponsePage } from './pages/CheckoutResponsePage';
import { HelmetProvider } from 'react-helmet-async';
import { QuizPage } from './pages/QuizPage';
import { EmailConfirmationPage } from './pages/EmailConfirmationPage';
import { RedirectBySector } from './components/Search/RedirectBySector';
import { RedirectByLogin } from './components/CandidateLogin/RedirectByLogin';
import { ScrollToTop } from './components/Shared/ScrollToTop';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { MetaPixel } from './components/Shared/MetaPixel';

const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "candidate/home", element: <CandidateHomePage /> },
    { path: "jobs/view/:jobId/:seo?", element: <JobViewPage /> },
    { path: "jobs/:type?", element: <SearchPage /> },
    { path: "jobs/sector/:sectorName", element: <RedirectBySector /> },
    { path: "candidates/:stage", element: <CandidateLoginRegisterPage /> },  
    { path: "candidate", element: <RedirectByLogin /> },
    { path: "jobs/apply/:jobId", element: <JobApplyPage /> },
    { path: "jobs/preapplicationform/:jobId", element: <CVLessJobApplyPage /> },
    { path: "emailmanager", element: <EmailManager /> },
    { path: "login", element: <Login /> },    
    { path: "candidate/:tab", element: <CandidateProfilePage /> },
    { path: "candidateSettings/:tab", element: <CandidateSettingsPage /> },
    { path: "organization/:organizationId/:seo?", element: <OrganizationJobsPage /> },
    { path: "cvFormats", element: <CVFormatsPage /> },
    { path: "press", element: <PressReleasesPage /> },
    { path: "home/differentlyabledfriendlyemployment", element: <DisabilityFriendlyPage /> },
    { path: "candidate/premium", element: <CandidatePremiumPage /> },
    // { path: "smsRegister", element: <SMSJobAlertsPage /> },
    { path: "testimonials/:tab?", element: <TestimonialsPage /> },
    { path: "contact", element: <ContactPage /> },
    { path: "home/preferredPartners", element: <PreferredPartnersPage /> },
    { path: "termsconditions", element: <TermsConditionsPage /> },
    { path: "privacyPolicy", element: <PrivacyPolicyPage/>},
    { path: "candidateCheckout", element: <CandidateCheckoutPage /> },
    { path: "checkoutResponse", element: <CheckoutResponsePage /> },
    { path: "quiz", element: <QuizPage/> },
    { path: "candidate/confirmEmail", element: <EmailConfirmationPage/> },
    { path: "error/errorindex", element: <ErrorPage /> },
    { path: "*", element: <HomePage /> }
  ]);
  return routes;
};

function App() {
  //This is to reset the viewport height when the soft keyboard pushes the UI up in android devices
  const initialHeight = window.innerHeight;

  useEffect(() => {
    window.addEventListener('resize', ResetViewportContent);
    return () => {
      // remove resize listener
      window.removeEventListener('resize', ResetViewportContent);
    }
  });
  
  function ResetViewportContent() {
    const metaViewport = document.querySelector("meta[name=viewport]");
    metaViewport!.setAttribute("content", "height=" + initialHeight + ", width=device-width, initial-scale=1.0");
  }

  function ErrorFallback() {
    return (<ErrorPage/>);
  }

  return (
    <StyleContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <HelmetProvider>
              <MetaPixel />
              <ScrollToTop/>
              <AppRoutes/>
            </HelmetProvider>
          </ErrorBoundary>          
        </BrowserRouter>
      </AuthContextProvider>      
    </StyleContextProvider>    
  );
}

export default App;
