import React, { useEffect, useState } from "react";

interface Props{
    styles?: any;
    className?: any;
}

export const Transition: React.FC<Props> = (props) => {
    const [isMounted, setIsMounted] = useState(false);
    
    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <div style={props.styles} className={props.className + " " +(isMounted ? "transition-enter-active" : "transition-enter")}>
            {props.children}
        </div>
    );
};
