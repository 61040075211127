import axios from "axios";
import { EmailManagerModel } from "../models/EmailManager";
import { getHost } from "../helpers/Common";

const apiBaseUrl = getHost() + "api/";

export async function postFormData(bodyFormData: EmailManagerModel) {
    return new Promise<any>((resolve) => {
        axios({
            method: "post",
            url: apiBaseUrl + "emailmanager/sendBatchEmailsToSQS",
            data: bodyFormData,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function () {
                resolve(false);
            });
    });
}