import React, { useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { TextInput } from '../Shared/Inputs/TextInput';
import { postLostPasswordRequest } from '../../services/CandidateService';
import { useNavigate } from 'react-router-dom';
import { ResponseModel } from '../../models/ResponseModel';
import useErrorPage from '../../hooks/useErrorPage';
import { validateEmail } from '../../helpers/Common';
import { InputValidation } from '../../models/InputValidation';
import { LostPasswordLoader } from '../Loaders/LostPasswordLoader';

interface Props {
    styles: any;
    openAlert(status: string, message: string): void;
}

export const LostPassword: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [validation, setValidation] = useState<InputValidation>();
    const [loading, setLoading] = useState<boolean>(false);
    const [toErrorPage] = useErrorPage();
    const styles = props.styles;

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;   
        validateInput(value);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value)
        if(validation?.invalid){
            validateInput(value);
        }        
    }

    async function submitEmail(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();        
        if(validation?.valid) {
            setLoading(true);
            await postLostPasswordRequest(email)
            .then((response: ResponseModel) => {
                setLoading(false);
                if(response.status) {            
                    navigate('/candidates/login');
                    props.openAlert("success", response.message);
                }
                else {
                    props.openAlert("error", response.message);
                }
            }) 
            .catch((error) => {            
                toErrorPage(error);
            });
        } else {
            validateInput(email)
        }
    }

    const validateInput = (value: string) => {
        if (value === "") {    
            setValidation({ valid: false, invalid: true, invalidMessage: "Email is required!" });
        } else if (!validateEmail(value)) {   
            setValidation({ valid: false, invalid: true, invalidMessage: "Invalid email address!"});
        } else {
            setValidation({ valid: true, invalid: false });
        }     
    }
    
    return (
        <>
        {loading ? <LostPasswordLoader/> : 
        <form style={{...styles.passwordForm, ...styles.passwordForm.lost}} onSubmit={submitEmail}>
            <h5 style={styles.title}>Lost Your Password?</h5>
            <p style={styles.subTitle}>Please enter your email address.</p>
            <TextInput
                label={'Email *'}
                inputType={'text'}
                name={"email"}
                value={email}
                handleChange={handleInputChange}
                validation={validation}
                handleBlur={handleInputBlur}
            />
            <Button type="submit" style={{...styles.submitButton, ...styles.resetButton}}>{"SEND ME THE LINK"}</Button>
        </form>}
        </>
    )
}