import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { SingleSelect } from "../components/Shared/SingleSelect";
import { SelectInputs } from "../models/SelectInputs";
import { Pagination } from "../components/Shared/Pagination";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { getPressReleases } from "../services/HomeService";
import { PressRelease } from "../models/PressRelease";
import useErrorPage from "../hooks/useErrorPage";
import { PressReleaseLoader } from "../components/Loaders/PressReleaseLoader";
import { Helmet } from "react-helmet-async";

const sortBySelectInputs: SelectInputs = {
    default: 'Recent',
    options: [
        { "value": "NEWID()", "option": "Default"},
        { "value": "PublishedDate DESC", "option": "Recent"},
        { "value": "PublishedDate ASC", "option": "Old"}
    ]
}

export const PressReleasesPage: React.FC = () => {
    const [searchParams] = useSearchParams(); 
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("pressReleasesPage"))
    const [pressReleases, setPressReleases] = useState<PressRelease[]>([]);
    const [sortBy, setSortBy] = useState<string>("PublishedDate DESC");          
    const [totalPages, setTotalPages] = useState<number>();
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const pageSize: number = searchParams.get("pageSize") === null ? 15 : +searchParams.get("pageSize")!;       
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [loading, setLoading] = useState<boolean>(true);
    const [toErrorPage] = useErrorPage();
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    const scrollElementRef = useRef<any>();

    const perPageSelectInputs: SelectInputs = {
        default: pageSize.toString(),
        options: [            
            { "value": 15, "option": "15"},
            { "value": 30, "option": "30"},
            { "value": 60, "option": "60"}
        ]
    }

    useEffect(() => {
        getPressReleasesList();
    }, [sortBy, pageSize, page]);

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("pressReleasesPage"));    
    }, [isMobile]);

    async function getPressReleasesList() {
        setLoading(true);
        await getPressReleases(sortBy, pageSize, page)
        .then((pressReleaseList: PressRelease[]) => {
            setPressReleases(pressReleaseList);        
            if(pressReleaseList.length > 0) {
                let totalPageCount = Math.ceil(pressReleaseList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
            } else {
                setTotalPages(1);            
            }
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }
    
    const handlePagination = (page: number) => {
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);        
    } 

    const setPageSize = (pageSize: number) => {
        searchParams.set("page", "1");
        searchParams.set("pageSize", pageSize.toString());
        const newUrl = `?${searchParams}`;
        navigate(newUrl);
    }

    const readArticle = (articleUrl: string) => {        
        window.open(articleUrl, '_blank');        
    }
       
    return (
        <>
            <Helmet><title>Press Release - XpressJobs</title></Helmet>
            <Header styleSheet="lowHeader" searchEnable={false} heading={"Press Releases"}/>                       
            <div style={styles.container} ref={scrollElementRef}>                
                <div style={styles.selectWrapper}>                                          
                    <SingleSelect styles={styles.sortSelect} label={"Sort by"} selectInputs={sortBySelectInputs} getSelected={setSortBy} />
                    <SingleSelect styles={styles.postSelect} label={"Posts Per Page"} selectInputs={perPageSelectInputs} getSelected={setPageSize} />
                </div>
                {loading ? <PressReleaseLoader/> : <>
                    <div style={styles.grid}>
                        {pressReleases.map((pressRelease, index) =>                                 
                            <div key={index} style={{...styles.cardWrapper, ...(index === hoveredIndex && styles.cardWrapper.hovered)}} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(-1)} onClick={() => readArticle(pressRelease.pressReleaseUrl)}>                                
                                <img style={styles.image} src={pressRelease.imageUrl}></img>                            
                                <div style={styles.detailWrapper}>
                                    <p style={styles.source}>{"Published on "}<span style={styles.source.bold}>{pressRelease.sourceName + " | " + (new Date(pressRelease.publishedDate).toLocaleDateString("en-GB", {day: "numeric", month: "long", year: "numeric"}))}</span></p>
                                    <p style={styles.title}>{pressRelease.title}</p>
                                    <p style={styles.description}>{pressRelease.description}</p>
                                    <p style={styles.readMore}>{"Read Article"}</p>                                
                                </div>                            
                            </div>                                
                        )}
                    </div>
                    {(totalPages! > 1) && <Pagination page={page} totalPages={totalPages!} handlePagination={handlePagination}/>}
                </>}                                       
            </div>                   
            <GetInTouch/>
            <Footer/>
        </>
    );
}