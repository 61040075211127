import Axios from "axios";
import { buildWebStorage, setupCache } from 'axios-cache-interceptor';
import { ContactModel } from "../models/ContactModel";
import { getHost } from "../helpers/Common";

const instance = Axios.create();
const axios = setupCache(instance, { storage: buildWebStorage(localStorage, 'axios-cache:') });
const apiBaseUrl = getHost() + "api/";

export async function getLocations() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/locations", {
            id: 'list-locations',
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 365 // 1 year.
            }
        })
        .then((response) => {
            response.cached = false;
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getProvinces() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/provinces", {
            id: 'list-provinces',
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 365 // 1 year.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getFeaturedJobs(jobCount: number, sortBy: string, preferredSectorIds?: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "jobs/featuredJobs", {
            params: {
                sortBy: sortBy,
                recordsCount: jobCount,
                preferredSectorIds: preferredSectorIds
            },
            cache: {
                ttl: 1000 * 60 * 60 * 24 // 1 day.
            }           
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getSectors(recordsCount: number, sortBy: string) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/sectorCount", {
            params: {
                recordsCount: recordsCount,
                sortBy: sortBy,
                byCVLess: false,
                byWalkIn: false
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTopHiringOrganizations(recordsCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/topHiringOrganizations", {
            params: {
                recordsCount: recordsCount
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 // 1 day.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getStatistics() {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/stats")
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getFeaturedOrganizations(recordsCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/featuredOrganizations", {
            params: {
                recordsCount: recordsCount
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 // 1 day.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getPressReleases(sortBy: string, pageSize: number, page: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/pressReleases", {            
            params: {   
                sortBy: sortBy,                
                pageSize: pageSize,
                page: page
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getDisabilityFriendlyOrganizations(recordsCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/disabilityFriendly", {            
            params: {   
                recordsCount: recordsCount
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getTestimonials(recentCount: number, topCount: number) {
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/testimonials", {            
            params: {
                recentCount: recentCount,
                topCount: topCount
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postContactRequest(contactModel: ContactModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "home/contact", contactModel, {
            headers: {
              'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getBanners(keyword: String) {    
    return new Promise<any>((resolve, reject) => {
        axios
        .get(apiBaseUrl + "home/banners", {            
            params: {   
                keyword: keyword
            },            
            cache: {
                ttl: 1000 * 60 * 60 * 24 // 1 day.
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function updateBannerClickCount(bannerId: number) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .put(apiBaseUrl + "home/bannerCount", "bannerId=" + bannerId )
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function getPartners() {
    return new Promise<any>((resolve,reject) => {
        axios
        .get(apiBaseUrl + "home/partners", {
            id: 'list-partners',
            cache: {
                ttl: 1000 * 60 * 60 * 24 * 30 // 30 days.
            }
        })
        .then((respone) => {
            resolve(respone.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}