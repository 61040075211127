import { Button } from "./Buttons/Button";
import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg';
import { ReactComponent as ShareIcon } from '../../assets/svg/share.svg';
import { ReactComponent as LinkedIn } from '../../assets/svg/linkedIn-2.svg';
import { useMediaQuery } from "react-responsive";
import { getHost, getSEOKeyword } from "../../helpers/Common";

export interface ShareProps {
    styles: any;
    jobId: number;
    jobTitle: string;
    organizationName: string;
}

export const Share: React.FC<ShareProps> = (props) => {
    const styles = props.styles;
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const baseURL = getHost();
    let jobUrl = baseURL + "jobs/view/" + props.jobId + "/" + getSEOKeyword(props.jobTitle + "-" + props.organizationName);
    let encodedJobUrl = encodeURIComponent(jobUrl);
    let encodedJobTitle = encodeURIComponent(props.jobTitle);

    const handleShareButtonClick = async (event: any) => {
        event.stopPropagation();
        var jobDetails: ShareData = {
            url: jobUrl,
            title: props.jobTitle,
            text: props.jobTitle
        }
        await navigator.share(jobDetails)
    }
    
    const handleWhatsappShareButton = (event: any) => {
        event.stopPropagation();
        var whatsappUrl = (isMobile ? "whatsapp://" : "https://web.whatsapp.com/") + "send?text=" + props.jobTitle + " Apply through: " + jobUrl;
        window.open(whatsappUrl, '_blank');
    }

    const handleFacebookShareButton = (event: any) => {
        event.stopPropagation();
        var facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedJobUrl}`;
        window.open(facebookUrl, '_blank');
    }

    const handleTwitterShareButton = (event: any) => {
        event.stopPropagation();
        var twitterUrl = `https://twitter.com/intent/tweet?url=${encodedJobUrl}&text=${encodedJobTitle}`;
        window.open(twitterUrl, '_blank');
    }

    const handleLinkedinShareButton = (event: any) => {
        event.stopPropagation();
        var linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedJobUrl}`;
        window.open(linkedinUrl, '_blank');
    }

    return (
        <div style={styles}>
            <Button type="button" style={styles.button} onClick={handleShareButtonClick}>
                <ShareIcon style={{...styles.icon, ...styles.icon.share}} />
            </Button>
            <Button type="button" style={styles.button} onClick={handleWhatsappShareButton}>
                <Whatsapp style={{...styles.icon, ...styles.icon.whatsapp}} />
            </Button>            
            <Button type="button" style={styles.button} onClick={handleFacebookShareButton}>
                <Facebook style={{...styles.icon, ...styles.icon.facebook}} />
            </Button>            
            <Button type="button" style={styles.button} onClick={handleShareButtonClick}>
                <Instagram style={{...styles.icon, ...styles.icon.instagram}} />
            </Button>            
            <Button type="button" style={styles.button} onClick={handleTwitterShareButton}>
                <Twitter style={{...styles.icon, ...styles.icon.twitter}} />
            </Button>                        
            <Button type="button" style={styles.button} onClick={handleLinkedinShareButton}>
                <LinkedIn style={{...styles.icon, ...styles.icon.linkedIn}} />
            </Button>            
        </div>
    );
}