import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const searchHistory = {
    mobile: {
        container: {
            position: "relative",
            width: "85%",
            margin: "-3.02vw auto 13.88vw",
            backgroundColor: Color.Cultured,
            borderRadius: "0px 0px 2.41vw 2.41vw",
            transition: "all .2s ease-in-out",
            padding: "0 2vw",
            height: "0",
            expand: {
                padding: "4.83vw 2.41vw 2.41vw 2.41vw",
                margin: "-2.41vw auto 10.87vw",
                height: "67.63vw",
            }
        },
        wrapper: {
            height: "100%",
            overflowX: "hidden"
        },
        card: {            
            padding: "2.41vw 0",
            borderBottom: "1px solid " + Color.MiddleAsh,
            WebkitTapHighlightColor: "transparent",
            hover: {
                backgroundColor: Color.White,
                borderBottom: "1px solid " + Color.White,
            }
        },       
        contentWrapper: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        detailWrapper: {
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2.66vw",
            width: "fit-content",
            alignItems: "center"            
        },
        title: {
            margin: "1.69vw 0 0 0",
            fontSize: "3.86vw",
            color: Color.LightBlack,
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: 'hidden',
            maxWidth: "100%"
        },
        text: {
            display: "block",
            fontSize: "2.89vw",
            whiteSpace: "nowrap",            
            marginRight: "3.62vw",
            overflow: "hidden",
            float: "left",
            color: Color.MiddleBlack
        },
        locationIcon: {
            width: "3.38vw",
            height: "2.78vw",
            fill: Color.MiddleBlack,
            marginRight: "1.2vw"
        },
        icon: {
            width: "3.38vw",
            height: "3.38vw",
            fill: Color.MiddleBlack,
            marginRight: "1.2vw",            
            float: "left"
        },
        actionButtons: {
            display: "flex",
            marginLeft: "auto",
            alignItems: "center"            
        },
        redirectButton: {
            backgroundColor: Color.Blue,
            width: "10.86vw",
            height: "7.72vw",
            borderRadius: "1.2vw",
            cursor: "pointer",            
            marginLeft: "2.41vw",
        },
        redirectIcon: {
            width: "100%"
        },
        iconsWrapper: {
            marginLeft: "1.46vw",
            display: "flex"
        },
        notificationButton: {
            backgroundColor: Color.DarkAsh,
            borderRadius: "50%",
            width: "6.28vw",
            height: "6.28vw",
            fill: Color.DeepAsh,
            marginRight: "1.33vw",
            cursor: "pointer",
            hover: {
                backgroundColor: Color.LightGreen,
                fill: Color.Green,
            }
        },
        notificationIcon: {
            width: "80%",
            height: "80%",
            verticalAlign: "middle"
        },
        binButton: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            width: "6.28vw",
            height: "6.28vw",
            cursor: "pointer",
            backgroundColor: Color.DarkAsh,
            fill: Color.DeepAsh,
            padding: "0",
            hover: {
                backgroundColor: Color.LightRed,
                fill: Color.Red,
            }
        },
        binIcon: {
            width: "2.90vw",
            height: "3.14vw"            
        },
        closeIcon: {
            position: "absolute",
            right: "0",
            left: "0",
            margin: "auto",
            width: "3vw",
            height: "3vw",
            backgroundColor: Color.LightGray,
            padding: "2vw",
            borderRadius: "50%",
            cursor: "pointer",
            fill: Color.MiddleBlack,
            zIndex: "2",
            WebkitTapHighlightColor: "transparent",
            transition: "transform .2s ease-in-out",
            close: {
                transform: "rotate(180deg)"
            }
        },
        noResults: {
            wrapper: {
                borderRadius: "2.77vw",
                border: "1px solid " + Color.HarvestGold,
                backgroundColor: Color.LightGold,
                display: "flex",
                alignItems: "center" as "center",
                justifyContent: "center" as "center",
                padding: "2.41vh",
                color: Color.HarvestGold,
                fontSize: "4.5vw",
                fontFamily: Font.Poppins,
                margin: "21.73vw auto 0 auto"
            },
            icon: {
                marginRight: "5.43vw",
                width: "8.33vw",
                height: "8.33vw"
            }
        },
        link: {
            color: Color.DeepBlack,
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    desktop: {
        container: {
            width: "67.92vw",
            padding: "0 0.3vw",
            margin: "-0.78vw auto 3.75vw",
            borderRadius: "0 0 0.52vw 0.52vw",
            expand: {
                padding: "0.78vw 0.52vw 0.78vw 1.04vw",
                margin: "0 auto 0",
                height: "14.89vw"
            }
        },
        card: {            
            padding: "0.52vw",
            alignItems: "center",
            hover: {
                borderRadius: "0.3vw"
            }
        },               
        detailWrapper: {
            marginTop: "unset",
            width: "unset",
            maxWidth: "70%",
            marginLeft: "unset"
        },
        title: {
            margin: "0 2.34vw 0 0",
            fontSize: "1.04vw"            
        },
        text: {
            fontSize: "0.78vw",
            marginRight: "1.30vw"            
        },
        locationIcon: {
            width: "0.88vw",
            height: "revert-layer",            
            marginRight: "0.52vw"
        },
        icon: {
            width: "0.8vw",
            height: "0.93vw",            
            marginRight: "0.52vw"
        },
        actionButtons: {            
            flexDirection: "row-reverse"
        },
        redirectButton: {
            width: "2.34vw",
            height: "1.66vw",
            padding: "0",
            borderRadius: "0.26vw",
            order: 0,
            marginLeft: 0,
            marginRight: "1.04vw",
            boxShadow: "0.15vw 0.15vw 0.52vw #00000027"
        },
        redirectIcon: {
            width: "1.05vw",
            height: "1.05vw",
            verticalAlign: "middle"
        },
        iconsWrapper: {
            marginLeft: "1.04vw",
            display: "flex"
        },
        notificationButton: {
            width: "1.66vw",
            minWidth: "23px",
            minHeight: "23px",
            height: "1.66vw",
            marginRight: "0.52vw"
        },
        binButton: {
            width: "1.66vw",
            height: "1.66vw"
        },
        binIcon: {
            width: "0.73vw",
            height: "0.81vw"
        },
        closeIcon: {
            width: "0.6vw",
            height: "0.6vw",
            padding: "0.5vw"
        },
        noResults: {
            wrapper: {
                width: "75%",
                borderRadius: "0.52vw",
                margin: "10vh auto 0 auto",
                padding: "1.56vw 0 1.56vw 0",
                fontSize: "0.937vw"
            },
            icon: {
                marginRight: "1.02vw",
                width: "1.58vw",
                height: "1.58vw"
            }
        },
    }
}