import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const errorHeader = {
    mobile: {
        row: {
            display: "flex",
            flexDirection: "row" as "row",
            position: "relative",
            zIndex: "3"
        },
        header: {
            background: Color.DeepBlack,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "12.14vh",
            position: "relative"
        },
        desktopImageLeft: {
            display: "none"
        },
        longBuildingSvg: {
            display: "none"
        },
        startCurve: {
            display: "none"
        },
        endCurve: {
            display: "none"
        },
        title: {
            paddingTop: "2%",
            paddingLeft: "4%",
            cursor: "pointer",
            opacity: "1",
            WebkitTapHighlightColor: "transparent",
            margin: "0",
            width: "39.37vw",
            height: "9.18vw"
        },
        label: {
            textDecoration: "none",
            fontSize: "5vw",
            fontWeight: "bold" as "bold",
            letterSpacing: "0px",
            color: Color.LightAsh,
            opacity: "1",
            cursor: "pointer",
            hover: {
                borderBottom: "0.48vw solid " + Color.DarkAsh
            }
        },
        logoutDropdownLabel: {
            color: Color.Red
        },
        logoutIcon: {
            float: "right"
        },
        logoutDropdownItem: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            hover: {
                fill: Color.White,
                backgroundColor: Color.Blue,
                padding: "1.38vw",
                borderRadius: "1.94vw",
                color: Color.White
            },
            leave: {
                fill: Color.Red,
                backgroundColor: Color.White,
                padding: "1.38vw"
            }
        },
        dropdownLabel: {
            position: "relative" as "relative",
            textDecoration: "none",
            fontSize: "4.4vw",
            fontWeight: "medium",
            letterSpacing: "0px",
            color: Color.MiddleBlack,
            cursor: "pointer",
            opacity: "1"
        },
        storeIcon: {
            width: "4.2vw",
            height: "4.2vw",
            paddingTop: "1vw",
            marginLeft: "0"
        },
        storeLabel: {
            fontSize: "4.34vw"
        },
        dropdownItem: {
            hover: {
                backgroundColor: Color.Blue,
                padding: "1.38vw",
                borderRadius: "1.94vw",
                color: Color.White
            },
            leave: {
                backgroundColor: Color.White,
                padding: "1.38vw"
            }
        },
        desktopMenu: {
            explore: {
                hover: {
                    width: "11.2vw"
                }
            },
            store: {
                hover: {
                    width: "8.8vw"
                }
            },
            storeButton: {
                hover: {
                    width: "8.8vw"
                }
            }
        },
        loginWrapper: {
            display: "contents"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "0.16vw solid " + Color.Green,
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Blue,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            marginLeft: "auto",
            marginRight: "4%",
            paddingRight: "0%",
            paddingTop: "2%"
        },
        notifyButton: {
            position: "relative" as "relative",
            width: "10vw",
            height: "10vw",
            backgroundColor: Color.White,
            border: "none",
            borderRadius: "50%",
            cursor: "pointer"
        },
        notifyIcon: {
            width: "4.44vw",
            height: "2.43vh",
            fill: Color.Blue
        },
        userWrapper: {
            marginLeft: "auto",
            marginTop: "3%"
        },
        userDropdown: {
            wrapper: {
                display: "none",
                position: "relative" as "relative",
                marginTop: "3vh",
                marginRight: "1.5vw"
            },
            button: {
                backgroundColor: "transparent",
                color: Color.LightAsh,
                fontSize: "1.17vw",
                fontWeight: 500,
                border: "none",
                cursor: "pointer"
            },
            item: {
                textAlign: "left"
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "row" as "row",
            },
            labelIcon: {
                width: "1.09vw",
                height: "1.09vw",
                fill: Color.LightAsh,
                marginLeft: "1.35vh",
                marginTop: "4vh",
                transition: "transform 0.1s linear",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            label: {
                textDecoration: "none",
                fontSize: "1.17vw",
                letterSpacing: "0px",
                color: Color.LightAsh,
                opacity: "1" 
            },
            leave: {
                display: "none"
            },
            hover: {
                display: "block",
                position: "absolute" as "absolute", 
                width: "22vh",
                height: "auto",
                padding: "0.6vw",
                left: "-4vw",
                backgroundColor: Color.White,
                boxShadow: "0 0.2vw 1.35vh rgb(0 0 0 / 0.5)",
                borderRadius: "1.35vh",
                zIndex: 3
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        },
        userAvatar: {
            height: "3.66vw",
            width: "3.66vw",
            border: "0.5vw solid " + Color.Green,
            borderRadius: "50%",
            cursor: "pointer"
        }
    },
    desktop: {  
        header: {
            height: "17.47vh"
        },    
        recruitersButton: {
            display: "inline"
        },
        jobSeekersButton: {
            display: "inline"
        },  
        title: {
            paddingTop: "4.9vh",
            paddingLeft: "1.5vw",
            width: "15.52vw",
            height: "3.65vw"
        },
        label: {
            fontSize: "1vw"
        },
        logoutIcon: {
            width: "0.67vw",
            height: "0.572vw"
        },
        logoutDropdownItem: {
            hover: {
                borderRadius: "0.4vw",
                padding: "0.36vw"
            },
            leave: {
                padding: "0.36vw"
            }
        },
        dropdownLabel: {
            fontSize: "1vw"
        },
        dropdownItem: {
            hover: {
                borderRadius: "0.4vw",
                padding: "0.36vw"
            },
            leave: {
                padding: "0.36vw"
            }
        },
        storeContainer: {
            display: "flex", 
            flexDirection: "row",
            justifyContent: "center", 
            alignItems: "center"
        },
        storeIcon: {
            width: "1.19vw",
            height: "1.19vw",
            paddingTop: "0"
        },
        storeLabel: {
           marginLeft: "0.52vw",
           fontSize: "1.04vw",
           marginRight: "0.1vw"
        },
        desktopMenu: {
            explore: {
                hover: {
                    width: "11.2vw"
                }
            },
            storeButton: {
                button: {
                    height: "3.64vw",
                    width: "11.09vw",
                    backgroundColor: Color.Green,
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                    marginTop: "-1vw",
                    borderRadius: "0.52vw"
                },
                hover: {
                    marginTop: "1vw",
                    width: "10vw"
                }
            }
        },
        notifyButton: {
            width: "2.9vw",
            height: "2.9vw",
            marginRight: "1.46vw",
            marginTop: "5vh"
        },
        notifyIcon: {
            width: "1.31vw",
            height: "1.31vw"
        },
        userWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginLeft: "unset",
            marginTop: "unset"
        },
        userDropdown: {
            wrapper: {
                display: "inline-block"
            }
        },
        mobileMenuButton: {
            display: "none"
        }
    }
}
