import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';

interface Props {
    styles: any;
}

export const JobViewLoader: React.FC<Props> = (props) => {
    let styles = props.styles;
    let logoStyles = styles.verticalDetailCard.logo;

    return (
        <div style={styles.baseWrapper}>
            <div style={styles.leftWrapper}>
                <div style={styles.mobileDetailCard}>
                    <div style={styles.verticalDetailCard}>
                        <ContentLoader
                            speed={1}
                            style={logoStyles}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                            width={logoStyles.height}
                            height={logoStyles.height}
                        >
                            <rect x="0" y="0" rx="3" ry="3" width={logoStyles.width} height={logoStyles.height} />
                        </ContentLoader>
                        <ContentLoader
                            speed={1}
                            height={styles.verticalDetailCard.height}
                            width={"100%"}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                        >
                            <rect x="5%" y="5%" rx="3" ry="3" width="70%" height="3%" />
                            <rect x="5%" y="10%" rx="3" ry="3" width="40%" height="3%" />
                            <rect x="5%" y="15%" rx="3" ry="3" width="60%" height="2%" />
                            <rect x="5%" y="18%" rx="3" ry="3" width="30%" height="1.8%" />
                            <rect x="5%" y="22%" rx="3" ry="3" width="50%" height="1.8%" />
                            <rect x="5%" y="25%" rx="3" ry="3" width="60%" height="1.7%" />
                            <rect x="5%" y="28%" rx="3" ry="3" width="90%" height="1.5%" />
                            <rect x="5%" y="40%" rx="3" ry="3" width="70%" height="1.4%" />
                            <rect x="5%" y="44%" rx="3" ry="3" width="30%" height="1.4%" />
                            <rect x="10%" y="50%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="50%" rx="3" ry="3" width="20%" height="1.8%" />
                            <rect x="10%" y="54%" rx="3" ry="3" width="20%" height="1.4%" />
                            <rect x="40%" y="54%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="10%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="20%" y="70%" rx="3" ry="3" width="60%" height="10%" />
                        </ContentLoader>
                    </div>
                </div>
                <div style={styles.description}>
                    <ContentLoader
                        speed={1}
                        width={"100%"}
                        height={700}
                        backgroundColor={Color.LoaderBackground}
                        foregroundColor={Color.LoaderForeground}
                    >
                        <rect x="0" y="23" rx="3" ry="3" width="50%" height="12" />
                        <rect x="0" y="42" rx="3" ry="3" width="28%" height="6" />
                        <rect x="0" y="67" rx="3" ry="3" width="18%" height="5" />
                        <rect x="0" y="76" rx="3" ry="3" width="22%" height="6" />
                        <rect x="0" y="86" rx="3" ry="3" width="32%" height="6" />
                        <rect x="0" y="108" rx="3" ry="3" width="40%" height="7" />
                        <rect x="0" y="124" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="135" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="146" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="155" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="165" rx="3" ry="3" width="68%" height="5" />
                        <rect x="0" y="187" rx="3" ry="3" width="57%" height="7" />
                        <rect x="0" y="204" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="214" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="222" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="233" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="243" rx="3" ry="3" width="68%" height="5" />
                        <rect x="0" y="266" rx="3" ry="3" width="57%" height="7" />
                        <rect x="0" y="279" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="293" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="303" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="313" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="322" rx="3" ry="3" width="68%" height="5" />
                        <rect x="0" y="338" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="349" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="360" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="371" rx="3" ry="3" width="100%" height="5" />
                        <rect x="0" y="381" rx="3" ry="3" width="68%" height="5" />
                        <rect x="0" y="391" rx="3" ry="3" width="57%" height="7" />
                        <rect x="0" y="412" rx="3" ry="3" width="100%" height="300" />
                    </ContentLoader>
                </div>
            </div>
            <div style={styles.rightWrapper}>
                <div style={styles.desktopDetailCard}>
                    <div style={styles.verticalDetailCard}>
                        <ContentLoader
                            speed={1}
                            style={logoStyles}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                            width={logoStyles.height}
                            height={logoStyles.height}
                        >
                            <rect x="5%" y="0" rx={logoStyles.borderRadius} ry={logoStyles.borderRadius} width={logoStyles.width} height={logoStyles.height} />
                        </ContentLoader>
                        <ContentLoader
                            speed={1}
                            height={styles.verticalDetailCard.height}
                            width={"100%"}
                            backgroundColor={Color.LoaderBackground}
                            foregroundColor={Color.LoaderForeground}
                        >
                            <rect x="5%" y="5%" rx="3" ry="3" width="70%" height="3%" />
                            <rect x="5%" y="10%" rx="3" ry="3" width="40%" height="3%" />
                            <rect x="5%" y="15%" rx="3" ry="3" width="60%" height="2%" />
                            <rect x="5%" y="18%" rx="3" ry="3" width="30%" height="1.8%" />
                            <rect x="5%" y="22%" rx="3" ry="3" width="50%" height="1.8%" />
                            <rect x="5%" y="25%" rx="3" ry="3" width="60%" height="1.7%" />
                            <rect x="5%" y="28%" rx="3" ry="3" width="90%" height="1.5%" />
                            <rect x="5%" y="40%" rx="3" ry="3" width="70%" height="1.4%" />
                            <rect x="5%" y="44%" rx="3" ry="3" width="30%" height="1.4%" />
                            <rect x="10%" y="50%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="50%" rx="3" ry="3" width="20%" height="1.8%" />
                            <rect x="10%" y="54%" rx="3" ry="3" width="20%" height="1.4%" />
                            <rect x="40%" y="54%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="10%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="40%" y="58%" rx="3" ry="3" width="20%" height="1.5%" />
                            <rect x="20%" y="70%" rx="3" ry="3" width="60%" height="10%" />
                        </ContentLoader>
                    </div>
                </div>
                <div style={styles.organizationCard}>
                    <ContentLoader
                        speed={1}
                        height={150}
                        width={"100%"}
                        backgroundColor={Color.LoaderBackground}
                        foregroundColor={Color.LoaderForeground}
                    >
                        <rect x="30%" y="45" rx="3" ry="3" width="60%" height="10" />
                        <rect x="30%" y="62" rx="3" ry="3" width="50%" height="20" />
                        <rect x="5%" y="101" rx="3" ry="3" width="20%" height="10" />
                        <rect x="5%" y="130" rx="3" ry="3" width="70%" height="20" />
                        <rect x="5%" y="27" rx="3" ry="3" width="58" height="58" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
}