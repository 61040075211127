import React, { useContext, useEffect, useRef, useState } from 'react';
import { getCandidateSavedJobs } from '../../services/JobService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../../components/Shared/Buttons/Button';
import { CandidateHomeTexts as Texts } from '../../helpers/LayoutTexts';
import { LongJobCard } from '../../components/Shared/LongJobCard';
import { SingleSelect } from '../../components/Shared/SingleSelect';
import { Job } from '../../models/Job';
import { SelectInputs } from '../../models/SelectInputs';
import { Pagination } from './Pagination';
import { postCandidateSaveJob } from '../../services/CandidateService';
import useErrorPage from '../../hooks/useErrorPage';
import { ReactComponent as RightArrow} from '../../assets/svg/right_arrow.svg';
import { FeedbackCard } from './FeedbackCard';
import { SavedJobsloader } from '../Loaders/SavedJobsLoader';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {    
    candidate: LoggedCandidate;
    title?: string;    
    viewAllEnable?: boolean;
    loadMoreEnable?: boolean;
    setHasJobs?(value: boolean): void;
}

const sortBySelectInputs: SelectInputs = {
    default: 'Recent',
    options: [
        { "value": "NEWID()", "option": "Default"},
        { "value": "JobId DESC", "option": "Recent"},
        { "value": "JobId ASC", "option": "Old"}
    ]
}

export const CandidateSavedJobs: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams(); 
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateSavedJobs"));
    const [loading, setLoading] = useState<boolean>(true);    
    const [savedJobs, setSavedJobs] = useState<Job[]>([]);
    const [sortBy, setSortBy] = useState<string>("JobId DESC");
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });       
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!; 
    const pageSize: number = searchParams.get("pageSize") === null ? 5 : +searchParams.get("pageSize")!; 
    const [totalPages, setTotalPages] = useState<number>(1); 
    const [recordCount, setRecordCount] = useState<number>(0);    
    const [toErrorPage] = useErrorPage();
    const scrollElementRef = useRef<any>();
    
    useEffect(() => {            
        setStyles(styleContext.getComponentStyle("candidateSavedJobs"));
    }, [isMobile]);

    useEffect(() => {                                                                                          
        getSavedJobList();                              
    }, [page, pageSize, sortBy]);

    async function getSavedJobList() {        
        setLoading(true);
        await getCandidateSavedJobs(props.candidate.candidateId, page, pageSize, sortBy, props.candidate.token)
        .then((savedJobList: Job[]) => {
            setSavedJobs(savedJobList);
            if(savedJobList.length > 0) {
                let totalPageCount = Math.ceil(savedJobList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
                setRecordCount(savedJobList[0].recordCount);
            } else {
                setTotalPages(1);
                setRecordCount(0);
                props.setHasJobs && props.setHasJobs(false);            
            }                                            
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });      
    }  

    const handlePagination = (page: number) => {        
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
        scrollElementRef.current.scrollIntoView();        
    }

    const loadMore = () => {    
        searchParams.set("pageSize", "20");
        const newUrl = `?${searchParams}`;
        navigate(newUrl);        
    }

    async function removeSavedJob (jobId: number) {
        await postCandidateSaveJob(props.candidate.candidateId, jobId, true, props.candidate.token)        
        .then((savedJobIds: number[]) => {
            const savedJobIdString = JSON.stringify(savedJobIds);            
            localStorage.setItem('savedJobIds', savedJobIdString);
            getSavedJobList();
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    return (        
        <>
            <div style={styles.wrapper} ref={scrollElementRef}>
                <div style={styles.title}>
                    <div>{props.title}</div>
                    <div style={styles.title.recordText}><span style={styles.title.RecordCount}>{recordCount}</span>{Texts.jobPosts}</div>
                </div>
                <SingleSelect styles={styles.sortBySelect} label={Texts.sortBy} selectInputs={sortBySelectInputs} getSelected={setSortBy} />
            </div>
            {loading ? <SavedJobsloader /> : 
            <>
                {savedJobs.length !== 0 ? <>
                    {savedJobs.map((savedJob, index) =>
                        <div key={index}>
                            <LongJobCard styles={styles.card} job={savedJob} saveJob={removeSavedJob} isLastItem={index === savedJobs.length - 1} />
                        </div>
                    )}
                    {(pageSize > 5 && totalPages > 1) && <Pagination page={page} totalPages={totalPages} handlePagination={handlePagination} />}
                    {props.viewAllEnable && <Button style={styles.viewAll} onClick={() => navigate('/candidate/managesavedjobs')}>
                        {isMobile ? <>{Texts.viewAllLower} <RightArrow style={styles.viewAll.icon} /></> : Texts.viewAllUpper}
                    </Button>}
                    {(props.loadMoreEnable && pageSize === 5 && recordCount > 5) && <Button style={styles.loadMore} onClick={loadMore}>
                        {isMobile ? Texts.loadMoreLower : Texts.loadMoreUpper}
                    </Button>}
                </>
                : <FeedbackCard styles={styles.noResults} message={"No Saved Jobs found!"} />}
            </>}
        </>
    );
}