import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const loaderStyles = {
    mobile: {
        wrapper: {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: "2.68vh 2.42vw",
            marginTop: "3.46vh"
        },
        card: {
            display: "inline-block",
            width: "100%",
            height: "30.24vh"
        }
    },
    desktop: {
        wrapper: {
            gridTemplateColumns: "repeat(4, 1fr)",
            gridGap: "3.70vh 0.83vw",
            marginTop: "3.98vh"
        },
        card: {
            width: "100%",
            height: "35.74vh",
            flex: "none"
        }
    }
};
 
export const CVFormatLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var styles  = loaderStyles.mobile;
    if(!isMobile) {
        let extendStyles = merge(loaderStyles.mobile, loaderStyles.desktop); 
        styles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 6 : 12;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={styles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={styles.card.height}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="0%" rx="8" ry="8" width="100%" height="85%"/>
                    <rect x="0" y="90%" rx="3" ry="3" width="100%" height="10%"/>                    
                </ContentLoader>
            </div>
        )
    }

    return (
        <div style={styles.wrapper}>
            {loaderElements}
        </div>

    );
}