import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

const loaderStyles = {
    mobile: {
        wrapper: {
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gridGap: "3.57vh 0",
            margin: "2vh 20% 6vh"
        },
        card: {
            display: "inline-block",
            width: "100%",
            height: "10vh"
        }
    },
    desktop: {
        wrapper: {
            gridTemplateColumns: "repeat(3, 1fr)",
            gridGap: "5vh 12vw",
            margin: "10vh 7% 10vh 12%"
        },
        card: {
            width: "15vw",
            height: "4.55vw",
            flex: "none"
        }
    }
}
export const PartnersLoader: React.FC = () =>{
    const isMobile = useMediaQuery({ query: "(max-width: 786px)"});
    var styles = loaderStyles.mobile;
    if(!isMobile) {
        let extendedStyles = merge(loaderStyles.mobile, loaderStyles.desktop);
        styles = extendedStyles;
    }
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 1 : 9;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={styles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={"100%"}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="0" y="0%" rx="0" ry="0" width="100%" height="100%"/>
                </ContentLoader>
            </div>
        )
    }


    return(
        <div style={styles.wrapper}>
            {loaderElements}
        </div>
    )
}