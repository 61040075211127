import React, { useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { QuizModel } from '../../models/QuizModel';
import { QuizApplyModel } from '../../models/QuizApplyModel';
import useErrorPage from '../../hooks/useErrorPage';
import { postInterviewDate } from '../../services/QuizService';
import { QuizComplete } from './QuizComplete';
import { DropdownItem } from '../Shared/DropdownItem';
import { ReactComponent as Icon } from '../../assets/svg/drop_down.svg';
import { ReactComponent as SuccessfullyApplied } from '../../assets/svg/successfully-applied.svg';
import { ReactComponent as Tick } from '../../assets/svg/tick_mark.svg';

interface Props {
    styles: any;
    quiz: QuizModel;
    interviewDates: string[];
    openAlert(status: string, message: string): void;
}

export const InterviewDateForm: React.FC<Props> = (props) => {
    const styles = props.styles;
    const quiz = props.quiz;
    const [interviewDate, setInterviewDate] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [isForm, setIsForm] = useState<boolean>(true);
    const [hovered, setHovered] = useState<boolean>(false);
    const [toErrorPage] = useErrorPage();
     
    async function handleSubmit (event: React.FormEvent<HTMLFormElement>)
    {        
        event.preventDefault();
        if(interviewDate !== "") {        
            setLoading(true);
            let quizApplyModel: QuizApplyModel = {
                quizId: quiz.quizId,
                jobQuizId: quiz.jobQuizId,
                candidateId: quiz.candidateId,
                jobApplicationId: quiz.jobApplicationId,
                selectedInterviewDate: interviewDate
            }            
            await postInterviewDate(quizApplyModel)
            .then(() => {
                setIsForm(false);
                setLoading(false);
            })
            .catch((error) => {            
                toErrorPage(error);
            });
        }
        else {
            props.openAlert("error", "Please select a interview date");
        }
    }

    return (                   
        <>
            {isForm ? <form onSubmit={handleSubmit} style={styles.interview.form}> 
                <h3 style={styles.interview.headerTitle}>Please select a date for the interview</h3>
                <p style={styles.interview.question}>Preferred Interview Date</p>
                <div style={styles.interview.answer} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    <div style={{...styles.interview.answerWrapper,...(hovered && styles.interview.answerWrapper.hover)}}>
                        <div style={styles.interview.answerText}>{(interviewDate === "") ? "-- please select --" : interviewDate}</div>
                        <Icon style={{...styles.interview.answerIcon, ...(hovered && styles.rotateIcon)}}/>
                    </div>
                    <div style={hovered ? styles.interview.hover : styles.interview.leave} className={"scrollArea"}>
                        {props.interviewDates.map((date, index) =>
                            <DropdownItem key={index} styles={styles.interview.datePicker} onClick={() => setInterviewDate(date)}>
                                {date} 
                                {interviewDate === date && <Tick style={styles.interview.tickMark}/>}
                            </DropdownItem>
                        )}
                    </div>
                </div>
                <Button type="submit" style={styles.interview.button} disabled={loading}>{"SUBMIT"}</Button>
            </form>  
            : <QuizComplete 
                styles={styles.complete} 
                content={
                <>
                    <SuccessfullyApplied style={styles.complete.image}/>
                    <h3 style={styles.complete.title}>Thank you applying with XpressJobs.</h3>
                    <p style={styles.complete.description}>You will be contacted by recruiter for the further process.</p>
                </>}
            />}
        </>        
    )
}