import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { Color } from '../../enums/Color';
import { merge } from 'react-merge';

const styles = {
    mobile: {
        wrapper: {
            margin: "29px 5%"
        },
        card: {
            display: "inline-block",
            width: "100%",
            marginLeft: "1%",
            marginRight: "1%"
        }        
    },
    desktop: {
        card: {
            width: "28%"
        }
    }
};

export const JobCategoriesLoader: React.FC = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    var loaderStyles  = styles.mobile;   
    if(!isMobile) {           
        let extendStyles = merge(styles.mobile, styles.desktop); 
        loaderStyles = extendStyles;
    } 
    const loaderElements = new Array<any>();
    const loaderElementsCount = isMobile ? 4 : 15;
    for (let i = 0; i < loaderElementsCount; i++) {
        loaderElements.push(
            <div key={i} style={loaderStyles.card}>
                <ContentLoader
                    speed={1}
                    width={"100%"}
                    height={70}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                >
                    <rect x="5%" y="31" rx="5" ry="5" width="60%" height="22" />
                    <rect x="70%" y="45" rx="5" ry="5" width="20%" height="10" />
                    <rect x="70%" y="30" rx="5" ry="5" width="12%" height="10" />
                </ContentLoader>
            </div>
        )
    }
    return (
        <div style={loaderStyles.wrapper}>
            {loaderElements}
        </div>
    );
}