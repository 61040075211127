import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Title } from '../../assets/svg/xpressJobs-logo.svg';

export interface Props {    
    style: any; 
}

export const XpressJobsTitle: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    return (
        <div style={props.style}>
            <Title onClick={() =>  navigate('/')}/>
        </div>
        );
}