import { Color } from "../enums/Color"
import { commonHeader } from "./CommonHeader"

export const jobViewHeader = {
    mobile: {
        ...commonHeader.mobile,
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "48.30vw",
            opacity: "1",
            position: "relative"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "80%"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            marginLeft: "0px",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            display: "none"
        },
        jobTitleWrapper: {
            display: "none",
            marginTop: "15vh",
            marginLeft: "7.32vw",
            color: Color.White,
            position: "relative" as "relative",
            alignItems: "center" as "center"
        },
        jobTitle: {
            margin: "0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "62%",
            fontWeight: "bold" as "bold",
            fontSize: "2.5vw"
        },
        organization: {
            display: "none",
            marginLeft: "7.32vw",
            marginTop: "0.92vh",
            fontSize: "1.5vw",
            color: Color.White,
            icon: {
                width: "1.09vw",
                height: "1.09vw",
                fill: Color.White,
                marginLeft: "1.09vw",
                marginTop: "0.21vw"
            }
        },
        savedJobIcon: {
            width: "1.5vw",
            height: "1.8vw",
            marginLeft: "1vw",
            fill: Color.White
        },
        savedJobText: {
            color: Color.White,
            marginLeft: "0.2vw",
            fontSize: "1.17vw",
            marginRight: "0.95vw",
            fontWeight: 500
        },
        savedJobWrapper: {
            display: "flex",
            alignItems: "center",
            marginLeft: "0.4vw"
        }
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "20.83vw",
            paddingBottom: "0"
        },
        longBuildingSvg: {
            width: "36%"
        },
        desktopImageLeft: {
            display: "none"
        },
        recruitersButton: {
            display: "inline"
        },
        jobSeekersButton: {
            display: "inline"
        },
        jobTitleWrapper: {
            display: "flex"
        },
        jobTitle: {
            fontSize: "2.60vw"
        },
        organization: {
            display: "block",
            fontSize: "1.5vw",
            fontWeight: 500,
            icon: {
                width: "1.54vw",
                height: "1.77vw",
                fill: Color.White,
                marginLeft: "1.09vw",
                marginTop: "0.21vw"
            }
        }
    }
}
