import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { ContactModel, ContactValidation } from "../models/ContactModel";
import { TextInput } from "../components/Shared/Inputs/TextInput";
import { Button } from "../components/Shared/Buttons/Button";
import { GoogleMaps } from "../components/Shared/GoogleMaps";
import { Communication } from "../components/Contact/Communication";
import { TextAreaInput } from "../components/Shared/Inputs/TextAreaInput";
import { ReactComponent as ContactLogo} from "../assets/svg/contact.svg";
import { postContactRequest } from "../services/HomeService";
import useErrorPage from "../hooks/useErrorPage";
import { validateEmail, validatePhoneNumber } from "../helpers/Common";
import { useAlert } from "../hooks/useAlert";
import { ReactComponent as LongBuildingSvg } from '../assets/svg/login_register_building.svg';
import { SubmitFormText } from '../helpers/LayoutTexts'
import { ContactUsLoader } from "../components/Loaders/ContactUsLoader";

const intialFormInputs: ContactModel = {
    name: "",
    email: "",
    phoneNumber: "",
    message: ""        
}

const initialFormValidation: ContactValidation = {
    name: {},
    email: {},
    phoneNumber: {},
    message: {}
}

export const ContactPage: React.FC = () => {    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("contactPage"))               
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });       
    const [formInputs, setFormInputs] = useState<ContactModel>(intialFormInputs);
    const [validation, setValidation] = useState<ContactValidation>(initialFormValidation);
    const [loading, setLoading] = useState<boolean>(false)
    const mapZoomIndex: number = isMobile ? 18 : 20;
    const [toErrorPage] = useErrorPage();
    const { Alert, openAlert } = useAlert();

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("contactPage"));    
    }, [isMobile]);
    
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }
    
    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleTextBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const handleTextAreaBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "name") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Name is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if(!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "phoneNumber") {                 
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Phone number is required"}}));
            } 
            else if(!validatePhoneNumber(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid phone number!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }     
        if(name === "message") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Message is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }   
    }    

    const validateForm = (): number => {
        let formValidation: ContactValidation = {
            name: {valid: true},
            email: {valid: true},
            phoneNumber: {valid: true},
            message: {valid: true}
        }        
        if(formInputs.name === "") {
            formValidation.name = {valid: false, invalid: true, invalidMessage: "Name is required"};
        }        
        if(formInputs.email === "") {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if (!validateEmail(formInputs.email)) {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        }        
        if (formInputs.phoneNumber === "") {
            formValidation.phoneNumber = {valid: false, invalid: true, invalidMessage: "Phone number is required"};
        }
        else if (!validatePhoneNumber(formInputs.phoneNumber)) {
            formValidation.phoneNumber = {valid: false, invalid: true, invalidMessage: "Invalid phone number!"};
        }        
        if(formInputs.message === "") {
            formValidation.message = {valid: false, invalid: true, invalidMessage: "Message is required"};
        }        
        setValidation(formValidation);
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;        
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;
        if(validInputCount < 4){
            validInputCount = validateForm();
        }
        if(validInputCount === 4) { 
            setLoading(true); 
            await postContactRequest(formInputs)
            .then((success: boolean) => {
                setLoading(false);
                if(success) {
                    openAlert("success", "your request has been submitted successfully.");
                    setFormInputs(intialFormInputs);
                    setValidation(initialFormValidation);
                }
                else {
                    openAlert("error", "There was a problem with your submission. Please try again later.");
                }
            })
            .catch((error) => {
                toErrorPage(error);
            });
        }
        else {
            openAlert("error", SubmitFormText.error);
        }
    }

    return (
        <>
            <Header styleSheet={"lowestHeader"} searchEnable={false} />
            <div style={styles.wrapper}>
                <div style={styles.toggleWrapper}>
                    <div style={styles.communicationWrapper}>
                        <ContactLogo style={styles.image}/>
                        {!isMobile && <Communication styles={styles.communication}/>}
                    </div>
                    <div style={styles.container}>
                        <div style={styles.title}>{"Contact Us"}</div>
                        {loading ? <ContactUsLoader/> : 
                        <form onSubmit={handleSubmit} style={styles.form}>                    
                            <TextInput
                                inputType={'text'}
                                name={'name'}
                                value={formInputs.name}
                                label={'Name *'}
                                handleChange={handleInputChange}
                                handleBlur={handleTextBlur}
                                validation={validation.name}
                            />
                            <TextInput
                                inputType={'text'}
                                name={'email'}
                                value={formInputs.email}
                                label={'Email *'}
                                handleChange={handleInputChange}
                                handleBlur={handleTextBlur}
                                validation={validation.email}
                            />  
                            <TextInput
                                inputType={'text'}
                                name={'phoneNumber'}
                                value={formInputs.phoneNumber}
                                label={'Phone Number *'}
                                handleChange={handleInputChange}
                                handleBlur={handleTextBlur}
                                validation={validation.phoneNumber}
                            /> 
                            <TextAreaInput                
                                name={'message'}
                                value={formInputs.message}
                                label={'Message *'}                                                  
                                handleChange={handleTextareaChange}
                                handleBlur={handleTextAreaBlur}
                                validation={validation.message}
                            />                   
                            <Button style={styles.submitButton} type="submit" onClick={handleSubmit} >{"SUBMIT"}</Button>
                        </form>}
                    </div>
                </div>
                 <LongBuildingSvg style={styles.buildingSvg}/>
            </div>               
            <GoogleMaps styles={styles.googleMap} lat={6.91502} lng={79.85273} zoom={mapZoomIndex}/>
            {isMobile && <Communication styles={styles.communication}/>}
            <Footer/>
            <Alert/>
        </>
    );
}