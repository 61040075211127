import ContentLoader from "react-content-loader";
import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive"
import { Color } from "../../enums/Color";

const loaderStyle = {
    mobile : {
        basicInfoWrapper: {
            marginRight: "unset",
            marginTop: "7.25vw",
            height: "130.43vw",
            borderBottom: "2px solid" + Color.LoaderBackground
        },
        careerInfoWrapper: {
           marginTop: "7.25vw",
           height: "81.88vw",
           borderBottom: "2px solid" + Color.LoaderBackground
        },
        contactInfoWrapper: {
           marginTop: "7.25vw",
           height: "75.36vw"
        }
    },
    desktop: {
        basicInfoWrapper: {
            marginRight: "10%",
            marginTop: "2.08vw",
            height: "23.13vw",
        },
        careerInfoWrapper: {
            marginTop: "1.56vw",
            marginRight: "10%",
            height: "19.84vw"
        },
        contactInfoWrapper: {
            marginTop: "1.56vw",
            marginRight: "10%",
            height: "15.26vw"
        }
    }
};

export const MyInformationLoader : React.FC = () => {
    const isMobile = useMediaQuery ({ query : "(max-width: 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }

    return (
        <>
            <div style={styles.basicInfoWrapper}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width="auto"
                    height={styles.basicInfoWrapper.height}
                > 
                    {isMobile ? <> 
                        <rect x="0" y="0" rx="3" ry="3" width="20.05%" height="3.33%" />
                        <rect x="4.01%" y="7.04%" rx="3" ry="3" width="20.05%" height="3.33%" />
                        <rect x="0" y="12.22%" rx="3" ry="3" width="48.66%" height="9.26%" />
                        <rect x="51.34%" y="12.22%" rx="3" ry="3" width="48.66%" height="9.26%" />
                        <rect x="4.01%" y="25.18%" rx="3" ry="3" width="25.66%" height="3.33%" />
                        <rect x="0" y="30.37%" rx="3" ry="3" width="100%" height="9.26%" />
                        <rect x="4.01%" y="43.33%" rx="3" ry="3" width="18.18%" height="3.33%" />
                        <rect x="0" y="48.52%" rx="3" ry="3" width="100%" height="9.26%" />
                        <rect x="4.01%" y="61.48%" rx="3" ry="3" width="40.37%" height="3.33%" />
                        <rect x="0" y="66.66%" rx="3" ry="3" width="100%" height="27.78%" />
                    </> :
                    <> 
                        <rect x="0.92%" y="0" rx="3" ry="3" width="8.55%" height="5.18%" />
                        <rect x="0.92%" y="10.81%" rx="3" ry="3" width="8.18%" height="5.63%" />
                        <rect x="51.65%" y="10.81%" rx="3" ry="3" width="9.93%" height="5.63%" />
                        <rect x="0" y="18.69%" rx="3" ry="3" width="23.90%" height="15.77%" />
                        <rect x="25.37%" y="18.69%" rx="3" ry="3" width="23.90%" height="15.77%" />
                        <rect x="50.74%" y="18.69%" rx="3" ry="3" width="49.26%" height="15.77%" />
                        <rect x="0.92%" y="41.21%" rx="3" ry="3" width="15.63%" height="5.63%" />
                        <rect x="51.65%" y="41.21%" rx="3" ry="3" width="6.99%" height="5.63%" />
                        <rect x="0" y="49.10%" rx="3" ry="3" width="49.26%" height="44.14%" />
                        <rect x="50.74%" y="49.10%" rx="3" ry="3" width="21.12%" height="4.26%" />
                    </>}
                </ContentLoader>
            </div>
            <div style={styles.careerInfoWrapper}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width="auto"
                    height={styles.basicInfoWrapper.height}
                >
                    {isMobile ? <>
                        <rect x="0" y="0" rx="3" ry="3" width="20.05%" height="3.33%" />
                        <rect x="4.01%" y="7.04%" rx="3" ry="3" width="52.14%" height="3.33%" />
                        <rect x="0" y="12.22%" rx="3" ry="3" width="100%" height="9.26%" />
                        <rect x="4.01%" y="25.19%" rx="3" ry="3" width="36.63%" height="4.26%" />
                        <rect x="0" y="31.37%" rx="3" ry="3" width="100%" height="26.85%" />
                    </> :
                    <>
                        <rect x="0.92%" y="0" rx="3" ry="3" width="9.93%" height="6.04%" />
                        <rect x="0.92%" y="12.91%" rx="3" ry="3" width="20.22%" height="6.56%" />
                        <rect x="0" y="22.09%" rx="3" ry="3" width="100%" height="17.37%" />
                        <rect x="0.92%" y="46.34%" rx="3" ry="3" width="14.15%" height="6.56%" />
                        <rect x="0" y="55.53%" rx="3" ry="3" width="100%" height="24.82%" />
                    </>}
                </ContentLoader> 
            </div>
            <div style={styles.contactInfoWrapper}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width= "auto"
                    height= {styles.contactInfoWrapper.height}
                >
                    {isMobile ? <>
                        <rect x="0" y="0" rx="3" ry="3" width="25.40%" height="5.77%" />
                        <rect x="4.01%" y="12.18%" rx="3" ry="3" width="11.50%" height="7.37%" />
                        <rect x="0" y="21.15%" rx="3" ry="3" width="100%" height="16.03%" />
                        <rect x="4.01%" y="43.59%" rx="3" ry="3" width="35.83%" height="7.37%" />
                        <rect x="0" y="52.56%" rx="3" ry="3" width="100%" height="16.03%" />
                        <rect x="4.01%" y="75%" rx="3" ry="3" width="17.38%" height="7.37%" />
                        <rect x="0" y="83.97%" rx="3" ry="3" width="100%" height="16.03%" />
                    </> :
                    <>
                        <rect x="0.92%" y="0" rx="3" ry="3" width="10.84%" height="7.85%" />
                        <rect x="0.92%" y="18.08%" rx="3" ry="3" width="11.67%" height="8.53%" />
                        <rect x="51.65%" y="18.08%" rx="3" ry="3" width="13.88%" height="8.53%" />
                        <rect x="0" y="30.03%" rx="3" ry="3" width="49.26%" height="23.89%" />
                        <rect x="50.74%" y="30.03%" rx="3" ry="3" width="49.26%" height="23.89%" />
                        <rect x="0.92%" y="64.16%" rx="3" ry="3" width="6.71%" height="8.53%" />
                        <rect x="0" y="76.10%" rx="3" ry="3" width="100%" height="23.89%" />
                    </>}
                </ContentLoader> 
            </div>
        </>
    )
}