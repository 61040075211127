import React, { useContext, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { AuthenticateBySocialApps } from './AuthenticateBySocialApps';
import { TextInput } from '../Shared/Inputs/TextInput';
import { Option } from '../../models/SelectInputs';
import { RegisterModel, RegisterValidation } from '../../models/RegisterModel';
import { postCandidateRegister } from '../../services/CandidateService';
import { ResponseModel } from '../../models/ResponseModel';
import { Link, useNavigate } from 'react-router-dom';
import { CheckboxInput } from '../Shared/Inputs/CheckboxInput';
import useErrorPage from '../../hooks/useErrorPage';
import { FileInput } from '../Shared/Inputs/FileInput';
import { validateCV, validateEmail,validateName, validateLettersOnlyText } from '../../helpers/Common';
import { RadioGroup } from '../Shared/Inputs/RadioGroup';
import { SubmitFormText } from '../../helpers/LayoutTexts'
import { AuthContext } from '../../providers/AuthContextProvider';
import { RegisterFormLoader } from '../Loaders/RegisterFormLoader';

interface Props {
    styles: any; 
    callbackUrl: string | null;
    message: string | null;
    title: string;
    changeStage(stage: string): void;
    openAlert(status: string, message: string): void;
    showCVUploadPopup(candidateId: number): void;
}

const genderOptions: Option[] = [
    { value: "male", option: "Male" },
    { value: "female", option: "Female" }
]

const initialRegisterModel: RegisterModel = {
    RefId: "",
    Forename: "",
    Surname: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    FacebookLoginID: false,
    LinkedinLoginID: false,
    GoogleId: false,
    Gender: "",
    HomeTown: "",
    MobileNumber: "",
    CandidateCV: "",
    PrivacyPolicy: true,
    ReceiveJobsByEmail: true
}

const initialRegisterValidation: RegisterValidation = {
    Forename: {},
    Surname: {},
    Email: {},
    Password: {},
    ConfirmPassword: {},
    HomeTown:{},
    CandidateCV: {},
    PrivacyPolicy: {valid: true}
}

export const Register: React.FC<Props> = (props) => {
    const navigate = useNavigate();    
    const [registerModel, setRegisterModel] = useState<RegisterModel>(initialRegisterModel);
    const [validation, setValidation] = useState<RegisterValidation>(initialRegisterValidation);
    const [candidateCV, setCandidateCV] = useState<File>();
    const styles = props.styles;
    const [toErrorPage] = useErrorPage();
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setRegisterModel((values: any) => ({ ...values, [name]: value }));
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value);
        }
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.checked;
        setRegisterModel((values: any) => ({ ...values, [name]: value }));
        if(name === "PrivacyPolicy" && !value) {            
            setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "You should agree to Privacy Policy & Terms/Conditions"}}));            
        }      
    }

    const handleFileChange = (selectedFiles: FileList) => {
        if (selectedFiles.length > 0) {
            setCandidateCV(selectedFiles[0]);            
            setRegisterModel((values: any) => ({ ...values, ["CandidateCV"]: selectedFiles[0].name }));            
            let cvValidation = validateCV(selectedFiles[0]);
            setValidation((values: any) => ({...values, ["CandidateCV"]: cvValidation}));
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);        
    }

    const validateInput = (name: string, value: string) => {        
        if(name === "Forename" || name === "Surname") {
            setValidation((values: any) => ({...values, [name]: validateName(name, value, 50)}));            
        }
        if(name === "Email") {            
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Email is required"}}));
            } 
            else if (!validateEmail(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Invalid email address!"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "Password") {
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password is required"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }
        if(name === "ConfirmPassword") {
            const password = registerModel.Password;      
            if(value === "") {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password confirmation is required"}}));
            } 
            else if (password !== value) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"}}));
            } 
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }  
        if(name === "HomeTown" && value !== "") {
            if(!validateLettersOnlyText(value)) {
                setValidation((values: any) => ({...values, [name]: {valid: false, invalid: true, invalidMessage:"Symbols or numbers are not allowed"}}));
            }
            else {
                setValidation((values: any) => ({...values, [name]: {valid: true, invalid: false}}));
            }
        }        
    }    

    const validateForm = (): number => {
        let formValidation: RegisterValidation = {
            Forename: {valid: true},
            Surname: {valid: true},
            Email: {valid: true},
            Password: {valid: true},
            ConfirmPassword: {valid: true},
            HomeTown:{valid: true},
            CandidateCV: {valid: true},
            PrivacyPolicy: {valid: true}            
        }
        formValidation.Forename = validateName("Forename", registerModel.Forename, 50);                
        formValidation.Surname = validateName("Surname", registerModel.Surname, 50);        
        if(registerModel.Email === "") {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        } 
        else if(!validateEmail(registerModel.Email)) {
            formValidation.Email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        } 
        if(registerModel.Password === "") {
            formValidation.Password = {valid: false, invalid: true, invalidMessage: "Password is required"};
        }
        if(registerModel.ConfirmPassword === "") {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password confirmation is required"};
        } 
        else if(registerModel.Password !== registerModel.ConfirmPassword) {
            formValidation.ConfirmPassword = {valid: false, invalid: true, invalidMessage: "Password and confirmation do not match"};
        }
        if(registerModel.HomeTown !== "" && !validateLettersOnlyText(registerModel.HomeTown)){
            formValidation.HomeTown = {valid: false, invalid: true, invalidMessage:"Symbols or numbers are not allowed"};
        }
        formValidation.CandidateCV = validateCV(candidateCV);
        if(!registerModel.PrivacyPolicy) {
            formValidation.PrivacyPolicy = {valid: false, invalid: true, invalidMessage: "You should agree to Privacy Policy & Terms/Conditions"};
        }
        setValidation(formValidation);        
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;
    }

    async function submitRegistration(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;
        if(validInputCount < 8){
            validInputCount = validateForm();
        }
        if(validInputCount === 8) {     
            setLoading(true)       
            const formData = new FormData();
            formData.append("candidateModelString", JSON.stringify(registerModel));
            formData.append("candidateCV", candidateCV!);
            await postCandidateRegister(formData)
            .then((registerResponse: ResponseModel) => {
                setLoading(false);
                if(registerResponse.status) {
                    authContext.setLoggedCandidate(registerResponse.data, registerResponse.token); 
                    if(props.callbackUrl) {
                        navigate(props.callbackUrl);
                    } 
                    else {
                        navigate('/candidate/home');
                    }
                }
                else {
                    props.openAlert("error", registerResponse.message);
                }
            })
            .catch((error) => {            
                toErrorPage(error);
            });
        }
        else {                        
            props.openAlert("error", SubmitFormText.error);        
        }
    }

    return (
        <>
        {loading ? <RegisterFormLoader/> :
        <form style={{...styles.registerForm, ...(props.message && styles.registerForm.messageEnabled)}} onSubmit={submitRegistration}>
            <div style={styles.accordionButtonsContainer}>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.left }}
                    onClick={() => props.changeStage("login")} >
                    Login
                </div>
                <div
                    style={{ ...styles.accordionButton, ...styles.accordionButton.right, ...styles.accordionButton.selected }}
                    onClick={() => props.changeStage("register")} >
                    Register
                </div>
            </div>
            {props.message && <div style={styles.callbackMessage}>{props.message}</div>}
            <p style={{...styles.boldTitle, ...styles.boldTitle.register}}>{props.title}</p>
            <div style={styles.row}>
                <TextInput
                    label={"First Name *"}
                    inputType={'text'}
                    name={"Forename"}
                    value={registerModel.Forename}
                    handleChange={handleInputChange}
                    handleBlur={handleInputBlur}
                    validation={validation.Forename}                    
                />
                <TextInput
                    label={'Last Name *'}
                    styles={styles.registerLeftInput}
                    inputType={'text'}                    
                    name={"Surname"}
                    value={registerModel.Surname}
                    handleChange={handleInputChange}
                    handleBlur={handleInputBlur}
                    validation={validation.Surname}
                />
            </div>
            <TextInput
                label={'Email *'}
                inputType={'text'}
                name={"Email"}
                value={registerModel.Email}
                handleChange={handleInputChange}
                handleBlur={handleInputBlur}
                validation={validation.Email}
            />
            <div style={styles.row}>
                <TextInput
                    label={'Password *'}
                    inputType={'password'}
                    name={"Password"}
                    value={registerModel.Password}
                    handleChange={handleInputChange}
                    handleBlur={handleInputBlur}
                    validation={validation.Password}
                />
                <TextInput
                    label={'Confirm Password *'}
                    styles={styles.registerLeftInput}
                    inputType={'password'}
                    name={"ConfirmPassword"}                    
                    value={registerModel.ConfirmPassword}
                    handleChange={handleInputChange}
                    handleBlur={handleInputBlur}
                    validation={validation.ConfirmPassword}
                />
            </div>
            <div style={styles.row}>
                <RadioGroup
                    styles={styles.selectGender}
                    name="Gender"
                    options={genderOptions}
                    handleChange={handleInputChange}
                    label="Your Gender"
                />
                <TextInput
                    label={"Home Town"}
                    styles={styles.registerLeftInput}                    
                    inputType={'text'}
                    name={"HomeTown"}
                    value={registerModel.HomeTown}
                    handleChange={handleInputChange}
                    handleBlur={handleInputBlur}
                    validation={validation.HomeTown}  
                />
            </div>
            <FileInput
                label={'CV *'}                
                id={"candiate-cv"}
                name={"CandidateCV"}                
                selectedFileName={registerModel.CandidateCV}
                styles={styles.cvUpload}
                handleChange={(event) => handleFileChange(event!.currentTarget.files!)}                
                validation={validation.CandidateCV}
            />
            <div style={styles.fileWarning}><span style={styles.fileWarning.bold}>Supported Files</span> : pdf, docx, doc | <span style={styles.fileWarning.bold}>Max file size</span> : 2MB</div>
            <div style={styles.policyWrapper}>
                <div style={{...styles.row,...(!registerModel.PrivacyPolicy && styles.policyLabelError)}}>
                    <CheckboxInput name="PrivacyPolicy" value={registerModel.PrivacyPolicy} handleChange={handleCheckboxChange}>
                        <span style={styles.policyLabel}>I agree to <Link style={{...styles.sharpText.black,...(!registerModel.PrivacyPolicy && styles.policyLabelError)}} to={'/privacyPolicy'}>Privacy Policy</Link> {"&"} <Link style={{...styles.sharpText.black,...(!registerModel.PrivacyPolicy && styles.policyLabelError)}} to={'/termsconditions'}>Terms/Conditions.</Link></span>
                    </CheckboxInput>
                </div>
                <div style={{...styles.row, ...styles.jobsByEmail}}>
                    <CheckboxInput name="ReceiveJobsByEmail" value={registerModel.ReceiveJobsByEmail} handleChange={handleCheckboxChange} >
                        <span style={styles.policyLabel}>Receive jobs by email</span>
                    </CheckboxInput>
                </div>           
            </div>
            <div style={styles.row}>
                <div style={styles.registerWrapper}>
                    <Button style={styles.submitButton} type="submit" disabled={loading}>Register</Button>
                    <p style={styles.loginNowWrapper}>Already have an account ? <span style={styles.sharpText.bold} onClick={() => props.changeStage("login")}>Login Now</span></p>
                </div>
                <div style={styles.social}>
                    <p style={styles.social.registerUsing.text}>Or Register Using</p>
                    <div style={styles.social.registerUsing}>
                        <AuthenticateBySocialApps styles={styles.social} callbackUrl={props.callbackUrl} openAlert={props.openAlert} showCvUploadPopup={props.showCVUploadPopup}/>
                    </div>
                </div>
            </div>
        </form>}
        </>
    )
}