import axios from "axios";
import { QuizModel } from "../models/QuizModel";
import { QuizApplyModel } from "../models/QuizApplyModel";
import { getHost } from "../helpers/Common";

const apiBaseUrl = getHost() + "api/";

export async function validateQuiz(key: string) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "quiz/validate", {
            params: {
                key: key
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postQuizResponse(quizModel: QuizModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "quiz/quizResponse", quizModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function getQuizQuestions(quizId: number, page: number) {    
    return new Promise<any>((resolve, reject) => {
        axios        
        .get(apiBaseUrl + "quiz/questions", {
            params: {
                quizId: quizId,
                pageNumber: page
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    });
}

export async function postQuizAnswers(quizApplyModel: QuizApplyModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "quiz/answers", quizApplyModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function finishQuizRequest(quizApplyModel: QuizApplyModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "quiz/finish", quizApplyModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}

export async function postInterviewDate(quizApplyModel: QuizApplyModel) {    
    return new Promise<any>((resolve, reject) => {
        axios 
        .post(apiBaseUrl + "quiz/interviewDate", quizApplyModel)
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        })
    })
}