import React, { useContext, useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { Job } from '../../models/Job';
import { SelectInputs } from '../../models/SelectInputs';
import { StyleContext } from '../../providers/StyleContextProvider';
import { SingleSelect } from './SingleSelect';
import { ShortJobCard } from './ShortJobCard';
import { ShortJobCardListLoader } from '../Loaders/ShortJobCardListLoader';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Button } from './Buttons/Button';
import { merge } from 'react-merge';
import useSaveJob from '../../hooks/useSaveJob';
import { FeedbackCard } from './FeedbackCard';
import useErrorPage from '../../hooks/useErrorPage';

interface Props {
    styles?: any;
    title: string;    
    sortEnabled: boolean;
    initialSortBy?: string;
    sortSelectInputs?: SelectInputs;    
    loadJobs(sortBy?: string): Promise<any>;
    noJobsMessage?: string;
    setHasJobs?(value: boolean): void;       
    onRedirect?(): void;
};

export const ShortJobCardList: React.FC<Props> = (props) => {    
    const styleContext = useContext(StyleContext);    
    const[styles, setStyles] = useState<any>(styleContext.getComponentStyle("shortJobCardList"));     
    const[loading, setLoading] = useState<boolean>(true);
    const[jobs, setJobs] = useState<Job[]>([]);
    const sliderRef = useRef<Slider | null>(null);
    const [sortBy, setSortBy] = useState<string | undefined>(props.initialSortBy);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const {saveCandidateJob} = useSaveJob();
    const [isGrid, setIsGrid] = useState<boolean>(false);
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;
    const [toErrorPage] = useErrorPage();

    useEffect(() => {                 
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        getJobList();                              
    }, [sortBy]);

    useEffect(() => {                                     
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("shortJobCardList"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                                 
    }, [isMobile]);

    async function getJobList() {
        await props.loadJobs(sortBy)
        .then((jobList: Job[]) => {
            setJobs(jobList);
            let jobCount = jobList.length;
            if(props.setHasJobs){
                props.setHasJobs(jobCount > 0);
            }
            if((isMobile && jobCount < 5) || (!isMobile && jobCount < 9)) {
                setIsGrid(true);
            }
            else {
                setIsGrid(false);
            }
            setLoading(false); 
        })
        .catch((error) => {                        
            if(isMountedRef.current) {                
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getJobList, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
            }
        });
    }

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    }

    const previousSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const getSelected = (selectedValue: string) => {
        setSortBy(selectedValue);
    };

    const saveJob = (jobId: number, isSave: boolean) => {
        saveCandidateJob(jobId, isSave);
    }

    const sliderSettings = {
        className: "center",
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 2,
        speed: isMobile ? 500 : 1000,
        autoplay: isMobile ? false : true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: isMobile ? 1 : 4,
        swipeToSlide: true,
        touchThreshold: 100,
        useTransform: false,
        appendDots: (dots: any) => (
            <div style={styles.dotsWrapper}>
                <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };

    return (
        <div style={styles}>
            <div style={styles.wrapper}>
                <div style={styles.titleWrapper}>
                    <span style={styles.title}>{props.title}</span>
                    <Button style={styles.redirectButton} onClick={props.onRedirect}>{"View all"}<RightArrow style={styles.redirectButton.icon} /></Button>
                </div>
                {props.sortEnabled && <SingleSelect styles={styles.select} label="Sort by" selectInputs={props.sortSelectInputs!} getSelected={getSelected}/>}
                {!isGrid && <div style={styles.sliderButtonWrapper}>
                    <Button style={styles.leftSliderButton} onClick={previousSlide}><LeftArrow style={styles.buttonIcon} /></Button>
                    <Button style={styles.rightSliderButton} onClick={nextSlide}><RightArrow style={styles.buttonIcon} /></Button>
                </div>}                
            </div>             
            {loading ? <ShortJobCardListLoader/> :            
            jobs.length > 0 ? (isGrid ? <div style={styles.grid}>
                {jobs.map((job, index) =>
                    <div key={index} style={styles.gridItem}>
                        <ShortJobCard styles={styles.card} job={job} saveJob={saveJob} />                            
                    </div>                     
                )}
            </div> 
            : <div style={styles.slider}>
                <Slider ref={sliderRef} {...sliderSettings}>
                    {jobs.map((job, index) =>
                        <div key={index} style={styles.gridItem}>
                            <ShortJobCard styles={styles.card} job={job} saveJob={saveJob} />                            
                        </div>                     
                    )}
                </Slider>
            </div>) : <FeedbackCard message={props.noJobsMessage!} styles={styles.noResults}/>
            }      
        </div>
    );
}