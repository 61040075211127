import { Color } from "../enums/Color";

export const candidatePremiumPage = {
    mobile: {        
        premiumCard: {
            position: "relative" as "relative",            
            width: "90%",
            margin: "-11.44vh 5% 0 5%",
            borderRadius :"2.42vw",
            border: "0.24vw solid " + Color.HarvestGold,
            backgroundColor: Color.OldLace,                        
            fontWeight: "bold" as "bold",
            textAlign: "center" as "center",
            overflow: "hidden" as "hidden",
            boxShadow: "2.42vw 2.42vw 12.07vw #0000001A"
        },
        premium: {
            position: "relative" as "relative",
            transform: "rotate(-22deg)",
            left: "-26.74vw",
            top: "1.62vh",
            height: "4.62vh",
            lineHeight: "4.62vh",
            fontWeight: "bold" as "bold",
            fontSize: "3.14vw",
            textAlign: "center" as "center",
            backgroundColor: Color.HarvestGold,
            color: Color.OldLace
        },
        premiumIcon: {
            width: "4.38vw",
            height: "3.50vw",
            fill: Color.OldLace,
            marginRight: "2.41vw",
            marginLeft: "-8.50vw"
        },
        toggleWrapper: {
            display: "flex",
            flexDirection: "column" as "column"
        },
        makePremium: {   
            width: "90%",
            fontSize: "6.04vw",            
            margin: "2.23vh auto 0 auto",
        },        
        price: {
            color: Color.HarvestGold,
            fontSize: "9.42vw",
            marginTop: "4.46vh",
            lkr: {
                fontSize: "6.04vw"
            }
        },
        perYear: {
            fontSize: "3.86vw",
            marginBottom: "1.78vh",
        },
        buyNowButton: {           
            width: "58.94vw",
            height: "12.08vw",
            marginBottom: "3.57vh",      
            color: Color.White,            
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,            
            borderRadius: "2.41vw",            
            fontSize: "4.35vw",
            boxShadow: "1.21vw 1.21vw 5.58vh #00000027"
        },
        description: {
            margin: "5.58vh 5% 3.35vh 5%",
            fontSize: "3.38vw",
            lineHeight: "6.04vw",     
            color: Color.MiddleBlack
        },
        privilegeCard: {
            width: "90%",            
            margin: "0 5% 1.78vh 5%",
            borderRadius :"2.41vw",
            border: "0.24vw solid " + Color.MiddleGold,
            crownWrapper: {
                width: "14.01vw",
                height: "14.01vw",
                borderRadius :"7.24vw",
                backgroundColor: Color.OldLace,
                margin: "2.68vh 0 0 5.80vw",
                textAlign: "center" as "center",
                lineHeight: "16.50vw"
            },
            crownIcon: {
                width: "6.52vw",
                height: "5.22vw",
                fill: Color.HarvestGold
            },
            text: {
                fontSize: "4.83vw",
                fontWeight: "bold" as "bold",
                margin: "3.125vh 5.80vw 3.57vh 5.80vw",
                color: Color.LightBlack,
                lineHeight: "6.76vw"
            }
        },
        video: {
            width: "90%",
            height: "25.89vh",
            border: "none",
            borderRadius :"2.90vw",
            margin: "7.82vh 5%"
        },
        buyNowWrapper: {
            textAlign: "center" as "center"
        },
        iconGride: {
            margin: "8.10vw 0 20.29vw 0"
        },
        paymentIcon: {
            maxWidth: "21.25vw",
            maxHeight: "6.28vw",
            margin: "0 1.93vw 0 0",
            WebkitFilter: "grayscale(1)", 
            filter: "grayscale(1)"
        }
    },
    desktop: {        
        premiumCard: {
            height: "13.33vw",
            width: "60%",
            margin: "-8.89vh 20% 0 20%",
            borderRadius :"0.52vw",
            border: "0.05vw solid " + Color.HarvestGold,
            textAlign: "unset" as "unset",
            boxShadow: "0.52vw 0.52vw 0.78vw #0000001A"
        },
        premium: {
            left: "-6%",
            top: "1.12vw",
            width: "30%",
            height: "2.3vw",
            fontSize: "1.04vw"
        },
        premiumIcon: {
            width: "1.41vw",
            height: "1.13vw",
            margin: "0.50vw 0.71vw 0 -1vw"
        },
        toggleWrapper: {
            flexDirection: "row" as "row"
        },
        makePremium: {            
            width: "23.54vw",
            textAlign: "left" as "left",
            fontSize: "2.60vw",            
            margin: "2.78vh 0 0 3.64vw"
        },
        priceWrapper: {
            textAlign: "right" as "right",
            margin: "0 3.64vw 0 auto"
        },
        price: {
            fontSize: "2.34vw",
            margin: "0 0.52vw 0 0",
            lkr: {
                fontSize: "1.46vw"
            }
        },
        perYear: {
            fontSize: "1.04vw",
            margin: "0.46vh 0.52vw 1.01vw 0"
        },
        buyNowButton: {           
            width: "18.125vw",
            height: "3.65vw",
            marginBottom: "3.82vh",         
            fontSize: "1.04vw",
            borderRadius: "0.52vw"
        },
        description: {
            width: "50%",
            margin: "4.63vh 25%",
            fontSize: "0.84vw",
            textAlign: "center",
            lineHeight: "1.3vw"
        },
        privilegeWrapper: {
            width: "86%",
            margin: "0 7% 0 7%"
        },
        privilegeCard: {
            width: "99%",
            height: "13.33vw",            
            margin: "0 0.83vw 0 0",
            borderRadius :"0.52vw",
            border: "0.05vw solid " + Color.MiddleGold,
            crownWrapper: {
                width: "3.02vw",
                height: "3.02vw",
                borderRadius :"1.56vw",
                backgroundColor: Color.OldLace,
                margin: "2.22vh 0 0 1.25vw",
                textAlign: "center" as "center",
                lineHeight: "3.50vw"
            },
            crownIcon: {
                width: "1.41vw",
                height: "1.13vw"
            },
            text: {
                fontSize: "1.04vw",
                fontWeight: "bold" as "bold",
                margin: "2.59vh 1.25vw 2.96vh 1.25vw",
                lineHeight: "1.46vw"
            }
        },
        video: {
            width: "60%",
            height: "64.35vh",
            borderRadius :"1.04vw",
            margin: "7.41vh 20% 9.26vh 20%"
        },
        iconGride: {
            margin: "1.95vw 15% 6.35vw 19%"
        },
        paymentIcon: {
            maxWidth: "8.02vw",
            maxHeight: "2.95vw",
            margin: "0 3.49vw 0 0"
        }
    }
}