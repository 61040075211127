import React from 'react';
import { ReactComponent as ScreeningStage } from '../../assets/svg/screening-stage.svg';
import { ReactComponent as AppliedStage } from '../../assets/svg/applied-stage.svg';

interface Props {
    styles: any;
    applyStage: number;
}

export const CvLessApplyStatus: React.FC<Props> = (props) => {
    const styles = props.styles;
    const applyStage= props.applyStage;
    return (
        <div style={{...styles, ...styles.cvLess}}>
            <div style={{...styles.boxHolder, ...styles.boxHolder.applying, ...(applyStage === 1 && styles.boxHolder.active)}}>
                <div style={{...styles.box, ...(applyStage === 1 ? styles.box.active : styles.box.pass)}}>
                    <ScreeningStage style={{...styles.icon,  ...styles.icon.screening, ...(applyStage === 1 ? styles.icon.active : styles.icon.pass)}} />
                </div>
            </div>            
            <hr style={{...styles.progressBar, ...styles.cvLessProgressBar, ...(applyStage === 2 && styles.progressBar.active)}} />
            <div style={{ ...styles.boxHolder, ...styles.boxHolder.applied, ...(applyStage === 2 && styles.boxHolder.active) }}>
                <div style={{...styles.box, ...(applyStage === 2 && styles.box.active)}}>
                    <AppliedStage style={{ ...styles.icon, ...styles.icon.applied, ...(applyStage === 2 && styles.icon.active) }} />
                </div>
            </div>
        </div>
    )
}