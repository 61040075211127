import { Color } from "../enums/Color";

export const singleSelect = {
    mobile: {        
        display: "flex",
        flexDirection: "column" as "column",
        width: "auto",
        marginLeft: "4.83vw",                
        marginTop: "2.41vw",
        WebkitTapHighlightColor : "transparent",     
        label: {                
            fontSize: "3.62vw",
            fontWeight: 500
        },
        button: {
            width: "35.74vw",
            height: " 8.45vw",
            fontSize: "3.62vw",
            marginTop: "1.20vw",
            cursor: "pointer"
        },            
        buttonWrapper: {        
            display: "flex",
            flexDirection: "row" as "row",
            width: "100%",
            height: "100%",
            alignItems: "center",
            border : `1px ${Color.LightSilver} solid`,
            borderRadius: "2.41vw"
        },
        buttonText: {
            marginLeft: "auto",
            color: Color.SharpAsh
        },
        buttonIcon: {
            width: "3.2vw",
            height: "3.2vw",
            fill: Color.SharpAsh,              
            marginLeft: "1.33vw",
            marginRight: "auto",
            transition: "transform .1s ease-in-out",
            hover: {
                transform: "rotate(180deg)"
            }
        },
        leave: {
            display: "none"            
        },
        hover: {                
            display: "block",
            position: "absolute" as "absolute",
            width: "54.93vw",
            marginTop: "0.74vh",                             
            backgroundColor: Color.White,
            border: "1px solid " + Color.LightSilver,
            borderRadius: "2.41vw",
            zIndex: 1,
            paddingTop: "1.33vw"
        },                
        search: {                                
            display: "none"
        },
        option: {
            color: Color.MiddleBlack,
            fontWeight: "500",
            hover: {
                backgroundColor: Color.LightBlue,       
                borderRadius: "1.69vw",
                cursor: "pointer",
                position: "relative" as "relative",
                margin: "0 1.33vw",
                padding: "1.33vw"
            },
            leave: {
                position: "relative" as "relative",
                margin: "0 1.33vw",
                padding: "1.33vw"
            },
            icon: {
                fill: Color.White,
                width: "4.53vw",
                height: "4.53vw",
                position: "absolute" as "absolute",
                right: "2.66vw"
            }
        },
        bottomBorder: {
            border: 0,
            height: "1px",
            width: "92%",
            background: Color.MiddleAsh,
            margin: "0 auto",
            zIndex: "1"
        },
        hoveredBorder: {
            background: Color.White,
            borderRadius: "1px"
        }              
    },
    desktop: {                              
        flexDirection: "row" as "row",            
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "3.38vw",
        alignItems: "center",
        label: {                
            marginTop: "auto",
            marginBottom: "auto",     
            marginLeft: "none",
            fontSize: "0.937vw"
        },
        button: {
            width: "10.31vw",
            height: "2.08vw",
            marginTop: "auto",
            marginLeft: "1.04vw",
            fontSize: "0.937vw"
        },
        buttonWrapper: {
            borderRadius: "0.52vw",
            hover: {
                borderRadius: "0.52vw 0.52vw 0 0"
            }
        },
        buttonIcon: {
            width: "0.8vw",
            height: "0.8vw",
            marginLeft: "0.36vw",
            marginTop: "0.32vh"
        },
        hover: { 
            width: "10.31vw",
            marginTop: "auto",
            border: "1px solid " + Color.LightSilver,
            borderTop: "none",
            borderRadius: "0px 0px 0.52vw 0.52vw",
            paddingTop: "0.36vw",
            paddingBottom: "0.36vw"
        },
        option: {
            fontSize: "0.83vw",
            hover: {
                margin: "0 0.31vw",
                padding: "0.31vw 0.52vw",
                borderRadius: "0.36vw"
            },
            leave: {
                margin: "0 0.31vw",
                padding: "0.31vw 0.52vw"
            },
            icon: {
                width: "1.24vw",
                height: "1.24vw",
                right: "0.73vw"
            }
        }        
    }
}
