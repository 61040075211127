export const formatLocations = (locations: string | null | undefined): string => {
    if (!locations) {
      return '';
    }
  
    const cityNames: string[] = locations
      .split(';')
      .map((location: string) => location.split(',')[0].trim());
  
    return cityNames.length > 2 
      ? `${cityNames[0]}, ${cityNames[1]}, etc.` 
      : cityNames.join(', ');
};