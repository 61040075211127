import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { GetInTouch } from "../components/Shared/GetInTouch";
import { getDisabilityFriendlyOrganizations } from "../services/HomeService";
import { DisabilityFriendlyTexts as Texts } from '../helpers/LayoutTexts';
import { Logo } from "../components/Shared/Logo";
import { ReactComponent as Building } from "../assets/svg/offer-job-buildings.svg";
import { Helmet } from "react-helmet-async";
import { Organization } from '../models/Organization';
import { LogoGridLoader } from '../components/Loaders/LogoGridLoader';
import useErrorPage from '../hooks/useErrorPage';
import Slider from "react-slick";

export const DisabilityFriendlyPage: React.FC = () => {
    const styleContext = useContext(StyleContext);
    const [paragraphTexts, setParagraphTexts] = useState<{title: string; paragraphs: string[];}[]>(Texts.posts);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("disabilityFriendlyPage"));
    const [organizations, setoOrganizations] = useState<Organization[]>([]);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [toErrorPage] = useErrorPage();
    const [loading, setLoading] = useState<boolean>(true);
    const organizationsCount: number = 42;
    const albumPictureURLs = [
        "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/guy-sitting-on-a-table.png",
        "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/disabled-lady-working.png",
        "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/girl-in-meeting.png",
        "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/working-lady.png"
    ];

    useEffect(() => {
        setStyles(styleContext.getComponentStyle("disabilityFriendlyPage"));
        getLogoList();
    }, [isMobile]);

    async function getLogoList() {
        await getDisabilityFriendlyOrganizations(organizationsCount)
        .then((organizationList: Organization[]) => {
            setoOrganizations(organizationList);
            let tempParagraphTexts = [...paragraphTexts];
            tempParagraphTexts[0].paragraphs[2] = tempParagraphTexts[0].paragraphs[2].replace('many', organizationList[0].recordCount.toString());
            setParagraphTexts(tempParagraphTexts);
            setLoading(false)
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const sliderSettings = {
        infinite: true,
        autoplay: true,
        rows: isMobile ? 2 : 1,
        pauseOnHover: true,  
        speed: 5000,
        autoplaySpeed: 0,
        slidesToShow: isMobile ? 5 : 14,
        slidesToScroll: 3,
        cssEase: "linear"
    };
     
    return (
        <>
            <Helmet><title>Differently-Abled Friendly Employment - XpressJobs</title></Helmet>
            <Header styleSheet="disabilityFriendlyHeader" heading={Texts.heading} />
            <div style={styles.pictureAlbum}>
                {albumPictureURLs.map((url, index) => <img style={{ ...styles.albumPicture, ...styles["albumPicture" + index] }} key={index} src={url} alt={"picture-" + index} />)}
            </div>
            <img style={styles.disabledLadyPicture} src={Texts.disabledLadyImageURL} alt="Working lady" />
            <div style={styles.friendlyEnvironment}>                
                <h4 style={styles.postTitle}>Differently-Abled <span style={styles.postTitleSpan}>Friendly Employment</span></h4>
                {paragraphTexts[0].paragraphs.map((item , index) => <React.Fragment key={index}><p style={styles.postContent}>{item}</p><br /></React.Fragment>)}
            </div>
            {loading ? <LogoGridLoader style={styles.logoGride} rowsCount={isMobile ? 2 : 1} /> :
            <div style={styles.logoGride}>
                <div className="slider-container">
                <Slider {...sliderSettings}>
                    {organizations.map((organization, index) => 
                    <div key={index}>
                        <Logo style={styles.logoGride.logo} organizationId={organization.organizationId} organizationName={organization.organizationName} hasLogo={organization.hasLogo} logoUri={organization.logoUri} />
                    </div>    
                    )} 
                </Slider>
                </div>
            </div>}
            <div style={styles.placement}>
                <img style={styles.placementImage} src={Texts.guyWithIPadImageURL} alt="Guy with iPad" />
                <div style={styles.placementText}>
                    <h4 style={styles.postTitle}><span style={styles.postTitleSpan}>Placement </span>For The Differently-Abled</h4>
                    {paragraphTexts[1].paragraphs.map((item, index) => <React.Fragment key={index}><p style={styles.postContent}>{item}</p><br /></React.Fragment>)}
                </div>
            </div>
            <div style={styles.offerJob} className="center-content">
                <Building style={styles.offerJobBuilding} />
                <div>
                    <h2 style={styles.offerJobTitle}>{Texts.offerJobTitle}</h2>
                    <div style={styles.offerJobContent}>
                        <p style={styles.offerJobText}>{Texts.offerJobContent}</p>
                        <br />
                        <p style={styles.offerJobText}>{Texts.offerJobSubContent}</p>
                    </div>
                </div>
            </div>
            <p style={styles.bottomText}>{Texts.bottomText}</p>
            <GetInTouch />
            <Footer />
        </>
    );
}