import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as CloseCircle } from '../../assets/svg/close-circle.svg';
import { ReactComponent as CloseSlice } from '../../assets/svg/close.svg';
import { StyleContext } from '../../providers/StyleContextProvider';

interface Props {
    open: boolean;
    styles?: any;
    toggle?(): void;
    closeCircle?: boolean;
    closeSlice?: boolean;
}

export const PopUpWindow: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("popUpWindow"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    if (!props.open) return null;

    return (
        <div style={styles}>
            <div style={styles.backgroundLayer} onClick={props.toggle}></div>
            <div style={styles.container}>
                {props.closeCircle && <CloseCircle style={styles.closeButton} onClick={props.toggle}/>}
                {props.closeSlice && <CloseSlice style={styles.closeButton} onClick={props.toggle}/>}
                {props.children}
            </div>
        </div>
    )
}