import { Color } from "../enums/Color"
import { commonHeader } from "./CommonHeader"

export const lowestHeader = {
    mobile: {
        ...commonHeader.mobile,
        row: {
            display: "flex",
            flexDirection: "row" as "row",
            position: "relative",
            zIndex: "3"
        },
        header: {
            background: Color.Blue,
            marginTop: "0%",
            marginLeft: "0%",
            width: "100%",
            height: "48.30vw",
            position: "relative",
        },
        desktopImageLeft: {
            display: "none"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "80%"
        },
        recruitersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.DeepBlack,
            border: "none",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "1.04vw",
            marginTop: "2.08vw",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            boxShadow: "5px 5px 50px #00000027",
            display: "none"
        },
        jobSeekersButton: {
            color: Color.White,
            fontWeight: "bold" as "bold",
            backgroundColor: Color.Green,
            border: "none",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            position: "relative" as "relative",
            marginRight: "2.60vw",
            marginTop: "2.08vw",
            transform: "none",
            width: "15vw",
            fontSize: "1.04vw",
            borderRadius: "0.6vw",
            height: "3.64vw",
            boxShadow: "5px 5px 50px #00000027",
            display: "none"
        },
        heading: {
            display: "none"
        },
        text: {
            display: "none"
        },
        jobTitleWrapper: {
            display: "none"
        }
    },
    desktop: {
        ...commonHeader.desktop,
        header: {
            height: "7.81vw"            
        },
        longBuildingSvg: {
            display: "none"
        },
        startCurve: {
            display: "block",            
            top: 0                        
        },
        endCurve: {
            display: "block",            
            top: "4vw"                        
        },
        desktopImageLeft: {
            display: "none"
        },
        recruitersButton: {
            display: "inline"
        },
        jobSeekersButton: {
            display: "inline"
        },
        mobileMenuButton: {
            display: "none",
        }
    }
}
