const currentYear = new Date().getFullYear();
// comment adClientId in test environment
const adClientId = "ca-pub-2924409830318564";
// const adClientId = "ca-pub-xxxxxxxxxxxxxxxxxx";

export const CommonTexts = {
    defaultCompanyLogo: "https://s3-ap-southeast-1.amazonaws.com/xpressjobstest/Organizations/xpressjobs_logo.jpg",
    defaultUser: process.env.PUBLIC_URL + "/img/default_user.jpg",
    metaDescription: "XpressJobs is trusted by over 6000 Sri Lankan & global organisation, has a proven track record of utilising recruitment technology with a Sri Lankan touch.",
    siteTitle: "XpressJobs : Jobs in Sri Lanka"
}

export const Contacts = {
    address: {
        no: "46,",
        road: "Hudson Road,",
        city: "Colombo 03."
    },
    email: "info@xpress.jobs",
    telephone: "+94117422414"
}

export const HomeTexts = {
    heading: "Give your Career a Jumpstart",    
    desktopText: "XpressJobs is Sri Lanka’s premier Job Board. A marketplace tasked to help companies hire the best talent. XpressJobs is already trusted by over 8000 Sri Lankan and global organizations, and has a proven track record of utilizing recruitment technology with a Sri Lankan touch. XpressJobs has now processed over 4 million job applications.",
    mobileText: "XpressJobs is Sri Lanka’s premier Job Board. XpressJobs is already trusted by over 8000 Sri Lankan and global organizations, and processed over 4 million job applications.",
    mobileSearch: "I'm looking for...",
    desktopSearch: "I'm looking for...   (Eg : Job title, Position, Company)",
    metaDescription: "XpressJobs is trusted by over 6000 Sri Lankan & global organisation, has a proven track record of utilising recruitment technology with a Sri Lankan touch.",
    siteTitle: "XpressJobs : Jobs in Sri Lanka"    
}

export const CandidateHomeTexts = {
    recommendedJobs: "Recommended For You",
    appliedJobs: "Jobs You Applied",
    viewAllUpper: "VIEW ALL",
    viewAllLower: "View All",
    loadMoreUpper: "LOAD MORE",
    loadMoreLower: "Load More",
    sortBy: "Sort by",
    savedJobPosts: "Saved Job Posts",
    savedJobs: "Saved Jobs",
    jobPosts: " Job Posts",
    adClient: adClientId,
    verticalAdSlot: "2458974270",
    noAppliedJobs: "You haven't applied for any jobs yet.",
    verifyEmail: "Please check your email for the confirmation link to verify your account."
}

export const JobCategoriesTexts = {
    title: "Job Categories"
}

export const FeaturedJobsTexts = {
    title: "Featured Jobs"
}

export const TopHiringOrganizationsTexts = {
    title: "Top Hiring Companies"
}

export const FooterTexts = {
    address: "46, Hudson Road, Colombo 03, Sri Lanka",
    email: "info@xpress.jobs",
    telephone: "011-7422414",    
    newsletterText: "Subscribe to our newsletter and receive an email alert on the most matching jobs every Wednesday.",
    searchPlaceholder: "Enter your email. . .",
    emailError: "Invalid email address",
    copyright: `© ${currentYear} Xpressjobs.lk (Private) Limited. All Rights Reserved`
}

export const JobViewTexts = {
    video: "Video",
    urgent: "Urgent",
    apply: "APPLY FOR JOB",
    downloadApplication: "DOWNLOAD JOB APPLICATION",
    save: "SAVE JOB",
    summary: "Summary",
    education: "Education",
    experience: "Experience",
    salary: "Salary Range",
    Benefits: "Benefits",
    description: "Job Description",
    jobAd: "View Job Ad",
    unemployment: "Unemployment in Sri Lanka is estimated to be over 390,816, Share this job and help another!",
    jobPost: " Job Post",
    jobPosts: " Job Posts",
    adClient: adClientId,
    verticalAdSlot: "4820030849",
    horizontalAdSlotOne: "5941540827",
    horizontalAdSlotTwo: "7063050802"
}

export const SearchPageTexts = {
    results: " Job Results",
    sortBy: "Sort by",
    perPage: "Posts Per Page",
    searchFilters: "Search Filters",
    industry: "All Industries",
    jobType: "Job Type",
    jobLevel: "Job Level",
    posted: "Posted",
    location: "Location",
    remote: "Work From Home / Hybrid",
    saveSearch: "Save This Search",
    savePlaceholder: "Give it a name…",
    saveButton: "SAVE SEARCH",
    saveSearchSuccess: "Search saved successfully",
    saveSearchError: "Error occurred while saving",
    searchHistoryNoCandidate: "You need to login to your account to get saved search histories",
    searchHistoryNoResult: "You have no saved search histories",
    adClient: adClientId,
    verticalAdSlotOne: "8789289349",
    verticalAdSlotTwo: "1956239255",
    verticalAdSlotThree: "1281451837",
    horizontalAdSlotOne: "4925054191",
    horizontalAdSlotTwo: "7359645844"
}

export const JobApplyPageTexts = {
    applyStage: {
        apply: "Apply for this job",
        screening: "Screening Stage",
        applied: "Successfully applied for the job",
        drafted: "Application Successfully Drafted"
    },
    stageDescription: {
        apply: "Please fill in your details below, then click 'Apply' button to submit your application. Your application will be treated with absolute confidentiality.",
        cvLessApply: "Please fill out the details below to submit your application. Your application will be treated with absolute confidence. Please note that the job is a CV-less job; however, we recommend you submit your CV at the next stage if you possess one. Answering all questions increases your chances of landing an interview.",
        screening: "Please answer the below questions accurately to increase your chances of being selected (shortlisted) for an interview. Applicants providing false answers may be disqualified from applying through XpressJobs.",
        applied: "Well done! Your application will now reach the recruiter. Please go to \"Status of Applications\" to view the status and progress of your application. Wishing you all the best.",
        drafted: "Congratulations! Your job application has been securely drafted. Continue your application journey from where you left off by visiting the 'Draft' page. Best of luck in your job search!"
    },
    fullName: "Full Name *",
    email: "Email *",
    cv: "CV *",
    cvDescription: "Please note, current CV displayed on the left will be sent to the recruiter when you hit the apply button. Once the application is sent out, the CV cannot be changed or updated. Please send out the latest CV available.",
    coverLetter: "Cover Letter (Optional)",
    uploadCV: "Drop file here or click to select",
    uploadCVMobile: "Click to select",
    maxFileSize: "Max file size : 2MB",
    applyNotice: "Please make sure to complete the 'questionnaire' at the next stage of application. We recommend the jobseekers to have two working phone numbers on the CV.",
    applyButton: "APPLY FOR JOB",
    submitNotice: "When you click the submit button, the CV and the answers provided will be sent immediately and directly to the recruiter. The content submitted will be considered final. Only shortlisted candidates will be contacted.",
    submitButton: "SUBMIT",    
    cvLessApplyNotice: "Please note that once you hit the submit button, the application will be directly sent to the recruiter.",
    cvLessCVUpload: "Your application has been sent to the organisation successfully. If you would like to upload your CV, please proceed.",
    saveDraft: "Save Draft",
    cancelButton: "Cancel",
    backToSearch: "BACK TO SEARCH",
    readTheGuidlines: "read the guidelines before answer the questions.",
    guideLinesTitle: "Application Screening Criteria",
    guideLines: [
        "Answers you provide are used to screen your application. This is an automated process.",
        "A score is calculated based on the answers you provide. If your score is less than the pass mark, the application will be marked as \"screening failed\". However, the application will get forward to the applied company via an email.",
        "Even though you have passed the screening, employers have the right to call only the selected candidates.",
        "Fake answers would get the recruiters to flag you. (E.g. If you do not have a degree, please don't click Yes.) 5 fake answer triggers from recruiters would result your account to be blacklisted.",
        "If you commit to attend to an interview and do not show-up, your account would be marked as \"interview no show\". 5 interview no show triggers from recruiters would result your account to be blacklisted."
    ],
    questionnaireNotCompleted: "Looks like you haven't answered some questions. Please answer to proceed.",
    viewMyApplications: "VIEW MY APPLICATIONS",
    viewDrafts: "VIEW DRAFTS",
    backToHome: "GO BACK TO HOME"
}

export const GetInTouchTexts = {
    title: "Got A Question? Get In Touch",
    description: "We're here to help. Send us an email or Call us at ",
    telephone: "+94117422414",
}

export const SubmitFormText = {
    error : "Some of the required fields are missing."
}

export const CandidateProfilePageTexts = {
    shareCV: "Share my CV with employers",
    completeProfile: "Complete your profile for better experience",    
    deleteWarningMessage: "This will delete your profile with all the saved information. And those includes …",
    deleteSuccessMessage: "Your Xpressjobs account is deleted successfully. Thank you for using Xpressjobs",
    adClient: adClientId,
    verticalAdSlot: "3875997725",
    horizontalAdSlot: "4684299472",
    addNewCV: "Add new CV to your profile",
    fileSize: "Max file size : 2MB"
}

export const CVFormatsPageTexts = {
    text: "Don’t pay for expensive CV-writers or waste your time looking for CV formats that are editable. You can now choose a CV-format depending on the job type you would like to apply and buy it off Xpressjobs for just Rs 300/= per CV."
}

export const DisabilityFriendlyTexts = {
    heading: "Employment Opportunities for the Ready, Willing and Able to Work !",
    disabledLadyImageURL: "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/disabled-lady-working.png",
    guyWithIPadImageURL: "https://v2-marketing-content.s3.ap-southeast-1.amazonaws.com/disability-friendly/disabled-guy-with-ipad.png",
    posts: [
        {
            title: "Differently-Abled Friendly Employment",
            paragraphs: [
                "We get many calls, emails from individuals who are differently-abled struggling to find work in Sri Lanka. The single biggest barrier to full employment for the differently-abled is the “fear of the unknown” not knowing if a company is open to hiring them. At the same time we had companies who loved to hire ready willing and able to work individuals and just wanted to get this news out there!",
                "So we bridge the gap by introducing a new tick off box for companies to indicate they offer disabled friendly employment in the job site.",
                "We currently have many companies onboard and signed up with us who are offering differently-able friendly employment and the list keeps growing daily. The logo in front of the company will indicate that they are differently-abled friendly.",
                "We hope the site will also help identify job opportunity for people with different disabilities - Visual, auditory, orthopedic, etc. in a much easier manner."
            ]
        },
        {
            title: "Placement For The Differently-Abled",
            paragraphs: [
                "The objective of this page is to find gainful employment to those who are physically challenged in some way. The job seekers can easily access this site and post their profile and explain what kind of job they are looking for and will have the ability to mention details about them.",
                "The site can also be used by the employers who would like to engage the differently challenged people in their organization. They can post the jobs available in the organization for which they are willing to employ differently-abled people also want to give them and equal opportunity to apply.",
                "The employers can also search and access the job profiles of the job seekers and directly contact them and assess the suitability of the candidates."
            ]
        }
    ],
    offerJobTitle: "Are You A Recruiter And Willing To Offer A Job?",
    offerJobContent: "Would like to be listed as an equal opportunity provider/ differently-abled friendly employment ?",
    offerJobSubContent: "Sign up with us if you’re not already registered. If you already recruit through us, just email us at info@xpress.jobs or give us a call to get your company listed on this page too.",
    bottomText: "Share this link to differently-abled individuals, spread the word so more companies will come on board with us to give an equal opportunity to differently-abled candidates."

}

export const CandidatePremiumPageTexts = {
    makePremium: "Make Your Account Premium!",
    description: "Making your account premium will make your CV go to the top of the CV list of every application you make. When a recruiter logs into the recruiter accounts your CV would be one of the prioritized CVs and on top of the CV pile giving you a higher chance of being shortlisted for an interview.",
    makeTop: "Make your application on top of the application list",
    highlightApplication: "Highlight your application to recruiters with a “premium label”",
    adsFree: "Google Ads free job browsing",
    bioData: "Give a quick glance of your xpressjobs account bio-data to recruiters when they click on your name"
}

export const PreferredPartnersTexts = {
    text: "Please click on each logo to understand more details on each respective partnership. If you think of possible partnerships with XpressJobs please get in touch with us."
}

export const ErrorPageTexts = {
    connect: "We couldn’t connect the wires",
    unavailable: "The page you are looking for might have been removed or is temporarily unavailable.",
    copyright: "© 2023 Xpressjobslk (Private) Limited. All Rights Reserved"
}

export const OrganizationPageTexts = {    
    adClient: adClientId,    
    horizontalAdSlotOne: "1178462447",
    horizontalAdSlotTwo: "6047645744"
}

export const PaymentIcons: string[] = [    
    process.env.PUBLIC_URL + "/img/visa.png",
    process.env.PUBLIC_URL + "/img/vishwa.png",
    process.env.PUBLIC_URL + "/img/ez-cash.png",
    process.env.PUBLIC_URL + "/img/m-cash.png",
    process.env.PUBLIC_URL + "/img/amex.png",
    process.env.PUBLIC_URL + "/img/vw.png"
]

export const  GoogleMapTexts={
    markerURL: "https://www.google.com/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v4_outline-2-medium.png,assets/icons/spotlight/spotlight_pin_v4-2-medium.png,assets/icons/spotlight/spotlight_pin_v4_dot-2-medium.png&highlight=c5221f,ea4335,b31412?scale=1",
    locationURL: "https://www.google.com/maps/place/XpressJobs/@6.915065,79.85273,20z/data=!4m14!1m7!3m6!1s0x3ae2596a037bddb3:0x913d83bb98e1f46e!2sSupreme+Corporate+Solutions+(Pvt)+Ltd!8m2!3d6.914933!4d79.8525303!16s%2Fg%2F11c7614506!3m5!1s0x3ae25bdf2f5c384d:0x58cf15579acc3966!8m2!3d6.9150188!4d79.8527285!16s%2Fg%2F11g8wvrpn4?hl=en-US&entry=ttu"
}