import { useContext, useEffect, useState } from "react";
import { StyleContext } from "../../providers/StyleContextProvider";
import { useMediaQuery } from "react-responsive";
import { merge } from "react-merge";

interface Props {
  styles?: any;   
}

export const Tooltip: React.FC<Props> = (props) => {
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("tooltip"));
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  useEffect(() => {
    let extendedStyles = merge(styles, props.styles)
    setStyles(extendedStyles)
  }, [isMobile]);

  return (
    <div style={styles}>
      <div style={styles.pointer}></div>
      {props.children}
    </div>
  );
};
