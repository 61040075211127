export  const homePage = {
    mobile: {        
        flippingWrapper: {
            display: "flex",
            flexDirection: "column"
        }
    },
    desktop: {
        flippingWrapper: {
            flexDirection: "column-reverse"
        }
    }
}