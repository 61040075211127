import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive"
import { Color } from "../../enums/Color";
import ContentLoader from "react-content-loader";
import { LoggedCandidate } from "../../models/LoggedCandidate";

interface Props {
  candidate : LoggedCandidate | null;
}

const loaderStyle = {
  mobile: {
    imageWrapper: {
      height: "24.15vw",
      width: "24.15vw",
      position: "absolute" as "absolute",
      top: "36.23vw"
    },
    detailsWrapper: {
      height: "136.71vw"
    }
  },
  desktop: {
    imageWrapper: {
      height: "10.16vw",
      width: "10.16vw",
      top: "12.96vw"
    },
    detailsWrapper: {
      height: "18.49vw",
      marginTop: "unset"
    }
  }
};

export const PersonalDetailsLoader : React.FC<Props> = (props) => {
  const isMobile = useMediaQuery({query : "(max-width : 786px)"});
  var styles = loaderStyle.mobile;
  if(!isMobile) {
    let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
    styles = extendedStyle;
  }

  return (
    <>
      <div style={styles.imageWrapper}>
        <ContentLoader
          speed={1}
          backgroundColor={Color.LoaderBackground}
          foregroundColor={Color.LoaderForeground}
          width= {styles.imageWrapper.width}
          height= {styles.imageWrapper.height}
        >
          {isMobile ? <>
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
            </> :
            <>
              <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
            </>}
        </ContentLoader> 
      </div>
      <div style={styles.detailsWrapper}>
        <ContentLoader
          speed={1}
          backgroundColor={Color.LoaderBackground}
          foregroundColor={Color.LoaderForeground}
          width= "auto"
          height= {styles.detailsWrapper.height}
        > 
          { isMobile ? <>
            <rect x="34.76%" y="0" rx="3" ry="3" width="31.28%" height="6.18%" />
            <rect x="68.72%" y="0" rx="3" ry="3" width="31.28%" height="6.18%" />
            <rect x="0" y="10.60%" rx="3" ry="3" width="25.94%" height="3.18%" />
            <circle cx="30.81%" cy="12%" r="2.5%" />
            <rect x="0" y="16.25%" rx="3" ry="3" width="27.81%" height="3%" />
            <rect x="0" y="22.32%" rx="3" ry="3" width="42.51%" height="2.30%" />
            <rect x="0" y="26.15%" rx="3" ry="3" width="42.51%" height="2.30%" />
            <rect x="0" y="31.10%" rx="3" ry="3" width="37.97%" height="3.18%" />
            <rect x="0" y="36.93%" rx="3" ry="3" width="100%" height="9.89%" />
            <rect x="0" y="53.89%" rx="3" ry="3" width="100%" height="25.80%" />
            <rect x="0" y="85.87%" rx="3" ry="3" width="55.08%" height="3.18%" />
            <rect x="85.83%" y="85.87%" rx="3" ry="3" width="8.82%" height="3.18%" />
            <rect x="0" y="92.05%" rx="3" ry="3" width="100%" height="7.95%" />
          </> : 
          <>
            <rect x="0" y="19.15%" rx="3" ry="3" width="15.91%" height="14.08%" />
            {!props.candidate?.isPremium && <rect x="0" y="37.46%" rx="3" ry="3" width="15.91%" height="14.08%" />}
            <rect x="19.58%" y="0" rx="3" ry="3" width="14.85%" height="9.60%" />
            {props.candidate?.isPremium && <circle cx="39%" cy="5%" r="2%" />}
            <rect x="78.06%" y="0" rx="3" ry="3" width="16.80%" height="5.07%" />
            <rect x="96.49%" y="0" rx="3" ry="3" width="2.69%" height="5.63%" />
            <rect x="19.58%" y="12.39%" rx="3" ry="3" width="11.34%" height="6.48%" />
            <rect x="19.58%" y="24.51%" rx="3" ry="3" width="19.33%" height="5.35%" />
            <rect x="19.58%" y="32.68%" rx="3" ry="3" width="23.25%" height="5.07%" />
            <rect x="19.58%" y="51.83%" rx="3" ry="3" width="7.26%" height="6.48%" />
            <rect x="19.58%" y="66.76%" rx="3" ry="3" width="77.49%" height="19.15%" />  
          </>} 
        </ContentLoader>
      </div>
    </>
  )
}