import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RedirectByLogin: React.FC = () => {
    const navigate = useNavigate();   
    
    useEffect(() => {                     
        navigate("/candidates/login"); // Redirect to the candidates login page
    }, []);
    
    return null; // This component doesn't render anything
};