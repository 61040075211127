import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApplicationStatus } from '../../models/ApplicationStatus';
import { CandidateApplication } from '../../models/CandidateApplication';
import { getCandidateJobApplications } from '../../services/CandidateService';
import { getApplicationStatus } from '../../services/JobService';
import { Option } from '../../models/SelectInputs';
import { MultySelect } from '../Shared/MultySelect';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { ApplicationCard } from './ApplicationCard';
import { Pagination } from '../Shared/Pagination';
import useErrorPage from '../../hooks/useErrorPage';
import { ReactComponent as CheckMark } from '../../assets/svg/checkmark.svg';
import { FeedbackCard } from '../Shared/FeedbackCard';
import { StatusOfApplicationsLoader } from '../Loaders/StatusOfApplicationLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSEOKeyword } from '../../helpers/Common';
import { LoggedCandidate } from '../../models/LoggedCandidate';

interface Props {    
    candidate: LoggedCandidate;
}

export const StatusOfApplications: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();    
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("statusOfApplications"));    
    const [jobApplications, setJobApplications] = useState<CandidateApplication[]>([]);
    const [statuses, setStatuses] = useState<ApplicationStatus[]>([]);
    const [options, setOptions] = useState<Option[]>([]);    
    const [selectedStatusLabels, setSelectedStatusLabels] = useState<string>("All");
    const [loading, setLoading] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });    
    const page: number = searchParams.get("page") === null ? 1 : +searchParams.get("page")!;
    const statusIds: string = searchParams.get("statusIds") === null ? "" : searchParams.get("statusIds")!;
    const [totalPages, setTotalPages] = useState<number>(1); 
    const [recordCount, setRecordCount] = useState<number>(0);
    const pageSize: number = isMobile ? 5 : 15;
    const [toErrorPage] = useErrorPage();
    const scrollElementRef = useRef<any>();
    
    useEffect(() => {
        setStyles(styleContext.getComponentStyle("statusOfApplications"));                   
    }, [isMobile]);

    useEffect(() => {
        getStatusList();
    }, [])

    useEffect(() => {
        getJobApplications();
        createOptionList(statuses);
    }, [page, statusIds])

    async function getStatusList() {        
        await getApplicationStatus()
        .then((statusList: ApplicationStatus[]) => {            
            createOptionList(statusList);
            setStatuses(statusList);
        })
        .catch((error) => {
            toErrorPage(error);
        });
    }

    async function getJobApplications() {
        await getCandidateJobApplications(page, pageSize, props.candidate.candidateId, statusIds, props.candidate.token)
        .then((jobApplicationList: CandidateApplication[]) => {
            setJobApplications(jobApplicationList);
            if(jobApplicationList.length > 0) {
                let totalPageCount = Math.ceil(jobApplicationList[0].recordCount / pageSize);            
                setTotalPages(totalPageCount);
                setRecordCount(jobApplicationList[0].recordCount);
            } else {
                setTotalPages(1);
                setRecordCount(0);
            } 
            setLoading(false);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const createOptionList = (statusList: ApplicationStatus[]) => {
        let statusIdArray: string[] = [];     
        if(statusIds !== "") {
            statusIdArray = statusIds.split(',');
        }
        const tempOptions = new Array<Option>();
        let optionAll: Option = {
            value: "0",
            option: <>                
                <label style={styles.optionLabel}>{"All"}</label>                
                {statusIdArray.length === 0 ? <CheckMark style={styles.checkMark}/> : <div style={styles.checkbox}></div>}
            </>,
            checked: statusIdArray.length === 0
        }
        tempOptions.push(optionAll);
        statusList.forEach((status) => {            
            let option: Option = {
                value: status.applicationStatusId.toString(),
                option: <>
                    <label style={{...styles.optionDot, ...{backgroundColor: status.colorCode}}}></label>
                    <label style={styles.optionLabel}>{status.status}</label>                    
                    {statusIdArray.includes(status.applicationStatusId.toString()) ? <CheckMark style={styles.checkMark}/> : <div style={styles.checkbox}></div>}
                </>,                
                checked: statusIdArray.includes(status.applicationStatusId.toString())
            }
            tempOptions.push(option);
        });
        setOptions(tempOptions);
        let tempSelectedStatusLabels = "All";            
        if(statusIdArray.length !== 0) {
            tempSelectedStatusLabels = "";
            statusList.filter(x => statusIdArray.includes(x.applicationStatusId.toString())).forEach(status => {                                    
                tempSelectedStatusLabels = tempSelectedStatusLabels + status.status + ",";                       
            });
            tempSelectedStatusLabels = tempSelectedStatusLabels.substring(0, tempSelectedStatusLabels.length - 1);
        }                                      
        setSelectedStatusLabels(tempSelectedStatusLabels);
    }    

    const selectOption = (selectedStatusId: string) => {        
        if(selectedStatusId === "0") {
            searchParams.delete("statusIds");           
        }
        else {               
            let statusIdArray: string[] = [];     
            if(statusIds !== "") {
                statusIdArray = statusIds.split(',');
            }        
            let selectedStatusIndex = statusIdArray.indexOf(selectedStatusId);
            if(selectedStatusIndex === -1) {
                statusIdArray.push(selectedStatusId);
            }
            else {
                statusIdArray.splice(selectedStatusIndex, 1);
            }
            if(statusIdArray.length === 0) {
                searchParams.delete("statusIds");
            }
            else {
                searchParams.set("statusIds", statusIdArray.toString());
            }            
        }              
        searchParams.set("page", "1");
        const newUrl = `?${searchParams}`;        
        navigate(newUrl);
    }

    const handlePagination = (page: number) => {
        searchParams.set("page", page.toString());
        const newUrl = `?${searchParams}`;
        scrollElementRef.current.scrollIntoView();
        navigate(newUrl);                
    }

    const onCardClick = (application: CandidateApplication) => {        
        let seoKeyword = getSEOKeyword(application.jobTitle + "-" + application.organizationName);
        navigate("/jobs/view/" + application.jobId + "/" + seoKeyword + "?applyEnable=false");        
    }

    return (
        <>
            <p style={styles.title}>Status of Applications</p>
            {loading ? <StatusOfApplicationsLoader/> : <div>
                <div style={styles.topWrapper} ref={scrollElementRef}>
                    <div style={styles.applicationCount}><span style={styles.applicationCount.count}>{recordCount}</span> Applications</div>
                    <div style={styles.filterByWrapper}>
                        <label style={styles.filterByLabel}>Filter by</label>
                        <MultySelect styles={styles.filterBySelect} label={selectedStatusLabels} options={options} selectOption={selectOption}/>
                    </div>
                </div>
                {jobApplications.length > 0 ? <>
                    <div style={styles.applicationsWrapper}>
                        {jobApplications.map((jobApplication, index) => 
                            <ApplicationCard key={index} styles={styles.application} application={jobApplication} onClick={onCardClick}/>
                        )}
                    </div>
                    {(totalPages > 1) && <Pagination page={page} totalPages={totalPages} handlePagination={handlePagination}/>}
                </>
                : <FeedbackCard styles={styles.noResults} message={"No results found!"}/>}
            </div>}
        </>
    )
}