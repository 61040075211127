import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';

interface Props {
    styles: any;
}

export const TestimonialLoader: React.FC<Props> = (props) => {
    let styles = props.styles;    

    return (
        <> 
            <div style={styles.rowWrapper}>
                <img style={styles.image_2} src={process.env.PUBLIC_URL + "/img/testimonial_2.png"}/>
                <ContentLoader
                speed={1}
                height={"52vh"}
                width={"100%"}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                >
                    <rect x="30%" y="-2.08vh" rx="3" ry="3" width="40%" height="4.46vh"/>
                    <rect x="5%" y="12%" rx="3" ry="3" width="30%" height="3%"/>                    
                    <rect x="80%" y="24%" rx="3" ry="3" width="10%" height="12%"/>
                    <rect x="5%" y="38%" rx="3" ry="3" width="60%" height="4%"/>
                    <rect x="5%" y="44%" rx="3" ry="3" width="30%" height="4%"/>                    
                    <rect x="5%" y="56%" rx="3" ry="3" width="70%" height="1.8%"/>
                    <rect x="5%" y="60%" rx="3" ry="3" width="76%" height="1.8%"/>
                    <rect x="5%" y="64%" rx="3" ry="3" width="68%" height="1.8%"/>
                    <rect x="5%" y="68%" rx="3" ry="3" width="90%" height="1.8%"/>
                    <rect x="5%" y="72%" rx="3" ry="3" width="86%" height="1.8%"/>
                    <rect x="5%" y="76%" rx="3" ry="3" width="76%" height="1.8%"/>
                    <rect x="5%" y="80%" rx="3" ry="3" width="80%" height="1.8%"/>
                    <rect x="5%" y="84%" rx="3" ry="3" width="20%" height="1.8%"/>
                    <rect x="70%" y="86%" rx="3" ry="3" width="20%" height="12%"/>                    
                </ContentLoader>
                <img style={styles.image_3} src={process.env.PUBLIC_URL + "/img/testimonial_3.png"}/>    
            </div>            
        </>                       
    );
}