import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Cart} from "../../assets/svg/cart.svg";

interface Props {
    styles: any;
}

export const StoreButton: React.FC<Props> = (props) => {
    const styles = props.styles;
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const storeItems = [
        "CV Format Store",
        "SMS Job Alerts",
        "Online Courses"
    ]

    const handleClick = (index: number) => {
        if (index === 0) {
            navigate("/cvFormats");
        }
        else if (index === 1) {
            navigate("/smsRegister")
        }
        else if (index === 2) {
            window.open("https://school.xpress.jobs/", "_blank");
        }
    }

    return (
        <div style={styles} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
            onClick={() => { setHover(!hover); }}
        >
            <Cart style={styles.icon}/>
            <div style={styles.text}>{"STORE"}</div>
            {hover && <div style={styles.dropdown}>
                {storeItems.map((item, index) =>
                    <div key={index} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(-1)}>
                        <div style={{ ...styles.dropdownLabel, ...(hoveredIndex === index && styles.dropdownLabel.hover) }} onClick={() => handleClick(index)}>{item}</div>
                        <hr style={{ ...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || index + 1 === storeItems.length) && styles.hoveredBorder) }} />
                    </div>
                )}
            </div>}
        </div>
    )
}
