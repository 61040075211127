import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Header } from "../components/Shared/Header";
import { StyleContext } from "../providers/StyleContextProvider";
import { Footer } from "../components/Shared/Footer";
import { CheckoutModel, CheckoutValidation } from "../models/CheckoutModel";
import { TextInput } from "../components/Shared/Inputs/TextInput";
import { Button } from "../components/Shared/Buttons/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as CircleClose } from '../assets/svg/circle-close.svg';
import { ReactComponent as EmptyCart } from '../assets/svg/empty-cart.svg';
import { CVFormat } from "../models/CVFormat";
import { postCheckoutRequest } from "../services/CheckoutService";
import useErrorPage from "../hooks/useErrorPage";
import { WebXPayModel } from "../models/WebXPayModel";
import $ from 'jquery'
import { PremiumCard } from "../components/CandidatePremium/PremiumCard";
import { getPromoCodeValidation } from "../services/CandidateService";
import { AuthContext } from "../providers/AuthContextProvider";
import { PromoCode } from "../models/PromoCode";
import { PaymentIcons } from '../helpers/LayoutTexts';
import { LoggedCandidate } from "../models/LoggedCandidate";
import { validateEmail,validateName } from '../helpers/Common';
import { SubmitFormText } from '../helpers/LayoutTexts'
import { useAlert } from '../hooks/useAlert';

interface StateProps {
    checkoutType: number;   
    cvList?: CVFormat[];  
    price: number;
    previousUrl: string; 
}

interface PriceGroup {
    [key: number]: {
        price: number;
        totalCount: number;
    };
  }

const intialFormInputs: CheckoutModel = {     
    fullName: "",
    email: "",
    promoCode: ""
}

const checkoutValidation: CheckoutValidation = {
    fullName: {},
    email: {}
}

export const CandidateCheckoutPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [checkout, setCheckout] = useState<CheckoutModel>(intialFormInputs);
    const styleContext = useContext(StyleContext);    
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("candidateCheckoutPage")); 
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();              
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const state = location.state as StateProps;  
    const [cvFormats, setCVFormats] = useState<CVFormat[]>(state.checkoutType === 1 ? state.cvList! : []);
    const [price, setPrice] = useState<number>(state.price);
    const [promoInvalid, setPromoInvalid] = useState<boolean>(false);
    const [promoInvalidMessage, setPromoInvalidMessage] = useState<string>();
    const [discount, setDiscount] = useState<number>();
    const [validation, setValidation] = useState<CheckoutValidation>(checkoutValidation);
    const previousUrl = state.previousUrl;    
    const [toErrorPage] = useErrorPage();
    const { Alert, openAlert } = useAlert();
    
    useEffect(() => {        
        setStyles(styleContext.getComponentStyle("candidateCheckoutPage"));    
    }, [isMobile]);

    useEffect(() => {       
        if(candidate !== null) {   
            let tempFormInputs: CheckoutModel = {  
                candidateId: candidate.candidateId,   
                fullName: candidate.forename + " " + candidate.surname,
                email: candidate.email,
                promoCode: "",
            }
            setCheckout(tempFormInputs);
        } 
    }, []);

    const groupedCv: PriceGroup = cvFormats.reduce((sortedCv: PriceGroup, { price }) => {
        if (!sortedCv[price]) {
            sortedCv[price] = { price, totalCount: 0 };
        };
        sortedCv[price].totalCount += 1;
    
        return sortedCv;
    }, {});

    const sortedCv = Object.values(groupedCv);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setCheckout((values: any) => ({ ...values, [name]: value }));
        if(name === "promoCode") {
            setPromoInvalid(false);
        }
        if(validation[name] && validation[name].invalid) {
            validateInput(name, value)
        }
    }

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        validateInput(name, value);
    }
 
    const validateInput = (name: string, value: string) => {
        if (name === "fullName") {
            setValidation((values: any) => ({...values, [name]: validateName(name, value, 100)}));
        }
        if(name === "email") {
            if (value === "") {
                setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "Email is required" } }));
            } else if (!validateEmail(value)) {
                setValidation((values: any) => ({ ...values, [name]: { valid: false, invalid: true, invalidMessage: "Invalid email address!" } }));
            } else {
                setValidation((values: any) => ({ ...values, [name]: { valid: true, invalid: false }}));
            }
        }
    }
    
    const validateForm = ():number => {
        let formValidation: CheckoutValidation = {
            fullName: { valid: true },
            email: { valid: true }
        }
        formValidation.fullName = validateName('fullName', checkout.fullName, 100);
        if (checkout.email === "") {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Email is required"};
        }
        else if (!validateEmail(checkout.email)) {
            formValidation.email = {valid: false, invalid: true, invalidMessage: "Invalid email address!"};
        }        
        setValidation(formValidation);
        const validInputCount = Object.keys(formValidation).filter(key => formValidation[key].valid).length;
        return validInputCount;
    }
    
    async function handleCheckoutClick(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let validInputCount = Object.keys(validation).filter(key => validation[key].valid).length;        
        if(validInputCount < 2) {
            validInputCount = validateForm();
        }
        if(validInputCount === 2) {            
            checkout.checkoutType = state.checkoutType;
            checkout.cvFormats = cvFormats;
            checkout.price = price;        
            await postCheckoutRequest(checkout)
            .then((webXPayModel: WebXPayModel) => {
                $('.checkout').append('<form action="' + webXPayModel.checkoutUrl + '" id="form" name="form" method="post" style="display:none;">' +
                    '<input type="text" name="first_name" value="' + webXPayModel.firstName + '" />' +
                    '<input type="text" name="last_name" value="' + webXPayModel.lastName + '" />' +
                    '<input type="text" name="email" value="' + webXPayModel.email + '" />' +
                    '<input type="text" name="contact_number" value="' + webXPayModel.contactNumber + '" />' +
                    '<input type="text" name="address_line_one" value="' + webXPayModel.address + '" />' +
                    '<input type="text" name="process_currency" value="' + webXPayModel.currency + '" />' +
                    '<input type="text" name="secret_key" value="' + webXPayModel.secretKey + '" />' +
                    '<input type="text" name="custom_fields" value="' + webXPayModel.customFields + '" />' +
                    '<input type="text" name="payment" value="' + webXPayModel.paymentStr + '" />' +
                '</form>');            
                var checkoutForm = document.getElementById("form") as HTMLFormElement;
                checkoutForm.submit();
            })
            .catch((error) => {
                toErrorPage(error);            
            });
        }
        else {
            openAlert("error", SubmitFormText.error);
        }
    }

    async function handlePromoCodeApply(event: React.FormEvent<HTMLFormElement>) {   
        event.preventDefault();        
        if(candidate === null) {
            setPromoInvalid(true);
            setPromoInvalidMessage("Please Login to proceed");
        } else if (checkout.promoCode === "") {
            setPromoInvalid(true);
            setPromoInvalidMessage("Promo Code is required");
        } else {
            await getPromoCodeValidation(candidate.candidateId, checkout.promoCode, state.checkoutType, candidate!.token)
            .then((promoCode: PromoCode) => {
                if(promoCode) {                    
                    let discountRate = promoCode.discount;
                    let deduction = (price*discountRate)/100;
                    setDiscount(deduction);
                } else {
                    setDiscount(undefined);
                    setPromoInvalid(true);
                    setPromoInvalidMessage("Invalid Promo Code. Try Again");
                }
            })
            .catch((error) => {
                toErrorPage(error);            
            });
        }        
    }

    const handleCloseButtonClick = () => {
        navigate(previousUrl);
    }

    const removeCV = (cvFormat: CVFormat) => {
        const index = cvFormats.indexOf(cvFormat, 0);
        if (index > -1) {            
            let tempCvList = [...cvFormats];
            tempCvList.splice(index, 1);
            setCVFormats(tempCvList);
            setPrice(price - cvFormat.price);
        }
    }

    return (
        <div style={styles}>
            <div style={styles.backdropLayer} onClick={handleCloseButtonClick}></div>
            <div style={styles.mainContainer}>
                {!isMobile && <Header styleSheet="lowHeader" searchEnable={false} heading={"Checkout"} backButtonEnable={true} backButtonText={'Back'}/>}
                <div style={{...styles.container, ...styles.toggleWrapper}}>
                    <div style={styles.leftWrapper}>                        
                        <Close style={styles.closeIcon} onClick={handleCloseButtonClick}/>                        
                        <div style={styles.title}>{"Checkout"}</div>                        
                        <form onSubmit={handleCheckoutClick} style={styles.formWrapper}>                        
                            {state.checkoutType === 1 && <div style={styles.infoWrapper}>
                                <div style={styles.subTitle}>{"Your Info"}</div>
                                <div style={styles.toggleWrapper}>                                                            
                                    <TextInput
                                        inputType={'text'}
                                        name={'fullName'}
                                        value={checkout.fullName}
                                        label={isMobile ? 'Name *':'Full Name *'}
                                        handleChange={handleInputChange}
                                        handleBlur={handleInputBlur}   
                                        styles={{...styles.input, ...styles.rightMargin}}
                                        validation={validation.fullName}
                                    />                            
                                    <TextInput
                                        inputType={'text'}
                                        name={'email'}
                                        value={checkout.email}
                                        label={'Email *'}
                                        handleChange={handleInputChange}
                                        handleBlur={handleInputBlur}
                                        styles={{...styles.input, ...styles.leftMargin}}
                                        validation={validation.email}
                                    />
                                </div>
                            </div>}  
                            {(isMobile && state.checkoutType === 2) && <PremiumCard styles={styles.premiumCard}/>}                          
                            <div style={{...styles.promoWrapper, ...(promoInvalid && styles.promoInvalid)}}> 
                                <div style={styles.toggleWrapper}>
                                    <div style={{...styles.subTitle, ...styles.subTitle.promoTitle}}>{"Have a Promo Code?"}</div>
                                    <div style={styles.codeWrapper}>
                                        <TextInput
                                            inputType={'text'}
                                            name={'promoCode'}
                                            value={checkout.promoCode}
                                            handleChange={handleInputChange}
                                            styles={styles.promoInput}
                                            disabled={discount !== undefined}
                                            placeHolder={'XXXX XXXXX XXXXXXXXX'}
                                        />
                                        <Button type="button" style={{...styles.applyButton, ...(discount && styles.applyButton.applied)}} onClick={handlePromoCodeApply} disabled={discount !== undefined}>{discount ? "APPLIED" : "APPLY"}</Button>
                                    </div>
                                </div>
                                {promoInvalid && <div style={styles.promoInvalidMessage}>{promoInvalidMessage}</div>}
                            </div>
                            <div style={styles.paymentWrapper}> 
                                <hr style={styles.iconLine}/>                               
                                <div style={styles.subTitle}>{"Available Payment Methods"}</div>
                                <div style={styles.iconGride}>
                                    {PaymentIcons.map((paymentIcon, index) =>                    
                                        <img key={index} src={paymentIcon} style={styles.paymentIcon}/>                    
                                    )}
                                </div>                               
                            </div>                            
                        </form>
                    </div>
                    <div style={styles.rightWrapper}>
                        <div style={styles.cart}>
                            <div style={styles.cart.body}>
                                <h1 style={styles.cart.heading}>{"Summary"}</h1>
                                {state.checkoutType === 1 && <>
                                    {price !== 0 ? <>
                                        {cvFormats.map((cv, index) => 
                                            <div key={index} style={styles.cart.itemWrapper}>                                                 
                                                <img style={styles.cart.image} src={cv.previewUrl}></img>
                                                <div>
                                                    <p style={styles.cart.name}>{cv.name}</p>
                                                    <p style={styles.cart.description}>{cv.description}</p>
                                                </div>
                                                <div style={styles.cart.cvPrice}>{cv.price} LKR</div>                                        
                                                <CircleClose style={styles.cart.closeIcon} onClick={() => removeCV(cv)}/>
                                            </div>
                                        )}                                    
                                    </>
                                    : <EmptyCart style={styles.cart.emptyCart}/>}
                                </>}
                                {state.checkoutType === 2 && <PremiumCard styles={styles.premiumCard}/>}
                            </div>
                            <hr style={styles.cart.line}/>
                            {(state.checkoutType === 1 && price) &&
                             <div style={styles.cart.cvSummaryWrapper}>
                                <div style={styles.cart.cvSummary}>{cvFormats.length} CVs</div>
                                 <>
                                   {sortedCv.map((cv, index) => 
                                     <div key={index}>
                                        <div style={styles.cart.cvSummary}>{cv.price} x {cv.totalCount}</div>
                                     </div>
                                   )}
                                </>
                             </div>}
                            <p style={{...styles.cart.label, ...(!discount && styles.cart.boldLabel)}}>Total <span style={{...styles.cart.price, ...(!discount && styles.cart.boldPrice)}}>{price} LKR</span></p>
                            {discount && <p style={styles.cart.label}>Discount <span style={styles.cart.price}>{-discount} LKR</span></p>}
                            {discount && <p style={styles.cart.netLabel}>Net Total <span style={styles.cart.netPrice}>{price - discount} LKR</span></p>}
                        </div>
                        {price > 0 && <Button type="button" style={styles.checkoutButton} onClick={handleCheckoutClick}>{"CHECKOUT"}</Button>}
                    </div>
                </div>
                {!isMobile && <Footer/>}
                <div className="checkout"></div>
            </div>
            <Alert/>
        </div>
    );
}