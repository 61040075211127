import { Color } from "../enums/Color"
import { Font } from "../enums/Font"

export const commonHeader = {
    mobile: {
        row: {
            display: "flex",
            flexDirection: "row" as "row",
            position: "relative"
        },
        desktopImageLeft: {
            display: "none",
            position: "absolute" as "absolute",
            height: "65%",
            bottom: "0",
            left: "0",
            transform: "translate(-40%, 0)"
        },
        longBuildingSvg: {
            position: "absolute" as "absolute",
            right: "0",
            bottom: "0",
            width: "95%"
        },
        startCurve: {
            display: "none",
            position: "absolute" as "absolute",
            top: "-9.8vw",
            width: "62vw",
            height: "25.88vw"
        },
        endCurve: {
            display: "none",
            position: "absolute" as "absolute",
            top: "-4.5vw",
            width: " 71.82vw",
            height: "25.88vw"
        },
        backButton: {
            wrapper: {
                display: "block",
                position: "absolute" as "absolute",
                top: "18.42vw",
                left: "6.28vw",
                alignItems: "center",
                zIndex: "1",
                cursor: "pointer",
                WebkitTapHighlightColor: "transparent"
            },
            button: {
                width: "6.7vw",
                height: "6.7vw",
                backgroundColor: Color.White,
                boxShadow: "0px 0px 0px 1.45vw rgb(255 255 255 / 0.35)",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0"
            },
            icon: {
                fill: Color.DeepBlack,
                width: "2.41vw"
            },
            text: {
                display: "none",
                marginLeft: "0.78vw",
                fontSize: "0.93vw",
                fontWeight: "bold",
                color: Color.White
            }
        },
        title: {
            margin: "4.83vw 0 0 4.83vw",
            cursor: "pointer",
            width: "39.37vw",
            height: "9.18vw"
        },
        label: {
            textDecoration: "none",
            fontSize: "4.34vw",
            fontWeight: "bold" as "bold",
            letterSpacing: "0px",
            color: Color.LightAsh,
            opacity: "1",
            cursor: "pointer",
            hover: {
                borderBottom: "2px solid " + Color.DarkAsh
            }
        },
        dropdownLabel: {
            position: "relative" as "relative",
            textDecoration: "none",
            fontSize: "4.4vw",
            fontWeight: "medium",
            letterSpacing: "0px",
            color: Color.MiddleBlack,
            cursor: "pointer",
            opacity: "1"
        },
        logoutDropdownLabel: {
            color: Color.Red,
            fontWeight: 500
        },
        logoutIcon: {
            float: "right"
        },
        logoutDropdownItem: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            hover: {
                fill: Color.White,
                backgroundColor: Color.Blue,
                padding: "1.38vw",
                borderRadius: "1.94vw",
                color: Color.White
            },
            leave: {
                fill: Color.Red,
                backgroundColor: Color.White,
                padding: "1.38vw"
            }
        },
        storeIcon: {
            width: "4.2vw",
            height: "4.2vw",
            paddingTop: "1vw",
            marginLeft: "0"
        },
        storeLabel: {
            fontSize: "4.34vw"
        },
        dropdownItem: {
            color: Color.MiddleBlack,
            fontWeight: "500",
            hover: {
                backgroundColor: Color.Blue,
                padding: "1.38vw",
                borderRadius: "1.94vw",
                color: Color.White
            },
            leave: {
                backgroundColor: Color.White,
                padding: "1.38vw"
            }
        },
        desktopMenu: {
            explore: {
                hover: {
                    width: "11.2vw"
                }
            },
            store: {
                hover: {
                    width: "8.8vw"
                }
            },
            storeButton: {
                hover: {
                    width: "8.8vw"
                }
            }
        },
        loginWrapper: {
            display: "contents"
        },
        mobileMenuButton: {
            backgroundColor: "transparent",
            border: "none",
            margin: "5.2vw 4.83vw 0 auto",
            padding: "unset"
        },
        notifyButton: {
            position: "relative" as "relative",
            width: "10vw",
            height: "10vw",
            backgroundColor: Color.White,
            border: "none",
            borderRadius: "50%",
            cursor: "pointer"
        },
        notifyIcon: {
            width: "4.44vw",
            height: "2.43vh",
            fill: Color.Blue
        },
        userWrapper: {
            marginLeft: "auto",
            marginTop: "3%"
        },
        userDropdown: {
            wrapper: {
                display: "none",
                position: "relative" as "relative",
                marginTop: "3vh",
                marginRight: "1.5vw"
            },
            button: {
                backgroundColor: "transparent",
                color: Color.LightAsh,
                fontSize: "1.17vw",
                fontWeight: 500,
                border: "none",
                cursor: "pointer"
            },
            item: {
                textAlign: "left"
            },
            labelWrapper: {
                display: "flex",
                flexDirection: "row" as "row"
            },
            labelIcon: {
                width: "1.09vw",
                height: "1.09vw",
                fill: Color.LightAsh,
                marginLeft: "1.35vh",
                marginTop: "4vh",
                transition: "transform 0.1s linear",
                hover: {
                    transform: "rotate(180deg)"
                }
            },
            label: {
                textDecoration: "none",
                fontSize: "1.17vw",
                letterSpacing: "0px",
                color: Color.LightAsh,
                opacity: "1"
            },
            leave: {
                display: "none"  
            },
            hover: {
                display: "block",
                position: "absolute" as "absolute",
                width: "22vh",
                height: "auto",
                padding: "0.6vw",
                left: "-4vw",
                backgroundColor: Color.White,
                boxShadow: "0 0.2vw 1.35vh rgb(0 0 0 / 0.5)",
                borderRadius: "1.35vh",
                zIndex: 3
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.White,
                borderRadius: "1px"
            }
        },
        userAvatar: {
            height: "3.66vw",
            width: "3.66vw",
            border: "0.5vw solid " + Color.Green,
            borderRadius: "50%",
            cursor: "pointer",
            objectFit: "cover"
        }
    },
    desktop: {
        longBuildingSvg: {
            display: "block",
            width: "unset",
            height: "98%"
        },
        desktopImageLeft: {
            display: "block"
        },
        mobileImage: {
            display: "none"
        },
        startCurve: {
            display: "block"
        },
        endCurve: {
            display: "block"
        },
        backButton: {
            wrapper: {
                display: "flex",
                top: "6.7vw",
                left: "7.6vw"
            },
            button: {
                width: "2.6vw",
                height: "2.6vw",
                boxShadow: "0px 0px 0px 0.5vw rgb(255 255 255 / 0.35)"
            },
            icon: {
                width: "0.78vw"
            },
            text: {
                display: "block",
                marginLeft: "1.2vw"
            }
        },
        title: {
            margin: "2.08vw 0 0 2.70vw",
            width: "15.52vw",
            height: "3.65vw"  
        },
        label: {
            fontSize: "0.937vw"
        },
        dropdownLabel: {
            fontSize: "0.937vw"
        },
        logoutIcon: {
            width: "0.67vw",
            height: "0.572vw"
        },
        logoutDropdownItem: {
            hover: {
                borderRadius: "0.4vw",
                padding: "0.36vw"
            },
            leave: {
                padding: "0.36vw"
            }
        },
        logoutDropdownLabel: {
           marginLeft: "0.2vw"
        },
        dropdownItem: {
            fontSize: "0.83vw",         
            hover: {
                borderRadius: "0.36vw",
                padding: "0.31vw 0.52vw"
            },
            leave: {
                padding: "0.31vw 0.52vw"
            }
        },
        storeContainer: {
            display: "flex", 
            flexDirection: "row",
            justifyContent: "center", 
            alignItems: "center"
        },
        storeIcon: {
            width: "1.19vw",
            height: "1.19vw",
            paddingTop: "0"
        },
        storeLabel: {
           marginLeft: "0.52vw",
           fontSize: "1.04vw",
           marginRight: "0.1vw"
        },
        desktopMenu: {
            storeButton: {
                button: {
                    height: "3.64vw",
                    width: "11.09vw",
                    backgroundColor: Color.DeepBlack,   
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                    marginTop: "-1vw",
                    borderRadius: "0.52vw"
                },
                hover: {
                    marginTop: "1vw",
                    width: "10vw"
                }
            }
        },
        notifyButton: {
            width: "2.9vw",
            height: "2.9vw",
            marginRight: "1.46vw",
            marginTop: "5vh"
        },
        notifyIcon: {
            width: "1.31vw",
            height: "1.31vw"
        },
        userWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            marginLeft: "unset",
            marginTop: "unset"
        },
        userDropdown: {
            wrapper: {
                display: "inline-block"
            },
            hover: {
                width: "9.16vw",
                left: "-3.5vw"
            }
        },
        mobileMenuButton: {
            display: "none"
        }
    }
}
