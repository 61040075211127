
export const preferredPartnersPage = {
    mobile: {
        text: {
            margin: "2vh 5% 0vh",
            fontFamily: "Poppins",
            fontSize: "1.8vh"
        },
        container: {
            margin: "10vh 5%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            justifyItems: "center" as "center",
            rowGap: "5vh"
        },
        wrapper: {
            overflow: "hidden"
        }, 
        logoWrapper: {
            margin: "8vh 0 12vh 0"
        },
        logo: {
            height: "10vh",
            width: "80%",
            objectFit: "scale-down",
            cursor: "pointer",
            margin: "auto"
        }        
    },
    desktop: {
        text: {
            display: "none"
        },
        logo: { 
            height: "4vw",           
            maxWidth: "20vw"
        }       
    }
}