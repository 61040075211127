export enum Color {    
    Blue = "#01A9E0",
    LightBlue = "#EBFAFF",
    SharpBlue = "#A5E1F4",
    BlueYonder = "#4267B2",
    HonoluluBlue = "#0072B1",
    Green = "#51CB20",
    LightGreen = "#C2EDB0",
    SharpGreen = "#DCF5D2",
    Ash = "#EAEAEB",    
    LightAsh = "#F4F4F4",
    MiddleAsh = "#D5D5D6",
    SharpAsh = "#909090",
    DeepAsh = "#515251",
    DarkAsh = "#DFDFDF",
    Gainsboro = "#DEDEDE",
    White = "#FFFFFF",
    Black = "#000000",
    LightBlack = "#3C3C3C",
    MiddleBlack = "#515151",
    DeepBlack = "#0B132B",
    Red = "#FB3640", 
    LightRed = "#F5D8DA",
    CoralRed = "#FB364019",
    DarkRed = "#C70E20",
    LoaderBackground = "#F3F3F3",
    LoaderForeground = "#ECEBEb",
    LightSilver = "#D6D5D6",
    DarkGray = "#707070",
    HarvestGold = "#E39500",
    MiddleGold = "#E3950026",
    LightGold = "#E3950019",
    LightGray = "#D5D5D5",
    Gray = "#D1D1D1",
    BrightGray = "#EAEAEB",
    Nyanza = "#E4F7DD",
    Cultured = "#F4F5F5",
    OldLace = "#FDF3E0",
    Munsell = "#00BE7E",
    AliceBlue = "#EBFAFF",
    Linen = "#FEEAEB"
}