import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const candidateSettingsPage = {
    mobile: {
        mainWrapper: {
            margin: "1.67vh 4.83vw 0 4.83vw",
        },
        personalDetails: {
            display: "flex",
            flexDirection: "column" as "column",
            color: Color.MiddleBlack
        },
        imageWrapper: {
            display: "flex",
            flexDirection: "row" as "row",
            position: "relative" as "relative"
        },
        shareWrapper: {
            display: "flex",
            flexDirection: "row",
            marginTop: "4.83vw",
            fontSize: "3.86vw",
            justifyContent: "center",
            alignItems: "center",
            color: Color.MiddleBlack
        },
        shareCVSwitch: {
            switch: {
                width: "7.98vw",
                height: "3.83vw"
            },
            switchCircle: {
                marginTop: "0.3vw",
                marginRight: "0.8vw",
                width: "3.38vw",
                height: "3.38vw"
            }
        },
        imageHolder: {
            width: "24.15vw",
            height: "24.15vw",
            borderRadius: "2.41vw",
            overflow: "hidden",
            marginTop: "-15.65vw",
            position: "relative"
        },
        candidateImage: {
            width: "100%",
            height: "100%",
            objectFit: "cover"
        },
        cameraIconWrapper: {
            position: "absolute",
            bottom: "1.20vw",
            right: "1.20vw",
            backgroundColor: Color.White,
            width: "6.03vw",
            height: "6.03vw",
            borderRadius: "50%",
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
        },
        cameraIcon: {
            fill: Color.MiddleBlack,
            width: "2.89vw",
            height: "2.17vw"
        },
        premiumNameWrapper: {
            display: "flex",
            gap: "1.50vw"
        },
        premiumButton: {
            width: "28.26vw",
            position: "absolute",
            backgroundColor: Color.HarvestGold,
            borderRadius: "1.2vw",
            textTransform: "uppercase",
            height: "8.695vw",
            marginTop: "0",
            fontWeight: "bold",
            fontSize: "3.62vw",
            color: Color.White,
            top: "12vw",
            right: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2.17vw"
        },
        crownIcon: {
            width: "3.44vw",
            height: "2.75vw",
            fill: Color.White,
            marginRight: "1.42vw"
        },
        crownWrapper: {
            display: "flex",
            width: "4.35vw",
            height: "4.34vw",
            backgroundColor: Color.HarvestGold,
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "6.25vw",
            mouse: "pointer"
        },
        premiumCrownIcon: {
            width: "2.64vw",
            fill: Color.White   
        },
        premiumRemeiningDays: {
            position: "absolute",
            width: "60vw",
            fontSize: "3vw",
            top: "72.80vw",
            right: "4.25vw",
            borderRadius: "none",
            pointer: {
                right: "57.30vw",
                borderTop: "1px solid" + Color.Gray,
                borderLeft: "1px solid" + Color.Gray,
                borderRight: "unset",
                borderBottom: "unset",
                boxShadow: "unset"
            }
        },
        storeButton: {
            display:"flex",
            width: "28.26vw",
            border: "none",
            backgroundColor: Color.Blue,
            borderRadius: "1.21vw",
            textTransform: "uppercase",
            height: "8.45vw",
            fontWeight: "bold",
            fontSize: "3.62vw",
            color: Color.White,
            margin: "0 0 0 auto",
            textAlign: "center",
            lineHeight: "8.45vw",
            icon: {
                margin : "2.34vw 2.42vw 2.17vw 5.16vw",
                width: "3.93vw",
                height: "3.93vw",
                transform: "rotate(-15deg)"
            },
            storeButtonName: {
                fontWeight: "bold",
                fontSize: "3.62vw",

            },       
            dropdown: {
                position: "absolute",
                width: "56.52vw",
                height: "auto",
                margin: "auto",
                padding: "1.38vw",
                backgroundColor: Color.White,
                zIndex: 1,
                boxShadow: "5px 6px 20px #00000027",
                right: "0",
                top: "9.85vw",
                border: "1px solid " + Color.LightSilver,
                borderRadius: "2.41vw",
                textAlign: "left",
                lineHeight: "normal"
            },
            dropdownLabel: {
                display: "block",
                position: "relative" as "relative",
                textDecoration: "none",
                fontWeight: "medium",
                letterSpacing: "0px",
                color: Color.MiddleBlack,
                cursor: "pointer",
                opacity: "1",
                textTransform: "capitalize",
                fontSize: "3.62vw",
                backgroundColor: Color.White,
                padding: "1.38vw",
                hover: {
                    backgroundColor: Color.LightBlue,
                    borderRadius: "1.69vw"
                }
            },
            bottomBorder: {
                border: 0,
                height: "1px",
                width: "92%",
                background: Color.MiddleAsh,
                margin: "0 auto",
                zIndex: "1"
            },
            hoveredBorder: {
                background: Color.LightBlue,
                borderRadius: "1px"
            },
        },     
        name: {
            fontWeight: "bold",
            color: Color.Black,
            fontSize: "3.86vw",
            margin: "6.04vw 0 0 0",
        },
        jobTitle: {
            color: Color.Black,
            fontSize: "3.62vw",
            marginTop: "1.21vw",
            empty: {
                cursor: "pointer"
            }
        },
        shareText: {
            margin: "0",
            fontSize: "3.86vw"
        },
        line: {
            display: "none"
        },
        title: {
            color: Color.LightBlack,
            fontWeight: "bold",
            fontSize: "6.03vw",
            margin: "4.46vh 0 0 0"
        },
        tabs: {
            container: {
                marginTop: "7.246vw",
            },
            linkWrapper: {
                display: "flex",
                flexDirection: "row",
                backgroundColor: Color.LightAsh,
                margin: "0 -4.83vw 0 0"
            },
            link: {
                WebkitTapHighlightColor: "transparent",
                flexShrink: "0",
                fontSize: "3.86vw",
                color: Color.DeepAsh,
                borderRadius: "1.20vw",
                padding: "2.89vw 4.83vw",
                marginRight: "2.42vw",
                alignSelf: "center",
                cursor: "pointer",
                active: {
                    backgroundColor: Color.DeepBlack,
                    color: Color.LightAsh,
                },
            },
            contentWrapper: { }
        },
        form: {
            margin: "7.24vw 0 24.15vw 0",
            title: {
                fontWeight: "bold",
                color: Color.LightBlack,
                fontSize: "3.86vw"
            },
            formWrapper: {
                marginTop: "9.66vw"
            },
            inputWrapper: {
                marginTop: "4.83vw"
            },
            label: {
                color: Color.DeepAsh,
                fontSize: "3.86vw",
                font: Font.Poppins,
                marginLeft: "3.62vw"
            },
            textInput: {
                width: "100%",
                height: "12.08vw",
                marginTop: "1.20vw"
            },
            line: {
                display: "block",
                height: "0.24vw",
                color: Color.MiddleAsh,
                backgroundColor: Color.MiddleAsh,
                margin: "7.24vw 0 2.41vw 0",
                border: "none",
            },
            updateButton: {
                width: "100%",
                height: "12.07vw",
                border: "none",
                backgroundColor: Color.Green,
                borderRadius: " 2.415vw",
                fontWeight: "bold",
                fontSize: "4.35vw",
                color: Color.White,
                marginTop: "9.66vw",
                boxShadow: "1vw 1vw 3vw #00000029"
            },
        },
        deleteTab: {
            color: Color.Red,
            backgroundColor: Color.Linen
        },
        deleteWarningDialog: {
            container: {
                width: "calc(100% - 9.66vw)",
                height: "61.83vw",
                padding: "7.246vw 4.93vw",
                borderRadius: "2.415vw 2.415vw 0 0"
            }
        },
        deleteDialogTitle: {
            marginBottom: "7vw",
            fontSize: "7.24vw",
            color: Color.LightBlack
        },
        userVerifyTextInput: {
            height: "13.08vw",
            fontSize: "3.34vw" 
        },
        deleteDialogMessage: {
            margin: "4.83vw 0 0 0",
            fontSize: "3.86vw",
            color: Color.MiddleBlack,
            font: Font.Default,
            fontWeight: "600"
        },
        deleteButtons: {
            justifyContent: "end"
        },
        deleteButton: {
            width: "50%",
            height: "12.07vw",
            fontWeight: "bold",
            fontSize: "4.83vw",
            borderRadius: "2.41vw",
            margin: "7.24vw 0 0 0",
            blue: {
                border: "3px solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                marginRight: "2.89vw",
            },
            red: {
                border: "none",
                color: Color.Red,
                backgroundColor: Color.LightRed
            }
        }
    },
    desktop: {
        mainWrapper: {
            width: "56.66vw",
            margin: "5.27vh auto 5.50vw auto",
            position: "relative"
        },
        personalDetails: {
            flexDirection: "row"
        },
        imageWrapper: {
            flexDirection: "column",
            marginTop: "1.2vw"
        },
        detailWrapper: {
            marginLeft: "2.34vw",
        },
        shareWrapper: {
            width: "15vw",
            position: "absolute",
            right: 0,
            marginTop: "-2.3vw",
            gap: "1.04vw"
        },
        shareCVSwitch: {
            switch: {
                width: "1.718vw",
                height: "unset",
                marginRight: "1vw"  
            },
            switchCircle: {
                width: "0.729vw",
                height: "0.729vw",                
                marginTop: "unset",
                marginRight: "unset"
            }
        },
        shareText: {
            fontSize: "0.83vw",
            fontWeight: "400"
        },
        imageHolder: {
            width: "10.15vw",
            height: "10.15vw",
            marginTop: "-18vh",
            borderRadius: "0.52vw",
        },
        cameraIconWrapper: {
            width: "1.30vw",
            height: "1.30vw"
        },
        cameraIcon: {
            width: "0.94vw",
            height: "0.94vw",
            verticalAlign: "text-bottom",
        },
        premiumNameWrapper: {
            display: "flex",
            gap: "0.78vw"
        },
        premiumButton: {
            position: "unset",
            width: "10.16vw",
            height: "4.63vh",
            backgroundColor: Color.HarvestGold,
            margin: "0.78vw 0 0 0",
            borderRadius: "0.52vw",
            fontSize: "0.833vw",
            color: Color.White,
            padding: "unset"
        },
        crownIcon: {
            width: "1.04vw",
            height: "0.83vw",
            marginRight: "0.62vw"
        },
        crownWrapper: {
            width: "1.77vw",
            height: "1.77vw",
            marginTop: "0.17vw"
        },
        premiumCrownIcon: {
            width: "1.04vw",
            height: "0.73vw"
        },
        premiumRemeiningDays: {
                position: "absolute",
                right: "11.5vw",
                top: "2.30vw",
                width: "20vw",
                height: "2vw",
                padding: "1vw",
                fontSize: "1vw",
            pointer: {
                 top: "-0.40vw",
                 right: "19.70vw"
            }
        },
        storeButton: {
            display: "none",
            width: "100%",
            borderRadius: "0.26vw"
        },
        name: {
            fontSize: "1.56vw",
            color: Color.LightBlack,
            margin: "0"
        },
        jobTitle: {
            fontSize: "1.04vw",
            marginTop: "0.52vw",
            empty: {

            }
        },
        line: {
            display: "block",
            height: "0.15vw",
            color: Color.Gainsboro,
            backgroundColor: Color.LightAsh,
            margin: "2vw 0 2.60vw -25%",
            border: "none",
            width: "85.41vw"
        },
        title: {
            margin: "0.52vw 0 0 0.52vw",
            fontSize: "1.56vw"
        },
        tabs: {
            container: {
                display: "flex",
                flexDirection: "row",
                marginTop: "2.864vw"
            },
            linkWrapper: {
                display: "flex",
                flexDirection: "column",
                width: "13.54vw",
                height: "fit-content",
                padding: " 1.041vw 0.78vw",
                margin: 0,
                borderRadius: "0.52vw"
            },
            link: {
                flexShrink: "0",
                width: "77.50%",
                fontSize: "0.937vw",
                borderRadius: "0.52vw",
                padding: "0.78vw 1.30vw",
                marginRight: 0,
                textAlign: "left",
                fontWeight: "500"
            },
            contentWrapper: {
                width: "35.10vw",
                marginLeft: "8.02vw"
            },
            title: {
                fontSize: "1.56vw",
                marginTop: "4.62vh"
            }
        },
        form: {
            margin: "0 0 7.81vw 0",
            title: {
                fontSize: "1.56vw",
                marginLeft: "1.56vw"
            },
            formWrapper: {
                marginTop: "2.864vw"
            },
            inputWrapper: {
                marginTop: "1.56vw"
            },
            label: {
                fontSize: "1vw",
                marginLeft: "0.52vw",
                fontWeight: "300"
            },
            textInput: {
                height: "3.645vw",
                borderRadius: "0.52vw",
                icon: {
                    top: "1.30vw"
                },
            },
            line: {
                width: "42.29vw",
                margin: "2.86vw 0 0.208vw 0",
                height: "0.052vw"
            },
            updateButton: {
                width: "20.729vw",
                fontSize: "1.041vw",
                marginTop: "2.86vw",
                padding: "0",
                height: "2.604vw",
                float: "right",
                borderRadius: "0.52vw"
            },
        },
        deleteWarningDialog: {
            container: {
                width: "21.33vw",
                height: "11.01vw",
                padding: "3.489vw 3.54vw",
                borderRadius: "0.52vw"
            }
        },
        deleteDialogTitle: {
            fontSize: "1.56vw",
            color: Color.LightBlack,
            marginBottom: "1.51vw"
        },
        userVerifyTextInput: {
            height: "3.65vw",
            width: "unset",
            fontSize: "1vw"
        },
        deleteDialogMessage: {
            margin: "1.51vw 0 0 0",
            fontSize: "0.83vw",
            color: Color.MiddleBlack,
            lineHeight: "1.5"
        },
        deleteButton: {
            width: "10.15vw",
            height: "2.604vw",
            fontSize: "1.041vw",
            borderRadius: "0.52vw",
            margin: "1.25vw 0 0 0",
            blue: {
                border: "3px solid " + Color.Blue,
                color: Color.Blue,
                background: "transparent",
                marginRight: "0.625vw",
            },
            red: {
                border: "none",
                color: Color.Red,
                backgroundColor: Color.Linen
            },
        },
    }
}