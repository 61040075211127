import React, { useState } from "react";
import { ReactComponent as Warning } from "../../../assets/svg/warning.svg";
import { Tooltip } from "../Tooltip";
import { useMediaQuery } from "react-responsive";

interface Props {
  styles: any;
  invalidMessage: string;
}

export const ErrorIndicator: React.FC<Props> = (props) => {
  let styles = props.styles;
  const [showError, setShowError] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const showErrorPopUp = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowError(!showError);
  }
  
  return (
    <>
      {showError && <Tooltip><div style={styles}>{props.invalidMessage}</div></Tooltip>}
      <div>
        <Warning style={styles.icon} 
          {...(!isMobile && { onMouseEnter: () => setShowError(true), onMouseLeave: () => setShowError(false) })}
          onClick={showErrorPopUp}
        />
      </div>
    </>
  );
};
