import { merge } from "react-merge";
import { useMediaQuery } from "react-responsive"
import { Color } from "../../enums/Color";
import ContentLoader from "react-content-loader";

const loaderStyle = {
    mobile : {
        registerForm: {
            width: "90.34vw",
            backgroundColor: Color.White,
            padding: "0 4.83vw",
            marginTop: "14.49vw",
            zIndex: "1"
        },
        accordionButton: {
            height: "9.42vw",
            left: {
                width: "22vw"
            },
            right: {
                width: "29vw"
            }
        },
        boldTitle: {
            margin: "7.25vw 0 6.04vw 3.62vw",
            width: "19.32vw",
            height: "5.56vw"
        },
        itemWrapper: {
            height: "210.39vw"
        }
    },
    desktop: {
        registerForm: {
            position: "absolute" as "absolute",
            width: "42.5vw",
            height: "39.58vw",
            right: "7.29vw",
            top: "9.375vw",
            borderRadius: "0.52vw",
            border: `2px solid ${Color.LightAsh}`,
            padding: "2.08vw 2.6vw",
            marginTop: 0
        },
        accordionButton: {
            height: "2.96vw",
            left: {
                width: "7.29vw"
            },
            right: {
                width: "9.42vw"
            }
        },
        boldTitle: {            
            margin: "1.56vw 0 0.78vw 0.52vw",
            width: "8.96vw",
            height: "1.04vw"   
        },
        itemWrapper: {
            height: "33.18vw"
        }
    }
}

export const RegisterFormLoader : React.FC = () => {
    const isMobile = useMediaQuery({ query : "(max-width : 786px)"});
    var styles = loaderStyle.mobile;
    if(!isMobile) {
        let extendedStyle = merge(loaderStyle.mobile, loaderStyle.desktop);
        styles = extendedStyle;
    }

    return (
        <div style={styles.registerForm} >
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width= "auto"
                height= {styles.accordionButton.height}
            > 
                <rect x="26%" y="0" rx="3" ry="3" width={styles.accordionButton.left.width} height="100%" />
                <rect x="43%" y="0" rx="3" ry="3" width={styles.accordionButton.right.width} height="100%" />
                <rect x="0" y="95%" rx="3" ry="3" width="100%" height="10%" />
            </ContentLoader>
            <div style={styles.boldTitle}>
                <ContentLoader
                    speed={1}
                    backgroundColor={Color.LoaderBackground}
                    foregroundColor={Color.LoaderForeground}
                    width= {styles.boldTitle.width}
                    height= {styles.boldTitle.height}
                > 
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
                </ContentLoader>
            </div>
            <ContentLoader
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
                width= "100%"
                height= {styles.itemWrapper.height}
            > 
                {isMobile ? <>
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="7.5%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="15%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="22.5%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="30%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="25" y="38%" rx="3" ry="3" width="26.47%" height="4.47%" />
                    <circle cx="45.5%" cy="40%" r="1.5%" />
                    <rect x="51%" y="39%" rx="3" ry="3" width="10%" height="2%" />
                    <circle cx="76%" cy="40%" r="1.5%" />
                    <rect x="81.5%" y="39%" rx="3" ry="3" width="10%" height="2%" />
                    <rect x="0" y="45%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="52.5%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="0" y="59%" rx="3" ry="3" width="25.07%" height="1.38%" />
                    <rect x="0" y="63.5%" rx="3" ry="3" width="5.33%" height="2.30%" />
                    <rect x="9%" y="63.75%" rx="3" ry="3" width="80.27%" height="1.95%" />
                    <rect x="0" y="67.5%" rx="3" ry="3" width="5.33%" height="2.30%" />
                    <rect x="9%" y="67.75%" rx="3" ry="3" width="80.27%" height="1.95%" />
                    <rect x="0" y="75%" rx="3" ry="3" width="100%" height="5.74%" />
                    <rect x="13.90%" y="83%" rx="3" ry="3" width="72.19%" height="2.07%" />
                    <rect x="0" y="88%" rx="3" ry="3" width="100%" height="0.3%" />
                    <rect x="15.73%" y="90.5%" rx="3" ry="3" width="68.53%" height="2.07%" />
                    <circle cx="33.8%" cy="97%" r="3.3%" />
                    <circle cx="49.27%" cy="97%" r="3.3%" />
                    <circle cx="64.73%" cy="97%" r="3.3%" />   
                </> :
                <>
                    <rect x="0" y="0" rx="3" ry="3" width="49.01%" height="10.99%" />
                    <rect x="51%" y="0" rx="3" ry="3" width="49.01%" height="10.99%" />
                    <rect x="0" y="13%" rx="3" ry="3" width="100%" height="10.99%" />
                    <rect x="0" y="26%" rx="3" ry="3" width="45.96%" height="10.99%" />
                    <rect x="48%" y="26%" rx="3" ry="3" width="51.84%" height="10.99%" />
                    <rect x="0" y="39%" rx="3" ry="3" width="45.96%" height="10.99%" />
                    <rect x="48%" y="39%" rx="3" ry="3" width="51.84%" height="10.99%" />
                    <rect x="0" y="52%" rx="3" ry="3" width="100%" height="10.99%" />
                    <rect x="0" y="64.75%" rx="3" ry="3" width="44.49%" height="2.83%" />
                    <rect x="0" y="72.50%" rx="3" ry="3" width="2.45%" height="2.83%" />
                    <rect x="4.90%" y="72.50%" rx="3" ry="3" width="44.24%" height="2.83%" />
                    <rect x="56.62%" y="72.50%" rx="3" ry="3" width="2.45%" height="2.83%" />
                    <rect x="61.52%" y="72.50%" rx="3" ry="3" width="21.20%" height="2.83%" />
                    <rect x="0" y="81%" rx="3" ry="3" width="45.96%" height="10.99%" />
                    <rect x="51.3%" y="81%" rx="3" ry="3" width="0.3%" height="10.99%" />
                    <rect x="56.97%" y="85%" rx="3" ry="3" width="17.52%" height="3.14%" />
                    <circle cx="79.5%" cy="86%" r="2.9%" />
                    <circle cx="86%" cy="86%" r="2.9%" />
                    <circle cx="92.5%" cy="86%" r="2.9%" />
                    <rect x="3.8%" y="95%" rx="3" ry="3" width="37.25%" height="3.14%" />
                </>}
            </ContentLoader>    
        </div>
    )
}