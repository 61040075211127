import React, { useContext, useEffect, useState } from 'react';
import { SelectInputs } from '../../models/SelectInputs';
import { ReactComponent as Icon } from '../../assets/svg/drop_down.svg';
import { ReactComponent as TickMark } from '../../assets/svg/tick_mark.svg';
import { DropdownItem } from './DropdownItem';
import { StyleContext } from '../../providers/StyleContextProvider';
import { useMediaQuery } from 'react-responsive';
import { merge } from 'react-merge';

interface Props {
    styles?: any;  
    label?: string;     
    selectInputs: SelectInputs;
    getSelected(selected: any): void;
    showSelectedIcon?: boolean;
}

export const SingleSelect: React.FC<Props> = (props) => {     
    const [hovered, setHovered] = useState<boolean>(false);
    const options = props.selectInputs.options;    
    const [selectedValue, setSelectedValue] = useState<string>(props.selectInputs.default!);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("singleSelect"));                
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
    
    useEffect(() => {      
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("singleSelect"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);                              
    }, [isMobile, props.styles]);

    const itemSelected = (index: number) => {
        options[index].checked = true;
        setSelectedValue(options[index].option); 
        props.getSelected(options[index].value);        
        setHovered(false);         
    }

    return (
        <div style={styles}>            
            <label style={styles.label}>{props.label}</label>
            <div style={styles.button} 
                onMouseEnter={() => { setHovered(true); }}
                onMouseLeave={() => { setHovered(false); }}
                onClick={() => { setHovered(!hovered); }}
            >                
                <div style={{...styles.buttonWrapper,...(hovered && styles.buttonWrapper.hover)}}>
                    <div style={styles.buttonText}>{selectedValue}</div> 
                    <Icon style={{...styles.buttonIcon, ...(hovered && styles.buttonIcon.hover)}}/>                    
                </div>
                <div style={hovered ? styles.hover : styles.leave} className={"scrollArea"}>                         
                    {options.map((option, index) =>                    
                        <div key={index} onMouseEnter={()=>{ setHoveredIndex(index); }} onMouseLeave={()=>{ setHoveredIndex(-1); }} >
                            <DropdownItem styles={styles.option} onClick={() => itemSelected(index)}>
                                {option.option}
                                {props.showSelectedIcon && selectedValue == option.value && <TickMark style={styles.option.icon}/>}                          
                            </DropdownItem>
                            <hr style={{ ...styles.bottomBorder, ...((hoveredIndex === index || hoveredIndex === index + 1 || index + 1 === options.length) && styles.hoveredBorder) }} />
                        </div>
                    )}
                </div>              
            </div>            
        </div>
    );
}