import { useNavigate } from "react-router-dom";

const useErrorPage = () => {
    const navigate = useNavigate();

    const toErrorPage = (error: any) => {
        if(error.response.status === 401) {
            localStorage.removeItem("candidate");
            localStorage.removeItem("savedJobIds");
            localStorage.removeItem("token");
            navigate('/candidates/login');
        }
        else {
            navigate('/error/errorindex', { state: { error: error.message }});
        }
    }
    
    return [toErrorPage];
};

export default useErrorPage;