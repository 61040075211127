import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

export const ScrollToTop : React.FC = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const noScroll: string | null = searchParams.get("noScroll");

  useEffect(() => {
    if(noScroll !== "true") {
      window.scrollTo(0, 0);
    }    
  }, [pathname]);

  return null;
}