import ContentLoader from 'react-content-loader';
import { Color } from '../../enums/Color';
import { useMediaQuery } from 'react-responsive';

export interface LoaderProps {
    style: any;
    rowsCount: number
}

export const LogoGridLoader: React.FC<LoaderProps> = (props) => {
    const loaderLogos = new Array<any>();
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const logosPerRow = isMobile ? 4 : 13;
    let styles = props.style;
    for (let i = 0; i < props.rowsCount *  logosPerRow; i++) {
        loaderLogos.push(
            <div key={i} style={styles.loaderBox}>
                <ContentLoader
                width={"100%"}
                height={"100%"}
                speed={1}
                backgroundColor={Color.LoaderBackground}
                foregroundColor={Color.LoaderForeground}
            >
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
            </div>
            
        )
    }
    return (
        <div style={styles.loaderContainer}>
            {loaderLogos}
        </div>
    ); 
}