import React from "react";
import { InputValidation } from "../../../models/InputValidation";
import { Option } from "../../../models/SelectInputs";
import { RadioInput } from "./RadioInput";
import { ErrorIndicator } from "./ErrorIndicator";

interface Props {
  styles: any;
  name: string;
  handleChange(event?: React.ChangeEvent<HTMLInputElement>): void;
  validation?: InputValidation;
  label: string;
  options: Option[];
}

export const RadioGroup: React.FC<Props> = (props) => {
  const styles = props.styles;

  return (
    <div style={{...styles.wrapper, ...(props.validation?.invalid && styles.wrapper.invalid)}}>
      <p style={styles.label}>{props.label}</p>
      {props.options!.map((option, index) => (
        <RadioInput
          key={index}
          styles={styles.radio}
          id={props.name + "_" + index}
          name={props.name}
          value={option.value}
          handleChange={props.handleChange}
        >
          {option.option}
        </RadioInput>
      ))}
      {props.validation?.invalid && <ErrorIndicator invalidMessage={props.validation?.invalidMessage!} styles={styles.inputError}/>}
    </div>
  );
};
