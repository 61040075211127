import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Sector } from '../../models/Sector';
import { SelectInputs } from '../../models/SelectInputs';
import { StyleContext } from '../../providers/StyleContextProvider';
import { SingleSelect } from '../Shared/SingleSelect';
import { JobCategoryCard } from './JobCategoryCard';
import Slider from "react-slick";
import { JobCategoriesTexts as Texts } from '../../helpers/LayoutTexts';
import { getSectors } from '../../services/HomeService';
import { JobCategoriesLoader } from '../Loaders/JobCategoriesLoader';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/svg/right_arrow.svg';
import { Button } from '../Shared/Buttons/Button';
import { merge } from 'react-merge';
import useErrorPage from '../../hooks/useErrorPage';
import { createSearchParams, useNavigate } from 'react-router-dom';

const sortBySelectInputs: SelectInputs = {
    default: 'A to Z',
    options: [
        { "value": "SectorCount DESC", "option": "No of Jobs" },
        { "value": "SectorCount ASC", "option": "Ascending" },
        { "value": "Sector", "option": "A to Z" }
    ]
}

interface Props {
    styles?: any;
}

export const JobCategories: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("jobCategories")); 
    const [loading, setLoading] = useState<boolean>(true);
    const [jobCategoryCards, setJobCategoryCards] = useState<any>([]);
    const sliderRef = useRef<Slider | null>(null); 
    const [toErrorPage] = useErrorPage();                 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [sortBy, setSortBy] = useState<string>("Sector");
    const [isGrid, setIsGrid] = useState<boolean>(false);
    const recordCount: number = 100;
    const isMountedRef = useRef<boolean>(true);
    const retryCountRef = useRef<number>(1);
    const maxRetries = 3;
      
    useEffect(() => {                 
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        getJobCategoryList();
    }, [sortBy]);

    useEffect(() => {        
        // merge base styles with component specific styles                  
        let style = styleContext.getComponentStyle("jobCategories"); 
        let extendedStyles = merge(style, props.styles);
        setStyles(extendedStyles);        
    }, [isMobile]);

    async function getJobCategoryList() {
        await getSectors(recordCount, sortBy)
        .then((jobCategoryList: Sector[]) => {
            let tempJobCategoryCards = new Array<any>();
            for (let i = 0; i < jobCategoryList.length; i++) {
                let tempCard = <div style={styles.cardWrapper} onClick={() => redirectBysector(jobCategoryList[i].sectorId, jobCategoryList[i].friendlyName)} key={i}>
                    <JobCategoryCard styles={styles.card} jobCategory={jobCategoryList[i]} />
                </div>
                tempJobCategoryCards.push(tempCard);
            }
            setJobCategoryCards(tempJobCategoryCards);
            if ((isMobile && jobCategoryList.length < 6) || (!isMobile && jobCategoryList.length < 16)) {
                setIsGrid(true);
            }
            else {
                setIsGrid(false);
            }
            setLoading(false);
        })
        .catch((error) => {            
            if(isMountedRef.current) {                      
                if (retryCountRef.current < maxRetries) {
                    // Retry the API call after 1 second
                    retryCountRef.current = retryCountRef.current + 1;
                    setTimeout(getJobCategoryList, 1000);
                } 
                else {
                    // Redirect to error page after max retries
                    toErrorPage(error);
                }  
            }
        });
    } 

    const nextSlide = () => {
        sliderRef.current?.slickNext();
    }

    const previousSlide = () => {
        sliderRef.current?.slickPrev();
    };

    const getSelected = (selectedValue: string) => {
        setSortBy(selectedValue);
    };

    const sliderSettings = {
        className: "center",
        infinite: true,
        centerPadding: "0%",
        dots: true,         
        rows: 5,
        speed: isMobile ? 500 : 1000,
        autoplay: isMobile ? false : true,
        autoplaySpeed: 8000,
        pauseOnHover: true,
        slidesPerRow: 1,
        slidesToShow: isMobile ? 2 : 4,
        slidesToScroll: isMobile ? 1 : 3,
        swipeToSlide: true,
        touchThreshold: 100,
        useTransform: false,
        appendDots: (dots: any) => (
            <div>
                <ul style={styles.dots}>{dots}</ul>
            </div>
        )
    };

    const redirectBysector = (sectorId: number, sectorName: string) => {
        const params = { Sectors: sectorId.toString(), SeoKeyword: sectorName + "-jobs" };
        navigate({
            pathname: '/jobs',
            search: `?${createSearchParams(params)}`,
        });        
    }
    
    return (
        <div style={styles}>
            <div style={styles.wrapper}>
                <div style={styles.title}>{Texts.title}</div>
                <SingleSelect label="Sort by" selectInputs={sortBySelectInputs} getSelected={getSelected} />
                {!isGrid && <div style={styles.sliderButtonWrapper}>
                    <Button style={styles.leftSliderButton} onClick={previousSlide}><LeftArrow style={styles.buttonIcon} /></Button>
                    <Button style={styles.rightSliderButton} onClick={nextSlide}><RightArrow style={styles.buttonIcon}/></Button>
                </div>}
            </div> 
            {loading ? <JobCategoriesLoader/> :
            (isGrid ? <div style={styles.grid}>
                {jobCategoryCards}
            </div> :  
            <div style={styles.slider}>                
                <Slider ref={sliderRef} {...sliderSettings}>{jobCategoryCards}</Slider>
            </div>)}             
        </div>
    );
}