import React, { useState, useEffect, useContext } from 'react';
import { StyleContext } from '../providers/StyleContextProvider';
import * as xlsx from 'xlsx';
import Swal from 'sweetalert2'
import { useMediaQuery } from 'react-responsive';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { postFormData } from '../services/EmailmanagerServices';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../components/Shared/Pagination';
import { AdminHeader } from '../components/Shared/AdminHeader';
import { EmailManagerModel } from '../models/EmailManager';

export const EmailManager: React.FC = () => {
    const navigate = useNavigate();
    const hasUserSession = sessionStorage.getItem("user");
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("emailManager"));
    const [subject, setSubject] = useState<string>("");
    const [emailBody, setEmailBody] = useState<any>("");
    const [senderEmail, setSenderEmail] = useState<string>("");
    const [senderName, setSenderName] = useState<string>("");
    const [excelFile, setExcelFile] = useState<string>("");
    const [receiverEmails, setReceiverEmails] = useState<string[]>([]);
    const [currentEmails, setcurrentEmails] = useState<string[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [firstEmailIndex, setFirstEmailIndex] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const emailsPerPage: number = 100;

    useEffect(() => {
        if (!hasUserSession) {
            navigate("/login")
        }
        setStyles(styleContext.getComponentStyle("emailManager"));
    }, [isMobile]);

    useEffect(() => {
        let tempFirstEmailIndex = ((pageNumber - 1) * emailsPerPage);
        let tempLastEmailIndex = tempFirstEmailIndex + emailsPerPage;
        let tempCurrentEmails = receiverEmails.slice(tempFirstEmailIndex, tempLastEmailIndex);
        let tempTotalPages = Math.ceil(receiverEmails.length / emailsPerPage);
        setFirstEmailIndex(tempFirstEmailIndex);
        setcurrentEmails(tempCurrentEmails);
        setTotalPages(tempTotalPages);
    }, [pageNumber, receiverEmails]);

    const handlePagination = (pageNumber: number) => {
        setPageNumber(pageNumber);
    };

    function RenderEmailAddresses() {
        return (
            <>
                {currentEmails &&
                    currentEmails.map((email: string, index: number) => (
                        <tr key={index}>
                            <td >{(firstEmailIndex + 1) + index}. {email}</td>
                            <td onClick={() => removeEmail(email)} style={styles.binIcon}><FaTrash /></td>
                        </tr>
                    ))}
            </>
        );
    }

    const formValidation = () => {
        if (subject && emailBody && senderEmail && senderName && receiverEmails.length !== 0) {
            //validate the senderEmail with Regular Experssion
            setSenderEmail(senderEmail.trim());
            if (/\S+@\S+\.\S+/.test(senderEmail)) {
                return true;
            }
            else {
                Swal.fire("Warning!!", "Sender Email is invalid!", "error");
                return false;
            }
        } else {
            Swal.fire("Warning!!", "All the fields are Mandatory!", "error");
            return false;
        }
    }
    const handlePostFormData = async (isTestEmail: boolean = false, testReceiverEmail: string = "") => {
        setIsLoading(true);
        var receiverEmailList = isTestEmail ? [testReceiverEmail] : receiverEmails;
        var formData: EmailManagerModel = {
            subject: subject,
            emailBody: emailBody,
            senderEmail: senderEmail,
            senderName: senderName,
            receiverEmailList: receiverEmailList
        }
        const result = await postFormData(formData);
        setIsLoading(false);
        if(result){
            Swal.fire("Operation Success", "Mailing process has been triggered", "success");
        }
        else{
            Swal.fire("Operation Failed", "There was an error during the process!!", "error")
        }            
    }

    const sendEmails = () => {
        if (formValidation()) {
            Swal.fire({
                title: "Confirmation!",
                text: `You are about to send this Email to '${receiverEmails.length}' Recipients.\nReady to go?`,
                icon: "warning",
                showDenyButton: true,
                confirmButtonText: "Yes"
            }).then((result) => {
                if (result.isConfirmed) {
                    handlePostFormData();
                    navigate("/");
                }
            })
        }
    }

    const resetForm = () => {
        setSubject('');
        setSenderEmail('');
        setSenderName('');
        setEmailBody('');
        setExcelFile('');
        setPageNumber(1);
        setReceiverEmails([]);

    }
    const sendTestEmail = async () => {
        if (formValidation()) {
            var { value: testReceiverEmail } = await Swal.fire({
                title: "Send a Test Email.",
                text: "Receiver's Address : example@mail.com",
                input: "email",
                showCancelButton: true,
                confirmButtonText: "Send"
            });
            if (testReceiverEmail) {
                handlePostFormData(true, testReceiverEmail);
            }
        }
    }

    const convertFileToJson = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const files = event?.target.files;
        if (files) {
            setExcelFile(event.target.value);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                const emailAddresses: string[] = [];
                json.map((emailAddress: any) => {
                    var receiverEmail = emailAddress["Emails"];
                    if (receiverEmail !== undefined && !emailAddresses.includes(receiverEmail)) {
                        emailAddresses.push(emailAddress["Emails"]);
                        return true;
                    } else {
                        return false;
                    }
                });
                if (emailAddresses.length > 0) {
                    setReceiverEmails(emailAddresses);
                    setPageNumber(1);
                }
                else {
                    Swal.fire({
                        title: "Oops...",
                        text: "Please rename the prefered column name to 'Emails'",
                        icon: "error"
                    })
                }
                setIsLoading(false);
            };

            try {
                reader.readAsArrayBuffer(files[0]);
            } catch (error) {
                setIsLoading(false);
            }
        }
    }

    const addNewEmail = async () => {
        const { value: newEmail } = await Swal.fire({
            title: "Add New Recipient:",
            text: "example@email.com",
            input: "email",
            showCancelButton: true,
            confirmButtonText: "Add"
        });
        if (newEmail) {
            if (!receiverEmails.includes(newEmail)) {
                setReceiverEmails([...receiverEmails, newEmail]);
            }
            else {
                Swal.fire("Warning", "This Email is already exist in the list..", "warning");
            }
        }
    }

    const removeEmail = (email: string) => {
        let tempReceiverEmails = [...receiverEmails.filter((i) => i !== email)];
        setReceiverEmails(tempReceiverEmails);
        if (currentEmails.length === 1) {
            setPageNumber(totalPages - 1);
        }
    }

    return (
        <>
            <AdminHeader />
            {isLoading &&
                <div style={styles.loader}>
                    <div style={styles.loader.loaderTextWrapper}>
                        <h1>Hold for a sec.</h1>
                        <p>We are working on it.</p>
                    </div>
                </div>}
            <div style={styles.row}>
                <div style={{ ...styles.wrapper, ...styles.emailsList }}>
                    <h4 style={styles.text}>Fetched Receiver's Email Addresses</h4>
                    <div style={styles.emailListTable}>
                        {receiverEmails.length > 0 ?
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={styles.listItem}>Emails</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <RenderEmailAddresses />
                                    </tbody>
                                </table>

                            </div>
                            : <p>List is empty!<br />Select an Excel document to start the operation..</p>}
                    </div>
                    {receiverEmails.length > 0 && <Pagination page={pageNumber} totalPages={totalPages} handlePagination={handlePagination} />}
                    <div>
                        <label
                            htmlFor='excel-document'
                            style={styles.button}>Select the Excel Document</label>
                        <input type="file"
                            id='excel-document'
                            style={styles.fileInput}
                            value={excelFile}
                            accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                            onChange={async (e) => {
                                await convertFileToJson(e);
                            }} />
                        <button style={styles.button} onClick={addNewEmail}><FaPlus /></button>
                        <label style={styles.emailsCount}>#{receiverEmails.length}</label>
                    </div>
                    <p><i>Make sure you named the email addresses column as "Emails"</i></p>

                </div>
                <div style={{ ...styles.wrapper, ...styles.editorForm }}>
                    <form method='POST' encType="multipart/form-data">
                        <div style={styles.inputGroup}>
                            <label>Subject</label>
                            <input type="text"
                                style={styles.inputField}
                                placeholder={"Enter the Subject.."}
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)} />
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Email Body</label>
                            {/* <Editor
                                apiKey="rc6oq3ha5cttti4gl67myek3wglvs6zgn1b7oao1khzin62f"
                                init={{
                                    icons: "thin",
                                    placeholder: "Type the Email Body here..",
                                    height: 600,
                                    menu: {
                                        file: { title: 'File', items: ' print' },
                                        edit: { title: 'Edit', items: 'undo redo | cut copy | selectall | searchreplace' },
                                        view: { title: 'View', items: ' preview fullscreen' },
                                        insert: { title: 'Insert', items: 'image link media | insertdatetime' },
                                        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | styles fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
                                        tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck wordcount' },
                                        table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
                                        help: { title: 'Help', items: 'help' }
                                    },
                                    plugins:
                                        "image  preview searchreplace fullscreen insertdatetime media wordcount table link"
                                    ,
                                    toolbar: 'undo redo | fontSize fontfamily | forecolor backcolor | bold italic | align | outdent indent | link image | ',
                                }}
                                value={emailBody}
                                onEditorChange={(content: any) => setEmailBody(content)}
                            /> */}
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Sender Email</label>
                            <input type="email"
                                style={styles.inputField}
                                value={senderEmail}
                                placeholder={"fname@xpress.jobs"}
                                onChange={(e) => setSenderEmail(e.target.value)}
                            />
                        </div>
                        <div style={styles.inputGroup}>
                            <label>Sender Name</label>
                            <input type="text"
                                style={styles.inputField}
                                value={senderName}
                                placeholder={"Fname Lname"}
                                onChange={(e) => setSenderName(e.target.value)} />
                        </div>
                        <div>
                            <button style={styles.button} type={'button'} onClick={sendEmails}>Send</button>
                            <button style={styles.button} type={'button'} onClick={resetForm}>Reset</button>
                            <button style={styles.button} type={'button'} onClick={sendTestEmail}>Send Test Email</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    );
}