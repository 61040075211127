import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as EyeOpen } from '../../../assets/svg/eye-open.svg';
import { ReactComponent as EyeClose } from '../../../assets/svg/eye-close.svg';
import { StyleContext } from '../../../providers/StyleContextProvider';
import { InputValidation } from '../../../models/InputValidation';
import { Button } from '../Buttons/Button';
import { ErrorIndicator } from './ErrorIndicator';

interface Props {
    label?: string;
    styles?: any;
    inputType: string;
    id?: string;
    name?: string;
    value: any;
    handleChange?(event?: React.ChangeEvent<HTMLInputElement>): void;
    handleBlur?(event?: React.FocusEvent<HTMLInputElement>): void;
    disabled?: boolean;
    validation?: InputValidation;
    placeHolder?: string    
}

export const TextInput: React.FC<Props> = (props) => {
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("input"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [focused, setFocused] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const validation = props.validation;    

    useEffect(() => {
        let extendedStyles = merge(styles, props.styles);
        setStyles(extendedStyles);
    }, [isMobile]);

    const handleOnBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        props.handleBlur && props.handleBlur(event);
    }

    return (
        <label htmlFor={props.id} style={{ ...styles, ...(focused && !props.disabled && styles.active), ...(validation?.valid && styles.valid), ...(validation?.invalid && styles.invalid) }} onClick={() => setFocused(true)}>
            <span style={{ ...styles.floatingLabel, ...((props.value || focused) && styles.floatingLabel.moveUp) }}>{props.label}</span>
            <input
                type={showPassword ? "text" : props.inputType}
                style={{...styles.inputField, ...(props.label && styles.inputField.moveDown), ...(props.disabled && styles.editDisabled)}}
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                disabled={props.disabled}
                onBlur={handleOnBlur}
                placeholder={props.placeHolder}
                className={props.disabled ? "disabledInput" : ""}
            />            
            {props.inputType === "password" &&
                <Button type={"button"} style={styles.iconButton} onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <EyeOpen style={styles.icon}/> : <EyeClose style={styles.icon}/>}
                </Button>
            }
            {validation?.invalid && <ErrorIndicator invalidMessage={validation.invalidMessage!} styles={styles.inputError}/>}
        </label>
    )
}