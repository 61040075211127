import { Color } from "../enums/Color";
import { Font } from "../enums/Font";

export const button = {
    mobile: {
        radio: {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            border: "2px solid " + Color.Gainsboro,
            padding: "3px",
            margin: "auto",
            cursor: "pointer",
            wrapper: {
                display: "inline-flex"
            },
            inner: {
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundColor: Color.Blue,
                unChecked: {
                    display: "none"
                }
            },
            text: {
                fontFamily: Font.Default,
                fontSize: "16px",
                color: Color.DeepBlack,
                margin: "auto"
            }
        },
        checkBox: {
            position: "absolute" as "absolute",
            borderRadius: "2px",
            marginTop: "3px",
            cursor: "pointer",
            border: "1px solid " + Color.SharpAsh,
            height: "10px",
            width: "10px",
            checked: {
                backgroundColor: Color.Blue,
                height: "12px",
                width: "12px",
                border: "none"
            }
        },
        switch: {
            backgroundColor: Color.SharpGreen,
            borderRadius: "250px",
            cursor: "pointer",
            display: "flex",
            height: "18px",
            width: "40px",
            padding: "2px",
            margin: "auto",
            marginRight: "unset"
        }
    },
    desktop: {
        
    }
}