import React, { useContext, useEffect, useState } from 'react';
import { merge } from 'react-merge';
import { MenuItem } from '../../models/MenuItem';
import { Dropdown } from './Dropdown';
import { useMediaQuery } from 'react-responsive';
import { StyleContext } from '../../providers/StyleContextProvider';

export interface Props {    
    menuItems: MenuItem[];
}

export const DesktopMenu: React.FC<Props> = (props) => {
    const [hoveredItem, setHoveredItem] = useState<number>(-1);
    const styleContext = useContext(StyleContext);
    const [styles, setStyles] = useState<any>(styleContext.getComponentStyle("desktopMenu"));
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

    useEffect(() => {
        let style = styleContext.getComponentStyle("desktopMenu");
        setStyles(style);
    }, [isMobile]);

    return (
        <div style={styles.wrapper}>
            <ul style={styles.list}>
                {props.menuItems.map((menuItem, index) =>
                    <li key={index} style={styles.listItem} onMouseEnter={() => setHoveredItem(index)} onMouseLeave={() => setHoveredItem(-1)}>
                        { menuItem.isList ? <Dropdown styles={merge(styles.dropdown, menuItem.styles)} items={menuItem.items!}><span>{menuItem.label!}</span></Dropdown> : <div style={styles.listItem.text}>{menuItem.item}</div> }
                        {hoveredItem === index && <hr style={styles.listItem.bottomBorder}/>}
                    </li>                    
                )}                
            </ul>
        </div>
    );
}