import React, { useEffect } from "react";

const metaPixelId = process.env.REACT_APP_META_PIXEL_ID;

export const MetaPixel : React.FC = () => {
  useEffect(() => {
    const fbq = (window as any).fbq;
    if (fbq) return;

    (window as any).fbq = function () {
      (window as any).fbq.callMethod
        ? (window as any).fbq.callMethod.apply((window as any).fbq, arguments)
        : (window as any).fbq.queue.push(arguments);
    };

    if (!(window as any)._fbq) (window as any)._fbq = (window as any).fbq;
    (window as any).fbq.push = (window as any).fbq;
    (window as any).fbq.loaded = !0;
    (window as any).fbq.version = "2.0";
    (window as any).fbq.queue = [];

    const t = document.createElement("script");
    t.async = true;
    t.src = "https://connect.facebook.net/en_US/fbevents.js";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(t, s);

    // Initialize Pixel
    (window as any).fbq("init", metaPixelId); 
    (window as any).fbq("track", "PageView");
    }, []);

  return null;
}